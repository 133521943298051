import React, { useState } from "react";

import Chart from "react-apexcharts";
import { Card, CardContent } from "@mui/material";
import { Col, Row } from "react-bootstrap";

const RadilBarServiceSuc = ({
  val = 0,
  label = "",
  titulo = "",
  text = "",
  facturas = 0,
  contadorInferiror = 0,
  isShowContadorInferior = false,
  mes = "",
  heightCard = "491px"
}) => {

 
  var options = {
    chart: {
      height: 280,
      type: "radialBar",
    },

    series: [(val / facturas) * 100],
    colors: ["#3ABE88"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "73%",
        },

        dataLabels: {
          showOn: "always",
          name: {
            offsetY: 40,
            show: true,
            color: "#888",
            fontSize: "20px",
            fontWeight: 500,
          },
          value: {
            offsetY: -20,
            formatter: function (param) { 
              return val;
            },
            color: "#3ABE88",
            fontSize: "50px",
            show: true,
            fontWeight: 600,
          },
        },
      },
    },

    stroke: {
      lineCap: "butt",
    },
    labels: [label],
  };

  return (
    <>
      <Card className="shadow" elevation={3} style={{ height: heightCard }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">{titulo}</h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />

{
            mes != "" && (
              <div >
                <center>
                  <p
                   className="mb-0"
                    style={{
                      color: "black",
                      fontWeight: "AvenirBlack",
                      fontSize: "25px",
                    }}
                  >
                    {mes}
                  </p>
                </center>
              </div>
            )
          }

          <div lassName="p-3 m-3" style={{ marginTop: "-25px" }}>
            <Row className="p-1 m-2">
              <Col>
                <Chart
                  options={options}
                  series={options.series}
                  type="radialBar"
                  height={300}
                />
              </Col>
            </Row>
          </div>

        
          {
            isShowContadorInferior && (
              <div style={{ marginTop: "-25px" }}>
                <center>
                  {
                    /*

 <p
                    style={{
                      color: "black",
                      fontWeight: "AvenirBlack",
                      fontSize: "25px",
                    }}
                  >
                    {text}
                  </p>

                    */
                  }
                 
                  <p
                    style={{
                      color: "#65748B",
                      fontWeight: "AvenirBlack",
                      fontSize: "20px",
                      marginTop: "-20px",
                    }}
                  >
                    {contadorInferiror}
                  </p>
                </center>
              </div>
            )
          }


        </CardContent>
      </Card>
    </>
  );
};
export default RadilBarServiceSuc;
