import React, { useRef, useState } from 'react'
import { parseHtmlToEditor } from '../../../Ayuda/Funciones/Ayuda';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HelperError from '../../../../../Includes/HelperError';
import { ESTILOS } from '../../../../../Lib/Funciones Generales/Colores';
import CargarArchivo from '../../../../../app/Perfil/Componentes/CargarArchivo';
import CargarCertificado from '../../../../../assets/CargarCertificado.svg'
import AccEliminar from '../../../../../assets/EliminarRojo.svg'
import { TIPO_MENSAJE } from '../../../../../Lib/Funciones Generales/Constantes';


const TabMensaje = ({ Values, setValues, Errores, setErrores, handlInputChange, editorState, setEditorState }) => {

    // const file = useRef(null)

    const handleChangeCheck = ({ target }) => {
        setValues({ ...Values, [target.name]: target.checked })
    }

    const handleChangeRadio = ({ target }) => {
        // setValues({ ...Values, [target.name]: target.checked })
        if (target.value == TIPO_MENSAJE.TEXTO) {
            setValues({ ...Values, tipo_mensaje: target.value, html: "" })
        } else {
            setValues({ ...Values, tipo_mensaje: target.value })
        }

    }

    const handleClickBorrarPlantilla = () => {
        setValues({ ...Values, html: '', url_archivo: '' })
    }

    // const handleChangeFile = (event) => {
    //     if (event) {
    //         const reader = new FileReader()
    //         reader.onloadend = () => {
    //             // callback(reader.result)
    //             console.log(reader.result)
    //             setEditorState(parseHtmlToEditor())
    //         }
    //         reader.readAsText(event.target.files[0])
    //     }
    // }

    // const handleClickCargarPlantilla = () => {
    //     file.current.click()
    // }

    return (
        <Row className="">

            <div className="w-100 d-flex justify-content-between mb-3">
                <div>
                    <h6 className="font-AvenirMedium m-0 p-0">
                        Envío del mensaje
                    </h6>
                    <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
                        Configura las opciones que tendrá este envío de mensaje
                    </h6>

                </div>
            </div>

            <Col sm={12} className="px-3 d-flex justify-content-end flex-wrap mb-2">

                <FormControlLabel
                    label="Correo electrónico"
                    control={
                        <Checkbox
                            sx={ESTILOS.checkbox}
                            name='is_envio_email'
                            checked={Values.is_envio_email}
                            onChange={handleChangeCheck}
                        />
                    }
                />
                <FormControlLabel
                    label="SMS"
                    control={
                        <Checkbox
                            sx={ESTILOS.checkbox}
                            name='is_envio_sms'
                            checked={Values.is_envio_sms}
                            onChange={handleChangeCheck}
                        />
                    }
                />
                <FormControlLabel
                    label="WhatsApp"
                    control={
                        <Checkbox
                            disabled
                            sx={ESTILOS.checkbox}
                            name='is_envio_whatsapp'
                            checked={Values.is_envio_whatsapp}
                            onChange={handleChangeCheck}
                        />
                    }
                />
            </Col>

            <Col sm={12} className="px-3 mb-4">
                <TextField
                    fullWidth
                    name="asunto"
                    label="Asunto del mensaje"
                    variant="outlined"
                    value={Values.asunto}
                    error={Errores.asunto}
                    helperText={
                        Errores.asunto ? "Asunto del mensaje no valido" : ""
                    }
                    onChange={handlInputChange}
                />

            </Col>

            <Col sm={12} className="px-3 d-flex justify-content-end flex-wrap">

                <div className='d-flex flex-wrap mt-2 pt-1 me-auto'>
                    <h6 className='font-Avenir mt-0 p-0 pe-1'>
                        Palabras clave:
                    </h6>
                    <h6 className='font-AvenirMedium mt-0 p-0 pe-1'>
                    {'{NOMBRE}'}
                    </h6>
                </div>

                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        checked={Values.tipo_mensaje == TIPO_MENSAJE.TEXTO}
                        value={TIPO_MENSAJE.TEXTO}
                        control={
                            <Radio sx={ESTILOS.checkbox} />
                        }
                        label={TIPO_MENSAJE.TEXTO}
                        onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                        checked={Values.tipo_mensaje == TIPO_MENSAJE.PLANTILLA}
                        value={TIPO_MENSAJE.PLANTILLA}
                        control={
                            <Radio sx={ESTILOS.checkbox} />
                        }
                        label={TIPO_MENSAJE.PLANTILLA}
                        onChange={handleChangeRadio}
                    />
                </RadioGroup>

                {/* <FormControlLabel
                    label="Cargar plantilla"
                    control={
                        <Checkbox
                            sx={ESTILOS.checkbox}
                            name='is_plantilla_personalizada'
                            checked={Values.is_plantilla_personalizada}
                            onChange={handleChangeCheck}
                        />
                    }
                /> */}

                {/* {
                    !Values.is_plantilla_personalizada ? (
                        <LoadingButton
                            loadingPosition="start"
                            onClick={handleClickCargarPlantilla}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            variant="contained"
                            xs={{ with: "100$" }}
                        >
                            <span className={"px-2"}>
                                Cargar plantilla
                            </span>
                        </LoadingButton>
                    ) : (
                        <Button
                            onClick={() => { }}
                            className="btn btn-eliminar font-AvenirBold py-2 px-4"
                            variant="outlined"
                        >
                            Eliminar plantilla
                        </Button>
                    )
                } */}

            </Col>


            {
                Values.tipo_mensaje == TIPO_MENSAJE.TEXTO ? (
                    <Col sm={12} className="px-3 pt-3">
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={(state) => {
                                setEditorState(state)
                                setErrores({ ...Errores, texto: false })
                            }}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName={Errores.texto ? 'wrapperErrorClassName' : 'wrapperClassName'}
                            editorClassName="editorClassName"
                        />
                        <HelperError Error={Errores.texto} Mensaje="Mensaje no valido" />
                    </Col>
                ) : (
                    <Col sm={12} className="px-3 pt-3">
                        {
                            Values.url_archivo == '' ? (
                                <CargarArchivo
                                    leerTexto={true}
                                    archivo={Values.html}
                                    handleChangeFile={(result) => {
                                        setValues({
                                            ...Values,
                                            url_archivo: result.url,
                                            html: result.archivo
                                        })
                                    }}
                                    archivosAceptados={{ "files/html": ['.html'] }}
                                    archivoDefault={CargarCertificado} >
                                    <strong> Cargar plantilla html </strong>
                                    <p className="text-mutted mb-0">
                                        Arrastra el archivo de la plantilla o
                                        <strong style={{ color: "#3ABE88" }}> seleccionala </strong> desde tu ordenador
                                    </p>
                                </CargarArchivo>
                            ) : (
                                <div className='d-flex justify-content-end flex-wrap'>
                                    <Button
                                        onClick={handleClickBorrarPlantilla}
                                        className="btn btn-eliminar font-AvenirBold py-2 px-4 mb-4"
                                        variant="outlined"
                                        style={{ border: 0 }}
                                    >
                                        <img src={AccEliminar} className='me-2' />  Borrar plantilla
                                    </Button>
                                    <iframe style={{ width: '100%', height: '100vh' }} src={Values.url_archivo} />
                                </div>
                                // <iframe style={{ width: '100%', height: '100vh' }} src={Values.url_archivo} />
                            )
                        }


                    </Col>
                )
            }

            <Col sm={12} className="px-3 mb-4 mt-4">
                <TextField
                    fullWidth
                    name="descripcion"
                    label="Descripcion interna"
                    variant="outlined"
                    value={Values.descripcion}
                    onChange={handlInputChange}
                />

            </Col>



            {/* <input ref={file} type='file' accept='.html' onChange={handleChangeFile} /> */}


        </Row>
    )
}

export default TabMensaje