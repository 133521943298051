import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Button, Card, CardContent } from "@mui/material"

import FacturacionDiseno from "../Items/FacturacionDiseno"
import ModalFacturacionDisenoImagen from "../Dialogos/ModalFacturacionDisenoImagen"
import ModalFacturacionDisenoCompra from "../Dialogos/ModalFacturacionDisenoCompra"
import ModalSoporte from "../Dialogos/ModalSoporte"
import ImgDefault from '../../../../assets/FacturaDiseno3p.jpg'

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { defaultCompraPlantilla } from "../../Funciones/FacturacionDiseno"
const MySwal = withReactContent(Swal);

const TabFacturacionDiseno = ({
  Plantillas,
  setPlantillas,
  idPlantillaActiva,
  ValuesGenerales,
  setValuesGenerales
}) => {


  const [showDialogoContacto, setShowDialogoContacto] = useState(false)
  const [showDialogoCompra, setShowDialogoCompra] = useState(false)
  const [showDialogoImagen, setShowDialogoImagen] = useState(false)
  const [plantillaSeleccionada, setPlantillaSeleccionada] = useState(null)


  const handleClickDefault = (ID) => {

    MySwal.fire({
      title: "Confirmación",
      html: '¿Desea asignar este diseño como predeterminado?',
      icon: "info",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
      timer: 15700,
    }).then((result) => {
      if (result.isConfirmed) {
        defaultCompraPlantilla(ID)
          .then((data) => {
            if (data.codigo == "200") {
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: true,
                timer: 15700,
              }).then((result) => {
                setValuesGenerales({ ...ValuesGenerales, plantilla_activa: ID })
              });
            } else {
              MySwal.fire({
                title: "Error",
                html: data.mensaje,
                icon: "info",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: true,
                timer: 15700,
              }).then((result) => {
              });
            }
          })
          .catch((data) => {
            MySwal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "info",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: true,
              timer: 15700,
            }).then((result) => {
            });
          });
      }
    });


  }
  const handleClickComprar = (ID) => {
    setShowDialogoCompra(true)
    setPlantillaSeleccionada(Plantillas.find(x => x.ID == ID))
  }
  const handleClickVerDiseno = (ID) => {
    setShowDialogoImagen(true)
    setPlantillaSeleccionada(Plantillas.find(x => x.ID == ID))
  }


  return (
    <>
      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 pt-3">
          <Row className="p-3">
            <Col sm={12} md={8}>
              <h6 className="mb-0 text-left font-AvenirBold">
                Diseños de factura
              </h6>
              <p>Selecciona el diseño preferido para tu factura</p>
            </Col>
            <Col sm={12} md={4} className="d-flex justify-content-end">
              <Button
                onClick={() => setShowDialogoContacto(true)}
                className="btn btn-Crexendo font-AvenirMedium py-2 px-5 mt-3 mb-3"
                variant="contained"
              >
                Solicitar diseño
              </Button>
            </Col>
          </Row>
          <Row className="p-3 pt-0">
            {
              Plantillas.map((data, index) => (
                <Col xs={6} lg={4} className="mb-3">
                  <FacturacionDiseno
                    key={'plantilla_' + data.ID}
                    imgDefault={ImgDefault}
                    data={data}
                    idPlantillaActiva={idPlantillaActiva}
                    handleClickComprar={handleClickComprar}
                    handleClickDefault={handleClickDefault}
                    handleClickVerDiseno={handleClickVerDiseno} />
                </Col>
              ))
            }
          </Row>


          <div className="px-3">
            <hr />
            <h6 className="mb-0 text-left font-AvenirMedium pt-3 pb-4">
              No podemos reembolsar tu compra una vez que ya se procesó el pago.
            </h6> 
          </div>

        </CardContent>
      </Card>


      {
        showDialogoImagen && plantillaSeleccionada && (
          <ModalFacturacionDisenoImagen
            Plantillas={Plantillas}
            imgDefault={ImgDefault}
            plantillaSeleccionada={plantillaSeleccionada}
            setShowDialogoImagen={setShowDialogoImagen}
            setPlantillaSeleccionada={setPlantillaSeleccionada}
          />
        )
      }

      {
        showDialogoCompra && plantillaSeleccionada && (
          <ModalFacturacionDisenoCompra
            imgDefault={ImgDefault}
            Plantillas={Plantillas}
            setPlantillas={setPlantillas}
            plantillaSeleccionada={plantillaSeleccionada}
            setPlantillaSeleccionada={setPlantillaSeleccionada}
            setShowDialogoCompra={setShowDialogoCompra}
          />
        )
      }

      {
        showDialogoContacto && (
          <ModalSoporte
            Titulo="Solicitar diseño"
            Asunto="Solicitud de nuevo diseño"
            EndPoint='perfil/SolicitarPlantilla'
            setShowDialogoSoporte={setShowDialogoContacto}
            ValuesGenerales={ValuesGenerales}
          />
        )
      }

    </>
  )
}

export default TabFacturacionDiseno