export const IMG = {
  TIPO_USUARIOS: "usuarios",
  TIPO_EMPRESA: "empresas",
  TIPO_PLANTILLAS: "plantillas",
  TIPO_ADDENDAS: "addendas",
  TIPO_USUARIOSISTEMA: "usuariossistema",
  TIPO_BANNER: "banner",
  TIPO_MAILING: "mailing"
};

export const RECEPTOR_GLOBAL = {
  ID: 0,
  RAZON_SOCIAL: 'PUBLICO EN GENERAL',
  RFC: 'XAXX010101000',
  USO_CFDI: "S01",
  REGIMEN_FISCAL_CLAVE: "616",
  REGIMEN_FISCAL_CLAVE_BIMESTRAL: "621",
  REGIMEN_FISCAL: "Sin obligaciones fiscales",
  REGIMEN_FISCAL_BIMESTRAL: "Incorporación Fiscal",
}


export const TIPO_TARJETA_SAT = {
  CREDITO: '04',
  DEBITO: '28',
}

export const TIPO_TARJETA = {
  CREDITO: "Crédito",
  DEBITO: "Débito",
};

export const TIPO_FACTURA = {
  LICENCIA: "LICENCIAS",
  ADDENDA: "ADDENDAS",
  PLANTILLA: "PLANTILLAS"
};

export const TIPO_MENSAJE = {
  TEXTO: "Texto",
  PLANTILLA: "Plantilla",
};

export const REGISTRO = {
  GOOGLE: "Google",
  FORMULARIO: "Registro",
  FACEBOOK: "Facebook"
};

export const SocketReceptor = {
  EMPRESA: 0,
  CLIENTE: 1,
  MANAGER: 2,
};

//Listas estaticas para los select del modulo de productos
export const TiposProductos = [
  { label: "Tipo de producto", value: "0" },
  { label: "Insumo", value: "1" },
  { label: "Materia prima", value: "2" },
  { label: "Producto terminado", value: "3" },
  { label: "Servicio", value: "4" },
];

export const PeriodicidadSat = [
  { label: "Periodicidad", value: "00" },
  { label: "01-Diaria", value: "01" },
  { label: "02-Semanal", value: "02" },
  { label: "03-Quincenal", value: "03" },
  { label: "04-Mensual", value: "04" },
  { label: "05-Bimestral", value: "05" },
];

export const MesesSat = [
  { label: "Mes", value: "00" },
  { label: "01-Enero", value: "01" },
  { label: "02-Febrero", value: "02" },
  { label: "03-Marzo", value: "03" },
  { label: "04-Abril", value: "04" },
  { label: "05-Mayo", value: "05" },
  { label: "06-Junio", value: "06" },
  { label: "07-Julio", value: "07" },
  { label: "08-Agosto", value: "08" },
  { label: "09-Septiembre", value: "09" },
  { label: "10-Octubre", value: "10" },
  { label: "11-Noviembre", value: "11" },
  { label: "12-Diciembre", value: "12" }
];

export const MesesBimestralSat = [
  { label: "Mes", value: "00" },
  { label: "13-(Enero y Febrero)", value: "13" },
  { label: "14-(Marzo y Abril)", value: "14" },
  { label: "15-(Mayo y Junio)", value: "15" },
  { label: "16-(Julio y Agosto)", value: "16" },
  { label: "17-(Septiembre y Octubre)", value: "17" },
  { label: "18-(Noviembre y Diciembre)", value: "18" },
];

export const TiposSeries = [ 
  { label: "Facturas CFDI", value: "FACTURA" },
  { label: "Complementos de pago", value: "PAGO" },
];
export const Impuestos = [
  { label: "I.V.A. 16%", value: "16" },
  { label: "I.V.A. 8%", value: "8" },
  { label: "Excento de I.V.A.", value: "-1" },
  { label: "Tasa cero", value: "0" },
];
export const ObjetosImpuestos = [
  { label: "Automático", value: "00" },
  { label: "01 - No objeto de impuesto", value: "01" },
  { label: "02 - Si objeto de impuesto", value: "02" },
  { label: "03 - Si objeto del impuesto y no obligado al desglose", value: "03" },
  { label: "04 - Si objeto del impuesto y no causa impuesto", value: "04" },
];
export const TipoDescuento = [
  { label: "Porcentaje", value: 1 },
  { label: "Neto", value: 2 },
];
export const TipoPersonaSat = [
  { label: "Tipo de persona", value: 0 },
  { label: "Fisica", value: 1 },
  { label: "Moral", value: 2 },
];