///////////////////
/* FUNCIONES GET */

import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";
import { validarUUID } from "../../../../Lib/Funciones Generales/Generales";

///////////////////
export const getFacturas = async (
  estatus,
  fecha_inicio = "",
  fecha_fin = ""
) => {
  let data = [];
  let queryParamsObj = {
    estatus: estatus,
    fecha_inicio,
    fecha_fin,
  };

  const url = `Facturacion/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const enviarFactura = async (Values, Errores, setErrores, tipo) => {
  if (Values.id != undefined) {
    let data = [];
    if (Values.correos.trim() == "") {
      Errores.correos = true;
      setErrores(Errores);
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El campo de corres no es valido" });
      });
    }
    let queryParamsObj = { id_factura: Values.id, correo: Values.correos, observaciones: Values.comentarios };
    const url = tipo == "FACTURA" ? `facturacion/EnviarFacturaCorreo?${stringify(queryParamsObj)}`
      : `pagos/EnviarFacturaCorreo?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const cancelarFactura = async (Values, Errores, setErrores) => {
  if (Values.id != undefined) {
    let data = [];
    if (Values.motivo.trim() == "") {
      Errores.correos = true;
      setErrores(Errores);
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El motivo es requerido" });
      });
    }
    if (Values.UUID_SUSTITUCION.trim() != "") {
      if (!validarUUID(Values.UUID_SUSTITUCION.trim())) {
        Errores.correos = true;
        setErrores(Errores);
        return new Promise((resolve, reject) => {
          resolve({ mensaje: "El campo de UUID de sustitución no es valido" });
        });
      }
    }
    Values.observaciones = String(Values.observaciones);

    let queryParamsObj = Values;
    const url = Values.tipo == "FACTURA" ? `facturacion/cancelarFactura`
      : `pagos/cancelarFactura`;

    const res = await postdData(url, Values);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const descargarXML = (id, uuid = "", xmltext = "") => {
  var filename = "Factura_" + id + ".xml";
  var pom = document.createElement("a");
  var bb = new Blob([xmltext], { type: "text/plain" });

  pom.setAttribute("href", window.URL.createObjectURL(bb));
  pom.setAttribute("download", filename);

  pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":");
  pom.draggable = true;
  pom.classList.add("dragout");

  pom.click();
};

export const getDatosEmisor = async () => {
  let data = [];
  const url = `perfil/getDatosFacturacion`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const em = JSON.parse(res.data.respuesta.emisor);
      let values = {
        emisor: em.length > 0 ? em[0] : {},
      };
      resolve(values);
    } else {
      reject([]);
    }
  });
};

export const timbrarCFDI = async (
  Values,
  Errores,
  setErrores,
  productosCarrito,
  datosReceptor,
  pagado

) => {
  let error = false;

  Values.id_cliente = datosReceptor.id;

  if (!Values.is_factura_global) {


    if (!Values.id_cliente || Values.id_cliente == "" || Values.id_cliente == 0) {
      Errores.id_cliente = true;
      error = true;
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Selecciona un receptor" });
      });
    }
  }

  const valoresConceptos = productosCarrito
    .filter((row) => {
      return row.CANTIDAD > 0; // Filtrar solo los elementos con cantidad mayor a 0
    })
    .map((row) => ({
      ID: row.ID,
      CANTIDAD: row.CANTIDAD,
      PRECIO: row.PRECIO_SIN_IVA,
    }));



  Values.conceptos = valoresConceptos;

  if (Values.fecha_emision == "") {
    Errores.fecha_emision = true;
    error = true;
  }

  if (!Values.tipo_comprobante || Values.tipo_comprobante == "") {
    Errores.tipo_comprobante = true;
    error = true;
  }

  if (!Values.serie || Values.serie == "" || Values.serie == undefined) {
    Errores.serie = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.moneda || Values.moneda == "" || Values.moneda == undefined) {
    Errores.moneda = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.moneda != "MXN") {
    if (
      !Values.tipo_cambio ||
      Values.tipo_cambio == "" ||
      Values.tipo_cambio == undefined
    ) {
      Errores.tipo_cambio = true;
      setErrores(Errores);
      error = true;
    }
  } else {
    Values.tipo_cambio = 0;
  }

  if (!Values.forma_pago || Values.forma_pago == "") {
    Errores.forma_pago = true;
    error = true;
  }

  if (!Values.metodo_pago || Values.metodo_pago == "") {
    Errores.metodo_pago = true;
    error = true;
  }

  if (!Values.uso_cfdi || Values.uso_cfdi == "") {
    Errores.uso_cfdi = true;
    error = true;
  }

  if (Values.conceptos == 0) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Selecciona al menos un concepto" });
    });
  }

  if (Values.is_factura_global) {

    if (!Values.periodicidad_global || Values.periodicidad_global == "" || Values.periodicidad_global === "00") {
      Errores.periodicidad_global = true;
      setErrores(Errores);
      error = true;
    }
    if (!Values.mes_global || Values.mes_global == "" || Values.mes_global === "00") {
      Errores.mes_global = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.anio_global || Values.anio_global == "" || Values.anio_global === "00") {
      Errores.anio_global = true;
      setErrores(Errores);
      error = true;
    }
  }

  // console.log("Values: ", Values.periodicidad_global)
  // console.log("Errores: ", Errores, error)
  // return;

  Values.pagado = pagado;

  if (!error) {
    let data = [];
    Values.regimen_fiscal = String(Values.regimen_fiscal);
    const body = { ...Values, anio_global: String(Values.anio_global) };
    const url = `Facturacion/timbrarCFDI`;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
