import { useRef } from "react";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";
import { Button } from "@mui/material";

const Addenda = ({
  data,
  handleClickComprar,
  imgDefault,
  isVisibleBotones = true,
}) => {
  const refElement = useRef(null);
  const handleMouseEnter = () => {
    refElement.current.style.border = `3px solid ${PALETA.colorPrimary}`;
  };

  const handleMouseLeave = () => {
    refElement.current.style.border = `3px solid ${PALETA.colorDivision}`;
  };

  return (
    <div
      className="w-100 h-100"
      ref={refElement}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        borderRadius: 15,
        border: `3px solid ${PALETA.colorDivision}`,
        minHeight: 291,
      }}
    >
      <div className="p-4 h-100 d-flex flex-wrap">
        <img
          className="mt-auto"
          src={data.IMG == "" ? imgDefault : data.IMG}
          style={{ width: "50%" }}
        />

        <div className="w-100 mt-auto">
          <div className="w-100">
            <div className="w-100 d-flex align-items-center mt-3">
              <h4 className="font-AvenirBold m-0 me-2">
                {data.PRECIO === 0 ? "Gratis" : formatMoneda(data.PRECIO) + " MXN"}
              </h4>
            </div>

            <h5 className="font-AvenirBold m-0 mt-3 w-100">{data.NOMBRE}</h5>
          </div>

          <div className="w-100" style={{ height: 90 }}>
            {isVisibleBotones && (
              <>
                {!data.ID_COMPRA && data.PRECIO != 0 && (
                  <Button
                    onClick={() => handleClickComprar(data.ID)}
                    className="btn btn-Crexendo font-AvenirMedium py-2 px-5 mt-4 mb-3"
                    variant="contained"
                  >
                    Comprar
                  </Button>
                )}
                {data.ID_COMPRA && <Button className="btn btn-cancelar font-AvenirMedium py-2 px-5 mt-4 mb-3"
                  variant="outlined" >Adquirida</Button>}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addenda;
