import { useContext } from "react";
import { createContext, useState } from "react";

const PermissionsContext = createContext();

const PermissionsProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);

  const handleSetUserPermissions = (permissions) => {
    let Permisos = [];
    const x = permissions.map((row) => {
      // console.log("Data", row.permisos);
      row.permisos.map((row2) => {
        if (row2.tienepermiso) {
          // console.log("Tiene acceso");
          permissions.push(row2.nombre);
          setUserPermissions(permissions);
        }
      });
    });

    // setUserPermissions(Permisos);
  };

  return (
    <PermissionsContext.Provider
      value={{
        userPermissions,
        setUserPermissions,
        handleSetUserPermissions,
        IsLoading,
        setIsLoading,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

const usePermisos = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("useCartInfo must be used within a PermissionsContext");
  }
  return context;
};

export { PermissionsProvider, usePermisos };
