import { getData, postdData } from "../../../Context/backend";

export const getInfFacturacion = async () => {
  let data = [];
  const url = `ConfigFacturacion/Consultar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró la información de esta configuración",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: String(data.ID),
          rfc: String(data.RFC),
          razon_social: String(data.RAZON_SOCIAL),
          regimen_fiscal: String(data.REGIMEN),
          cp: String(data.CP),
          cer_vigencia: String(data ? data.CER_VIGENCIA : ""),
          archivoCer: {
            archivo: "",
            nombre: "",
            loading: false,
          },
          archivoKey: {
            archivo: "",
            nombre: "",
            loading: false,
          },
          cer_pass: "",
          cargar_certificados: data
            ? data.CER_ACTUALIZADO != ""
              ? false
              : true
            : true,
          is_carga_sertificados: data
            ? data.CER_ACTUALIZADO != ""
              ? true
              : false
            : false,
        };

        resolve({ Value });
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardarInfoFacturacion = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.razon_social || Values.razon_social == "") {
    Errores.razon_social = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.regimen_fiscal || Values.regimen_fiscal == "") {
    Errores.regimen_fiscal = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.rfc || Values.rfc == "") {
    Errores.rfc = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.cp || Values.cp == "") {
    Errores.cp = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "ConfigFacturacion/Editar"
        : "ConfigFacturacion/Editar";

    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const guardarNegCertificados = async (Values) => {
  let data = [];
  const body = {
    archivoCer: Values.archivoCer,
    archivoKey: Values.archivoKey,
    cer_pass: Values.cer_pass,
    rfc: Values.rfc,
    razon_social: Values.razon_social,
  };

  const url = "ConfigFacturacion/EditarCertificados";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
