import React from "react";

import whatsflotante from "../Assets/8WhatsAppFlotante.svg";
function FooterFlotante() {
  return ( 
    <a href="https://wa.me/3326016638" target="_blank" className="footerflotante">
      <img src={whatsflotante} />
    </a>
  );
}

export default FooterFlotante;
