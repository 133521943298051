import { Card, CardContent } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";

const ChartTab = ({ ingresosSemanales, height }) => {
  return (
    <>
      <Card className="shadow" elevation={3} style={{ height: height }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Ingresos por semana
            </h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />
          <div lassName="p-3 m-3">
            <Row className="p-1 m-2 mt-4">
              {ingresosSemanales != undefined ? (
                ingresosSemanales.map((data, index) => (
                  <Col
                    key={data.semana}
                    xs={12}
                    sm={12}
                    md={12}
                    className="mb-4"
                  >
                    <h6 className="font-AvenirBold m-0">Semana {index + 1}</h6>
                    <h5
                      className="font-AvenirBold m-0"
                      style={{
                        color: "#3ABE88",
                      }}
                    >
                      Ganancias:{" "}
                      {data.CANTIDAD === null ? 0 : formatMoneda(data.CANTIDAD)}
                    </h5>
                    <p
                      className="m-0 text-muted font-Avenir"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {" "}
                      Del {data.RANGO} de {data.MES}
                    </p>
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
export default ChartTab;
