import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { guardar, getInfoPerfil } from "../Funciones/Perfiles";
import { IconContext } from "../../Includes/Menu";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import ImagenDefault from "../../../assets/EditarImagen.svg";
import { PALETA } from "../../../Lib/Funciones Generales/Colores";
import UpdatePassword from "./UpdatePassword";

const MySwal = withReactContent(Swal);

export default function Perfil() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");



  const [perfilType, setPerfilType] = useState(true);
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    img: ImagenDefault,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
  });

  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = useState(false);
  const avatar = useContext(IconContext);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    // let random = "";
    // if (Values.id != "0" && Values.id != 0) {
    //   random = "?" + Math.floor(Math.random() * 1000000);
    // }
    setValues({ ...Values, img: img });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  // useEffect(() => {
  //   if (Values.img != "" && Values.img != usuario.Values.img) {
  //     // console.log("IMAGEN DE PERFIL; ", Values.img, usuario.Values.img)
  //     usuario.setImgPerfilHeader(Values.img)
  //   }
  // }, [Values.img])

  useEffect(() => {
    document.title = "Perfil";
    getInfoPerfil(ImagenDefault)
      .then((resp) => {
        setValues(resp.Values);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  const GuardarCliente = () => {
    //setPerfilType(!perfilType);
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, perfilType)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == ImagenDefault ? ImagenDefault : Values.img;

  avatar.setUrl(Values.img == ImagenDefault ? ImagenDefault : Values.img);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Card className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Información de la cuenta</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">
              <Col
                sm={12}
                className="d-flex flex-wrap align-items-center px-3"
              >
                <Avatar
                  sx={{ width: 80, height: 80 }}
                  src={img_url}
                />
                <p
                  className="text-center my-0 ms-3 font-AvenirBold"
                  style={{ color: PALETA.colorPrimary, cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar
                </p>
              </Col>
              <Col sm={12} className="p-3 mt-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 100 }}
                />
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_paterno"
                  label="Apellido paterno"
                  variant="outlined"
                  value={Values.apellido_paterno}
                  error={Errores.apellido_paterno}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 100 }}
                />
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_materno"
                  label="Apellido materno"
                  variant="outlined"
                  value={Values.apellido_materno}
                  error={Errores.apellido_materno}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 100 }}
                />
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo"
                  variant="outlined"
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                  disabled
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  value={(Values.telefono === 0 || Values.telefono === "0") ? '' : Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 10 }}
                />
              </Col>
            </Col>

            <Col sm={12} className="d-flex justify-content-end ">
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={GuardarCliente}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : "Guardar"}
                </span>
              </LoadingButton>
            </Col>
          </Row>
        </CardContent>
      </Card>


      <UpdatePassword />


      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_USUARIOSISTEMA}
          Titulo="Cargar imagen de perfil"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
          ConfigRecortar={{
            requerida: true,
            aspectoAlto: 1,
            aspectoAncho: 1
          }}
        />
      )}
    </>
  );
}
