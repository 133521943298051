import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Avatar,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Tab,
  Tabs,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarEnvioMasivo, getInfEnvioMasivo } from "../Funciones/EnvioMasivo";
import ImagenDefault from "../../../../assets/EditarImagen.svg";
import TabDestinatarios from "./Tabs/TabDestinatarios";
import TabMensaje from "./Tabs/TabMensaje";
import "../../../../Lib/Funciones Generales/Prototipos";
import { TIPO_MENSAJE } from "../../../../Lib/Funciones Generales/Constantes";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import { parseHtmlToEditor } from "../../Ayuda/Funciones/Ayuda";

const MySwal = withReactContent(Swal);

const EnvioMasivo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [editorState, setEditorState] = useState(parseHtmlToEditor(''));

  const [openModal, setOpenModal] = useState(false);
  const [Values, setValues] = useState({
    id: id ?? "0",
    descripcion: "",
    is_envio_email: false,
    is_envio_sms: false,
    is_envio_whatsapp: false,
    is_plantilla_personalizada: false,
    is_todos_usuarios: false,
    destinatarios: [],
    destinatarios_eliminados: [],
    texto: "",
    asunto: "",
    url_archivo: "",
    html: "",
    tipo_mensaje: TIPO_MENSAJE.TEXTO
  });

  const [indexTab, setIndexTab] = useState(0)

  const [Errores, setErrores] = useState({
    nombre: false,
    precio: false,
    costo: false,
    descripcion: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name === "precio") {
      NewValue = { ...NewValue, [Name]: Value.toDecimal() };
    }

    if (Name == "is_personalizada") {
      NewValue = { ...NewValue, is_personalizada: Value };
    }

    setValues(NewValue);

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfEnvioMasivo(id, ImagenDefault)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setEditorState(parseHtmlToEditor(resp.html))
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarEnvioMasivo = () => {
    setIsGuardando(true);
    // console.log(editorState.getCurrentContent().getPlainText())
    guardarEnvioMasivo(Values, Errores, setErrores, editorState)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  // console.log("ID:", Values.destinatarios)

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Envío masivo
      </Button>


      <div className="d-flex flex-wrap justify-content-between mb-3">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={indexTab}
          onChange={(event, index) => setIndexTab(index)}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
        >
          <Tooltip
            title="Agregar destinatarios"
            placement="top">
            <Tab
              className="font-font-AvenirMedium" style={{ textTransform: 'none' }}
              label={<span style={{ fontSize: "12px" }}>Destinatarios</span>}
            />
          </Tooltip>

          <Tooltip
            title="Configurar mensaje de envío"
            placement="top">
            <Tab
              className="font-font-AvenirMedium" style={{ textTransform: 'none' }}
              label={<span style={{ fontSize: "12px" }}>Mensaje</span>}
            />
          </Tooltip>
        </Tabs>
      </div>



      <Card elevation={3} className="mb-4 shadow">
        <CardContent className="p-0">
          <div className="d-flex justify-content-between flex-wrap p-3">
            <h6 className="font-AvenirBold">
              {!id ? 'Nuevo envío masivo' : 'Editar envío masivo'}
            </h6>
            <div className="d-flex ">
              <h6 className="font-AvenirBold me-1" style={{ color: PALETA.colorPrimary }}>Destinatarios:</h6>
              <h6 className="font-AvenirBold">
                {
                  Array.isArray(Values.destinatarios) ? Values.destinatarios.length : 0
                }
              </h6>
            </div>

          </div>
          <Divider />
          <div className="p-3">
            <Row className="p-0 mb-2">

              <Col sm={12} >
                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <>
                    {
                      indexTab == 0 && (
                        <TabDestinatarios
                          Values={Values}
                          setValues={setValues}
                          Errores={Errores}
                          handlInputChange={handlInputChange}
                        />
                      )
                    }

                    {
                      indexTab == 1 && (
                        <TabMensaje
                          editorState={editorState}
                          setEditorState={setEditorState}
                          Values={Values}
                          setValues={setValues}
                          Errores={Errores}
                          setErrores={setErrores}
                          handlInputChange={handlInputChange}
                        />
                      )
                    }
                  </>
                )}
              </Col>
            </Row>

            <Stack
              className="p-3 d-flex justify-content-end w-100"
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarEnvioMasivo()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                </span>
              </LoadingButton>
              <Button
                onClick={() => navigate(-1)}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                variant="outlined"
              >
                Cancelar
              </Button>
            </Stack>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default EnvioMasivo;
