import { useRef } from "react";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";
import IcoVersionGratuita from "../../../../assets/VersionGratuita.svg";
import IcoVersionPagada from "../../../../assets/VersionPagada.svg";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";

export const LabelIncluye = ({ titulo }) => {
  return (
    <div className="d-flex mb-4">
      <CheckIcon className="me-3" style={{ color: PALETA.colorTexto }} />
      <h6
        className="font-AvenirMedium text-star m-0"
        style={{ overflowWrap: "break-word" }}
      >
        {titulo}
      </h6>
    </div>
  );
};

const Suscripcion = ({
  data,
  setIdPlanSelec,
  setShowDialogoDetalle,
  planSeleccionado = 0,
  isVistaDetalle = false,
  isVisibleBoton = false,
  periodo = "anual",
}) => {
  const refElement = useRef(null);
  const handleMouseEnter = () => {
    refElement.current.style.border = `3px solid ${PALETA.colorPrimary}`;
  };
  const handleMouseLeave = () => {
    refElement.current.style.border = `3px solid ${PALETA.colorDivision}`;
  };

  return (
    <div
      className="w-100 h-100"
      ref={refElement}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ borderRadius: 15, border: `3px solid ${PALETA.colorDivision}` }}
    >
      <div
        className="p-3 w-100 mb-4"
        style={{
          borderTopRightRadius: data.ID == planSeleccionado ? 12 : 15,
          borderTopLeftRadius: data.ID == planSeleccionado ? 12 : 15,
          backgroundColor:
            data.ID == planSeleccionado ? PALETA.colorPrimary : "#FFF",
          minHeight: 51.19,
        }}
      >
        <h6 className="w-100 text-white text-center font-AvenirBold m-0">
          {data.ID == planSeleccionado ? "SUSCRIPCIÓN ACTUAL" : ""}
        </h6>
      </div>

      <div className="px-4 d-flex flex-wrap">
        <div className="w-100 mb-4">
          <img
            src={data.PRECIO == 0 ? IcoVersionGratuita : IcoVersionPagada}
            width={70}
          />
        </div>

        <div className="w-100 mt-3 mb-5">
          <div className="w-100 d-flex">
            <h6 className="font-AvenirBold m-0 me-2">
              {data.ID == 0 ? (
                "GRATIS"
              ) : (
                <>
                  {formatMoneda(
                    periodo == "anual"
                      ? data.PRECIO_ANUAL
                      : data.PRECIO_SEMESTRAL
                  )}{" "}
                  MXN
                </>
              )}
            </h6>

            {data.ID == 0 ? (
              ""
            ) : (
              <>
                {periodo != "" && (
                  <p className="m-0 text-muted" style={{ fontSize: 12 }}>
                    {" "}
                    / {periodo}
                  </p>
                )}
              </>
            )}
          </div>
          <h6
            className="font-AvenirBold m-0 mt-2 mb-auto w-100"
            style={{ minHeight: 58 }}
          >
            {data.NOMBRE}
          </h6>
        </div>

        {!isVistaDetalle && (
          <h6
            onClick={() => setShowDialogoDetalle(true)}
            className="font-AvenirBold m-0 mt-auto mb-4 w-100"
            style={{ color: PALETA.colorPrimary, cursor: "pointer" }}
          >
            Más detalles
          </h6>
        )}
      </div>

      {isVistaDetalle && (
        <div className="px-4" style={{ marginTop: -30 }}>
          <h6 className="font-AvenirMedium m-0 text-muted">
            Este plan incluye
          </h6>
          <hr className="my-3" />

          <LabelIncluye
            titulo={`${
              data.PRODUCTOS_INCLUIDOS +
              " " +
              (data.PRODUCTOS_INCLUIDOS != 1 ? "productos" : "producto")
            }`}
          />
          <LabelIncluye
            titulo={`${
              data.CLIENTES_INCLUIDOS +
              " " +
              (data.CLIENTES_INCLUIDOS != 1 ? "clientes" : "cliente")
            }`}
          />
          <LabelIncluye
            titulo={`${ 
                 data.FACTURAS_POR_MES + (data.FACTURAS_POR_MES==1 ?" factura":" facturas")+" al mes no acumulables" 
            }`}
          />
          <LabelIncluye titulo={`Soporte técnico`} />
          <LabelIncluye titulo={`1 año de almacenamiento de tus facturas`} />
        </div>
      )}

      {isVistaDetalle &&
        data.ID != 0 &&
        data.ID != planSeleccionado &&
        isVisibleBoton && (
          <div className="px-3">
            <LoadingButton
              onClick={() => setIdPlanSelec(data.ID)}
              loadingPosition="start"
              className="btn btn-Crexendo font-AvenirMedium w-100  py-2 mt-3  mb-4"
              xs={{ with: "100%" }}
            >
              <span className={" "}>Obtener plan</span>
            </LoadingButton>
          </div>
        )}
    </div>
  );
};

export default Suscripcion;
