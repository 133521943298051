import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import FormasDePago from "./../../../../SAT/Componentes/FormasDePagoSat";
import SelectSeries from "../../../Catalogos/Series/Componentes/SelectSeries";
import UsoCFDI from "./../../../../SAT/Componentes/UsoCFDI";

const DatosCFDI = ({ Values, Errores, handlInputChange }) => {

  return (
    <Row className="p-3">
      <Col xs={12} md={4} lg={3} className="p-3">
        <FormControl fullWidth>
          <InputLabel id="demo-select-small-label">Fecha de emisión</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={Values.fecha_emision}
            error={Errores.fecha_emision}
            label="Fecha de emisión"
            onChange={handlInputChange}
            fullWidth
            name="fecha_emision"
            defaultValue={"0"}
          >
            <MenuItem value={"0"}> Actual </MenuItem>
            <MenuItem value={"-1"}>1 día antes</MenuItem>
            <MenuItem value={"-2"}>2 días antes</MenuItem>
            <MenuItem value={"-3"}>3 días antes</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col xs={12} md={4} lg={3} className="p-3">
        <SelectSeries
          tipo="FACTURA"
          name="serie"
          Value={Values.serie}
          Error={Errores.serie}
          handlInputChange={handlInputChange}
        />
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3">
        <FormControl fullWidth>
          <InputLabel id="demo-select-small-label">
            Tipo de comprobante
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={Values.tipo_comprobante}
            error={Errores.tipo_comprobante}
            label="Tipo de comprobante"
            onChange={handlInputChange}
            fullWidth
            name="tipo_comprobante"
            defaultValue={"0"}
          >
            <MenuItem value={"I"}>I - Ingreso </MenuItem>
            <MenuItem value={"E"}>E - Egreso</MenuItem>
          </Select>
        </FormControl>
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3">
        <UsoCFDI
          showCodigos={true}
          Value={Values.uso_cfdi}
          handlInputChange={handlInputChange}
          Error={Errores.uso_cfdi}
        />
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3">
        <FormasDePago
          showCodigos={true}
          Value={Values.forma_pago}
          Error={Errores.forma_pago}
          handleInputChange={handlInputChange}
          disabled={Values.metodo_pago == "PPD"}
        />
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3">
        <FormControl fullWidth>
          <InputLabel id="demo-select-small-label">Método de pago</InputLabel>
          <Select
            disabled={Values.is_factura_global}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={Values.metodo_pago}
            error={Errores.metodo_pago}
            label=" Método de pago"
            onChange={handlInputChange}
            fullWidth
            name="metodo_pago"
          >
            <MenuItem value={"PUE"}>PUE- Pago en una sola exhibición</MenuItem>
            <MenuItem value={"PPD"}> PPD - Pago en parcialidades</MenuItem>
          </Select>
        </FormControl>
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3">
        <FormControl fullWidth>
          <InputLabel id="demo-select-small-label">Moneda</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={Values.moneda}
            error={Errores.moneda}
            label="Moneda"
            onChange={handlInputChange}
            fullWidth
            name="moneda"
            defaultValue={"0"}
          >
            <MenuItem value={"MXN"}>MXN - Pesos Mexicanos </MenuItem>
            <MenuItem value={"USD"}>USD - Dolares</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col xs={12} md={4} lg={3} className="p-3">
        <TextField
          fullWidth
          disabled={Values.moneda == "MXN"}
          name="tipo_cambio"
          value={Values.tipo_cambio}
          label="Tipo de cambio"
          onChange={handlInputChange}
        />
      </Col>

      <Col xs={12} className="p-3 py-1">
        <Divider></Divider>
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3 ">
        <TextField
          fullWidth
          name="condicion_pago"
          value={Values.condicion_pago}
          label="Condiciones de pago"
          onChange={handlInputChange}
        />
      </Col>
      <Col xs={12} md={4} lg={3} className="p-3">
        <FormControl fullWidth>
          <InputLabel id="demo-select-small-label">Documento relacionado</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={Values.doc_relacionado}
            error={Errores.doc_relacionado}
            label="UUID Relacionado"
            onChange={handlInputChange}
            fullWidth
            name="doc_relacionado"
            defaultValue={"0"}
          >
            <MenuItem value={"00"}>
              Sin relación
            </MenuItem>
            <MenuItem value={"01"}>
              01 - Nota de crédito de los documentos relacionados
            </MenuItem>
            <MenuItem value={"02"}>
              02 - Nota de débito de los documentos relacionados
            </MenuItem>
            <MenuItem value={"03"}>
              03 - Devolución de mercancía sobre facturas o traslados previos
            </MenuItem>
            <MenuItem value={"04"}>
              04 - Sustitución de los CFDI previos
            </MenuItem>
            <MenuItem value={"05"}>
              05 - Traslados de mercancías facturados previamente
            </MenuItem>
            <MenuItem value={"06"}>
              06 - Factura generada por los traslados previos
            </MenuItem>
            <MenuItem value={"07"}>
              07 - CFDI por aplicación de anticipo
            </MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col xs={12} md={4} lg={3} className="p-3">
        <TextField
          fullWidth
          name="uuid_relacion"
          value={Values.uuid_relacion}
          label="UUID Relacionado"
          onChange={handlInputChange}
        />
      </Col>

      <Col xs={12} className="p-3">
        <TextField
          fullWidth
          multiline
          rows={2}
          value={Values.observaciones}
          label={"Observaciones"}
          inputProps={{ maxLength: 1500 }}
          name="observaciones"
          onChange={handlInputChange}
        />
      </Col>
    </Row>
  );
};

export default DatosCFDI;