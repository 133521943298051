///////////////////
/* FUNCIONES GET */

import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";
import { validarUUID } from "../../../../Lib/Funciones Generales/Generales";
import dayjs from "dayjs";

///////////////////
export const getPagos = async (id_factura) => {
  let data = [];
  let queryParamsObj = {
    id_factura: id_factura,
  };
  const url = `pagos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const enviarFactura = async (Values, Errores, setErrores) => {
  if (Values.id != undefined) {
    let data = [];
    if (Values.correos.trim() == "") {
      Errores.correos = true;
      setErrores(Errores);
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El campo de corres no es valido" });
      });
    }
    let queryParamsObj = { id_factura: Values.id, correo: Values.correos };
    const url = `pagos/EnviarFacturaCorreo?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const cancelarFactura = async (Values, Errores, setErrores) => {
  if (Values.id != undefined) {
    let data = [];
    if (Values.motivo.trim() == "") {
      Errores.correos = true;
      setErrores(Errores);
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El motivo es requerido" });
      });
    }
    if (Values.UUID_SUSTITUCION.trim() != "") {
      if (!validarUUID(Values.UUID_SUSTITUCION.trim())) {
        Errores.correos = true;
        setErrores(Errores);
        return new Promise((resolve, reject) => {
          resolve({ mensaje: "El campo de UUID de sustitución no es valido" });
        });
      }
    }
    Values.observaciones = String(Values.observaciones);

    let queryParamsObj = Values;
    const url = `pagos/cancelarFactura`;
    const res = await postdData(url, Values);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const descargarXML = (id, uuid = "", xmltext = "") => {
  var filename = "Factura_" + id + ".xml";
  var pom = document.createElement("a");
  var bb = new Blob([xmltext], { type: "text/plain" });

  pom.setAttribute("href", window.URL.createObjectURL(bb));
  pom.setAttribute("download", filename);

  pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":");
  pom.draggable = true;
  pom.classList.add("dragout");

  pom.click();
};

export const getDatosEmisor = async () => {
  let data = [];
  const url = `perfil/getDatosFacturacion`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const em = JSON.parse(res.data.respuesta.emisor);
      let values = {
        emisor: em.length > 0 ? em[0] : {},
      };
      resolve(values);
    } else {
      reject([]);
    }
  });
};

export const addPago = async (Values, Errores, setErrores, MonedaXML) => {
  let error = false;

  if (Values.fecha == "") {
    Errores.fecha = true;
    error = true;
  }

  const fecha = dayjs(Values.fecha);
  Values.fecha = fecha.format("YYYY-MM-DD");

  if (!Values.moneda || Values.moneda == "" || Values.moneda == undefined) {
    Errores.moneda = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.moneda != "MXN" || MonedaXML == "USD") {
    if (
      !Values.tipo_cambio ||
      Values.tipo_cambio == "" ||
      Values.tipo_cambio == undefined
    ) {
      Errores.tipo_cambio = true;
      setErrores(Errores);
      error = true;
    }
  } else {
    Values.tipo_cambio = 0;
  }

  if (!Values.forma_pago || Values.forma_pago == "") {
    Errores.forma_pago = true;
    error = true;
  }

  if (!error) {
    let data = [];
    Values.regimen_fiscal = String(Values.regimen_fiscal);
    const body = Values;
    const url = `pagos/insertar`;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getListaFac = async (id_cliente, query) => {
  console.log(id_cliente);
  let data = [];
  let queryParamsObj = {
    query: query,
    id_cliente: id_cliente,
  };

  const url = `pagos/search?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getFacturaByUUID = async (uuid) => {
  let data = [];
  let queryParamsObj = {
    uuid: uuid,
  };
  const url = `pagos/getbyUUID?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const fac = JSON.parse(res.data.respuesta.data);
      let DATA_FAC = fac.length > 0 ? fac[0] : {};

      const em = JSON.parse(res.data.respuesta.cliente);
      let DATA_CLIENTE = em.length > 0 ? em[0] : {};

      resolve({ factura: DATA_FAC, cliente: DATA_CLIENTE });
    } else {
      reject(res);
    }
  });
};

export const timbrarCFDI = async (
  Values,
  Errores,
  setErrores,
  documentos,
  datosReceptor
) => {
  let error = false;

  Values.id_cliente = datosReceptor.id;
  if (!Values.id_cliente || Values.id_cliente == "" || Values.id_cliente == 0) {
    Errores.id_cliente = true;
    error = true;
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Selecciona un receptor" });
    });
  }

  let DOC = [...documentos];
  let ERROR_VALIDACION = false;
  let ERROR_MENSAJE = "";

  const valoresConceptos = DOC.map((row) => {
    // row.hora_pago=dayjs(row.hora_pago).format("HH:mm");
    row.fecha_pago = dayjs(row.fecha_pago).format("YYYY-MM-DD");
    row.tipo_cambio = row.tipo_cambio == "" ? 0 : row.tipo_cambio;


    //Monto
    if (
      row.monto_pago == undefined ||
      row.monto_pago == null ||
      row.monto_pago == 0
    ) {
      ERROR_VALIDACION = true;
      ERROR_MENSAJE = "Ingresa correctamente el monto a pagar, este no puede ser 0";
    }

// Tipo de cambio 
    return row;
  });




  if (ERROR_VALIDACION) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: ERROR_MENSAJE });
    });
  }

  Values.documentos_rel = valoresConceptos;

  if (Values.fecha_emision == "") {
    Errores.fecha_emision = true;
    error = true;
  }

  if (!Values.tipo_comprobante || Values.tipo_comprobante == "") {
    Errores.tipo_comprobante = true;
    error = true;
  }

  if (!Values.serie || Values.serie == "" || Values.serie == undefined) {
    Errores.serie = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.uso_cfdi || Values.uso_cfdi == "") {
    Errores.uso_cfdi = true;
    error = true;
  }

  if (Values.conceptos == 0) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Selecciona al menos un concepto" });
    });
  }

  if (!error) {
    let data = [];
    Values.regimen_fiscal = String(Values.regimen_fiscal);
    const body = Values;
    const url = `Pagos/timbrarCFDI`;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const EliminarPago = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `Pagos/cancelarPago?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
