import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { getListaFac } from "./../../Funciones/Pagos";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Buscador from "../../../../../Includes/Buscador";
import { Col, Row } from "react-bootstrap";

function ModalSelectDocumento({
  onClose = () => {},
  handleProductoSeleccionado = () => {},
  titulo = "Selecciona una factura PPD",
  datosReceptor,
}) {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [documentos, setDocumentos] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const getListaProductos = () => {
    getListaFac(datosReceptor.id, ValueBusqueda)
      .then((resp) => {
        resp = JSON.parse(resp.data);
        if (resp.length > 0) {
          resp = resp.map((item) => {
            return item;
          });
        }
        setDocumentos(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getListaProductos();
  }, [ValueBusqueda, ValueSelect]);

  const quitarProducto = (producto) => {
    const nuevosProductos = documentos.filter((p) => p.ID !== producto.ID);
    setDocumentos(nuevosProductos);
  };

  console.log("Lista Productos", documentos);

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true}>
        <IconButton
          aria-label="Close"
          className="m-2"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon style={{ width: "30px", height: "30px" }} />
        </IconButton>
        {
          <DialogTitle>
            <h5 className="fw-bolder f-12 mt-2">{titulo}</h5>
          </DialogTitle>
        }
        <DialogContent style={{ minHeight: "70vh" }}>
          <div className="px-1 py-2">
            <Row>
              <Col xs={12} md={12}>
                {" "}
                <Buscador
                  placeholder="Buscar por folio o UUID"
                  ValueBusqueda={ValueBusqueda}
                  setValueBusqueda={setValueBusqueda}
                />
              </Col>{" "}
            </Row>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="d-none d-sm-table-cell  font-AvenirBold"
                  align="left"
                >
                  FACTURA
                </TableCell>
                <TableCell
                  className="d-none d-sm-table-cell  font-AvenirBold"
                  align="left"
                >
                  UUID
                </TableCell>

                <TableCell
                  className="d-none d-sm-table-cell  font-AvenirBold"
                  align="center"
                >
                  TOTAL
                </TableCell>
                <TableCell
                  className="d-none d-sm-table-cell  font-AvenirBold"
                  align="center"
                >
                  ABONOS
                </TableCell>
                <TableCell
                  className="d-none d-sm-table-cell  font-AvenirBold"
                  align="center"
                >
                  SALDO INSOLUTO
                </TableCell>
                <TableCell
                  className="d-none d-sm-table-cell  font-AvenirBold"
                  align="center"
                >
                  ACCIONES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentos && Array.isArray(documentos)
                ? documentos.map((row) => (
                    <>
                      <TableRow key={row.ID}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell font-AvenirMedium"
                        >
                          {row.SERIE + "-" + row.FOLIO}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell font-AvenirMedium"
                          style={{ fontSize: "11px" }}
                        >
                          {row.UUID}
                        </TableCell>

                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell font-AvenirMedium"
                        >
                          {formatMoneda(row.TOTAL)} {row.MONEDA}
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell font-AvenirMedium"
                        >
                          {formatMoneda(row.MONTO_PAGADO)} {row.MONEDA}
                        </TableCell>

                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell font-AvenirMedium"
                        >
                          {formatMoneda(row.TOTAL - row.MONTO_PAGADO)}{" "}
                          {row.MONEDA}
                        </TableCell>

                        
                        {row.TOTAL - row.MONTO_PAGADO > 0 ? (
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                              onClick={() => {
                                handleProductoSeleccionado(row, 1);
                                quitarProducto(row);
                              }}
                            >
                              Agregar
                            </Button>
                          </TableCell>
                        ) : (
                          <TableCell align="center">
                             <span
                           className="font-AvenirMedium text-center"
                           style={{ color: "#4fcabc" }}
                         >
                           PAGADO
                         </span>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  ))
                : null}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default ModalSelectDocumento;
