import { Col, Row } from "react-bootstrap";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pdf from "../../../../assets/Pdf.svg";

import { getHistorialCompras } from "../../Funciones/Compras";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import Buscador from "../../../../Includes/Buscador";
import SelectOrdenamiento from "../../../../Includes/SelectOrdenamiento";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";
import ModalFacturacion from "../../../../Lib/Modals/Facturacion/ModalFacturacion";
import DatosFacturacion from "../../../../Lib/Modals/Facturacion/Acciones/DatosFacturacion";
import visa from "../../../../assets/visa.jpg";
import MasterCard from "../../../../assets/masterCard.png";
import conektaLogo from "../../../../assets/conektaLogo.png";
import { TIPO_FACTURA } from "../../../../Lib/Funciones Generales/Constantes";

const TabCompras = () => {
  const [Lista, setLista] = useState({
    suscripciones: [],
    folios: [],
    addendas: [],
    plantillas: [],
  });

  const [ValueBusquedaAddendas, setValueBusquedaAddendas] = useState("");
  const [ValueSelectAddendas, setValueSelectAddendas] = useState(1);
  const [ValueBusquedaPlantillas, setValueBusquedaPlantillas] = useState("");
  const [ValueSelectPlantillas, setValueSelectPlantillas] = useState(1);
  const [pedidoSeleccionado, setPedidoSeleccionado] = useState(0);
  const [tipoFactura, setTipoFactura] = useState(TIPO_FACTURA.LICENCIA);
  const [showDialogFacturacion, setShowDialogFacturacion] = useState(false);

  useEffect(() => {
    getCompras();
  }, []);

  const getCompras = () => {
    getHistorialCompras()
      .then((data) => {
        setLista(data);
      })
      .catch((resp) => {
        setLista([]);
      });
  };

  const isFechaWithin30Days = (fechaCompra) => {
    const partesFecha = fechaCompra.split("/"); // Divide la fecha en partes

    // Crea una fecha usando el formato: MM/DD/YYYY
    const fechaCompraObj = new Date(
      `${partesFecha[1]}/${partesFecha[0]}/${partesFecha[2]}`
    );

    const fechaActual = new Date();

    const diferenciaMilisegundos = fechaCompraObj - fechaActual;
    const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

    return diferenciaDias <= 30;
  };



  return (
    <>
      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 pt-3">
          <Row className="p-0 mx-0 pt-3">
            <Col sm={12} md={12} className="px-3">
              <h6 className="text-left font-AvenirBold">
                Detalle de compra de planes
              </h6>
            </Col>
            <Col sm={12} md={12} className="px-3">
              <p className="text-left font-AvenirMedium">
                Visualiza el detalle de todas tus compras
              </p>
            </Col>

            <Table className="mt-4">
              <TableHead className="bg-tableHead">
                <TableRow>
                  <TableCell style={{ color: "#151916" }}>
                    <Typography className="font-AvenirBold ps-2">
                      {/* SOLICITUD/FOLIO  */}
                      FOLIO
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      PAQUETE
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      FECHA DE COMPRA
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      VIGENCIA
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      ESTATUS
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      PERIODICIDAD
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      FOLIOS MENSUALES
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      FORMA DE PAGO
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      ACCIONES
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(Lista.suscripciones) &&
                  Lista.suscripciones.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        <p className="m-0 ps-2 py-2">{String(row.ID).padStart(5, '0')}</p>
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.NOMBRE}
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.FECHA_COMPRA}
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.VIGENCIA}
                      </TableCell>
                      <TableCell
                        className="font-AvenirBold"
                        style={{
                          color:
                            row.ESTATUS === 1
                              ? PALETA.colorPrimary
                              : PALETA.colorError,
                          fontSize: "18px",
                        }}
                      >
                        {row.ESTATUS === 1 ? "ACTIVO" : row.ESTATUS === 2 ? "CANCELADO" : "ACTUALIZADO"}
                      </TableCell>
                      <TableCell
                        style={{ color: PALETA.colorPrimary, fontSize: "18px" }}
                        className="font-AvenirBold"
                      >
                        {row.MESES === 6 ? "SEMESTRAL" : "ANUAL"}
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.FOLIOS_ASIGNADOS}
                      </TableCell>

                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {
                          row.ID_TARJETA !== 0 && (
                            <div className="d-flex justify-content-between">
                              <div className=" align-self-center">
                                {" "}
                                <img
                                  src={
                                    row.BRAND_TARJETA == "visa"
                                      ? visa
                                      : row.BRAND_TARJETA == "mastercard"
                                        ? MasterCard
                                        : conektaLogo
                                  }
                                  style={{ maxWidth: "24px" }}
                                />
                              </div>
                              <div className="flex-grow-1 mx-3">
                                {row.TIPO_TARJETA} ***** {row.NUMERO_TARJETA}
                              </div>
                            </div>
                          )
                        }
                      </TableCell>

                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {isFechaWithin30Days(row.FECHA_COMPRA) ? (
                          <>
                            {(row.ID_FACTURA === 0 || row.ID_FACTURA === -1) ? (
                              <>
                                <DatosFacturacion
                                  setShowDialogFacturacion={
                                    setShowDialogFacturacion
                                  }
                                  setPedidoSeleccionado={setPedidoSeleccionado}
                                  setTipoFactura={setTipoFactura}
                                  id={row.ID}
                                  tipo={TIPO_FACTURA.LICENCIA}
                                />
                              </>
                            ) : (
                              <>
                                <Link
                                  to={"/app/pdf/facturacompra/" + row.UUID}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Tooltip
                                    title="Ver factura"
                                    placement="top"
                                    className="me-2"
                                  >
                                    <IconButton>
                                      <img src={Pdf} width={22} />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {row.UUID === null ? (
                              <></>
                            ) : (
                              <>
                                <Link
                                  to={"/app/pdf/facturacompra/" + row.UUID}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Tooltip
                                    title="Ver factura"
                                    placement="top"
                                    className="me-2"
                                  >
                                    <IconButton>
                                      <img src={Pdf} width={22} />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Row>
        </CardContent>
      </Card>

      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 pt-3">
          <Row className="p-0 mx-0 pt-3">
            <Col xs={12}>
              <Row className="p-0 m-0">
                <Col xs={12} md={7} lg={8}>
                  <Row className="p-0 m-0">
                    <Col sm={12} md={12} className="px-0">
                      <h6 className="text-left font-AvenirBold">
                        Compra de addendas
                      </h6>
                    </Col>
                    <Col sm={12} md={12} className="px-0">
                      <p className="text-left font-AvenirMedium">
                        Puedes comprar las addendas adicionales que requieras
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={5}
                  lg={4}
                  className="d-flex justify-content-end"
                >
                  {/* <Button
                    onClick={handleClickComprarAddenda}
                    className="btn btn-Crexendo font-AvenirMedium py-2 px-5 mt-3 mb-3"
                    variant="outlined"
                  >
                    Comprar
                  </Button> */}
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="mt-4">
              <Row className="p-0 m-0">
                <Col xs={12} md={8}>
                  <Buscador
                    placeholder="Buscar addendas"
                    ValueBusqueda={ValueBusquedaAddendas}
                    setValueBusqueda={setValueBusquedaAddendas}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <SelectOrdenamiento
                    ValueSelect={ValueSelectAddendas}
                    setValueSelect={setValueSelectAddendas}
                  />
                </Col>
              </Row>
            </Col>

            <Table className="mt-4">
              <TableHead className="bg-tableHead">
                <TableRow>
                  <TableCell style={{ color: "#151916" }}>
                    <Typography className="font-AvenirBold ps-2">
                      {" "}
                      FOLIO{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      ADDENDA
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      FECHA DE COMPRA
                    </Typography>
                  </TableCell>
                  {/* <TableCell className="font-AvenirBold" style={{ color: "#151916" }}>
                    <Typography className="font-AvenirBold"> MÉTODO DE PAGO</Typography>
                  </TableCell> */}
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold"> PRECIO</Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      FORMA DE PAGO
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      ACCIONES{" "}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(Lista.addendas) &&
                  Lista.addendas.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        <p className="m-0 ps-2 py-2">{String(row.ID).padStart(5, '0')}</p>
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.NOMBRE}
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.FECHA_COMPRA}
                      </TableCell>
                      {/* <TableCell style={{ color: "#65748B", fontSize: "18px" }} className="font-AvenirMedium">
                        </TableCell> */}
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {formatMoneda(row.MONTO)}
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {
                          row.ID_TARJETA !== 0 && (
                            <div className="d-flex justify-content-between">
                              <div className=" align-self-center">
                                {" "}
                                <img
                                  src={
                                    row.BRAND_TARJETA == "visa"
                                      ? visa
                                      : row.BRAND_TARJETA == "mastercard"
                                        ? MasterCard
                                        : conektaLogo
                                  }
                                  style={{ maxWidth: "24px" }}
                                />
                              </div>
                              <div className="flex-grow-1 mx-3">
                                {row.TIPO_TARJETA} ***** {row.NUMERO_TARJETA}
                              </div>
                            </div>
                          )
                        }
                      </TableCell>
                      <TableCell
                        style={{ color: PALETA.colorPrimary, fontSize: "18px" }}
                        className="font-AvenirBold"
                      >
                        {isFechaWithin30Days(row.FECHA_COMPRA) ? (
                          <>
                            {(row.ID_FACTURA === 0 || row.ID_FACTURA === -1) ? (
                              <>
                                <DatosFacturacion
                                  setShowDialogFacturacion={
                                    setShowDialogFacturacion
                                  }
                                  setPedidoSeleccionado={setPedidoSeleccionado}
                                  setTipoFactura={setTipoFactura}
                                  id={row.ID}
                                  tipo={TIPO_FACTURA.ADDENDA}
                                />
                              </>
                            ) : (
                              <>
                                <Link
                                  to={"/app/pdf/facturacompra/" + row.UUID}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Tooltip
                                    title="Ver factura"
                                    placement="top"
                                    className="me-2"
                                  >
                                    <IconButton>
                                      <img src={Pdf} width={22} />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {row.UUID === null ? (
                              <></>
                            ) : (
                              <>
                                <Link
                                  to={"/app/pdf/facturacompra/" + row.UUID}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Tooltip
                                    title="Ver factura"
                                    placement="top"
                                    className="me-2"
                                  >
                                    <IconButton>
                                      <img src={Pdf} width={22} />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Row>
        </CardContent>
      </Card>

      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 pt-3">
          <Row className="p-0 mx-0 pt-3">
            <Col xs={12}>
              <Row className="p-0 m-0">
                <Col xs={12} md={7} lg={8}>
                  <Row className="p-0 m-0">
                    <Col sm={12} md={12} className="px-0">
                      <h6 className="text-left font-AvenirBold">
                        Compra de diseños de factura
                      </h6>
                    </Col>
                    <Col sm={12} md={12} className="px-0">
                      <p className="text-left font-AvenirMedium">
                        Puedes comprar los diseños de facturas adicionales que
                        requieras
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={5}
                  lg={4}
                  className="d-flex justify-content-end"
                >
                  {/* <Button
                    onClick={handleClickComprarPlantillas}
                    className="btn btn-Crexendo font-AvenirMedium py-2 px-5 mt-3 mb-3"
                    variant="outlined"
                  >
                    Comprar
                  </Button> */}
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="mt-4">
              <Row className="p-0 m-0">
                <Col xs={12} md={8}>
                  <Buscador
                    placeholder="Buscar plantilla"
                    ValueBusqueda={ValueBusquedaPlantillas}
                    setValueBusqueda={setValueBusquedaPlantillas}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <SelectOrdenamiento
                    ValueSelect={ValueSelectPlantillas}
                    setValueSelect={setValueSelectPlantillas}
                  />
                </Col>
              </Row>
            </Col>

            <Table className="mt-4">
              <TableHead className="bg-tableHead">
                <TableRow>
                  <TableCell style={{ color: "#151916" }}>
                    <Typography className="font-AvenirBold ps-2">
                      {" "}
                      FOLIO{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      DISEÑO{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      FECHA DE COMPRA
                    </Typography>
                  </TableCell>
                  {/* <TableCell className="font-AvenirBold" style={{ color: "#151916" }}>
                    <Typography className="font-AvenirBold"> MÉTODO DE PAGO</Typography>
                  </TableCell> */}
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold"> PRECIO</Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      FORMA DE PAGO
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="font-AvenirBold"
                    style={{ color: "#151916" }}
                  >
                    <Typography className="font-AvenirBold">
                      {" "}
                      ACCIONES{" "}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(Lista.plantillas) &&
                  Lista.plantillas.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        <p className="m-0 ps-2 py-2">{row.ID}</p>
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.NOMBRE}
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {row.FECHA_COMPRA}
                      </TableCell>
                      {/* <TableCell style={{ color: "#65748B", fontSize: "18px" }} className="font-AvenirMedium">
                        </TableCell> */}
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {formatMoneda(row.MONTO)}
                      </TableCell>
                      <TableCell
                        style={{ color: "#65748B", fontSize: "18px" }}
                        className="font-AvenirMedium"
                      >
                        {
                          row.ID_TARJETA !== 0 && (
                            <div className="d-flex justify-content-between">
                              <div className=" align-self-center">
                                {" "}
                                <img
                                  src={
                                    row.BRAND_TARJETA == "visa"
                                      ? visa
                                      : row.BRAND_TARJETA == "mastercard"
                                        ? MasterCard
                                        : conektaLogo
                                  }
                                  style={{ maxWidth: "24px" }}
                                />
                              </div>
                              <div className="flex-grow-1 mx-3">
                                {row.TIPO_TARJETA} ***** {row.NUMERO_TARJETA}
                              </div>
                            </div>
                          )
                        }
                      </TableCell>
                      <TableCell
                        style={{ color: PALETA.colorPrimary, fontSize: "18px" }}
                        className="font-AvenirBold"
                      >
                        {isFechaWithin30Days(row.FECHA_COMPRA) ? (
                          <>
                            {(row.ID_FACTURA === 0 || row.ID_FACTURA === -1) ? (
                              <>
                                <DatosFacturacion
                                  setShowDialogFacturacion={
                                    setShowDialogFacturacion
                                  }
                                  setPedidoSeleccionado={setPedidoSeleccionado}
                                  setTipoFactura={setTipoFactura}
                                  id={row.ID}
                                  tipo={TIPO_FACTURA.PLANTILLA}
                                />
                              </>
                            ) : (
                              <>
                                <Link
                                  to={"/app/pdf/facturacompra/" + row.UUID}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Tooltip
                                    title="Ver factura"
                                    placement="top"
                                    className="me-2"
                                  >
                                    <IconButton>
                                      <img src={Pdf} width={22} />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {row.UUID === null ? (
                              <></>
                            ) : (
                              <>
                                <Link
                                  to={"/app/pdf/facturacompra/" + row.UUID}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Tooltip
                                    title="Ver factura"
                                    placement="top"
                                    className="me-2"
                                  >
                                    <IconButton>
                                      <img src={Pdf} width={22} />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Row>
        </CardContent>
      </Card>
      {showDialogFacturacion && (
        <ModalFacturacion
          isUsuario={true}
          tipo={tipoFactura}
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogFacturacion={setShowDialogFacturacion}
          getListaLicenciasVendidas={getCompras}
        />
      )}
    </>
  );
};

export default TabCompras;
