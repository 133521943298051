import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Row } from "react-bootstrap";

import CloseIcon from "@mui/icons-material/Close";

const ModalFacturacionDisenoImagen = ({
    setShowDialogoImagen,
    setPlantillaSeleccionada,
    plantillaSeleccionada,
    imgDefault = ''
}) => {


    return (
        <Dialog fullWidth maxWidth="sm" open={true}>
            <IconButton
                aria-label="Close"
                className="m-2"
                style={{ position: "absolute", right: "0%" }}
                onClick={() => {
                    setShowDialogoImagen(false);
                    setPlantillaSeleccionada(null)
                }}
            >
                <CloseIcon style={{ width: "30px", height: "30px" }} />
            </IconButton>
            {
                <DialogTitle>
                    <h5 className="fw-bolder f-12 mt-2 w-100 text-center">
                        {plantillaSeleccionada.NOMBRE}
                    </h5>
                </DialogTitle>
            }
            <DialogContent style={{ minHeight: "70vh" }}>
                <Row className="my-4">
                    <img src={plantillaSeleccionada.IMG == '' ? imgDefault : plantillaSeleccionada.IMG} className="img-fluid" />
                </Row>
            </DialogContent>
        </Dialog>
    )
}

export default ModalFacturacionDisenoImagen