import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Usuarios from "./Componentes/Usuarios";
import RepUsuarios from "./Componentes/RepUsuarios";

const routerConfigUsuarios = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepUsuarios />} />
        <Route path="/add" element={<Usuarios />} />
        <Route path="/edit/:id" element={<Usuarios />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerConfigUsuarios;
