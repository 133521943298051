
import logo from "../assets/LogoLateral.svg"; 

const StartLoad = ({ isLoged }) => {
  return (
    <div className="d-flex justify-content-center  ling-items-center" style={{height:"100vh",   backgroundColor: "#101727"}}>
      {<img src={logo} alt="asd" className="img-fluid" style={{maxWidth:"250px"}} />} 
    </div>
  );
};

export default StartLoad;
