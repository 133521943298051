import { Alert, AlertTitle, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Suscripcion from "./Items/Suscripcion";
import { useState } from "react";
import { useEffect } from "react";
import Switch from "../../../Includes/Switch";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";
import { LoadingButton } from "@mui/lab";
import SelectTarjetas from "../SelectTarjetas";
import { realizarCompra } from "../Funciones/Compras";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getPrecioAjusteNuevoPlan } from "../Funciones/Perfiles";
import Loading from "../../../Includes/Loading";
const MySwal = withReactContent(Swal);

export default function CompraPlan({
  idPlanSelec,
  setIdPlanSelec,
  data,
  periodo,
  planSeleccionado = 0,
}) {
  const [Plan, setPlan] = useState([]);
  const [tarjeta, setTarjeta] = useState([]);
  const [IsGuardando, setIsGuardando] = useState(false);

  const [IsLoading, setIsLoading] = useState(true);
  const [ajustePrecio, setAjustePrecio] = useState({
    precio: -1,
    puedecomprar: false,
  });

  const [values, setValues] = useState({
    id_plan: idPlanSelec,
    tarjeta: 0,
    recurrente: false,
    periodo: periodo,
  });

  const GuardarCompra = () => {
    MySwal.fire({
      title: "Confirmación",
      html: "¿Deseas comprar esta suscripción?",
      icon: "info",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
      timer: 15700,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsGuardando(true);
        realizarCompra(values)
          .then((data) => {
            if (data.codigo == "200") {
              setIsGuardando(false);
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: true,
                timer: 15700,
              }).then((result) => {
                window.location.reload(true);
              });
            } else {
              MySwal.fire({
                title: "Error",
                html: data.mensaje,
                icon: "info",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: true,
                timer: 15700,
              }).then((result) => {});
              setIsGuardando(false);
            }
          })
          .catch((data) => {
            MySwal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "info",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: true,
              timer: 15700,
            }).then((result) => {});
            setIsGuardando(false);
          });
      }
    });
  };

  useEffect(function () {
    const indiceElementoEncontrado = data.findIndex(
      (item) => item.ID === idPlanSelec
    );
    if (indiceElementoEncontrado !== -1) {
      setPlan(data[indiceElementoEncontrado]);
    }
  }, []);

  useEffect(
    function () {
      setValues((prevState) => ({
        ...prevState,
        tarjeta: tarjeta.id,
      }));
    },
    [tarjeta]
  );

  useEffect(
    function () {
      setValues((prevState) => ({
        ...prevState,
        periodo: periodo,
      }));
    },
    [periodo]
  );

  useEffect(
    function () {
      if (idPlanSelec != 0) {
        setIsLoading(true);
        getPrecioAjusteNuevoPlan(idPlanSelec, periodo)
          .then((data) => {
            if (!isNaN(data.precio)) {
              setIsLoading(false);
              setAjustePrecio({
                precio: data.precio,
                puedecomprar: data.puedecomprar,
              });
              console.log(ajustePrecio);
            } else {
              // setIsLoading(false);
            }
          })
          .catch((data) => {
            setIsLoading(false);
            setAjustePrecio(data);
          });
      }
    },
    [idPlanSelec, periodo]
  );

 
 

  let totalReal =
    periodo == "anual" ? Plan.PRECIO_ANUAL : Plan.PRECIO_SEMESTRAL;
  let total = ajustePrecio.precio == -1 ? totalReal : ajustePrecio.precio;
  let subtotal = total / 1.16;
  let iva = total - subtotal;

  return (
    <>
      <div className="row">
        <div className="col-12 text-start">
          <Button
            onClick={() => setIdPlanSelec(0)}
            className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
            variant="text"
          >
            <ArrowBackIcon className="me-3" />
            Regresar
          </Button>
        </div>
        <div className="col-12 col-md-5">
          {Plan.length != 0 && (
            <Suscripcion
              key={"modal_plan_" + Plan.ID}
              planSeleccionado={planSeleccionado}
              data={Plan}
              isVistaDetalle={true}
              periodo={periodo}
              setIdPlanSelec={setIdPlanSelec}
            />
          )}
        </div>
        <div className="col-12 col-md-7">
          {IsLoading ? (
            <>
              <div className="mt-5 pt-5"></div>
              <Loading />
              <center>
                <h6 className="font-AvenirMedium tetx-muted">Espere...</h6>
              </center>
            </>
          ) : (
            <>
              {ajustePrecio.puedecomprar ? (
                <>
                  <h6 className="font-AvenirBold text-center">
                    Elige una forma de pago
                  </h6>

                  <SelectTarjetas
                    paymentMethod={"TARJETA"}
                    tarjeta={tarjeta}
                    setTarjeta={setTarjeta}
                  />

                  <table className="w-100 mt-5">
                    <tr>
                      <td align="right">
                        <h6 className="font-Avenir ">Subtotal</h6>
                      </td>
                      <td align="right">
                        <h6 className="font-Avenir  ">
                          {Plan.length == 0 ? "..." : formatMoneda(subtotal)}
                          MXN
                        </h6>
                      </td>
                    </tr>

                    <tr>
                      <td align="right">
                        <h6 className="font-Avenir ">I.V.A.</h6>
                      </td>
                      <td align="right">
                        <h6 className="font-Avenir  ">
                          {Plan.length == 0 ? "..." : formatMoneda(iva)}
                          MXN
                        </h6>
                      </td>
                    </tr>

                    {ajustePrecio.precio != -1 && (
                      <tr>
                        <td align="right">
                          <h6 className="font-Avenir ">Ajuste</h6>
                        </td>
                        <td align="right">
                          <h6 className="font-Avenir  ">
                            -
                            {Plan.length == 0
                              ? "..."
                              : formatMoneda(totalReal - ajustePrecio.precio)}
                            MXN
                          </h6>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td align="right">
                        <h6 className="font-AvenirBold ">TOTAL A PAGAR</h6>
                      </td>
                      <td align="right">
                        <h6 className="font-AvenirBold  ">
                          {Plan.length == 0 ? "..." : formatMoneda(total)}
                          MXN
                        </h6>
                      </td>
                    </tr>
                  </table>

                  <div className="d-flex justify-content-end w-100 mt-4">
                    <div className="align-self-center me-3">
                      <h6 className="font-AvenirBold text-center ">
                        Pago recurrente
                      </h6>
                    </div>
                    <div>
                      <Switch
                        checked={values.recurrente}
                        onChange={(checked) => {
                          setValues((prevState) => ({
                            ...prevState,
                            recurrente: checked,
                          }));
                        }}
                        id={"ss"}
                        tituloActivo="Desactivar"
                        tituloInactivo="Activar"
                      />
                    </div>
                  </div>

                  <LoadingButton
                    onClick={() => {
                      GuardarCompra();
                    }}
                    loading={IsGuardando}
                    disabled={IsGuardando}
                    loadingPosition="start"
                    className="btn btn-Crexendo font-AvenirMedium  w-100 py-2 px-4 mt-3"
                    variant="contained"
                    xs={{ with: "100$" }}
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {IsGuardando ? "Realizando compra..." : "Pagar "}
                    </span>
                  </LoadingButton>
                </>
              ) : (
                <>
                  <Alert severity="error">
                    <AlertTitle>Lo sentimos</AlertTitle>
                    No es posible adquirir un plan con menos beneficios que tu plan actual contratado, intenta con un plan superior.
                  </Alert>
                </>
              )}
            </>
          )}
        </div>

        <div className="col-12 colmd-12"></div>
      </div>
    </>
  );
}
