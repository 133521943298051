import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField
} from "@mui/material";

import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { guardarCorreo, getInfCorreo, guardarConfNotificaciones } from "../Funciones/Correos";

import "../../../../Lib/Funciones Generales/Prototipos";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const Correo = () => {
  // const { id } = useParams();
  const id = 1;
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");


  /*
   * Valores de configuración del correo
   */
  const [Values, setValues] = useState({
    id: id ?? "0",
    descripcion: "",
    fecha: "",
    host: "",
    correo_emisor: "",
    nombre_emisor: "",
    user: "",
    password: "",
    puerto: 0,
  });
  const [Errores, setErrores] = useState({
    descripcion: false,
    fecha: false,
    host: false,
    correo_emisor: false,
    nombre_emisor: false,
    user: false,
    password: false,
    puerto: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target, tipo }) => {
    let Name = target.name;
    let Value = target.value;

    if (Name == "puerto") {
      Value = Value.toNumber();
    }

    setValues({ ...Values, [Name]: Value, });
    setErrores({ ...Errores, [Name]: false, });

  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfCorreo(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarCorreo = () => {
    setIsGuardando(true);
    guardarCorreo(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            // navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  useEffect(() => {
    document.title = `Correo`;
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container pb-5">
      <h4 className="font-AvenirBold mt-4 mb-4 pt-2">Correo</h4>

        <Card elevation={3}>
          <CardContent className="p-0">

            <Row className="p-3">
              <Col sm={12} md={4}>
                <h6 className="text-left font-AvenirBold">
                  Editar correo
                </h6>
              </Col>
              <Col sm={12} md={8} className="mt-3">

                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <Row className="p-3">
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="descripcion"
                        variant="outlined"
                        value={Values.descripcion}
                        error={Errores.descripcion}
                        inputProps={{
                          maxLength: 250,
                        }}
                        helperText={
                          Errores.descripcion ? "Descripción no valido" : ""
                        }
                        label="Descripción"
                        onChange={({ target }) => handlInputChange({ target, tipo: 'confCorreo' })}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="host"
                        variant="outlined"
                        value={Values.host}
                        error={Errores.host}
                        helperText={Errores.host ? "Host no valido" : ""}
                        label="Host"
                        onChange={({ target }) => handlInputChange({ target, tipo: 'confCorreo' })}
                        inputProps={{
                          maxLength: 250,
                        }}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="correo_emisor"
                        variant="outlined"
                        value={Values.correo_emisor}
                        error={Errores.correo_emisor}
                        inputProps={{
                          maxLength: 100,
                        }}
                        helperText={
                          Errores.correo_emisor ? "Correo emisor no valido" : ""
                        }
                        label="Correo emisor"
                        onChange={({ target }) => handlInputChange({ target, tipo: 'confCorreo' })}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="nombre_emisor"
                        variant="outlined"
                        value={Values.nombre_emisor}
                        error={Errores.nombre_emisor}
                        inputProps={{
                          maxLength: 100,
                        }}
                        helperText={
                          Errores.nombre_emisor ? "Nombre emisor no valido" : ""
                        }
                        label="Nombre emisor"
                        onChange={({ target }) => handlInputChange({ target, tipo: 'confCorreo' })}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="user"
                        variant="outlined"
                        value={Values.user}
                        error={Errores.user}
                        inputProps={{
                          maxLength: 250,
                        }}
                        helperText={Errores.user ? "Usuario no valido" : ""}
                        label="Usuario"
                        onChange={({ target }) => handlInputChange({ target, tipo: 'confCorreo' })}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="password"
                        variant="outlined"
                        value={Values.password}
                        error={Errores.password}
                        inputProps={{
                          maxLength: 250,
                        }}
                        helperText={Errores.password ? "Contraseña no valido" : ""}
                        label="Contraseña"
                        onChange={({ target }) => handlInputChange({ target, tipo: 'confCorreo' })}
                        type="password"
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="puerto"
                        variant="outlined"
                        value={Values.puerto}
                        error={Errores.puerto}
                        helperText={Errores.puerto ? "Puerto no valido" : ""}
                        label="Puerto"
                        onChange={({ target }) => handlInputChange({ target, tipo: 'confCorreo' })}
                      />
                    </Col>
                  </Row>
                )}
                <Stack
                  className="p-3 d-flex justify-content-end w-100"
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                  style={{}}
                >
                  <LoadingButton
                    loading={IsGuardando}
                    loadingPosition="start"
                    disabled={IsLoading}
                    onClick={() => GuardarCorreo()}
                    className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                    variant="contained"
                    xs={{ with: "100$" }}
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                    </span>
                  </LoadingButton>
                </Stack>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Correo;
