import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import TableSeries from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { getLista } from "../Funciones/Series";
import SelectOrdenamiento from "../../../../Includes/SelectOrdenamiento";
import Buscador from "../../../../Includes/Buscador";

const RepSeries = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  useEffect(() => {
    getListaSerie();
  }, [ValueBusqueda, ValueSelect]);

  const getListaSerie = () => {
    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Series`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Series</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <Link to="add/" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Crexendo px-4 " variant="contained">
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar serie por nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableSeries
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              getListaSerie={getListaSerie}
            ></TableSeries>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default RepSeries;
