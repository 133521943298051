import React, { useState } from "react";

import Chart from "react-apexcharts";
import { Card, CardContent } from "@mui/material";
import { Col, Row } from "react-bootstrap";

const Donut = ({
  val = 0,
  label = "",
  titulo = "",
  porcobrar = 0,
  pagadas = 0,
  contadorInferiror = 0,
  isShowContadorInferior = false,
  mes = "",
  heightCard = "491px",
}) => {

  

  var options2 = {
    series: [parseInt(porcobrar), parseInt(pagadas)],
    options: {
      colors: ["#FFB020","#3ABE88" ],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.series[opts.seriesIndex]; // Mostrar el valor real de la serie
        },
      },
      labels: ["Por cobrar", "Pagadas"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
              },
            },
          },
        },
      },
      legend: {
        position: 'bottom', // Coloca los indicadores en la parte inferior
      },
    },
  };

  return (
    <>
      <Card className="shadow" elevation={3} style={{ height: heightCard }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">{titulo}</h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />

          {mes != "" && (
            <div>
              <center>
                <p
                  className="mb-0"
                  style={{
                    color: "black",
                    fontWeight: "AvenirBlack",
                    fontSize: "25px",
                  }}
                >
                  {mes}
                </p>
              </center>
            </div>
          )}

          <div lassName="p-3 m-3" style={{ marginTop: "0px" }}>
            <Row className="p-1 m-2">
              <Col>
                <Chart
                  options={options2.options}
                  series={options2.series}
                  dataLabels={{
                    enabled: false,
                  }}
                  type="donut"
                  height={300}
                />
              </Col>
            </Row>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
export default Donut;
