import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  FormGroup,
} from "@mui/material";

import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import { getInfoSerie, Guardar } from "../Funciones/Series";
import Loading from "../../../../Includes/Loading";
import "../../../../Lib/Funciones Generales/Prototipos";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "../../../../Includes/Select";
import { TiposSeries } from "../../../../Lib/Funciones Generales/Constantes";
import Switch from "../../../../Includes/Switch";

const MySwal = withReactContent(Swal);

const Serie = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [predeterminado, setpredeterminada] = useState(false);
  // const [selecionadoSucu, setselecionadoSucu] = useState('');
  const [Values, SetValues] = useState({
    id: id ?? "0",
    nombre: "",
    folio_inicial: 1,
    predeterminada: false,
    tipo: "",
    activo: true,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    folio_inicial: false,
    predeterminada: false,
    id_sucursal: false,
    tipo: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    if (Name == "folio_inicial") {
      Value = Value.toNumber();
    }

    SetValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfoSerie(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            SetValues(resp);
            setpredeterminada(resp.predeterminada);
            setIsLoading(false);
          }
          SetValues(resp);
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarSerie = () => {
    setIsGuardando(true);
    Guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    document.title = `Almacén ${id ? id : " "}`;
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container pb-5">
        <Button
          onClick={() => navigate(-1)}
          className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
          variant="text"
        >
          <ArrowBackIcon className="me-3" />
          Series
        </Button>

        <Card elevation={3} className="mb-4 shadow">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="mb-0 text-left font-AvenirBold">
                {id ? "Editar serie" : "Crear nueva serie"}
              </h6>
            </div>
            <hr
              style={{
                backgroundColor: "#DFDEE0",
                height: "1px",
                opacity: "1",
              }}
              className="m-0 p-0"
            />

            {IsLoading ? (
              <Row className="px-3">
                <Col sm={12} className="p-3">
                  <Loading />
                </Col>
              </Row>
            ) : (
              <Row className="p-3">
                <Col sm={12} md={4} className="p-3">
                  <Select
                    Titulo="Tipo"
                    Name="tipo"
                    Value={Values.tipo}
                    Error={Errores.tipo}
                    HelperText={Errores.tipo ? "Tipo no valido" : ""}
                    Opciones={TiposSeries}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="nombre"
                    label="Nombre"
                    variant="outlined"
                    value={Values.nombre}
                    error={Errores.nombre}
                    helperText={Errores.nombre ? "Nombre no valido" : ""}
                    onChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <TextField
                    multiline
                    fullWidth
                    name="folio_inicial"
                    label="Folio inicial"
                    maxRows={3}
                    inputProps={{ maxLength: 249 }}
                    value={Values.folio_inicial}
                    error={Errores.folio_inicial}
                    placeholder="Folio inicial"
                    helperText={
                      Errores.folio_inicial ? "Folio inicial no valido" : ""
                    }
                    onChange={handlInputChange}
                    style={{ width: "100%", height: "80px" }}
                  />
                </Col>
                <Col sm={12} className="p-3 d-flex justify-content-between">
                  <div className="w-100">
                    <h6 className="font-AvenirMedium m-0 p-0">
                      ¿Es predeterminado?
                    </h6>
                    <h6
                      lassName="font-Avenir mt-0 p-0"
                      style={{ fontSize: "13px" }}
                    >
                      Sí activas esta opción esta serie sera la predeterminada
                    </h6>
                  </div>
                  <FormGroup>
                    <div className="d-flex justify-content-center">
                      <p
                        className="my-0 algin-self-center font-Avenir mt-2 text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {Values.predeterminada ? "SI" : "NO"}
                      </p>

                      <Switch
                        className="algin-self-center"
                        checked={Values.predeterminada}
                        name="predeterminada"
                        error={Errores.predeterminada}
                        onChange={(checked) => {
                          handlInputChange({
                            target: {
                              name: "predeterminada",
                              value: checked,
                            },
                          });
                        }}
                        disabled={Values.activo ? predeterminado : true}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Stack
              className="p-3"
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarSerie()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                </span>
              </LoadingButton>
              <Button
                onClick={() => navigate(-1)}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                variant="outlined"
              >
                Cancelar
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Serie;
