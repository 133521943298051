import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { getUnidadesMedidasInternas } from "../Funciones/Productos";


const UnidadMedidaInterna = ({ Value, Error, handlInputChange, Name = "unidad_de_medida" }) => {
    const [ValorFiltrado, setFiltro] = useState()
    const [UnidadMedidaInterna, setUnidadMedidaInterna] = useState({ cargado: false, lista: [{ CLAVE: 0, DESCRIPCION: "Unidad de medida interna" }] });

    useEffect(() => {
        getUnidadesMedidasInternas()
            .then((resp) => {
                let list = JSON.parse(resp.data);
                if (list.length != 0) {
                    setUnidadMedidaInterna({ cargado: true, lista: list });
                }
                else {
                    setUnidadMedidaInterna({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin unidades de medida interna disponibles" }] });
                }
            })
            .catch((resp) => {
                setUnidadMedidaInterna({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin unidades de medida interna disponibles" }] });
            });
    }, [])

    const getOpcion = () => {
        const opcion = UnidadMedidaInterna.lista.find(x => x.DESCRIPCION == Value)
        if (opcion) {
            return opcion
        } else {
            return null
        }
    }

    return (
        UnidadMedidaInterna.cargado ? (
            <Autocomplete
            required
                fullWidth
                defaultValue={getOpcion}
                isOptionEqualToValue={(option, value) => {
                    return option.DESCRIPCION == value.DESCRIPCION
                }}
                inputValue={ValorFiltrado}
                onChange={(e, value) => {
                    if (value) {
                        handlInputChange({ target: { name: Name, value: value.DESCRIPCION } })
                    }
                    else {
                        handlInputChange({ target: { name: Name, value: "" } })
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setFiltro(newInputValue);
                }}
                options={UnidadMedidaInterna.lista}
                getOptionLabel={(option) => {
                    return option.DESCRIPCION
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            error={Error}
                            helperText={Error ? "Selecciona una unidad de medida interna" : ""}
                            label="Unidad de medida interna"
                        />
                    )
                }}
            />
        ) : (
            <TextField
                fullWidth
                disabled={true}
                value={"Unidad de medida interna"}
            />
        )
    )
}

export default UnidadMedidaInterna