import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  MenuItem
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { TipoPersonaSat } from "../../../../Lib/Funciones Generales/Constantes";
import {
  guardar,
  getInfoCliente
} from "../Funciones/Clientes";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../../../Lib/Funciones Generales/Prototipos";
import Loading from "../../../../Includes/Loading";
import AccEditarImagen from "./../../../../assets/EditarImagen.svg";
import SelectEstado from "../../../../Direcciones/Componentes/SelectEstado";
import SelectMunicipio from "../../../../Direcciones/Componentes/SelectMunicipio";
import RegimenFiscalSatSat from "../../../../SAT/Componentes/RegimenFiscalSat";
import Select from "../../../../Includes/Select";

const MySwal = withReactContent(Swal);

const Cliente = () => {


  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const [Values, setValues] = useState({
    id: id ?? "0",
    id_tipo_persona: '',
    nombre_comercial: '',
    nombre_contacto: '',
    correo: '',
    telefono: '',
    rfc: '',
    razon_social: '',
    regimen_fiscal: '',
    calle: '',
    numero_ext: '',
    numero_int: '',
    cp: '',
    id_estado: '',
    id_municipio: 0,
    id_localidad: 0,
    colonia: '',
  });

  const [Errores, setErrores] = useState({
    id_tipo_persona: false,
    nombre_comercial: false,
    nombre_contacto: false,
    correo: false,
    telefono: false,
    rfc: false,
    razon_social: false,
    regimen_fiscal: false,
    calle: false,
    numero_ext: false,
    numero_int: false,
    cp: false,
    id_estado: false,
    id_municipio: false,
    id_localidad: false,
    colonia: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    //console.log(target);
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "id_estado") {
      NewValue = { ...NewValue, id_municipio: 0 };
    } else if (Name == "cp" || Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }


    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoCliente(id, AccEditarImagen).then((resp) => {
        //console.log(resp);
        if (resp?.err?.length == 0) {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        } else {
          setValues(resp);
          setIsLoading(false);
        }
      })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarCliente = () => {
    setIsGuardando(true);

    guardar(
      Values,
      Errores,
      setErrores
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container pb-5">
        <Button
          onClick={() => navigate(-1)}
          className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
          variant="text"
        >
          <ArrowBackIcon className="me-3" />
          Clientes
        </Button>

        <Card elevation={3} className="mb-4 shadow">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="mb-0 text-left font-AvenirBold">
                {id ? "Editar cliente" : "Crear nuevo cliente"}
              </h6>
            </div>
            <hr
              style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
              className="m-0 p-0"
            />

            {IsLoading ? (
              <Row className="px-3">
                <Col sm={12} className="p-3">
                  <Loading />
                </Col>
              </Row>
            ) : (

              <Row className="p-3">

                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    required
                    fullWidth
                    name="nombre_comercial"
                    label="Nombre comercial"
                    variant="outlined"
                    value={Values.nombre_comercial}
                    error={Errores.nombre_comercial}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Col>

                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    required
                    fullWidth
                    name="razon_social"
                    label="Razón social"
                    variant="outlined"
                    helperText={Errores.razon_social ? "Razón social invalida" : ""}
                    value={Values.razon_social}
                    error={Errores.razon_social}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 250,
                    }}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    required
                    fullWidth
                    name="rfc"
                    label="RFC"
                    variant="outlined"
                    helperText={Errores.rfc ? "RFC invalido" : ""}
                    value={Values.rfc}
                    error={Errores.rfc}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 13,
                    }}
                  />
                </Col>

                <Col sm={12} md={6} lg={4} className="p-3">
                  <Select
                    Titulo="Tipo de persona"
                    Name='id_tipo_persona'
                    Value={Values.id_tipo_persona}
                    Error={Errores.id_tipo_persona}
                    HelperText={Errores.id_tipo_persona ? "Tipo de persona no valida" : ""}
                    Opciones={TipoPersonaSat}
                    handlInputChange={handlInputChange} />
                </Col>


                <Col sm={12} md={6} lg={4} className="p-3">
                  <RegimenFiscalSatSat
                    required={true}
                    Value={Values.regimen_fiscal}
                    Error={Errores.regimen_fiscal}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    required
                    fullWidth
                    name="cp"
                    inputProps={{ maxLength: 5 }}
                    label="Domicilio fiscal"
                    placeholder="Código postal"
                    variant="outlined"
                    value={Values.cp}
                    error={Errores.cp}
                    onChange={handlInputChange}

                  />
                </Col>


                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    required
                    fullWidth
                    name="correo"
                    label="Correo electrónico"
                    variant="outlined"
                    error={Errores.correo}
                    helperText={Errores.correo ? "Correo incorrecto" : ""}
                    value={Values.correo}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 150,
                    }}
                  />
                </Col>


                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    fullWidth
                    name="telefono"
                    label="Teléfono"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    helperText={Errores.telefono ? "Teléfono inválido" : ""}
                    value={Values.telefono}
                    error={Errores.telefono}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    fullWidth
                    name="calle"
                    label="Calle"
                    variant="outlined"
                    value={Values.calle}
                    error={Errores.calle}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 250,
                    }}
                  />
                </Col>

                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    fullWidth
                    name="numero_ext"
                    label="Número exterior"
                    variant="outlined"
                    value={Values.numero_ext}
                    error={Errores.numero_ext}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 250,
                    }}
                  />
                </Col>

                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    fullWidth
                    name="numero_int"
                    label="Número interior"
                    variant="outlined"
                    value={Values.numero_int}
                    error={Errores.numero_int}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 250,
                    }}
                  />
                </Col>



                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    fullWidth
                    name="colonia"
                    label="Colonia"
                    variant="outlined"
                    value={Values.colonia}
                    error={Errores.colonia}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 250,
                    }}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <SelectEstado
                    Value={Values.id_estado}
                    Error={Errores.id_estado}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <SelectMunicipio
                    Estado={Values.id_estado}
                    Value={Values.id_municipio}
                    Error={Errores.id_municipio}
                    init={Values.init}
                    handlInputChange={handlInputChange}
                  />
                </Col>


                <Col sm={12} md={6} lg={4} className="p-3">
                  <TextField
                    fullWidth
                    name="nombre_contacto"
                    label="Nombre de contacto"
                    variant="outlined"
                    value={Values.nombre_contacto}
                    error={Errores.nombre_contacto}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Col>







              </Row>

            )}
            <Stack
              className="p-3"
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarCliente()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                </span>
              </LoadingButton>
              <Button
                onClick={() => navigate(-1)}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                variant="outlined"
              >
                Cancelar
              </Button>

            </Stack>
          </CardContent>
        </Card>
      </div>

    </>
  );
};

export default Cliente;
