import { useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Avatar, Chip, Divider } from "@mui/material";
import { clearStorageJWT_Manager, getLocalStorage_Manager } from "../Context/Storage";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import Swal from "sweetalert2";

import IconoPerfil from '../../assets/MenuMiPerfil.svg'
import IconoSalir from '../../assets/MenuCerrarSes.svg'


const CerrarSesion = () =>
  new Swal({
    title: "¿Estás seguro de cerrar sesión?",
    icon: "warning",
    showDenyButton: true,
    denyButtonText: "Cancelar",
    confirmButtonText: "Si, estoy seguro",
    confirmButtonColor: "#3ABE88",
    denyButtonColor: "#65748B",
    reverseButtons: true,
  }).then((result) => {
    //  alert(result.isConfirmed);

    if (result.isConfirmed) {
      Swal.fire({
        icon: "success",
        title: "Se ha cerrado tu sesión",
        showConfirmButton: false,
        timer: 1500,
      }).then(clearStorageJWT_Manager);
    }
  });
const AccountMenu = ({ Values, IsLoading }) => {

  // window.imgHeader = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const img_url = IsLoading ? getLocalStorage_Manager("img_manager") : Values.img;

  return (
    <>
      <Tooltip title="Configuración de la cuenta">
        <IconButton>
          <Avatar
            sx={{ width: 25, height: 25 }}
            src={img_url}
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <div className="text-center my-3 ">
          <h6 className="font-AvenirBold" style={{ fontSize: "14px" }}>
            {getLocalStorage_Manager("nombre_manager")}
          </h6>
          <Chip
          className="align-self-center"
          color="success"
          label="Admin"
          size="small"
        />

        </div>
      */}

        <div className="text-center my-3 px-3">
          <div className="d-flex align-items-center">
            <Avatar
              sx={{ width: 25, height: 25 }}
              src={img_url}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
            <div className="ps-0">
              <h6 className="font-AvenirBold m-0" style={{ fontSize: "14px" }}>
                Flexor Technologies
              </h6>
              <h6 className="font-Avenir text-left m-0" style={{ fontSize: "10px", textAlign: 'left' }}>
                {Values.correo}
              </h6>
            </div>
          </div>




        </div>

        <Divider className="mb-2" light />


        <Link to="perfil/" style={{ textDecoration: "none", color: "black" }}>
          <MenuItem>
            <ListItemIcon>
              <img src={IconoPerfil} width={20} />
            </ListItemIcon>
            Mi perfil
          </MenuItem>
        </Link>
        <Divider className="my-2" light />
        <Link
          onClick={CerrarSesion}
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default AccountMenu;
