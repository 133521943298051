import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getPlanes = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `planes/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarPlanes = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.precio_semestral || Values.precio_semestral == "") {
    Errores.precio_semestral = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.precio_anual || Values.precio_anual == "") {
    Errores.precio_anual = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.costo_semestral || Values.costo_semestral == "") {
    Errores.costo_semestral = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.costo_anual || Values.costo_anual == "") {
    Errores.costo_anual = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.productos_incluidos || Values.productos_incluidos == "") {
    Errores.productos_incluidos = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.clientes_incluidos || Values.clientes_incluidos == "") {
    Errores.clientes_incluidos = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.facturas_por_mes || Values.facturas_por_mes == "") {
    Errores.facturas_por_mes = true;
    setErrores(Errores);
    error = true;
  } 

  if (!error) {
    let data = [];
    const url =
    ((Values.id == undefined || Values.id == "" || Values.id == 0) && Values.id != -1)
        ? "planes/Insertar"
        : "planes/Editar";

    const body = Values;
    const res = await postdData(url, { ...body, id: body.id == -1 ? 0 : body.id });

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarPlanes = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `planes/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfPlanes = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id == -1 ? 0 : id,
  };
  const url = `planes/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este puesto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: data.ID == 0 ? -1 : data.ID,
          nombre: String(data.NOMBRE),
          precio_anual: String(data.PRECIO_ANUAL),
          precio_semestral: String(data.PRECIO_SEMESTRAL),
          costo_anual: String(data.COSTO_ANUAL),
          costo_semestral: String(data.COSTO_SEMESTRAL),
          clientes_incluidos: String(data.CLIENTES_INCLUIDOS),
          productos_incluidos: String(data.PRODUCTOS_INCLUIDOS),
          facturas_por_mes: String(data.FACTURAS_POR_MES),
          moneda: String(data.MONEDA),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      ID: id == -1 ? 0 : id,
      activo: checked,
    };
    const url = `planes/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
