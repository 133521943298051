import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoUsuario } from "../Funciones/Usuarios";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalCargarImagen from "../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "./../../../../assets/EditarImagen.svg";
import { IMG } from "../../../../Lib/Funciones Generales/Constantes";
import "../../../../Lib/Funciones Generales/Prototipos";
import SelectGrupoAcceso from "../../GruposDeAcceso/Componentes/SelectGrupoAcceso";

const MySwal = withReactContent(Swal);

const Usuario = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    id_grupo: "0",
    img: AccEditarImagen,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
    id_grupo: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    if (Name == "telefono") {
      Value = Value.toNumber();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoUsuario(id, AccEditarImagen)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarEmpledo = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Usuarios del sistema
      </Button>
      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            {IsLoading ? (
              <Row className="px-3">
                <Col sm={12} className="p-3">
                  <Loading />
                </Col>
              </Row>
            ) : (
              <Row className="p-3">
                <Col sm={12} md={4}>
                  <h6 className="text-left font-AvenirBold">
                    {id
                      ? "Editar usuario del sistema"
                      : "Nuevo usuario del sistema"}
                  </h6>
                </Col>
                <Col sm={12} md={8} className="mt-3">
                  <Col
                    sm={12}
                    className="d-flex flex-wrap align-items-center px-3"
                  >
                    <Avatar
                      className="shadow"
                      sx={{ width: 80, height: 80 }}
                      src={img_url}
                    />
                    <p
                      className="text-center my-0 ms-3 font-AvenirBold"
                      style={{ color: "#3ABE88", cursor: "pointer" }}
                      onClick={handleOpenModal}
                    >
                      Editar foto
                    </p>
                  </Col>

                  <Col sm={12} className="p-3 mt-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="nombre"
                      label="Nombre"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={Errores.nombre ? "Nombre no valido" : ""}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="apellido_paterno"
                      label="Apellido paterno"
                      variant="outlined"
                      value={Values.apellido_paterno}
                      error={Errores.apellido_paterno}
                      helperText={
                        Errores.apellido_paterno
                          ? "Apellido paterno no valido"
                          : ""
                      }
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="apellido_materno"
                      label="Apellido materno"
                      variant="outlined"
                      value={Values.apellido_materno}
                      error={Errores.apellido_materno}
                      helperText={
                        Errores.apellido_materno
                          ? "Apellido materno no valido"
                          : ""
                      }
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} className="p-3">
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="correo"
                      label="Correo electrónico"
                      variant="outlined"
                      helperText={Errores.correo ? "Correo incorrecto" : ""}
                      value={Values.correo}
                      error={Errores.correo}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} className="p-3">
                    <TextField
                      fullWidth
                      name="telefono"
                      label="Teléfono"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      helperText={Errores.telefono ? "Teléfono inválido" : ""}
                      value={Values.telefono}
                      error={Errores.telefono}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} className="p-3">
                    <SelectGrupoAcceso
                      Value={Values.id_grupo}
                      Error={Errores.id_grupo}
                      handlInputChange={handlInputChange}
                      Name="id_grupo"
                    />
                  </Col>
                  <Col sm={12} className="p-3">
                    {Values.id == 0 ? (
                      <>
                        <Alert severity="info">
                          <AlertTitle>Mensaje</AlertTitle>
                          Se enviará una contraseña temporal vía correo
                          electrónico
                        </Alert>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Col>
              </Row>
            )}

            <Col sm={12} className="d-flex justify-content-end ">
              <Stack
                className="p-3"
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                style={{}}
              >
                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  disabled={IsLoading}
                  onClick={() => GuardarEmpledo()}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                  variant="contained"
                  xs={{ with: "100$" }}
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                  </span>
                </LoadingButton>
                <Button
                  onClick={() => navigate(-1)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </Stack>
            </Col>
          </Row>
        </CardContent>
      </Card>
      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_USUARIOSISTEMA}
          Titulo="Cargar imagen del usuario del sistema"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Usuario;
