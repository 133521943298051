import { postdData } from "../../manager/Context/backend";
import validator from "validator";

export const EnviarCorreo = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.apellido || Values.apellido == "") {
    let Errores2 = Errores;

    Errores2.apellido = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.mensaje || Values.mensaje == "") {
    Errores.mensaje = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const body = Values;
    const url = `Contacto/contactanos`;
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await postdData(url, body, headers);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
