import React, { useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// function Carrousel() {
//   const pantallaURL1 =
//     "https://crexendo.blob.core.windows.net/home/PantallaDash.webp";
//   const pantallaURL2 =
//     "https://crexendo.blob.core.windows.net/home/PantallaClientes.webp";

//   const imgs = [pantallaURL1, pantallaURL2];
//   const [selectedIdx, setSelectedIdx] = useState(0);
//   const [selectedImg, setSelectedImg] = useState(imgs[0]);

//   const selectNewImg = (idx, imgs, next = true) => {
//     const nextIdx = next
//       ? (next ? selectedIdx < imgs.length - 1 : selectedIdx > 0)
//         ? selectedIdx + 1
//         : 0
//       : (next ? selectedIdx < imgs.length - 1 : selectedIdx > 0)
//       ? selectedIdx - 1
//       : imgs.length - 1;
//       setSelectedImg(imgs[nextIdx]);
//       setSelectedIdx(nextIdx);
//   };

//   const previous = () => {
//     selectNewImg(selectedIdx, imgs, false);
//   };

//   const next = () => {
//     selectNewImg(selectedIdx, imgs);
//   };

//   return(
//   <div>  
//   <img src={selectedImg} />
//   <button onClick={previous}>prev</button>
//   <button onClick={next}>next</button>
//   </div>
//   ) ;
// }

// export default Carrousel;

export default function Carrousel() {

    const pantallaURL1 =
   "https://crexendo.blob.core.windows.net/home/PantallaDash.webp";
   const pantallaURL2 =
     "https://crexendo.blob.core.windows.net/home/PantallaClientes.webp";

    var settings = {
        dots: true, 
        slidesToShow: 1.5,
        slidesToScroll: 1.5,
        infinite: false,
        variableWidth: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,

            }
          }
        ]
    };
    return (
    
      
      <Slider {...settings}>
        <div >
          <img src={pantallaURL1} className="img-fluid"/>
        </div>
        <div >
        <img src={pantallaURL2} className="img-fluid"/>
        </div>
      </Slider>
    );
  }