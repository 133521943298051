import { Route, Routes } from "react-router-dom";
import RepFacturas from "./Componentes/RepFactura";
const RouterFacturas = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RepFacturas />} />
      </Routes>
    </>
  );
};

export default RouterFacturas;
