

import { postdData, getData } from "../../Context/backend";

export const realizarCompra = async (
  Values = {},
  Errores = {},
  setErrores = () => { }
) => {
  try {
    let error = false;
    if (
      !Values.tarjeta ||
      Values.tarjeta == "" ||
      Values.tarjeta == "0"
    ) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Selecciona una tarjeta" });
      });
      error = true;
    }


    if (!error) {
      let data = [];

      const url = "planes/adquirirplan";

      const res = await postdData(url, Values);
      //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };

      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          //console.log(data);
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Completa todos los campos requeridos" });
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getHistorialCompras = async () => {
  let data = [];
  const url = `planes/consultarCompras`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
