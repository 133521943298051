import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  TableHead,
  Tooltip,
  Alert,
  Snackbar,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import Cancelar from "../../../../assets/Cancelar.svg";
import XML from "../../../../assets/XML.svg";
import Pdf from "../../../../assets/Pdf.svg";
import SobreCerrado from "../../../../assets/SobreCerrado.svg";
import SobreAbierto from "../../../../assets/SobreAbierto.svg";
import AccRealizarPago from "../../../../assets/AccRealizarPago.svg";

import { Link } from "react-router-dom";
import Loading from "../../../../Includes/Loading";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { MoreVert } from "@mui/icons-material";
import BACKEND_URL from "../../../Context/backend";

// const MySwal = withReactContent(Swal);

function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
  setShowModalEnviarFactura,
  showModalEnviarFactura,
}) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function TableProductos({
  Lista,
  IsLoading,
  setLista,
  ValueBusqueda,
  setShowModalEnviarFactura,
  setShowModalCancelarFactura,
  setShowModalPagos,
  setFacturaSeleccionada,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [open, setOpen] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState([]);
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("Facturas", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };



  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  useEffect(() => {
    //console.log({ Lista, ValueBusqueda });
    if (ValueBusqueda != "") {
      setPage(0);
    }
  }, [Lista]);
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {
              setShowModalPagos(true);
              setFacturaSeleccionada(idRow);
            }}
          >
            <IconButton>
              <img src={AccRealizarPago} width={20} />
            </IconButton>
            {idRow.METODO_PAGO == "PUE" ? "Ver pagos" : "Ver complementos"}
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowModalEnviarFactura(true);
              setFacturaSeleccionada(idRow);
            }}
          >
            <IconButton>
              {
                idRow.ENVIADO ?
                  <img src={SobreAbierto} width={20} />
                  : <img src={SobreCerrado} width={20} />
              }
            </IconButton>
            Enviar
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowModalCancelarFactura(true);
              setFacturaSeleccionada(idRow);
            }}
          >
            <IconButton>
              <img src={Cancelar} width={20} />
            </IconButton>
            Cancelar
          </MenuItem>

          <MenuItem>
            <Link
              to={"/app/pdf/factura/" + idRow.UUID}
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
            >
              <IconButton>
                <img src={Pdf} width={20} />
              </IconButton>
              Ver factura
            </Link>
          </MenuItem>

          {/* <MenuItem
            onClick={() => {
              descargarXML(idRow.ID, idRow.UUID ? idRow.UUID : "", idRow.XML);
            }}
          >
            <IconButton>
              <img src={XML} width={20} />
            </IconButton>
            Desc. XML
          </MenuItem> */}

          <MenuItem>
            <a
              href={
                BACKEND_URL +
                `facturacion/XMLFactura?UUID=${idRow.UUID}`
              }
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <IconButton>
                <img src={XML} width={18} />
              </IconButton>
              Desc. XML
            </a>
          </MenuItem>


        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead className="bg-tableHead">
            <TableRow>
              <TableCell className=" font-Avenir font-AvenirBold" align="left">
                FACTURA
              </TableCell>
              <TableCell className="d-none d-sm-table-cell font-Avenir font-AvenirBold" align="left">
                CLIENTE
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                MÉTODO DE PAGO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                TOTAL
              </TableCell>
              <TableCell className="d-none d-sm-table-cell font-Avenir font-AvenirBold" align="left">
                ESTADO
              </TableCell>
              <TableCell className="d-none d-sm-table-cell font-Avenir font-AvenirBold" align="left">
                ESTATUS SAT
              </TableCell>
              <TableCell
                className="  font-Avenir font-AvenirBold"
                align="right"
              >
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={8}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell component="th" scope="row" className="">
                    {row.SERIE}-{row.FOLIO}
                    <span className="d-sm-none d-table-cell">
                      <Typography
                        style={{
                          maxWidth: 150,
                          textOverflow: "ellipsis",
                          textAlign: "left",
                        }}
                        noWrap={true}
                      >
                        {row.CLIENTE}
                      </Typography>
                    </span>
                  </TableCell>

                  <TableCell component="th" scope="row" className="d-none d-sm-table-cell">
                    {row.CLIENTE}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {row.METODO_PAGO}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography
                      style={{
                        maxWidth: 150,
                        margin: "auto",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                      }}
                      noWrap={true}
                    >
                      {row.TOTAL.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Typography>

                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {row.PAGADO == 1 ? (
                      <span
                        className="font-AvenirMedium"
                        style={{ color: "#4fcabc" }}
                      >
                        PAGADO
                      </span>
                    ) : (
                      <span
                        className="font-AvenirMedium"
                        style={{ color: "#ffc04c" }}
                      >
                        PENDIENTE
                      </span>
                    )}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {row.CANCELADO == "0" ? (
                      <span>VIGENTE</span>
                    ) : (
                      <span style={{ color: "#d65858" }}>CANCELADO</span>
                    )}
                  </TableCell>

                  <TableCell align="right">
                    <Grid className="d-none d-sm-block">

                      {row.CANCELADO == "0" && (
                        <Tooltip
                          title="Cancelar factura"
                          placement="top"
                          onClick={() => {
                            setShowModalCancelarFactura(true);
                            setFacturaSeleccionada(row);
                          }}
                        >
                          <IconButton>
                            <img src={Cancelar} width={19} />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip
                        title={
                          row.METODO_PAGO == "PUE"
                            ? "Ver pagos"
                            : "Ver complementos"
                        }
                        placement="top"
                        onClick={() => {
                          setShowModalPagos(true);
                          setFacturaSeleccionada(row);
                        }}
                      >
                        <IconButton>
                          <img src={AccRealizarPago} width={20} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title="Enviar factura"
                        placement="top"
                        onClick={() => {
                          setShowModalEnviarFactura(true);
                          setFacturaSeleccionada(row);
                        }}
                      >
                        <IconButton>
                          {
                            row.ENVIADO ?
                              <img src={SobreAbierto} width={22} />
                              : <img src={SobreCerrado} width={22} />
                          }
                        </IconButton>
                      </Tooltip>

                      <Link
                        to={"/app/pdf/factura/" + row.UUID}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="Ver factura" placement="top">
                          <IconButton>
                            <img src={Pdf} width={18} />
                          </IconButton>
                        </Tooltip>
                      </Link>

                      <a
                        href={
                          BACKEND_URL +
                          `facturacion/XMLFactura?UUID=${row.UUID}`
                        }
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="Descargar xml" placement="top">
                          <IconButton>
                            <img src={XML} width={18} />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </Grid>

                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
