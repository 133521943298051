import { IconButton, Tooltip } from "@mui/material";
import Facturar from "../../../../assets/LateralFacturasON.svg";
import { TIPO_FACTURA } from "../../../Funciones Generales/Constantes";

const DatosFacturacion = ({
  setShowDialogFacturacion,
  setPedidoSeleccionado,
  setTipoFactura,
  id,
  tipo = TIPO_FACTURA.LICENCIA,
}) => {
  return (
    <>
      <Tooltip title="Facturar pedido" placement="top" className="me-2">
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            setPedidoSeleccionado(id);
            setShowDialogFacturacion(true);
            setTipoFactura(tipo);
          }}
        >
          <img src={Facturar} width={22} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DatosFacturacion;
