
import { postdData } from "../../Context/backend";

export const realizarCompraAddenda = async (
    Values = {},
    Errores = {},
    setErrores = () => { }
) => {
    try {
        let error = false;
        if (
            !Values.tarjeta ||
            Values.tarjeta == "" ||
            Values.tarjeta == "0"
        ) {
            return new Promise((resolve, reject) => {
                resolve({ mensaje: "Selecciona una tarjeta" });
            });
            error = true;
        }


        if (!error) {
            let data = [];

            const url = "addendas/adquirirAddenda";

            const res = await postdData(url, Values);
            //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };

            return new Promise((resolve, reject) => {
                if (!res.error) {
                    data = res.data;
                    //console.log(data);
                    resolve(data);
                } else {
                    reject(res);
                }
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve({ mensaje: "Completa todos los campos requeridos" });
            });
        }
    } catch (e) {
        console.log(e);
    }
};
