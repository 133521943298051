import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  TableHead,
  Tooltip,
  Alert,
  Snackbar,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import Cancelar from "../../../../assets/Cancelar.svg";
import XML from "../../../../assets/XML.svg";
import Pdf from "../../../../assets/Pdf.svg";
import Enviar from "../../../../assets/Enviar.svg";
import AccRealizarPago from "../../../../assets/AccRealizarPago.svg";

import { Link } from "react-router-dom";
import Loading from "../../../../Includes/Loading";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { MoreVert } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import { descargarXML } from "../Funciones/Facturas";
import BACKEND_URL from "../../../Context/backend";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";
import { getFormaPAgoByClave } from "../../../../SAT/Funciones/SAT";

import SobreCerrado from "../../../../assets/SobreCerrado.svg";
import SobreAbierto from "../../../../assets/SobreAbierto.svg";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function PagosFacturas({
  Lista,
  IsLoading,
  setShowModalEnviarFactura,
  setShowModalCancelarFactura,
  EliminarRegistro,
  setPagoSeleccionado,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [open, setOpen] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState([]);
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          {Lista.factura.METODO_PAGO.trim() == "PPD" ? (
            <>
              <MenuItem
                onClick={() => {
                  setShowModalCancelarFactura(true);
                  setPagoSeleccionado(idRow);
                }}
              >
                <IconButton>
                  <img src={Cancelar} width={20} />
                </IconButton>
                Cancelar factura
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShowModalEnviarFactura(true);
                  setPagoSeleccionado(idRow);
                }}
              >
                <IconButton>
                  <img src={Enviar} width={20} />
                </IconButton>
                Enviar
              </MenuItem>
              <MenuItem>
                <Link
                  to={"/app/pdf/pago/" + idRow.UUID}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Tooltip title="Ver factura" placement="top">
                    <IconButton>
                      <img src={Pdf} width={20} />
                    </IconButton>
                  </Tooltip>
                  Ver factura
                </Link>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  descargarXML(
                    idRow.ID,
                    idRow.UUID ? idRow.UUID : "",
                    idRow.XML
                  );
                }}
              >
                <IconButton>
                  <img src={XML} width={20} />
                </IconButton>
                Desc. XML
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem
                onClick={() => {
                  EliminarRegistro(idRow.ID);
                }}
              >
                <IconButton>
                  <img src={Cancelar} width={20} />
                </IconButton>
                Eliminar pago
              </MenuItem>
            </>
          )}
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead className="bg-tableHead">
            <TableRow>
              <TableCell
                colSpan={5}
                className="d-sm-none d-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                PAGO
              </TableCell>
              <TableCell
                className="d-sm-none d-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                MONTO
              </TableCell>

              <TableCell
                className="d-none d-sm-table-cell  font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA PAGO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FORMA DE PAGO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell  font-Avenir font-AvenirBold"
                align="left"
              >
                MONTO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell  font-Avenir font-AvenirBold"
                align="left"
              >
                MONEDA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell  font-Avenir font-AvenirBold"
                align="left"
              >
                TIPO DE CAMBIO
              </TableCell>

              <TableCell
                className="d-none d-sm-table-cell  font-Avenir font-AvenirBold"
                align="left"
              >
                ESTATUS
              </TableCell>

              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={8}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableBody>
                {Lista.pagos?.map((row) => {
                  // TOTAL_PAGADO += row.MONTO;
                  return (
                    <TableRow key={row.ID}>
                      <TableCell
                        colSpan={5}
                        component="th"
                        scope="row"
                        className="d-sm-none d-table-cell font-AvenirMedium"
                      >
                        <Typography
                          style={{
                            maxWidth: 150,
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                          noWrap={true}
                        >
                          {row.FEHA_PAGO}
                        </Typography>
                        <Typography
                          style={{
                            maxWidth: 150,
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                          noWrap={true}
                        >
                          {getFormaPAgoByClave(row.FORMA_PAGO)}
                        </Typography>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="d-sm-none d-table-cell font-AvenirMedium"
                      >
                        {row.MONTO.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}{" "}
                        {row.MONEDA}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="d-none d-sm-table-cell font-AvenirMedium"
                      >
                        <Grid className="d-none d-sm-block">
                          {row.FEHA_PAGO}
                        </Grid>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="d-none d-sm-table-cell font-AvenirMedium"
                      >
                        {getFormaPAgoByClave(row.FORMA_PAGO)}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="d-none d-sm-table-cell font-AvenirMedium"
                      >
                        {row.MONTO.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="d-none d-sm-table-cell font-AvenirMedium"
                      >
                        {row.MONEDA}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="d-none d-sm-table-cell font-AvenirMedium"
                      >
                        {row.MONEDA === "MXN"
                          ? "-"
                          : row.TIPO_CAMBIO.toFixed(3)}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className="d-none d-sm-table-cell font-AvenirMedium"
                      >
                        {row.CANCELADO == "0" ? (
                          <span>VIGENTE</span>
                        ) : (
                          <span style={{ color: "#d65858" }}>CANCELADO</span>
                        )}
                      </TableCell>

                      <TableCell align="right">
                        <Grid className="d-none d-sm-block">
                          {row.CANCELADO == "0" && (
                            <Tooltip
                              title={
                                Lista.factura.METODO_PAGO.trim() == "PPD"
                                  ? "Cancelar factura"
                                  : "Eliminar pago"
                              }
                              placement="top"
                              onClick={() => {
                                if (Lista.factura.METODO_PAGO.trim() == "PPD") {
                                  setShowModalCancelarFactura(true);
                                  setPagoSeleccionado(row);
                                } else {
                                  EliminarRegistro(row.ID);
                                }
                              }}
                            >
                              <IconButton>
                                <img src={Cancelar} width={20} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {Lista.factura.METODO_PAGO.trim() == "PPD" ? (
                            <>
                              <Tooltip
                                title="Enviar factura"
                                placement="top"
                                onClick={() => {
                                  setShowModalEnviarFactura(true);
                                  setPagoSeleccionado(row);
                                }}
                              >
                                <IconButton>
                                  {row.ENVIADO ? (
                                    <img src={SobreAbierto} width={22} />
                                  ) : (
                                    <img src={SobreCerrado} width={22} />
                                  )}
                                </IconButton>
                              </Tooltip>

                              <Link
                                to={"/app/pdf/pago/" + row.UUID}
                                target="_blank"
                                style={{ textDecoration: "none" }}
                              >
                                <Tooltip title="Ver factura" placement="top">
                                  <IconButton>
                                    <img src={Pdf} width={20} />
                                  </IconButton>
                                </Tooltip>
                              </Link>

                              <a
                                href={
                                  BACKEND_URL + `pagos/XMLPago?UUID=${row.UUID}`
                                }
                                target="_blank"
                                style={{ textDecoration: "none" }}
                              >
                                <Tooltip title="Descargar xml" placement="top">
                                  <IconButton>
                                    <img src={XML} width={20} />
                                  </IconButton>
                                </Tooltip>
                              </a>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid>
                          <DrawerHeader
                            style={{ backgroundColor: "#FFFFFF" }}
                            className="d-xsm-none d-sm-block d-sm-none"
                            onClick={() => {
                              setIdRow(row);
                              setStateRow(row.ACTIVO);
                            }}
                          >
                            <IconButton onClick={handleClick}>
                              <MoreVert />
                            </IconButton>
                          </DrawerHeader>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="right"
                    component="th"
                    scope="row"
                    className="font-AvenirMedium"
                  >
                    Estatus
                  </TableCell>
                  <TableCell align="right" className="font-AvenirMedium">
                    {Lista.factura.PAGADO == 1 ? (
                      <span
                        className="font-AvenirMedium"
                        style={{ color: "#4fcabc" }}
                      >
                        PAGADO
                      </span>
                    ) : (
                      <span
                        className="font-AvenirMedium"
                        style={{ color: "#ffc04c" }}
                      >
                        PENDIENTE
                      </span>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="right"
                    component="th"
                    scope="row"
                    className="font-AvenirMedium"
                  >
                    Total factura
                  </TableCell>
                  <TableCell align="right" className="font-AvenirMedium">
                    {formatMoneda(Lista.factura.TOTAL)} {Lista.factura.MONEDA}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="right"
                    component="th"
                    scope="row"
                    className="font-AvenirMedium"
                  >
                    Total pagado
                  </TableCell>
                  <TableCell
                    align="right"
                    component="th"
                    scope="row"
                    className="font-AvenirMedium"
                  >
                    {formatMoneda(Lista.factura.MONTO_PAGADO)}{" "}
                    {Lista.factura.MONEDA}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="right"
                    component="th"
                    scope="row"
                    className="font-AvenirBold"
                  >
                    Por pagar
                  </TableCell>
                  <TableCell
                    align="right"
                    component="th"
                    scope="row"
                    className="font-AvenirBold"
                  >
                    {formatMoneda(
                      Lista.factura.TOTAL - Lista.factura.MONTO_PAGADO
                    )}{" "}
                    {Lista.factura.MONEDA}
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          )}
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
