import React, { Component, useEffect, useState } from "react";
import { getInfoPerfilInformacionLanding } from "../../app/Perfil/Funciones/Perfiles";
import Suscripcion from "./Suscripcion";
import {
  CardContent,
  Card,
  ToggleButton,
  ToggleButtonGroup,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { Col, Row, TabPane } from "react-bootstrap";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function Planes() {
  const [planes, setPlanes] = useState([]);
  const [periodo, setPeriodo] = useState("anual");
  const [tab, setTab] = useState('1');
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const [ValuesGenerales, setValuesGenerales] = useState({
    /* Datos generales */
    id: 0,
    img: "",
    nombre: "",
    correo: "",
    telefono: "",
    plantilla_activa: 0,
    plan_activo: 0,

    /* Datos de facturacion */
    rfc: "",

    
    razon_social: "",
    regimen_fiscal: "",
    cp: "",
  });

  const ConsultarPerfil = () => {
    getInfoPerfilInformacionLanding().then((resp) => {
      console.log(resp);
      setValuesGenerales(resp.ValuesGenerales);
      // setValuesNegocio(resp.ValuesNegocio);
      setPlanes(resp.Planes);
      // setPlantillas(resp.Plantillas);
      // setAddendas(resp.Addendas)
      // setIsLoading(false);
    });
    // .catch((resp) => {
    //   setIsLoading(false);
    // });
  };

  useEffect(function () {
    ConsultarPerfil();
  }, []);

  const handleChange = (event, newAlignment) => {
    if (newAlignment != null) {
      setPeriodo(newAlignment);
    }
  };

  return (
    <div id="planes" className="my-5">

      <div className="d-flex flex-row justify-content-center mb-5">
        <h1 className="font-AvenirMedium" >Conoce nuestros planes</h1>
      </div>
      <TabContext value={tab}>
        <TabList
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          onChange={handleChangeTab}
          variant="standard"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          centered
        >
          <Tab value="1" label={<Typography fontWeight="bold">Anual</Typography>} className="font-font-AvenirMedium" style={{ textTransform: 'none', width: "10em" }} />
          <Tab value="2" label={<Typography fontWeight="bold">Semestral</Typography>} className="font-font-AvenirMedium" style={{ textTransform: 'none', width: "10em" }} />
        </TabList>

        <TabPanel value="1" className="px-0 mb-2">
          {/* <Suscripcion
              key={'plan_' + data.ID}
              data={data}
              periodo={periodo}
              isVistaDetalle={true}
              isVisibleBoton={true}
            /> */}
          <Row className="p-3 pt-0 d-flex justify-content-start mb-4" >
            {

              Array.isArray(planes) && (
                planes.map((data, index) => (
                  <Col xs={12} lg={4} className="mb-3"> 
                    <Suscripcion
                      key={'plan_' + data.ID}
                      data={data}
                      periodo={"anual"}
                      isVistaDetalle={true}
                      isVisibleBoton={true}
                    />
                  </Col>
                )))}
          </Row>
        </TabPanel>
        <TabPanel value="2">
          <Row className="p-3 pt-0 d-flex justify-content-start mb-4" >
            {/* <Suscripcion
              key={'plan_' + data.ID}
              data={data}
              periodo={periodo}
              isVistaDetalle={true}
              isVisibleBoton={true}
            /> */}
            {
              Array.isArray(planes) && (
                planes.map((data, index) => (
                  <Col xs={12} lg={4} className="mb-3">
                    <Suscripcion
                      key={'plan_' + data.ID}
                      data={data}
                      periodo={"semestral"}
                      isVistaDetalle={true}
                      isVisibleBoton={true}
                    />
                  </Col>

                )))
            }
          </Row>
        </TabPanel>
      </TabContext>
    </div >
  );
}
export default Planes;
