import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Addendas from "./Componentes/Addendas";
import RepAddendas from "./Componentes/RepAddendas";

const routerAddendas = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepAddendas />} />
        <Route path="/add" element={<Addendas />} />
        <Route path="/edit/:id" element={<Addendas />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerAddendas;
