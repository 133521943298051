import {
  Alert,
  Button,
  CardContent,
  Divider,
  Snackbar,
  TextField,
} from "@mui/material";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getInfoConfig, guardarDecimales, guardarCorreos, guardarTelefonos, requiereSMS, requiereWhatsApp } from "../Funciones/Configuraciones";
import { LoadingButton } from "@mui/lab";
import MisTarjetas from "../../../Perfil/Componentes/MisTarjetas";
import Switch from "../../../../Includes/Switch";

export default function Configuraciones() {
  const [open, setOpen] = useState(false);
  const [IsLoadingDecimales, setIsLoadingDecimales] = useState(false);
  const [IsGuardandoDecimales, setIsGuardandoDecimales] = useState(false);
  const [IsLoadingCorreos, setIsLoadingCorreos] = useState(false);
  const [IsGuardandoCorreos, setIsGuardandoCorreos] = useState(false);
  const [IsGuardandoTelefonos, setIsGuardandoTelefonos] = useState(false);
  const [type, setType] = useState('error');
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    decimales: 2,
    correo_vigencia: "",
    correo_folios: "",
    correo_certificados: "",
    telefono: "",

    telefono_sms: '',
    is_requiere_sms: false,
    is_requiere_whatsapp: false,
    telefono_whatsapp: ''
  });
  const [Errores, setErrores] = useState({
    decimales: false,
    correo_vigencia: false,
    correo_folios: false,
    correo_certificados: false,
    telefono: false,
    telefono_sms: false,
    telefono_whatsapp: false,
  });

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "decimales" || Name == "telefono" || Name == "telefono_sms" || Name == "telefono_whatsapp") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    getInfoConfig().then((resp) => {
      if (resp?.err?.length !== 0) {
        setValues(resp);
      }
    });
  }, []);

  const GuardarDecimales = () => {
    setIsGuardandoDecimales(true);
    guardarDecimales(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardandoDecimales(false);
          setType("success")
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoDecimales(false);
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoDecimales(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoDecimales(false);
      });
  };


  const GuardarCorreos = () => {
    setIsGuardandoCorreos(true);
    guardarCorreos(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setType("success")
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoCorreos(false);
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoCorreos(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoCorreos(false);
      });
  };


  const GuardarTelefonos = () => {
    setIsGuardandoTelefonos(true);
    guardarTelefonos(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setType("success")
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoTelefonos(false);
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoTelefonos(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoTelefonos(false);
      });
  };


  const onChangeRequiereSMS = (checked) => {
    requiereSMS(Values, Errores, setErrores, checked)
      .then((resp) => {
        setType("success")
        setValues({ ...Values, is_requiere_sms: !Values.is_requiere_sms })
        setMensaje('Actualizado correctamente');
        setOpen(true);
      })
      .catch((resp) => {
        setType("error")
        setMensaje('Problemas al actualizar');
        setOpen(true);
      });
  };

  const onChangeRequiereWhatsApp = (checked) => {
    requiereWhatsApp(Values, Errores, setErrores, checked)
      .then((resp) => {
        setType("success")
        setValues({ ...Values, is_requiere_whatsapp: !Values.is_requiere_whatsapp })
        setMensaje('Actualizado correctamente');
        setOpen(true);
      })
      .catch((resp) => {
        setType("error")
        setMensaje('Problemas al actualizar');
        setOpen(true);
      });
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container pb-5">
        <h4 className="font-AvenirBold mt-4 mb-4 pt-2">Configuración</h4>

        <Card elevation={3} className="mb-4 shadow">
          <CardContent className="p-3">
            <h6 className="font-AvenirBold">Métodos de pago</h6>
            <MisTarjetas />
          </CardContent>
        </Card>

        <Card elevation={3} className="mb-4 shadow">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="font-AvenirBold">Número de decimales</h6>
            </div>
            <Divider />
            <div className="p-3">
              <div className="row">
                <div className="col-12 col-md-4 pt-2">
                  <TextField
                    fullWidth
                    name="decimales"
                    label="Número de decimales"
                    variant="outlined"
                    value={Values.decimales}
                    error={Errores.decimales}
                    helperText={Errores.decimales ? "Nombre no valido" : ""}
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12 col-md-8 pt-2">
                  <label className="font-Avenir text-muted">
                    {/* El número de decimales es utilizado para realizar los
                    calculos al momento de generar una factura.
                    <br></br>Utilizar mayor decimales puede ser útil cuando
                    tienes precios o cantidades con mas de 2 decimales */}
                    El número de decimales es utilizado para
                    realizar los cálculos al momento de generar
                    una factura. Utiliza como máximo la cantidad de 2 decimales.
                  </label>
                </div>
              </div>
              <LoadingButton
                className="btn btn-Crexendo font-AvenirMedium mt-4 py-2 px-4 "
                loading={IsGuardandoDecimales}
                loadingPosition="start"
                disabled={IsLoadingDecimales}
                onClick={() => GuardarDecimales()}
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoDecimales ? "px-4" : "px-2"}>
                  Guardar
                </span>
              </LoadingButton>
            </div>
          </CardContent>
        </Card>

        <Card elevation={3} className="mb-4 shadow">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="font-AvenirBold">Correos para notificaciones</h6>
            </div>
            <Divider />
            <div className="p-3">
              <div className="row">
                <div className="col-12 col-md-4 pt-2">
                  <TextField
                    fullWidth
                    name="correo_vigencia"
                    label="Correos para notificaciones"
                    variant="outlined"
                    placeholder="Ingresa el correo"
                    value={Values.correo_vigencia}
                    error={Errores.correo_vigencia}
                    inputProps={{
                      maxLength: 100,
                    }}
                    helperText={
                      Errores.correo_vigencia ? "Correo no valido" : ""
                    }
                    onChange={handlInputChange}
                  />
                </div>

                <div className="col-12 col-md-4 pt-3 pt-md-2">
                  <TextField
                    fullWidth
                    name="correo_folios"
                    label="Correo para existencia de folios"
                    variant="outlined"
                    placeholder="Ingresa el correo"
                    value={Values.correo_folios}
                    error={Errores.correo_folios}
                    inputProps={{
                      maxLength: 100,
                    }}
                    helperText={Errores.correo_folios ? "Correo no valido" : ""}
                    onChange={handlInputChange}
                  />
                </div>

                <div className="col-12 col-md-4 pt-3 pt-md-2">
                  <TextField
                    fullWidth
                    name="correo_certificados"
                    label="Correo para vencimiento de certificado"
                    variant="outlined"
                    placeholder="Ingresa el correo"
                    value={Values.correo_certificados}
                    error={Errores.correo_certificados}
                    inputProps={{
                      maxLength: 100,
                    }}
                    helperText={
                      Errores.correo_certificados ? "Correo no valido" : ""
                    }
                    onChange={handlInputChange}
                  />
                </div>

                <div className="col-12 pt-2 ">
                  <label className="font-Avenir text-muted">
                  Te notificaremos vía correo electrónico cuando tu plan y certificado estén por vencer 
                  y cuando cuentes con pocos folios disponibles
                  </label>
                </div>
              </div>

              <LoadingButton
                className="btn btn-Crexendo font-AvenirMedium mt-4 py-2 px-4 "
                loading={IsGuardandoCorreos}
                loadingPosition="start"
                disabled={IsLoadingCorreos}
                onClick={() => GuardarCorreos()}
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoCorreos ? "px-4" : "px-2"}>
                  Guardar
                </span>
              </LoadingButton>
            </div>
          </CardContent>
        </Card>




        <Card elevation={3} className="mb-4 shadow">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="font-AvenirBold">Mensajes para notificaciones</h6>
            </div>
            <Divider />
            <div className="p-3">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 pt-2">
                  <TextField
                    fullWidth
                    input
                    name="telefono_sms"
                    label="Teléfono para SMS"
                    variant="outlined"
                    placeholder="Ingresa el teléfono"
                    value={Values.telefono_sms}
                    error={Errores.telefono_sms}
                    helperText={
                      Errores.telefono_sms ? "Teléfono no valido" : ""
                    }
                    onChange={handlInputChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-2 pt-2">
                  <Switch
                    checked={Values.is_requiere_sms}
                    onChange={onChangeRequiereSMS}
                    id={0}
                    tituloActivo="Si"
                    tituloInactivo="No"
                    on
                  />
                </div>


                <div className="col-12 col-md-6 col-lg-4 pt-2">
                  <TextField
                    fullWidth
                    input
                    name="telefono_whatsapp"
                    label="Teléfono para WhatsApp"
                    placeholder="Ingresa el teléfono"
                    variant="outlined"
                    value={Values.telefono_whatsapp}
                    error={Errores.telefono_whatsapp}
                    helperText={
                      Errores.telefono_whatsapp ? "Teléfono no valido" : ""
                    }
                    onChange={handlInputChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-2 pt-2">
                  <Switch
                    checked={Values.is_requiere_whatsapp}
                    onChange={onChangeRequiereWhatsApp}
                    id={0}
                    tituloActivo="Si"
                    tituloInactivo="No"
                    on
                  />
                </div>


                <div className="col-12 pt-2 ">
                  <label className="font-Avenir text-muted">
                  Te notificaremos vía SMS o WhatsApp cuando tu plan y certificado estén por vencer 
                  y cuando cuentes con pocos folios disponibles
                  </label>
                </div>
              </div>

              <LoadingButton
                className="btn btn-Crexendo font-AvenirMedium mt-4 py-2 px-4 "
                loading={IsGuardandoTelefonos}
                loadingPosition="start"
                onClick={GuardarTelefonos}
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoTelefonos ? "px-4" : "px-2"}>
                  Guardar
                </span>
              </LoadingButton>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
