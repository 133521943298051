import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";
import {EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';

export const parseEditorToHtml = (editorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()))
}

export const parseHtmlToEditor = (html) => {
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(html))
  )
}

///////////////////
/* FUNCIONES GET */
///////////////////
export const getAyuda = async (iTake, iSkip, Order, query) => {
  // return new Promise((resolve, reject) => {
  //   resolve([]);
  // });
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `ayuda/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const guardarAyuda = async (Values, Errores, setErrores) => {
  // return new Promise((resolve, reject) => {
  //   resolve({ mensaje: "Método no implementado" });
  // });
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  
  if (!Values.descripcion || Values.descripcion == "") {
    Errores.descripcion = true;
    setErrores(Errores);
    error = true;
  }
 
  Values.descripcion = Values.descripcion.replace("height:auto;", 'height: 100%').replace("width:auto;", "width:100%")
  if (!error) {
   
    let data = [];
    const url =
      (Values.id == undefined || Values.id == "")
        ? "ayuda/Insertar"
        : "ayuda/Editar";

    const body = Values;
    const res = await postdData(url, { ...body, id: body.id == -1 ? 0 : body.id });

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarAyuda = async (id) => {
  // return new Promise((resolve, reject) => {
  //   resolve({ mensaje: "Método no implementado" });
  // });
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `ayuda/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfAyuda = async (id, imgDefault) => {
  // return new Promise((resolve, reject) => {
  //   resolve({ mensaje: "Método no implementado" });
  // });
  let data = [];
  let queryParamsObj = {
    ID: id == -1 ? 0 : id,
  };
  const url = `ayuda/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta ayuda",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: data.ID == 0 ? -1 : data.ID,
          nombre: String(data.NOMBRE),
          descripcion: String(data.DESCRIPCION),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  // return new Promise((resolve, reject) => {
  //   resolve({ mensaje: "Método no implementado" });
  // });
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id == -1 ? 0 : id,
      activo: checked,
    };
    const url = `ayuda/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
