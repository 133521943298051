import { createContext, useState } from 'react';
import { getInfoPerfil } from '../Perfil/Funciones/Perfiles';
import AccEditarImagen from "../../assets/AvatarMenu.svg";
import { setLocalStorage } from './Storage';


export const ContextInfoUser = createContext();
export const GlobalProviderUser = ({ children }) => {


  const [Values, setValues] = useState({
    img: AccEditarImagen,
  });

  const setImgPerfilHeader = (img) => {
    setValues({ ...Values, img })
  }

  const setImgNegocioHeader = (img_negocio) => {
    setValues({ ...Values, img_negocio })
  }

  const [IsLoading, setIsLoading] = useState(true)


  const getInfoUsuario = (actualzarJWT = true) => {
    getInfoPerfil(AccEditarImagen, actualzarJWT)
      .then((resp) => {
        setValues(resp);
        setLocalStorage("key",resp.key);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false, resp);
      });
  };

  // Pasamos la función global al estado del proveedor
  const value = {
    getInfoUsuario,
    Values,
    IsLoading,
    setImgPerfilHeader,
    setImgNegocioHeader
  };

  return (
    <ContextInfoUser.Provider value={value}>
      {children}
    </ContextInfoUser.Provider>
  );
};
