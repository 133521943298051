import {
  FormGroup,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Menos from "./../../../../assets/Menos.svg";
import Mas from "./../../../../assets/Mas.svg";
import EliminarRojo from "./../../../../assets/EliminarRojo.svg";
import { styled } from "@mui/material/styles";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";

const Conceptos = ({
  productosCarrito = [],
  setProductosCarrito,
  Values, 
}) => {
  let TOTAL_IVA = 0;
  let TOTAL = 0;
  let SUBTOTAL = 0;

  let T_RETENCION_IEPS = 0;
  let T_RETENCION_ISR = 0;
  let T_RETENCION_IVA = 0;
  let TOTAL_DESCUENTO = 0;

  let TOTAL_IEPS = 0;

  productosCarrito.map((row) => {
    let DESCUENTO = (row.PRECIO * row.DESCUENTO) / 100;
    TOTAL_DESCUENTO += DESCUENTO;

    let iva =
      row.IVA == -1
        ? 0
        : parseFloat(row.CANTIDAD) *
          parseFloat(((row.PRECIO - DESCUENTO) * row.IVA) / 100);

    TOTAL_IVA += iva; 
    let SubTotal = parseFloat(row.CANTIDAD) * parseFloat(row.PRECIO);

    let RETENCION_IEPS = (parseFloat(row.RETENCION_IEPS) * (SubTotal - DESCUENTO)) / 100;
    let RETENCION_ISR = (parseFloat(row.RETENCION_ISR) * (SubTotal - DESCUENTO)) / 100;
    let RETENCION_IVA = (parseFloat(row.RETENCION_IVA) * (SubTotal - DESCUENTO)) / 100;

    let IEPS = (parseFloat(row.IEPS) * (SubTotal - DESCUENTO)) / 100;
    TOTAL_IEPS+=IEPS;
    
    T_RETENCION_IEPS += RETENCION_IEPS;
    T_RETENCION_ISR += RETENCION_ISR;
    T_RETENCION_IVA += RETENCION_IVA;

    TOTAL +=(SubTotal + iva + IEPS) - RETENCION_IEPS - RETENCION_ISR - RETENCION_IVA;
    SUBTOTAL += SubTotal;
  });
  console.log(productosCarrito);
  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD = Number(items[itemIndex].CANTIDAD) + 1;
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  

  const deleteItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    setProductosCarrito(items);
  };

  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value =
      target.value == "" ||
      target.value == undefined ||
      target.value == null ||
      target.value == null
        ? ""
        : target.value;
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID == ID)
      : -1;
    if (itemIndex !== -1) {
      //let cantidad = parseFloat(Value);
      //items[itemIndex].CANTIDAD = cantidad.toString();
      let cantidad = Value.toDecimal();
      items[itemIndex].CANTIDAD = !/^\d*\.?\d{0,2}$/.test(cantidad)
        ? parseFloat(cantidad).toFixed(2)
        : cantidad;
    } else {
      alert("producto no encontrado");
    }
    setProductosCarrito(items);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9fafc",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <div className="p-3">
        <Table sx={{ width: "100%" }} aria-label="spanning table">
          <TableHead className="bg-tableHead">
            <TableRow>
              <TableCell
                style={{ fontSize: "10px" }}
                className=" fw-bold font-Avenir font-AvenirBold"
              >
                ACCIONES
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                DESCRIPCIÓN
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                PRECIO
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                CANTIDAD
              </TableCell>

              {/*T_RETENCION_IEPS + T_RETENCION_ISR + T_RETENCION_IVA != 0 && (
                <TableCell
                  style={{ fontSize: "10px" }}
                  className="fw-bold font-Avenir font-AvenirBold"
                  align="left"
                >
                  RETENCIONES
                </TableCell>
              )*/}
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                DESCUENTO
              </TableCell>

              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="right"
              >
                IMPORTE
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productosCarrito.map((row) => {
              let SUBTOTAL = parseFloat(row.CANTIDAD) * parseFloat(row.PRECIO);

              let RETENCION_IEPS =
                (parseFloat(row.RETENCION_IEPS) * SUBTOTAL) / 100;
              let RETENCION_ISR =
                (parseFloat(row.RETENCION_ISR) * SUBTOTAL) / 100;
              let RETENCION_IVA =
                (parseFloat(row.RETENCION_IVA) * SUBTOTAL) / 100;

              let DESCUENTO = (row.PRECIO * row.DESCUENTO) / 100;
              let IMPORTE = parseFloat(row.CANTIDAD) * parseFloat(row.PRECIO);

              return (
                <TableRow key={row.ID}>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      className="   mx-1"
                      onClick={() => deleteItem(row)}
                    >
                      <img src={EliminarRojo} style={{ width: "15px" }} />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.DESCRIPCION}</TableCell>
                  <TableCell align="left">
                    {formatMoneda(parseFloat(row.PRECIO.toFixed(2)))}
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      style={{ maxWidth: "100px" }}
                      size="small"
                      fullWidth
                      id={row.ID}
                      label="Cantidad"
                      variant="outlined"
                      name={row.ID}
                      value={isNaN(row.CANTIDAD) ? "" : row.CANTIDAD}
                      //  helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                      onChange={handlInputChange}
                    />
                  </TableCell>

                  <TableCell align="left">
                    {row.DESCUENTO != 0 ? (
                      <>
                        -{formatMoneda(parseFloat(DESCUENTO).toFixed(2))}
                        <small> ({row.DESCUENTO + "%"})</small>
                      </>
                    ) : (
                      "$0.00"
                    )}
                  </TableCell>

                  <TableCell
                    align="right"
                    className="fw-bold font-Avenir font-AvenirBold"
                  >
                    {formatMoneda(IMPORTE)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <div className="w-100 d-flex justify-content-end mt-3">
          <div className="w-100"></div>

          <Table>
            <StyledTableRow>
              <TableCell
                className="font-AvenirMedium text-muted"
                align="right"
                style={{ fontSize: "14px" }}
              >
                SUBTOTAL
              </TableCell>
              <TableCell
                className="font-AvenirMedium text-muted"
                style={{ fontSize: "14px" }}
                align="right"
              >
                {formatMoneda(parseFloat(SUBTOTAL))}
                {" "+Values.moneda}
              </TableCell>
            </StyledTableRow>

            <StyledTableRow>
              <TableCell
                className="font-AvenirMedium text-muted"
                align="right"
                style={{ fontSize: "14px" }}
              >
                I.V.A.
              </TableCell>
              <TableCell
                className="font-AvenirMedium text-muted"
                style={{ fontSize: "14px" }}
                align="right"
              >
                {formatMoneda(parseFloat(TOTAL_IVA.toFixed(2)))}
                {" "+Values.moneda}
              </TableCell>
            </StyledTableRow>

            {T_RETENCION_IEPS != 0 && (
              <StyledTableRow>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  align="right"
                  style={{ fontSize: "14px" }}
                >
                  RETENCIÓN IEPS
                </TableCell>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  style={{ fontSize: "14px" }}
                  align="right"
                >
                  {formatMoneda(parseFloat(T_RETENCION_IEPS.toFixed(2)))}
                  {" "+Values.moneda}
                </TableCell>
              </StyledTableRow>
            )}
            {T_RETENCION_ISR != 0 && (
              <StyledTableRow>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  align="right"
                  style={{ fontSize: "14px" }}
                >
                  RETENCIÓN ISR
                </TableCell>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  style={{ fontSize: "14px" }}
                  align="right"
                >
                  {formatMoneda(parseFloat(T_RETENCION_ISR.toFixed(2)))}
                  {" "+Values.moneda}
                </TableCell>
              </StyledTableRow>
            )}
            {T_RETENCION_IVA != 0 && (
              <StyledTableRow>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  align="right"
                  style={{ fontSize: "14px" }}
                >
                  RETENCIÓN I.V.A.
                </TableCell>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  style={{ fontSize: "14px" }}
                  align="right"
                >
                  {formatMoneda(parseFloat(T_RETENCION_IVA.toFixed(2)))}
                  {" "+Values.moneda}
                </TableCell>
              </StyledTableRow>
            )}
  {TOTAL_IEPS != 0 && (
              <StyledTableRow>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  align="right"
                  style={{ fontSize: "14px" }}
                >
                  IEPS
                </TableCell>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  style={{ fontSize: "14px" }}
                  align="right"
                >
                  {formatMoneda(parseFloat(TOTAL_IEPS.toFixed(2)))}
                  {" "+Values.moneda}
                </TableCell>
              </StyledTableRow>
            )}
            {TOTAL_DESCUENTO != 0 && (
              <StyledTableRow>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  align="right"
                  style={{ fontSize: "14px" }}
                >
                  DESCUENTOS
                </TableCell>
                <TableCell
                  className="font-AvenirMedium text-muted"
                  style={{ fontSize: "14px" }}
                  align="right"
                >
                  -{formatMoneda(parseFloat(TOTAL_DESCUENTO.toFixed(2)))}
                  {" "+Values.moneda}
                </TableCell>
              </StyledTableRow>
            )}

            <StyledTableRow>
              <TableCell
                className="font-AvenirBold "
                align="right"
                style={{ fontSize: "15px" }}
              >
                TOTAL
              </TableCell>
              <TableCell
                className="font-AvenirBold "
                style={{ fontSize: "15px" }}
                align="right"
              >
                {formatMoneda(parseFloat(TOTAL - TOTAL_DESCUENTO))}
                {" "+Values.moneda}
              </TableCell>
            </StyledTableRow>
          </Table>
        </div> 
      </div>
    </>
  );
};

export default Conceptos;
