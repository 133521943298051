import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { PALETA } from "../../../../Lib/Funciones Generales/Colores"
import { Card, CardContent, ToggleButton, ToggleButtonGroup } from "@mui/material"
import Suscripcion from "../Items/Suscripcion"
import ModalSuscripcion from "../Dialogos/ModalSuscripcion"
import MisTarjetas from "../MisTarjetas"
import { LoadingButton } from "@mui/lab"

const TabSuscripcion = ({
  Suscripciones,
  setSuscripciones,
  planSeleccionado,
}) => {



  const [showDialogoDetalle, setShowDialogoDetalle] = useState(false)
  const [periodo, setPeriodo] = useState("anual");

  const handleChange = (event, newAlignment) => {
    if (newAlignment != null) {
      setPeriodo(newAlignment);

    }
  };

  return (
    <>
      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 pt-3">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Planes
            </h6>
            <p>Puedes migrar en cualquier momento al plan de tu preferencia</p>
          </div>
          <Row className="p-3 pt-0">
            <Col xs={12} className="d-flex justify-content-center mb-4">
              <ToggleButtonGroup
                color="primary"
                value={periodo}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                style={{border:"none"}}
              >
                <ToggleButton style={{ width: "180px" }} value="anual">Plan anual</ToggleButton>
                <ToggleButton style={{ width: "180px" }} value="semestral">Plan semestral</ToggleButton>
              </ToggleButtonGroup>
            </Col>
            {
              Array.isArray(Suscripciones) && (
                Suscripciones.map((data, index) => (
                  <Col xs={6} lg={4} className="mb-3">
                    <Suscripcion
                      key={'plan_' + data.ID}
                      data={data}
                      periodo={periodo}
                      planSeleccionado={planSeleccionado}
                      setShowDialogoDetalle={setShowDialogoDetalle} />
                  </Col>
                ))
              )
            }

          </Row>

          <div className="w-100 px-3">
            <hr className="my-4" />
          </div>

          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Métodos de pago
            </h6>
          </div>

          <CardContent className="p-3 pt-0">
            <MisTarjetas />
          </CardContent>

          <div className="w-100 px-3">
            <hr className="my-4" />
          </div>

          <div className="px-3 d-flex flex-wrap">
            <h6 className="mb-0 text-left font-AvenirMedium pt-2 pb-3">
              Una vez adquirida la suscripción no será posible realizar reembolsos pero si será posible cancelarla.
            </h6>
          </div>

          <div className="d-flex justify-content-end  my-3">
            <LoadingButton
              loading={false}
              loadingPosition="start"
              onClick={() => { setShowDialogoDetalle(true) }}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={false ? "px-4" : "px-2"}>
                Cambiar suscripción
              </span>
            </LoadingButton>
          </div>

        </CardContent>
      </Card>


      {
        showDialogoDetalle && (
          <ModalSuscripcion
            planes={Suscripciones}
            setShowDialogoDetalle={setShowDialogoDetalle}
            planSeleccionado={planSeleccionado}
          />
        )
      }

    </>
  )
}

export default TabSuscripcion