import { getData, postdData } from "../../Context/backend";
import { setLocalStorageJWT_Manager } from "../../Context/Storage";
import validator from "validator";


///////////////////
/* FUNCIONES GET */
///////////////////

export const getInfoPerfil = async (imgDefault, setTok = false) => {
  let data = [];
  const url = `perfilManager/Consultar?nwt=${setTok}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      //console.log(data);
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
        apellido_paterno: String(data.APELLIDO_PATERNO),
        apellido_materno: String(data.APELLIDO_MATERNO),
        correo: String(data.CORREO),
        telefono: String(data.TELEFONO),
        img:
          String(data.IMG) == ""
            ? imgDefault
            : String(data.IMG) + "?" + Math.floor(Math.random() * 1000000) + 1,
      };
      let permisos = JSON.parse(res.data.respuesta.permisos);
      console.log(permisos);
      if (setTok) {
        if (res.data.respuesta.setToken.confirm) {
          setLocalStorageJWT_Manager(res.data.respuesta.setToken.token);
        }
      }
      resolve({ Values: Values, permisos: permisos });
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    Errores.apellido_paterno = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.apellido_materno || Values.apellido_materno == "") {
    Errores.apellido_materno = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    }

    let data = [];
    const body = Values;
    //console.log(body);
    const url = "perfilManager/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const actualizarContrasena = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.contrasena_actual || Values.contrasena_actual == "") {
    Errores.contrasena_actual = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!Values.nueva_contrasena || Values.nueva_contrasena == "") {
      Errores.nueva_contrasena = true;
      setErrores(Errores);
      error = true;
    } else {
      if (Values.repetir_contrasena != Values.nueva_contrasena) {
        Errores.repetir_contrasena = true;
        setErrores(Errores);
        error = true;
      }
    }
  }

  if (!error) {
    let data = [];
    const body = {
      id: Values.id,
      contrasenA_ACTUAL: Values.contrasena_actual,
      nuevA_CONTRASENA: Values.nueva_contrasena,
      confirmaR_NUEVA_CONTRASENA: Values.repetir_contrasena,
    };
    console.log(body);
    const url = "perfilManager/CambiarPass";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        setLocalStorageJWT_Manager(data.respuesta.nwToken);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};



// export const guardarImagenBanner = async (Values, Errores, setErrores, isBannerFactura) => {
//   let error = false;


//   if (!Values.img64 || Values.img64 == "") {
//     Errores.img64 = true;
//     setErrores(Errores);
//     error = true;
//   } 

//   if (!error) {

//     if (Values.img64.includes("base64")) {
//       Values.img64 = Values.img64.split("base64,")[1];
//     }

//     let data = [];
//     const body = Values;
    
//     if(isBannerFactura){Values.ID = "7";
//     Values.TIPO = "banner";}
//     else {
//       Values.ID = "8";
//     Values.TIPO = "banner";
//     }
    
//     console.log(body);
//     const url = "img/upload";
//     const res = await postdData(url, body);
//     console.log(res);
//     return new Promise((resolve, reject) => {
//       if (!res.error) {
//         data = res.data;
//         setLocalStorageJWT_Manager(data.respuesta.nwToken);
//         resolve(data);
//       } else {
//         reject(res);
//       }
//     });
//   } else {
//     return new Promise((resolve, reject) => {
//       resolve({ mensaje: "No se cambió la imagen correctamente" });
//     });
//   }
// };
