import {
    CardContent,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { ConvertDate, GetDate } from "../../../Lib/Funciones Generales/ObtenerFecha";
import { BasicDatePicker } from "../../../Includes/DatePicker";
import FormasDePagoSat from "../../../SAT/Componentes/FormasDePagoSat";
import { Link } from "react-router-dom";
import { stringify } from "query-string";
import ModalSelectUsuario from "../../Catalogos/Clientes/Componentes/Dialogos/ModalSelectUsuario";


const RepFacturasComprasUsuarios = () => {

    const [showDialogoClientes, setShowDialogoClientes] = useState(false)
    const [datosReceptor, setDatosReceptor] = useState({
        id: 0,
        nombre: "",
    });
    const [Values, SetValues] = useState({
        estatus: 'Todos',
        forma_pago: 'Todos',
        fecha_inicio: GetDate(-7),
        fecha_fin: GetDate(0),
    })

    const getFiltro = () => {
        const filtro = { ...Values, id_cliente: 0, id_usuario: datosReceptor.id, is_manager: true }
        return stringify(filtro);
    }


    return (

        <>

            {showDialogoClientes && (
                <ModalSelectUsuario
                    handleSeleccionado={(seleccionado, user) => {
                        if (seleccionado) {
                            setDatosReceptor({ id: user.ID, nombre: user.NOMBRE })
                        }
                        setShowDialogoClientes(false)
                    }}
                />
            )}

            <Card elevation={3} className="mb-4 shadow">
                <CardContent className="p-0">
                    <div className="p-3">
                        <h6 className="font-AvenirBold"> Reporte de facturas de licencias </h6>
                    </div>
                    <Divider />
                    <div className="p-3">

                        <Row className="p-0 mb-2">

                            <Col xs={12} md={4} className="p-2 mt-md-0">
                                <BasicDatePicker
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    label="Fecha inicial"
                                    size="small"
                                    fecha={Values.fecha_inicio}
                                    setFecha={(fecha) => {
                                        SetValues({ ...Values, fecha_inicio: ConvertDate(fecha) })
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={4} className="p-2 mt-md-0">
                                <BasicDatePicker
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    label="Fecha final"
                                    size="small"
                                    fecha={Values.fecha_fin}
                                    setFecha={(fecha) => {
                                        SetValues({ ...Values, fecha_fin: ConvertDate(fecha) })
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={4} className="p-2 mt-md-0">
                                <FormControl size="small" fullWidth>
                                    <InputLabel size="small" id="demo-select-small-label">
                                        Estatus SAT
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={Values.estatus}
                                        label="Estatus SAT"
                                        fullWidth
                                        name="tipo_comprobante"
                                        onChange={({ target }) => {
                                            SetValues({ ...Values, estatus: target.value })
                                        }}
                                    >
                                        <MenuItem value={"Vigentes"}>Vigentes </MenuItem>
                                        <MenuItem value={"Cancelados"}>Cancelados</MenuItem>
                                        <MenuItem value={"Todos"}>Todos</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={4} className="p-2 mt-md-0">
                                <FormasDePagoSat
                                    size="small"
                                    showItemTodos={true}
                                    showCodigos={false}
                                    Value={Values.forma_pago}
                                    Error={false}
                                    handleInputChange={({ target }) => {
                                        SetValues({ ...Values, forma_pago: target.value })
                                    }}
                                    disabled={false}
                                />
                            </Col>
                            <Col xs={12} md={4} className="p-2 mt-md-0">
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={datosReceptor.nombre}
                                    label="Cliente"
                                    placeholder="Buscar cliente"
                                    onClick={(e) => {
                                        setTimeout(() => {
                                            e.target.blur()
                                        }, 1000)
                                        setShowDialogoClientes(true)
                                    }}
                                />
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <Link
                                        to={`/manager/pdf/repFactComprasPdf?${getFiltro()}`}
                                        target="_blank"
                                        style={{ textDecoration: "none", color: "black" }}
                                        className="mx-3"
                                    >
                                        <LoadingButton
                                            color="error"
                                            className="btn-Crexendo-error font-AvenirMedium mt-4 py-2 px-4 text-white "
                                            loading={false}
                                            loadingPosition="start"
                                            variant="contained"
                                            xs={{ with: "100$" }}
                                        >
                                            <span className={true ? "px-4" : "px-2"}>
                                                GENERAR REPORTE PDF
                                            </span>
                                        </LoadingButton>
                                    </Link>
                                    <Link
                                        to={`/manager/pdf/repFactComprasExcel?${getFiltro()}`}
                                        target="_blank"
                                        style={{ textDecoration: "none", color: "black" }}
                                    >
                                        <LoadingButton
                                            color="success"
                                            className="btn-Crexendo font-AvenirMedium ml-5 mt-4 py-2 px-4 text-white "
                                            loading={false}
                                            loadingPosition="start"
                                            variant="contained"
                                            xs={{ with: "100$" }}
                                        >
                                            <span className={true ? "px-4" : "px-2"}>
                                                GENERAR REPORTE EXCEL
                                            </span>
                                        </LoadingButton>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default RepFacturasComprasUsuarios