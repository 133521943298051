import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  useMediaQuery,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import { cancelarFactura, enviarFactura } from "../../Funciones/Facturas";
import { Col, Row } from "react-bootstrap";
import FormasDePagoSat from "../../../../../SAT/Componentes/FormasDePagoSat";
import dayjs from "dayjs";
import { BasicDatePicker } from "../../../../../Includes/DatePicker";
import { EliminarPago, addPago, getPagos } from "../../Funciones/Pagos";
import PagosFacturas from "../PagosFacturas";
import ModalEnvioFactura from "./ModalEnvioFactura";
import ModalCancelarFactura from "./ModalCancelarFactura";
import { Link } from "react-router-dom";
import Loading from "../../../../../Includes/Loading";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const ModalPagos = ({
  facturaSeleccionada,
  setFacturaSeleccionada,
  setShowModalPagos,
  getListaFacturas,
}) => {
  const [Values, setValues] = useState({
    id_factura: facturaSeleccionada.ID,
    fecha: dayjs(),
    monto: "",
    moneda: "MXN",
    tipo_cambio: "",
    forma_pago: "01",
    observaciones: "",
  });
  const [Errores, setErrores] = useState({
    id: false,
    fecha: false,
    monto: false,
    moneda: false,
    tipo_cambio: false,
    forma_pago: false,
    observaciones: false,
  });

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [IsGuardando, setIsGuardando] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const [showModalEnviarFactura, setShowModalEnviarFactura] = useState(false);
  const [showModalCancelarFactura, setShowModalCancelarFactura] =
    useState(false);
  const [pagoSeleccionado, setPagoSeleccionado] = useState([]);

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    let NewValue = Value;

    if (Name == "tipo_cambio") {
      const regex = /^[0-9]*\.?[0-9]{0,6}$/;
      if (!regex.test(Value)) {
        NewValue = Values.tipo_cambio;
      }
    }
    if (Name == "monto") {
      const regex = /^[0-9]*\.?[0-9]{0,6}$/;
      if (!regex.test(Value)) {
        NewValue = Values.monto;
      }
    }

    setValues((Values) => ({
      ...Values,
      [Name]: NewValue,
    }));
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const AñadirPago = () => {
    setIsGuardando(true);
    addPago(Values, Errores, setErrores, facturaSeleccionada.MONEDA)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          Swal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
            timer: 10000,
          }).then((result) => {
            gtePagos();
            getListaFacturas();
            setValues((Values) => ({
              ...Values,
              monto: "",
            }));
          });
        } else {
          Swal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            setIsGuardando(false);
          });
        }
      })
      .catch((data) => {
        console.log(data);
        Swal.fire({
          title: "Error",
          html: data.mensaje,
          icon: "warning",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          setIsGuardando(false);
        });
      });
  };

  const gtePagos = () => {
    setIsLoading(true);
    getPagos(facturaSeleccionada.ID)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        let factura = JSON.parse(resp.factura);
        setLista({ pagos: list, factura: factura[0] });
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  const EliminarRegistro = (id) => {
    new Swal({
      title: "¿Estás seguro de eliminar este pago?",
      text: "Esta acción será irreversible",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          EliminarPago(id)
            .then((resp) => {
              new Swal({
                title: "¡Éxito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                gtePagos();
                getListaFacturas();
              });
            })
            .catch((resp) => {
              alert("error al eliminar pago, intente mas tarde");
            });
        }
      }
    });
  };

  useEffect(() => {
    gtePagos();
  }, []);

  const ActuaizarEnviado = (id, tipo) => {
    const filteredLibraries = Lista.pagos.filter((item) => {
      if (item.ID == id) {
        if (tipo == "cancelado") {
          item.CANCELADO = 1;
        }
        if (tipo == "enviado") {
          item.ENVIADO = 1;
        }
      }
      return item;
    });
    Lista.pagos = filteredLibraries;
    setLista({ ...Lista });
  };

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog
        fullWidth
        fullScreen={isMobile}
        maxWidth="lg"
        open={true}
        PaperProps={{ elevation: 0 }}
      >
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setFacturaSeleccionada([]);
            setShowModalPagos(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">
            {facturaSeleccionada.METODO_PAGO == "PUE"
              ? "Pagos de factura "
              : "Complementos de pago"}{" "}
            {facturaSeleccionada.SERIE}-{facturaSeleccionada.FOLIO}
          </h6>
        </DialogTitle>
        <DialogContent>
          <Row>
            {facturaSeleccionada.METODO_PAGO == "PUE" ? (
              <>
                <Col sm={12} md={4} className="p-3">
                  <BasicDatePicker
                    fullWidth
                    format="DD/MM/YYYY"
                    label="Fecha de pago"
                    size="small"
                    fecha={Values.fecha}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <FormasDePagoSat
                    size="small"
                    showCodigos={true}
                    Value={Values.forma_pago}
                    Error={Errores.forma_pago}
                    handleInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <TextField
                    size="small"
                    fullWidth
                    name="monto"
                    value={Values.monto}
                    label="Monto"
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-small-label">Moneda</InputLabel>
                    <Select
                      size="small"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={Values.moneda}
                      error={Errores.moneda}
                      label="Fecha de emisión"
                      onChange={handlInputChange}
                      fullWidth
                      name="moneda"
                      defaultValue={"0"}
                    >
                      <MenuItem value={"MXN"}>MXN - Pesos Mexicanos </MenuItem>
                      <MenuItem value={"USD"}>USD - Dolares</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <TextField
                    size="small"
                    fullWidth
                    disabled={
                      Values.moneda == "MXN" &&
                      facturaSeleccionada.MONEDA != "USD"
                    }
                    name="tipo_cambio"
                    value={Values.tipo_cambio == 0 ? "" : Values.tipo_cambio}
                    label="Tipo de cambio"
                    onChange={handlInputChange}
                    error={Errores.tipo_cambio}
                    helperText={
                      Errores.tipo_cambio
                        ? "El tipo de cambio es requerido"
                        : ""
                    }
                  />
                </Col>

                <Col sm={12} md={4} className="p-3  pe-0  mb-3 mb-md-0">
                  <LoadingButton
                    disabled={facturaSeleccionada.CANCELADO == 1}
                    loading={IsGuardando}
                    loadingPosition="start"
                    onClick={() => {
                      AñadirPago();
                    }}
                    className="btn btn-Crexendo btn-Crexendo_fullWidth font-AvenirMedium  py-2 px-4  me-2"
                    variant="contained"
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {IsGuardando ? "Guardando pago..." : "Añadir pago"}
                    </span>
                  </LoadingButton>
                </Col>
              </>
            ) : (
              <Col sm={12} m className="p-3 pe-0 pt-0 text-end">
                {facturaSeleccionada.CANCELADO == 0 ? (
                  <Link
                    to={"./complemento/" + facturaSeleccionada.UUID}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <LoadingButton
                      loadingPosition="start"
                      className="btn btn-Crexendo btn-Crexendo_fullWidth font-AvenirMedium  py-2 px-4  me-2"
                      variant="contained"
                      xs={{ with: "100$" }}
                    >
                      <span className={IsGuardando ? "px-4" : "px-2"}>
                        {"Añadir complemento"}
                      </span>
                    </LoadingButton>
                  </Link>
                ) : (
                  <LoadingButton
                    disabled
                    loadingPosition="start"
                    className="btn btn-Crexendo btn-Crexendo_fullWidth font-AvenirMedium  py-2 px-4  me-2"
                    variant="contained"
                    xs={{ with: "100$" }}
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {"Añadir complemento"}
                    </span>
                  </LoadingButton>
                )}
              </Col>
            )}
            {Lista.length == 0 ? (
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            ) : (
              <Col sm={12} className="p-0 0-md-3">
                <PagosFacturas
                  Lista={Lista}
                  pagoSeleccionado={pagoSeleccionado}
                  setPagoSeleccionado={setPagoSeleccionado}
                  IsLoading={IsLoading}
                  EliminarRegistro={EliminarRegistro}
                  setShowModalEnviarFactura={setShowModalEnviarFactura}
                  setShowModalCancelarFactura={setShowModalCancelarFactura}
                />
              </Col>
            )}
          </Row>
        </DialogContent>
      </Dialog>

      {showModalEnviarFactura && pagoSeleccionado.length != 0 && (
        <ModalEnvioFactura
          ActuaizarEnviado={ActuaizarEnviado}
          facturaSeleccionada={{
            ...pagoSeleccionado,
            CORREO_CLIENTE: facturaSeleccionada.CORREO_CLIENTE,
          }}
          setFacturaSeleccionada={setPagoSeleccionado}
          setShowModalEnviarFactura={setShowModalEnviarFactura}
          tipo="PAGO"
        />
      )}

      {showModalCancelarFactura && (
        <ModalCancelarFactura
          ActuaizarEnviado={ActuaizarEnviado}
          facturaSeleccionada={pagoSeleccionado}
          setFacturaSeleccionada={setPagoSeleccionado}
          setShowModalCancelarFactura={setShowModalCancelarFactura}
          getListaFacturas={getPagos}
          tipo="PAGO"
        />
      )}
    </>
  );
};

export default ModalPagos;
