import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getOpcionesSeries } from "../Funciones/Series";

const SelectSeries = ({
  Value,
  Error,
  handlInputChange,
  name = "serie",
  tipo=""
}) => {
  const opcionInicial = { ID: "0", DESCRIPCION: "Serie" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Serie, setSerie] = useState({
    cargado: false,
    lista: [opcionInicial],
  });
  useEffect(() => {
    getOpcionesSeries(tipo)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
            setSerie({ cargado: true, lista: list });
        } else {
            setSerie({
            cargado: true,
            lista: [{ ID: "0", DESCRIPCION: "Sin series disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setSerie({
          cargado: true,
          lista: [{ ID: "0", DESCRIPCION: "Sin series disponibles" }],
        });
      });
  }, []);

  const getOpcion = () => {
    let opcion = Serie.lista.find((x) => x.ID == Value);
    if(!opcion){
      if(Serie.lista){
        if(Serie.lista.length > 0){
          opcion = Serie.lista[0]
          handlInputChange({ target: { name: name, value: String(Serie.lista[0].ID) } });
        }
      } 
    }
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Serie.cargado ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: name, value: String(value.ID) } });
        } else {
          handlInputChange({ target: { name: name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Serie.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona una serie" : ""}
            label="Serie"
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Serie"} />
  );
};

export default SelectSeries;
