import {
  Alert,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";

import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { IconContext } from "../../Includes/Menu";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import { getInfoPerfilInformacion, guardar } from "../Funciones/Perfiles";

import Loading from "../../../Includes/Loading";
import ModalCargarImagen from "../../../Lib/Imagen/Componentes/ModalCargarImagen";
import TabGenerales from "./Tab/TabGenerales";
import TabSuscripcion from "./Tab/TabSuscripcion";
import TabFacturacionDiseno from "./Tab/TabFacturacionDiseno";
import TabSoporte from "./Tab/TabSoporte";
import TabCompras from "./Tab/TabCompras";
import TabMiNegocio from "./Tab/TabMiNegocio";
import TabAddendas from "./Tab/TabAddendas";
import { ContextInfoUser } from "../../Context/ContextInfoUser";



export const DICPERFIL = {
  GEN_PRINCIPAL: 1,
  GEN_FACTURA: 2,
  GEN_CONTACTO: 3,
  GEN_CONTRASEÑA: 4,
  NEG_PRINCIPAL: 5,
  NEG_FACTURA: 6,
  NEG_CONTACTO: 7,
  NEG_CERTIFICADOS: 8
}



export default function Perfil() {

  // const avatar = useContext(IconContext);
  const usuario = useContext(ContextInfoUser)
  const { tabselect } = useParams();
  const [IsLoading, setIsLoading] = useState(true);
  const [Suscripciones, setSuscripciones] = useState([])
  const [Plantillas, setPlantillas] = useState([])
  const [Addendas, setAddendas] = useState([])

  const [openModal, setOpenModal] = useState(false);

  const [tab, setTab] = useState(tabselect == "suscripcion" ? 2 : 0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };


  const [ValuesGenerales, setValuesGenerales] = useState({
    /* Datos generales */
    id: 0,
    img: '',
    nombre: "",
    correo: "",
    telefono: "",
    plantilla_activa: 0,
    plan_activo: 0,

    /* Datos de facturacion */
    rfc: '',
    razon_social: '',
    regimen_fiscal: '',
    cp: '',

  });

  const [ValuesNegocio, setValuesNegocio] = useState({
    /* Datos generales */
    nombre: "",
    correo: "",
    telefono: "",

    /* Datos de facturacion */
    img: '',
    rfc: '',
    razon_social: '',
    regimen_fiscal: '',
    cp: '',
    id_estado: 0,

    /* Datos de certificados */
    archivoKey: { loading: false, archivo: '' },
    archivoCer: { loading: false, archivo: '' },
    cer_pass: '',
    cer_vigencia: '',
    cargar_certificados: true,
    is_carga_sertificados: false,

    /* Datos de contacto */
    nombre_contacto: '',
    nombre_materno_contacto: '',
    nombre_paterno_contacto: '',
    correo_facturacion: '',
    telefono_contacto: '',
  });

  const [ErroresGenerales, setErroresGenerales] = useState({
    /* Datos generales */
    nombre: false,
    correo: false,
    telefono: false,

    /* Datos de facturacion */
    rfc: false,
    razon_social: false,
    regimen_fiscal: false,
    cp: false,
  });

  const [ErroresNegocio, setErroresNegocio] = useState({

    /* Datos generales */
    nombre: false,
    correo: false,
    telefono: false,

    /* Datos de facturacion */
    rfc: false,
    razon_social: false,
    regimen_fiscal: false,
    cp: false,
    archivoKey: false,
    archivoCer: false,
    cer_pass: false,
    id_estado: false,

    /* Datos de contacto */
    nombre_contacto: false,
    nombre_materno_contacto: false,
    nombre_paterno_contacto: false,
    correo_facturacion: false,
    telefono_contacto: false,
  });


  // Definicion de variables para la carga de imagenes 
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    if (tab == 0) {
      setValuesGenerales({ ...ValuesGenerales, img: img });
    }
    else {
      setValuesNegocio({ ...ValuesNegocio, img: img });
    }
  };

  useEffect(() => {
    if (ValuesGenerales.img != "" && ValuesGenerales.img != usuario.Values.img) {
      // console.log("IMAGEN DE PERFIL; ", ValuesGenerales.img, usuario.Values.img)
      usuario.setImgPerfilHeader(ValuesGenerales.img)
    }
  }, [ValuesGenerales.img])

  useEffect(() => {
    if (ValuesNegocio.img != "" && ValuesNegocio.img != usuario.Values.img_negocio) {
      usuario.setImgNegocioHeader(ValuesNegocio.img)
    }
  }, [ValuesNegocio.img])



  const handleInputChange = (Values, setValues, Errores, setErrores, target, buscador) => {
    let Name = target ? target.name : buscador.name;
    let Value = target ? target.value : buscador.value;

    if (Name == "cp" || Name == "telefono" || Name == "telefono_contacto") {
      Value = Value.toNumber()
    }

    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  }

  const handlInputChangeGenerales = ({ target, buscador }) => {
    handleInputChange(ValuesGenerales, setValuesGenerales, ErroresGenerales, setErroresGenerales, target, buscador)
  };

  const handlInputChangeNegocio = ({ target, buscador }) => {
    handleInputChange(ValuesNegocio, setValuesNegocio, ErroresNegocio, setErroresNegocio, target, buscador)
  };


  // FUNCIÓN PARA LISTAR LA INFORMACIÓN
  const ConsultarPerfil = () => {
    getInfoPerfilInformacion()
      .then((resp) => {
        setValuesGenerales(resp.ValuesGenerales);
        setValuesNegocio(resp.ValuesNegocio);
        setSuscripciones(resp.Suscripciones);
        setPlantillas(resp.Plantillas);
        setAddendas(resp.Addendas)
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }


  useEffect(() => {
    document.title = "Perfil";
    ConsultarPerfil();
  }, []);

  const img_url = ValuesGenerales.img == "" ? 'https://tymtracking.blob.core.windows.net/admins/default.png' : ValuesGenerales.img;
  const img_url_negocio = ValuesNegocio.img == "" ? 'https://tymtracking.blob.core.windows.net/admins/default.png' : ValuesNegocio.img;

  // avatar.setUrl(ValuesGenerales.img == "" ? 'https://tymtracking.blob.core.windows.net/admins/default.png' : ValuesGenerales.img);


  return (
    <>

      <div className="container pb-5">
        <h2 className="font-AvenirBold mt-4 mb-2">Mi perfil</h2>
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
        >
          <Tab label="General" className="font-font-AvenirMedium" style={{ textTransform: 'none' }} />
          <Tab label="Mi negocio" className="font-font-AvenirMedium" style={{ textTransform: 'none' }} />
          <Tab label="Suscripción" className="font-font-AvenirMedium" style={{ textTransform: 'none' }} />
          <Tab label="Diseño de facturas" className="font-font-AvenirMedium" style={{ textTransform: 'none' }} />
          <Tab label="Addendas" className="font-font-AvenirMedium" style={{ textTransform: 'none' }} />
          <Tab label="Soporte" className="font-font-AvenirMedium" style={{ textTransform: 'none' }} />
          <Tab label="Historial de compras" className="font-font-AvenirMedium" style={{ textTransform: 'none' }} />
        </Tabs>



        {IsLoading ? (
          <Row className="px-3">
            <Col sm={12} className="p-3">
              <Loading />
            </Col>
          </Row>
        ) : (
          <>
            {
              tab == 0 && (
                <TabGenerales
                  img_url={img_url}
                  IsLoading={IsLoading}
                  Errores={ErroresGenerales}
                  Values={ValuesGenerales}
                  setValues={setValuesGenerales}
                  handleOpenModal={handleOpenModal}
                  handlInputChange={handlInputChangeGenerales}
                  setErrores={setErroresGenerales}
                />
              )
            }
            {
              tab == 1 && (
                <TabMiNegocio
                  ConsultarPerfil={ConsultarPerfil}
                  img_url={img_url_negocio}
                  IsLoading={IsLoading}
                  Errores={ErroresNegocio}
                  Values={ValuesNegocio}
                  setValues={setValuesNegocio}
                  handleOpenModal={handleOpenModal}
                  handlInputChange={handlInputChangeNegocio}
                  setErrores={setErroresNegocio}
                />
              )
            }
            {
              tab == 2 && (
                <TabSuscripcion
                  planSeleccionado={ValuesGenerales.plan_activo}
                  Suscripciones={Suscripciones}
                  setSuscripciones={setSuscripciones} />
              )
            }
            {
              tab == 3 && (
                <TabFacturacionDiseno
                  idPlantillaActiva={ValuesGenerales.plantilla_activa}
                  ValuesGenerales={ValuesGenerales}
                  setValuesGenerales={setValuesGenerales}
                  Plantillas={Plantillas}
                  setPlantillas={setPlantillas} />
              )
            }
            {
              tab == 4 && (
                <TabAddendas
                  ValuesGenerales={ValuesGenerales}
                  Addendas={Addendas}
                  setAddendas={setAddendas} />
              )
            }
            {
              tab == 5 && (
                <TabSoporte ValuesGenerales={ValuesGenerales} />
              )
            }
            {
              tab == 6 && (
                <TabCompras />
              )
            }
          </>
        )}




      </div>

      {openModal && (
        <ModalCargarImagen
          id={tab == 0 ? ValuesGenerales.id : ValuesNegocio.id}
          tipo={tab == 0 ? IMG.TIPO_USUARIOS : IMG.TIPO_EMPRESA}
          Titulo="Editar imagen"
          Descripcion={tab == 0 ? "Esta es la imagen que aparecerá en tu perfil." : "Esta es la imagen que aparecerá en tu negocio."}
          mostrar={openModal}
          imgUrl={tab == 0 ? img_url : img_url_negocio}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
          ConfigRecortar={{
            requerida: true,
            aspectoAlto: 1,
            aspectoAncho: 1
          }}
        />
      )}
    </>
  );
}
