import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Col, Row } from "react-bootstrap";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import { CargarImagen } from "../Funciones/CargarImagenApp";

import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import Modal from "../../../Includes/Modal";
import Swal from "sweetalert2";
import ModalRecortarImagen from "./ModalRecortarImagen";
import { getImgDimensions } from "../Funciones/CanvasPreview";

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const ModalCargarImagen = ({
  id = 0,
  tipo = "",
  Titulo = "Editar imagen",
  Descripcion = "Esta es la imagen que aparecerá en tu perfil",
  Subtitulo = "la imagen",
  requiereConfirmar = false,
  mostrar = false,
  imgUrl = AccEditarImagen,
  fnCerrar = () => {},
  fnDevolverImagen = (src) => {},
  imgDefault = false,
  ConfigRecortar = {
    requerida: false,
    aspectoAlto: 4,
    aspectoAncho: 3,
    siBajarCalidad: true,
  },
}) => {
  const [loadingRecortar, setLoadingRecortar] = useState(false);
  const [showDialogRecortar, setShowDialogoRecortar] = useState(false);
  const [error, setError] = useState({
    mostrar: false,
    mensaje: "",
    severity: "error",
  });
  const [{ img, imgRecortada, loading, width, height, subirRecorte }, setImg] =
    useState({
      img: imgUrl,
      imgRecortada: "",
      width: 0,
      height: 0,
      type: "",
      loading: false,
      subirRecorte: false,
    });

  const ConfirmarSubirImagen = (img_64, img_data) => {
    setImg({ img: img_data, loading: false });
    new Swal({
      title: "¿Estás seguro de guardar " + Subtitulo + "?",
      text: "Esta acción es irreversible",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        SubirImagen(img_64);
      } else {
        fnCerrar();
      }
    });
  };

  const SubirImagen = (img_64, img_data, subirRecorte) => {
    if (requiereConfirmar) {
      setImg({ img: img_data, loading: true, subirRecorte: subirRecorte });
    }

    CargarImagen(id == -1 ? "0" : id.toString(), tipo, img_64)
      .then((resp) => {
        if (resp.respuesta.includes("http")) {
          setError({
            mostrar: true,
            mensaje: resp.mensaje,
            severity: "success",
          });
          setImg({ img: img, loading: false });
          setTimeout(() => {
            fnDevolverImagen(resp.respuesta);
            fnCerrar();
          }, 1000);
        }
      })
      .catch((resp) => {
        console.log(resp);
        setError({
          mostrar: true,
          mensaje: "Problemas al actualizar la imagen",
          severity: "error",
        });
        setImg({ img: imgUrl, loading: false });
      });
  };

  const onDrop = useCallback((files) => {
    setImg({ img: img, loading: true, imgRecortada: "" });
    if (files) {
      if (files.length > 0) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setTimeout(() => {
            if (!ConfigRecortar.requerida) {
              setImg({
                img: reader.result,
                loading: true,
              });
            } else {
              getImgDimensions(window.URL.createObjectURL(files[0])).then(
                (resp) => {
                  setImg({
                    img: reader.result,
                    loading: true,
                    width: resp.width,
                    height: resp.height,
                  });
                }
              );
            }
          }, 1000);
        };
        reader.readAsDataURL(files[0]);
      } else {
        setImg({ img: imgUrl, loading: false });
      }
    } else {
      setImg({ img: imgUrl, loading: false });
    }
  }, []);

  // console.log("ID: ", id, " TIPO: ", tipo)

  useEffect(() => {
    console.log("SUBIR RECORTE: ", subirRecorte);
    if (img && !subirRecorte) {
      if (AccEditarImagen != img && img != imgUrl) {
        const img_64 = img.split("base64,")[1];

        if (id && id != 0) {
          let validarTipo = false;
          Object.keys(IMG).map((tipoImagen) => {
            if (IMG[tipoImagen] == tipo) {
              validarTipo = true;
            }
          });

          if (validarTipo) {
            // Validar si en la programación requiere mandar a recortar la imagen
            if (!ConfigRecortar.requerida) {
              if (!requiereConfirmar) {
                SubirImagen(img_64, img);
              } else {
                ConfirmarSubirImagen(img_64, img);
              }
            } else {
              setShowDialogoRecortar(true);
              setImg({
                img: img,
                loading: false,
                width: width,
                height: height,
                imgRecortada: "",
              });
            }
          } else {
            setImg({ img: img, loading: false });
            setError({
              mostrar: true,
              mensaje: "Falta asignar el tipo de imagen",
              severity: "error",
            });
          }
        } else {
          if (!ConfigRecortar.requerida) {
            setImg({ img: img, loading: false });
            fnDevolverImagen(img);
            fnCerrar();
          } else {
            setImg({
              img: img,
              loading: false,
              width: width,
              height: height,
              imgRecortada: "",
            });
            setShowDialogoRecortar(true);
          }
        }
      }
    } else {
      setLoadingRecortar(true);
      setTimeout(() => {
        if (id && id != 0) {
          const img_64 = img.split("base64,")[1];
          if (!requiereConfirmar) {
            SubirImagen(img_64, imgRecortada, false);
          } else {
            ConfirmarSubirImagen(img_64, imgRecortada, false);
          }
        } else {
          fnDevolverImagen(img);
          fnCerrar();
        }
      }, 1000);
    }
  }, [img]);

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 1000000 }}
        open={loading || loadingRecortar}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <StyledSnackbar
        direction="right"
        open={error.mostrar}
        autoHideDuration={6000}
        style={{ zIndex: 2000000 }}
        onClose={() => setError({ ...error, mostrar: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          style={{ zIndex: 2000000 }}
          onClose={() => setError({ ...error, mostrar: false })}
          severity={error.severity}
          sx={{ width: "100%" }}
        >
          {error.mensaje}
        </Alert>
      </StyledSnackbar>

      <Modal
        Titulo={Titulo}
        mostrar={mostrar}
        fnCerrar={fnCerrar}
        maxWidth="lg"
        textoGuardar=""
        textoCancelar=""
      >
        <Row className="mx-0 px-0">
          <Col sm={12} md={4} className="px-3 m-0 pt-0">
            <p className="font-AvenirMedium px-2 m-0 my-2">{Descripcion}</p>
          </Col>
          <Col sm={12} md={8}>
            <div
              {...getRootProps()}
              style={{ border: "1px dotted lightgray" }}
              className="p-3 mt-0 mb-4 mx-4"
            >
              <input {...getInputProps()} />
              {
                <Row className="p-4 w-100 h-100">
                  <Col sm={6} md={4} className="d-flex justify-content-center">
                    <img
                      id={"imgAzure"}
                      src={imgDefault ? AccEditarImagen : img}
                      className="img-fluid"
                    />
                  </Col>
                  <Col sm={6} md={8}>
                    <div className="w-100 h-100 d-flex">
                      <div className="m-auto">
                        <strong> Selecciona {Subtitulo} </strong>
                        <p className="text-mutted mb-0">
                          Arrastra {Subtitulo} o{" "}
                          <strong style={{ color: "#3ABE88" }}>
                            {" "}
                            selecciona
                          </strong>{" "}
                          <br />
                          desde tu ordenador
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              }
            </div>
            {fileRejections && fileRejections.length != 0 && (
              <div className="alert alert-danger py-4 mx-4" role="alert">
                <strong> Error al cargar: </strong> Solo se aceptan archivos JPG
                / JPGE / PNG / WEBP /
              </div>
            )}
          </Col>
        </Row>
      </Modal>

      {img && showDialogRecortar && (
        <ModalRecortarImagen
          img={img}
          imgWidth={width}
          imgHeight={height}
          imgUrl={imgUrl}
          aspectoAncho={ConfigRecortar.aspectoAncho}
          aspectoAlto={ConfigRecortar.aspectoAlto}
          siBajarCalidad={ConfigRecortar.siBajarCalidad}
          handleClose={(recortar, imgRecortada) => {
            setShowDialogoRecortar(false);
            if (!recortar) {
              setImg({ img: imgUrl, loading: false });
            } else {
              setImg({ img: imgRecortada, loading: false, subirRecorte: true });
              setShowDialogoRecortar(false);
            }
          }}
        />
      )}
    </>
  );
};

export default ModalCargarImagen;
