import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";
import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";
import RFCValidator from "../../../../Lib/Funciones Generales/ValidarRFC";

export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  if (Order != "1" && query != "") {
    Order = "1";
  }
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  //console.log(queryParamsObj);
  const url = `clientes/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoCliente = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `clientes/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Values = {
          id: String(data.ID),
          id_tipo_persona: String(data.ID_TIPO_PERSONA),
          nombre_comercial: String(data.NOMBRE_COMERCIAL),
          nombre_contacto: String(data.NOMBRE_CONTACTO),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO == 0 ? '' : data.TELEFONO),
          rfc: String(data.RFC),
          razon_social: String(data.RAZON_SOCIAL),
          regimen_fiscal: String(data.REGIMEN_FISCAL),
          calle: String(data.CALLE),
          numero_ext: String(data.NUMERO_EXT),
          numero_int: String(data.NUMERO_INT),
          cp: String(data.CP),
          id_estado: data.ID_ESTADO,
          id_municipio: data.ID_MUNICIPIO,
          id_localidad: data.ID_LOCALIDAD,
          colonia: String(data.COLONIA),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardar = async (
  Values = {},
  Errores = {},
  setErrores = () => { }
) => {
  try {
    let error = false;
    /* REQUERIDOS */
    if (!Values.nombre_comercial || Values.nombre_comercial == "") {
      Errores.nombre_comercial = true;
      setErrores(Errores);
      error = true;
    }

    // if (!Values.nombre_contacto || Values.nombre_contacto == "") {
    //   Errores.nombre_contacto = true;
    //   setErrores(Errores);
    //   error = true;
    // }

    if (!Values.correo || Values.correo == "") {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!validator.isEmail(Values.correo)) {
        Errores.correo = true;
        setErrores(Errores);
        error = true;
      }
    }

    // if (!Values.telefono || Values.telefono == "") {
    //   Errores.telefono = true;
    //   setErrores(Errores);
    //   error = true;
    // } else {
    //   if (!validator.isNumeric(Values.telefono)) {
    //     Errores.telefono = true;
    //     setErrores(Errores);
    //     error = true;
    //   } else if (Values.telefono.length != 10) {
    //     Errores.telefono = true;
    //     setErrores(Errores);
    //     error = true;
    //   }
    // }

    if (Values.telefono && Values.telefono != "") {
      if (!validator.isNumeric(Values.telefono)) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
      } else if (Values.telefono.length != 10) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
      }
    }

    if (!Values.razon_social || Values.razon_social == "") {
      Errores.razon_social = true;
      setErrores(Errores);
      error = true;
    }

    // if (!Values.calle || Values.calle == "") {
    //   Errores.calle = true;
    //   setErrores(Errores);
    //   error = true;
    // }


    // if (!Values. numero_ext || Values. numero_ext == "") {
    //   Errores. numero_ext = true;
    //   setErrores(Errores);
    //   error = true;
    // }



    // if (!Values.colonia || Values.colonia == "") {
    //   Errores.colonia = true;
    //   setErrores(Errores);
    //   error = true;
    // }


    // if (!Values.id_estado || Values.id_estado == "" || Values.id_estado == 0) {
    //   Errores.id_estado = true;
    //   setErrores(Errores);
    //   error = true;
    // }

    // if (!Values.id_municipio || Values.id_municipio == "" || Values.id_municipio == 0) {
    //   Errores.id_municipio = true;
    //   setErrores(Errores);
    //   error = true;
    // }


    if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
      Errores.cp = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.rfc || Values.rfc == "") {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!RFCValidator(Values.rfc)) {
        Errores.rfc = true;
        setErrores(Errores);
        error = true;
      }
    }

    if (
      !Values.regimen_fiscal ||
      Values.regimen_fiscal == "" ||
      Values.regimen_fiscal == "0"
    ) {
      Errores.regimen_fiscal = true;
      setErrores(Errores);
      error = true;
    }

    if (
      !Values.id_tipo_persona ||
      Values.id_tipo_persona == "" ||
      Values.id_tipo_persona == "0"
    ) {
      Errores.id_tipo_persona = true;
      setErrores(Errores);
      error = true;
    }

    Values.regimen_fiscal = String(Values.regimen_fiscal);
    Values.id_estado = Values.id_estado == "" ? 0 : Values.id_estado;
    Values.id_municipio = Values.id_municipio == "" ? 0 : Values.id_municipio;
    Values.id_localidad = Values.id_localidad == "" ? 0 : Values.id_localidad;

    if (!error) {
      let data = [];

      const url =
        Values.id == undefined || Values.id == "" || Values.id == 0
          ? `clientes/Insertar`
          : "clientes/Editar";

      const res = await postdData(url, Values);
      //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };

      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          //console.log(data);
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Completa todos los campos requeridos" });
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `clientes/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `clientes/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `clientes/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getOpcionesCM = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
    IS_MOSTRADOR: true,
  };
  const url = `clientes/getOpcionesClienteMostrador?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
