import { MenuItem, TextField } from "@mui/material";

const Select = ({
  Name,
  Error,
  Value,
  Opciones = [],
  Titulo = "Selecciona",
  Size = "medium",
  HelperText,
  required = false,
  handlInputChange
}) => {

  return (
    <TextField
      error={Error}
      required={required}
      name={Name}
      helperText={HelperText}
      size={Size}
      fullWidth
      id="outlined-select-currency"
      select
      onChange={handlInputChange}
      label={Titulo}
      value={Value}
      className="mt-3 mt-sm-3 mt-md-0"
    >
      {Opciones.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
