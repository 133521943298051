import { stringify } from "query-string";
import { getData, postUrl } from "../../../Context/backend";

export const getNotificaciones = async (tipo_usuario_receptor) => {
  let queryParamsObj = { tipo_usuario_receptor };
  const url = `notificacionesManager/ContadorNotificaciones?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      //console.log(res)
      const contadores = JSON.parse(res.data.respuesta.data);
      resolve({ contadores });
    } else {
      reject([]);
    }
  });
};

export const verNotificaciones = async (tipo_usuario_receptor) => {
  let queryParamsObj = { tipo_usuario_receptor };
  const url = `notificacionesManager/VerNotificaciones?${stringify(
    queryParamsObj
  )}`;
  const res = await postUrl(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      resolve({ respuesta: res.data.respuesta });
    } else {
      reject([]);
    }
  });
};
