import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Planes from "./Componentes/Planes";
import RepPlanes from "./Componentes/RepPlanes";

const routerPlanes = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepPlanes />} />
        <Route path="/add" element={<Planes />} />
        <Route path="/edit/:id" element={<Planes />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerPlanes;
