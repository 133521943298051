import { useState, useEffect } from "react";
import { Alert, Grid, Menu, MenuItem, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TableHead } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Eliminar, Desactivar } from "../Funciones/Series";

import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Tooltip from "@mui/material/Tooltip";
import MoreVert from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";

import Switch from "../../../../Includes/Switch";
import Loading from "../../../../Includes/Loading";
import AccEliminar from "../../../../assets/AccEliminar.svg";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));
export default function TableProductos({
  Lista,
  IsLoading,
  setLista,
  ValueBusqueda,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EliminarRegistro = (id) => {
    new Swal({
      title: "¿Estás seguro de eliminar esta serie?",
      text: "Esta acción es irreversible",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(id)
            .then((resp) => {
              new Swal({
                title: "¡Éxito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarserieDeTabla(id);
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };
  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarserieDeTabla = (id) => {
    const filteredLibraries = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(filteredLibraries);
  };

  const onChange = (checked, id) => {
    setIdRow(id);
    setStateRow(checked);
    ActivarOrDesactivar(id, checked);
  };

  const ActivarOrDesactivar = (id, checked) => {
    if (id != undefined) {
      Desactivar(id, checked)
        .then((resp) => {
          const ListaFiltrado = Lista.filter((item) => {
            if (item.ID == id) {
              item.ACTIVO = checked;
            }
            return item;
          });
          setLista(ListaFiltrado);
          setSeverity("success");
          setMensaje("Cambios guardados correctamente");
          setOpen(true);
        })
        .catch((resp) => {
          setSeverity("error");
          setMensaje("Error al guardar los cambios");
          setOpen(true);
        });
    }
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    if (ValueBusqueda != "") {
      setPage(0);
    }
  }, [Lista]);

  const getRow = () => {
    let row = null
    try {
      row = Lista.find(item => item.ID == idRow)
    }
    catch { }
    return row ? row : { EMITIDOS: 0 };
  }

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem>
            <Switch
              checked={stateRow}
              onChange={onChange}
              id={idRow}
              tituloActivo="Desactivar serie "
              tituloInactivo="Activar series"
              on
            />
            {stateRow ? "Desactivar" : "Activar"}
          </MenuItem>

          {
            getRow().EMITIDOS == 0 && (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  EliminarRegistro(idRow);
                }}
              >
                <IconButton>
                  <img src={AccEliminar} />
                </IconButton>
                Eliminar
              </MenuItem>
            )
          }


        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead className="bg-tableHead">
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                ACTUALIZADO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                TIPO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                SERIE
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FOLIO INICIAL
              </TableCell>

              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={5}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.ACTUALIZADO}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Grid className="">{row.TIPO}</Grid>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Grid className="">{row.NOMBRE}</Grid>
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FOLIO_INICIAL}
                  </TableCell>

                  <TableCell align="right">
                    <Grid key={row.ID} className="d-none d-sm-block">
                      <Switch
                        checked={row.ACTIVO}
                        onChange={onChange}
                        id={row.ID}
                        tituloActivo="Desactivar serie "
                        tituloInactivo="Activar serie"
                        on
                      />
                      {row.EMITIDOS == 0 && (
                        <Tooltip title="Eliminar" placement="top">
                          <IconButton onClick={() => EliminarRegistro(row.ID)}>
                            <img src={AccEliminar} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
