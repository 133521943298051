import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Autocomplete, Card, CardContent, InputAdornment, MenuItem, TextField } from '@mui/material';
import { getOpcionesAyuda } from '../../Funciones/Soporte';

import SearchIcon from "@mui/icons-material/Search";
import IconoSoporte from '../../../../assets/IconoSoporte.png'
import ModalSoporte from '../Dialogos/ModalSoporte';
import Ayuda from '../Items/Ayuda';

const TabSoporte = ({
  ValuesGenerales
}) => {

  function quitarAcentos(cadena){
    const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
    return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
  }

  const [showDialogoContacto, setShowDialogoContacto] = useState(false)
  const [ayudaSeleccionada, setAyudaSeleccionada] = useState({
    NOMBRE: '',
    DESCRIPCION: ''
  })
  const [opciones, setOpciones] = useState({
    cargado: false,
    lista: [],
    filtro: []
  });

  const consultar = (valor) => {
    getOpcionesAyuda(valor)
      .then((resp) => {

        if (resp.data.length != 0) {
          setOpciones({ cargado: true, lista: resp.data, filtro: resp.data});
        } else {
          setOpciones({
            cargado: true,
            lista: [],
          });
        }
      })
  };

  const handleChangeAcordion = (index) => {
    console.log("INDEX: ", index)
    opciones.filtro[index].mostrar = !opciones.filtro[index].mostrar
    setOpciones({ ...opciones })
  }

  useEffect(() => {
    consultar("")
  }, [])

  console.log(opciones.lista)


  return (
    <>
      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-3">
          <p className='font-AvenirBold'>
            !Hola!
          </p>
          <p className='font-AvenirMedium m-0 mt-n2 mb-3'>
            Explora nuestros artículos para encontrar las respuestas que necesitas
          </p>

          <Row className="p-0 pt-4 pb-3">
            <Col xs={12} md={8} lg={6} >
              <TextField
                fullWidth
                name="nombre"
                label="Buscar por palabra clave"
                variant="outlined"
                placeholder='Buscar'
                onChange={({ target }) => {

                  clearInterval(window.intervalo);
                  window.intervalo = setInterval(function () {
                    clearInterval(window.intervalo)
                    setOpciones({
                      ...opciones, filtro: opciones.lista.filter(
                        ayuda => (
                          quitarAcentos(ayuda.NOMBRE).toLowerCase().includes(quitarAcentos(target.value).toLocaleLowerCase()) || 
                          quitarAcentos(ayuda.DESCRIPCION).toLowerCase().includes(quitarAcentos(target.value).toLocaleLowerCase())
                        )
                      )
                    })                    
                  }, 1000);

                 
                }}
                inputProps={{ maxLength: 100 }}
              />
            </Col>
          </Row>
        </CardContent>
      </Card>

      {
        Array.isArray(opciones.filtro) && (
          opciones.filtro.map((data, index) => (
            <Ayuda
              data={data}
              handleChange={() => handleChangeAcordion(index)} />
          ))

        )
      }


      <div
        className='d-flex'
        style={{
          position: 'fixed',
          right: 20,
          bottom: 20,
          cursor: 'pointer',
          zIndex: 1
        }}
        onClick={() => setShowDialogoContacto(true)}>
        <div className='p-3 px-4 my-auto shadow me-2' style={{ backgroundColor: '#f7f7f7', borderRadius: 10 }}>
          <p className='m-0 font-AvenirBold'>¿Como podemos ayudarte?</p>
        </div>
        <img src={IconoSoporte} style={{ width: 80, height: 80 }} />
      </div>

      {
        showDialogoContacto && (
          <ModalSoporte
            EndPoint='perfil/SolicitarSoporte'
            setShowDialogoSoporte={setShowDialogoContacto}
            ValuesGenerales={ValuesGenerales}
          />
        )
      }

    </>
  )
}

export default TabSoporte