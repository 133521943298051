import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import TableFacturas from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { getFacturas } from "../Funciones/Facturas";
import { BasicDatePicker } from "../../../../Includes/DatePicker";
import {
  ConvertDate,
  GetDate,
} from "../../../../Lib/Funciones Generales/ObtenerFecha";
import ModalEnvioFactura from "./Dialogos/ModalEnvioFactura";
import ModalCancelarFactura from "./Dialogos/ModalCancelarFactura";
import { FormControl, InputLabel, Menu, MenuItem, Select } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Facturas = () => {
  const [facturaSeleccionada, setFacturaSeleccionada] = useState([]);
  const [showModalEnviarFactura, setShowModalEnviarFactura] = useState(false);
  const [showModalCancelarFactura, setShowModalCancelarFactura] =
    useState(false);

  const [estatus, setEstatus] = useState("Todos");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const [rangoFecha, setRangoFecha] = useState({
    inicio: GetDate(-7),
    fin: GetDate(0),
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeFecha = (tipo, fecha) => {
    fecha = ConvertDate(fecha);
    setRangoFecha({ ...rangoFecha, [tipo]: fecha });
  };

  const getListaFacturas = () => {
    setIsLoading(true);
    getFacturas(estatus, rangoFecha.inicio, rangoFecha.fin)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };


  const ActuaizarEnviado = (id, tipo) => {
    const filteredLibraries = Lista.filter((item) => {
      if (item.ID == id) {
        if ((tipo == "cancelado")) {
          item.CANCELADO = 1;
        }
        if ((tipo == "enviado")) {
          item.ENVIADO = 1;
        }
      } 
      return item;
    });
    setLista(filteredLibraries);
  };

  useEffect(() => {
    document.title = `Facturas`;
  }, []);

  useEffect(() => {
    getListaFacturas();
  }, [ValueBusqueda, estatus, rangoFecha]);

  return (
    <>
      <div class="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Facturas</h4>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3 mb-2">
          <Col xs={12} md={3} className="mt-3 mt-md-0">
            <FormControl size="small" fullWidth>
              <InputLabel size="small" id="demo-select-small-label">
                Estatus SAT
              </InputLabel>
              <Select
                size="small"
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={estatus}
                label="Estatus SAT"
                fullWidth
                name="tipo_comprobante"
                onChange={({ target }) => {
                  setEstatus(target.value);
                }}
              >
                <MenuItem value={"Ingreso"}>Vigentes </MenuItem>
                <MenuItem value={"Cancelados"}>Cancelados</MenuItem>
                <MenuItem value={"Todos"}>Todos</MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} md={3} className="mt-3 mt-md-0">
            <BasicDatePicker
              fullWidth
              format="DD/MM/YYYY"
              label="Fecha inicial"
              size="small"
              fecha={rangoFecha.inicio}
              setFecha={(fecha) => {
                handleChangeFecha("inicio", fecha);
              }}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3 mt-md-0">
            <BasicDatePicker
              fullWidth
              format="DD/MM/YYYY"
              label="Fecha final"
              size="small"
              fecha={rangoFecha.fin}
              setFecha={(fecha) => {
                handleChangeFecha("fin", fecha);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableFacturas
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              ValueBusqueda={ValueBusqueda}
              setFacturaSeleccionada={setFacturaSeleccionada}
              setShowModalEnviarFactura={setShowModalEnviarFactura}
              setShowModalCancelarFactura={setShowModalCancelarFactura}
            />
          </Col>
        </Row>
      </Card>

      {showModalEnviarFactura && facturaSeleccionada.length != 0 && (
        <ModalEnvioFactura
        ActuaizarEnviado={ActuaizarEnviado}
          facturaSeleccionada={facturaSeleccionada}
          setFacturaSeleccionada={setFacturaSeleccionada}
          setShowModalEnviarFactura={setShowModalEnviarFactura}
        />
      )}

      {showModalCancelarFactura && (
        <ModalCancelarFactura
        ActuaizarEnviado={ActuaizarEnviado}
          facturaSeleccionada={facturaSeleccionada}
          setFacturaSeleccionada={setFacturaSeleccionada}
          setShowModalCancelarFactura={setShowModalCancelarFactura}
          getListaFacturas={getListaFacturas}
        />
      )}
    </>
  );
};

export default Facturas;
