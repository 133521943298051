import {
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import EliminarRojo from "./../../../../../assets/EliminarRojo.svg";
import { styled } from "@mui/material/styles";
import { formatMoneda } from "../../../../../Lib/Funciones Generales/Generales";
import FormasDePagoSat from "../../../../../SAT/Componentes/FormasDePagoSat";
import { BasicDatePicker } from "../../../../../Includes/DatePicker";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import dayjs from "dayjs";
import { BasicTimePicker } from "../../../../../Includes/TimePicker";
import Switch from "../../../../../Includes/Switch";
import { Label } from "@mui/icons-material";

const DocumentosRel = ({ documentos = [], setDocumentos }) => {
  const [Errores, setErrores] = useState({
    id: false,
    fecha: false,
    monto: false,
    moneda: false,
    tipo_cambio: false,
    forma_pago: false,
    observaciones: false,
  });

  const deleteItem = (newItem) => {
    const items = [...documentos];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    setDocumentos(items);
  };

  const handlInputChange = ({ target }) => {
    let ID = target.id;
    console.log(target);

    let Value =
      target.value == undefined || target.value == null ? "" : target.value;

    const items = [...documentos];

    const itemIndex = items.findIndex((item) => item.ID == ID);

    if (itemIndex !== -1) {
      let NewValue = items[itemIndex];

      if (target.name === "tipo_cambio") {
        if (Value != "") {
          const pattern = /^-?\d*(\.\d{0,6})?$/;
          if (pattern.test(Value)) {
            let cantidad = parseFloat(Value);
            NewValue = { ...NewValue, [target.name]: Value.toDecimal() };
          }
        } else {
          NewValue = { ...NewValue, [target.name]: "" };
        }
      }
      if (target.name === "monto_pago") {
        if (Value != "") {
          const pattern = /^-?\d*(\.\d{0,2})?$/;
          if (pattern.test(Value)) {
            let cantidad = parseFloat(Value);
            NewValue = { ...NewValue, [target.name]: Value.toDecimal() };
          }
        } else {
          NewValue = { ...NewValue, [target.name]: "" };
        }
      } else if (target.name == "parcialidad") {
        let cantidad = parseFloat(Value);
        if (!Number.isInteger(cantidad)) {
          cantidad = "";
        }
        NewValue = { ...NewValue, [target.name]: cantidad };
      } else {
        NewValue = { ...NewValue, [target.name]: Value };
      }

      items[itemIndex] = NewValue;
      setDocumentos(items);
    } else {
      alert("producto no encontrado");
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9fafc",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let TOTAL_MXN = 0;
  let TOTAL_USD = 0;
  return (
    <>
      <div className="p-3">
        {documentos.map((row) => {
          if (row.moneda_pago == "MXN") {
            TOTAL_MXN += !parseFloat(row.monto_pago)
              ? 0
              : parseFloat(row.monto_pago);
          }
          if (row.moneda_pago == "USD") {
            TOTAL_USD += !parseFloat(row.monto_pago)
              ? 0
              : parseFloat(row.monto_pago);
          }
          // console.log(row.hora_pago)

          return (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      ACCIONES
                    </TableCell>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      FACTURA
                    </TableCell>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      UUID
                    </TableCell>

                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      TOTAL
                    </TableCell>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      ABONOS
                    </TableCell>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="right"
                    >
                      SALDO INSOLUTO
                    </TableCell>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.ID}>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        className="   mx-1"
                        onClick={() => deleteItem(row)}
                      >
                        <img src={EliminarRojo} style={{ width: "15px" }} />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {row.SERIE + "-" + row.FOLIO}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {row.UUID}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {formatMoneda(row.TOTAL)} {row.MONEDA}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {formatMoneda(row.MONTO_PAGADO)} {row.MONEDA}
                    </TableCell>

                    <TableCell
                      align="right"
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {formatMoneda(row.TOTAL - row.MONTO_PAGADO)} {row.MONEDA}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={6}>
                      <>
                        <Row>
                          <Col sm={12} md={4} className="p-3">
                            <TextField
                              id={row.ID}
                              size="small"
                              fullWidth
                              name="parcialidad"
                              value={row.parcialidad}
                              label="Núm. Parcialidad"
                              onChange={handlInputChange}
                            />
                          </Col>

                          <Col sm={12} md={4} className="p-3">
                            <TextField
                              id={row.ID}
                              size="small"
                              fullWidth
                              name="monto_pago"
                              value={row.monto_pago}
                              label="Monto a pagar"
                              onChange={handlInputChange}
                            />
                          </Col>

                          <Col sm={12} md={4} className="p-3">
                            <FormControl fullWidth>
                              <InputLabel id="demo-select-small-label">
                                Moneda
                              </InputLabel>
                              <Select
                                id="32423"
                                size="small"
                                labelId="demo-select-small-label"
                                value={row.moneda_pago}
                                error={Errores.moneda}
                                label="Moneda"
                                onChange={({ target }) => {
                                  handlInputChange({
                                    target: {
                                      value: target.value,
                                      name: target.name,
                                      id: row.ID,
                                    },
                                  });
                                }}
                                fullWidth
                                name="moneda_pago"
                                defaultValue={"0"}
                              >
                                <MenuItem value={"MXN"}>
                                  MXN - Pesos Mexicanos{" "}
                                </MenuItem>
                                <MenuItem value={"USD"}>USD - Dolares</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>
                          <Col sm={12} md={4} className="p-3">
                            <TextField
                              id={row.ID}
                              size="small"
                              fullWidth
                              disabled={
                                row.moneda_pago == "MXN" && row.MONEDA != "USD"
                              }
                              name="tipo_cambio"
                              value={row.tipo_cambio}
                              error={Errores.tipo_cambio}
                              helperText={
                                Errores.tipo_cambio
                                  ? "El tipo de cambio es requerido"
                                  : ""
                              }
                              label="Tipo de cambio"
                              onChange={handlInputChange}
                            />
                          </Col>

                          <Col sm={12} md={4} className="p-3">
                            <BasicDatePicker
                              id={row.ID}
                              fullWidth
                              name="fecha_pago"
                              format="DD/MM/YYYY"
                              label="Fecha de pago"
                              size="small"
                              fecha={row.fecha_pago}
                              setFecha={(fecha) => {
                                handlInputChange({
                                  target: {
                                    value: fecha,
                                    name: "fecha_pago",
                                    id: row.ID,
                                  },
                                });
                              }}
                            />
                          </Col>

                          <Col sm={12} md={4} className="p-3">
                            <BasicTimePicker
                              id={row.ID}
                              name="hora_pago"
                              label="Hora de pago"
                              size="small"
                              hora={dayjs(row.hora_pago, "HH:mm")}
                              setTime={(hora) => {
                                console.log(hora);
                                handlInputChange({
                                  target: {
                                    value: dayjs(hora).format("HH:mm"),
                                    name: "hora_pago",
                                    id: row.ID,
                                  },
                                });
                              }}
                            />
                          </Col>
                          <Col sm={12} md={4} className="p-3">
                            <FormasDePagoSat
                              id={row.ID}
                              size="small"
                              showCodigos={true}
                              Value={row.forma_pago}
                              Error={Errores.forma_pago}
                              handleInputChange={({ target }) => {
                                handlInputChange({
                                  target: {
                                    value: target.value,
                                    name: target.name,
                                    id: row.ID,
                                  },
                                });
                              }}
                            />
                          </Col>

                          <Col sm={12} md={4} className="p-3">
                            <FormControl fullWidth>
                              <InputLabel id="demo-select-small-label">
                                Impuestos
                              </InputLabel>
                              <Select
                                size="small"
                                labelId="demo-select-small-label"
                                value={row.impuesto}
                                error={Errores.impuesto}
                                label="Impuestos"
                                onChange={({ target }) => {
                                  handlInputChange({
                                    target: {
                                      value: target.value,
                                      name: target.name,
                                      id: row.ID,
                                    },
                                  });
                                }}
                                fullWidth
                                name="impuesto"
                                defaultValue={"0"}
                              >
                                <MenuItem value={"0"}>Sin impuestos</MenuItem>
                                <MenuItem value={"16"}>16% de I.V.A.</MenuItem>
                                <MenuItem value={"8"}>8% de I.V.A.</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>

                          <Col sm={12} className="px-3 py-0 text-end">
                            <label>Incluir información adicional</label>
                            <Switch
                              checked={row.siInfodeposito}
                              onChange={(checked) => {
                                handlInputChange({
                                  target: {
                                    value: checked,
                                    name: "siInfodeposito",
                                    id: row.ID,
                                  },
                                });
                              }}
                              id={row.ID}
                              tituloActivo="Desactivar "
                              tituloInactivo="Activar"
                            />
                          </Col>

                          {row.siInfodeposito && (
                            <>
                              <Col sm={12} className="px-3 py-0">
                                <Divider light />
                              </Col>
                              <Col sm={12} md={4} className="p-3">
                                <TextField
                                  id={row.ID}
                                  size="small"
                                  fullWidth
                                  name="cuenta_ordenante"
                                  value={row.cuenta_ordenante}
                                  error={Errores.cuenta_ordenante}
                                  helperText={
                                    Errores.cuenta_ordenante
                                      ? "La cuenta ordenante es requerida"
                                      : ""
                                  }
                                  label="Cuenta ordenante"
                                  onChange={handlInputChange}
                                 
                                />
                              </Col>
                              <Col sm={12} md={4} className="p-3">
                                <TextField
                                  id={row.ID}
                                  size="small"
                                  fullWidth
                                  name="cuenta_beneficiario"
                                  value={row.cuenta_beneficiario}
                                  error={Errores.cuenta_beneficiario}
                                  helperText={
                                    Errores.cuenta_beneficiario
                                      ? "La cuenta beneficiario es requerida"
                                      : ""
                                  }
                                  label="Cuenta beneficiario"
                                  onChange={handlInputChange}
                                
                                />
                              </Col>

                              <Col sm={12} md={4} className="p-3">
                                <TextField
                                  id={row.ID}
                                  size="small"
                                  fullWidth
                                  name="numero_operacion"
                                  value={row.numero_operacion}
                                  error={Errores.numero_operacion}
                                  helperText={
                                    Errores.numero_operacion
                                      ? "Número de operación es requerido"
                                      : ""
                                  }
                                  label="Número de operación"
                                  onChange={handlInputChange}
                                />
                              </Col>

                              <Col sm={12} md={4} className="p-3">
                                <TextField
                                  id={row.ID}
                                  size="small"
                                  fullWidth
                                  name="rfc_emisor"
                                  value={row.rfc_emisor}
                                  error={Errores.rfc_emisor}
                                  helperText={
                                    Errores.rfc_emisor
                                      ? "RFC banco emisor es requerido"
                                      : ""
                                  }
                                  label="RFC banco emisor"
                                  onChange={handlInputChange}
                                />
                              </Col>

                              <Col sm={12} md={4} className="p-3">
                                <TextField
                                  id={row.ID}
                                  size="small"
                                  fullWidth
                                  name="rfc_receptor"
                                  value={row.rfc_receptor}
                                  error={Errores.rfc_receptor}
                                  helperText={
                                    Errores.rfc_receptor
                                      ? "RFC banco receptor es requerido"
                                      : ""
                                  }
                                  label="RFC banco receptor"
                                  onChange={handlInputChange}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Divider className="mb-5" />
            </>
          );
        })}

        <div className="w-100 d-flex justify-content-end p-3">
          <table>
            <tr>
              <td align="left" className="pe-4 fw-bolder">
                <h5 className="font-AvenirBold">Total MXN</h5>
              </td>
              <td align="right" className="pe-4 fw-bolder">
                <h5 className="font-AvenirBold">{formatMoneda(TOTAL_MXN)}</h5>
              </td>
            </tr>
            <tr>
              <td align="left" className="pe-4 fw-bolder">
                <h5 className="font-AvenirBold">Total USD</h5>
              </td>
              <td align="right" className="pe-4 fw-bolder">
                <h5 className="font-AvenirBold">{formatMoneda(TOTAL_USD)}</h5>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default DocumentosRel;
