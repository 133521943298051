import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import RepClientes from "./Componentes/RepClientes";

const routerConfigClientes = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepClientes />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerConfigClientes;
