import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function Loading() {

      const styles = {
        colorPrimary: {
            backgroundColor: '#3ABE88',
          },
          barColorPrimary: {
            backgroundColor: '#3ABE88',
          }
      }; 
  return ( 
    <Box className='my-2' sx={{ width: '100%' }}>
      <LinearProgress style={styles}  />
    </Box>
  );
}