import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoLateral from "../../assets/LogoLateral.svg";
import AvatarMenu from "../../assets/AvatarMenu.svg";

import LateralDashboardOFF from "../../assets/LateralDashboardOFF.svg";
import LateralDashboardON from "../../assets/LateralDashboardON.svg";
import LateralClientesOFF from "../../assets/LateralClientesOFF.svg";
import LateralClientesON from "../../assets/LateralClientesON.svg";
import LateralConfOFF from "../../assets/LateralConfOFF.svg";
import LateralConfON from "../../assets/LateralConfON.svg";
import LateralSeriesON from "../../assets/LateralSeriesON.svg";
import LateralSeriesOFF from "../../assets/LateralSeriesOFF.svg";
import LateralFacturasON from "../../assets/LateralFacturasON.svg";
import LateralFacturasOFF from "../../assets/LateralFacturasOFF.svg";
import LateralDifusionON from "../../assets/LateralDifusionON.svg";
import LateralDifusionOFF from "../../assets/LateralDifusionOFF.svg";

import LicenciasON from "../../assets/LicenciasON.svg";
import LicenciasOFF from "../../assets/LicenciasOFF.svg";

import ReportesLateralOFF from "../../assets/ReportesLateralOFF.svg";
import ReportesLateralON from "../../assets/ReportesLateralON.svg";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  clearStorageJWT_Manager,
  getLocalStorage_Manager,
} from "../Context/Storage";

import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
const MySwal = withReactContent(Swal);

export default function Sidebar({ openMenu, permisosMenu, IsLoading }) {
  const location = useLocation();
  const colors = {
    focus: "#3ABE88",
    neutral: "#FFFFFF",
  };

  const [menuActivo, setMenuActivo] = useState({
    configuracion: false,
  });

  const [activeItem, setActiveItem] = useState(null);
  const [Values, setValues] = useState({
    img: AvatarMenu,
  });

  const handleClickPerfil = () => {
    if (activeItem === "Perfil") {
      setActiveItem(null);
    } else {
      setActiveItem("Perfil");
    }
  };

  const handleClickCatalogos = () => {
    if (activeItem === "Catalogos") {
      setActiveItem(null);
    } else {
      setActiveItem("Catalogos");
    }
  };

  const handleClickConfig = () => {
    if (activeItem === "Configuraciones") {
      setActiveItem(null);
    } else {
      setActiveItem("Configuraciones");
    }
  };

  const handleClickMenu = (opcion) => {
    setMenuActivo({ ...menuActivo, [opcion]: !menuActivo[opcion] });
  };

  const img_url = IsLoading
    ? getLocalStorage_Manager("img_manager")
    : Values.img;
  const CerrarSesion = () =>
    new Swal({
      title: "¿Estás seguro de cerrar sesión?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Se ha cerrado tu sesión",
          showConfirmButton: false,
          timer: 1500,
        }).then(clearStorageJWT_Manager);
      }
    });
  //Empresa
  const [IsGuardando, setIsGuardando] = useState(false);

  return (
    <div
      className=" d-flex flex-column justify-content-between h-100 "
      style={{
        backgroundColor: "#100D1C",
      }}
    >
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        style={{
          backgroundColor: "#101727",
          height: "100%",
          color: "white",
          zIndex: 1,
          paddingTop: openMenu ? "10px" : "50px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {
          //#region INICIO
        }
        {openMenu ? (
          <center>
            <Link to="inicio/" style={{ textDecoration: "none" }}>
              <img
                className="mb-5 mt-3"
                src={LogoLateral}
                width={180}
                style={{ margin: "auto" }}
              />
            </Link>
          </center>
        ) : (
          <></>
        )}
        <hr className="d-lg-none d-lg-block" />
        {/*------------------------------      PERFIL   ----------------------------------------------------*/}
        <ListItemButton
          className="ps-2 d-lg-none d-lg-block"
          onClick={handleClickPerfil}
          style={{
            color:
              location.pathname == "/manager/perfil/"
                ? colors.focus
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            <Avatar className="border" src={img_url}></Avatar>
          </ListItemIcon>
          <ListItemText
            className="text-truncate"
            style={{ maxWidth: "150px" }}
            primary={getLocalStorage_Manager("nombre_manager")}
          />
          {activeItem === "Perfil" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Perfil"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="d-lg-none d-lg-block">
            <Link
              to="perfil/"
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/manager/facturas/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton className="ps-3">
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Mi perfil" />
              </ListItemButton>
            </Link>
            <Link
              onClick={CerrarSesion}
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/manager/clientes/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton className="ps-3">
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>

        <hr className="d-lg-none d-lg-block" />

        {/*------------------------------      DASHBOARD   ----------------------------------------------------*/}
        <Link
          to="inicio/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/manager/" ||
                location.pathname == "/manager/inicio/"
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/manager/" ||
                    location.pathname == "/manager/inicio/"
                    ? LateralDashboardON
                    : LateralDashboardOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>

        {/*------------------------------      CLIENTES   ----------------------------------------------------*/}
        {permisosMenu.includes("Clientes") == true ? (
          <Link
            to="clientes/"
            style={{
              textDecoration: "none",
              color:
                location.pathname == "/manager/clientes/"
                  ? colors.focus
                  : colors.neutral,
            }}
            className="mb-1 mt-5"
          >
            <ListItemButton className="ps-2">
              <ListItemIcon>
                <img
                  src={
                    location.pathname == "/manager/clientes/"
                      ? LateralClientesON
                      : LateralClientesOFF
                  }
                  width={20}
                  style={{
                    margin: "auto",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}
        {/*------------------------------      LICENCIAS   ----------------------------------------------------*/}

        {permisosMenu.includes("Licencias") == true ? (
          <Link
            to="licencias/"
            style={{
              textDecoration: "none",
              color:
                location.pathname == "/manager/licencias/"
                  ? colors.focus
                  : colors.neutral,
            }}
            className="mb-1 mt-5"
          >
            <ListItemButton className="ps-2">
              <ListItemIcon>
                <img
                  src={
                    location.pathname == "/manager/licencias/"
                      ? LicenciasON
                      : LicenciasOFF
                  }
                  width={20}
                  style={{
                    margin: "auto",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Licencias" />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {/*------------------------------      FACTURAS   ----------------------------------------------------*/}
        {permisosMenu.includes("Facturas") == true ? (
          <Link
            to="facturas/"
            style={{
              textDecoration: "none",
              color:
                location.pathname == "/manager/facturas/"
                  ? colors.focus
                  : colors.neutral,
            }}
            className="mb-1 mt-5"
          >
            <ListItemButton className="ps-2">
              <ListItemIcon>
                <img
                  src={
                    location.pathname == "/manager/facturas/"
                      ? LateralFacturasON
                      : LateralFacturasOFF
                  }
                  width={20}
                  style={{
                    margin: "auto",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Facturas" />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}


        {/*------------------------------      REPORTES   ----------------------------------------------------*/}
        {permisosMenu.includes("Reportes") == true && (
          <Link
            to="reportes/"
            style={{
              textDecoration: "none",
              color:
                location.pathname == "/manager/reportes/"
                  ? colors.focus
                  : colors.neutral,
            }}
            className="mb-1 mt-5"
          >
            <ListItemButton className="ps-2">
              <ListItemIcon>
                <img
                  src={
                    location.pathname == "/manager/reportes/"
                      ? ReportesLateralON
                      : ReportesLateralOFF
                  }
                  width={20}
                  style={{
                    margin: "auto",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
            </ListItemButton>
          </Link>
        )}


        {/*------------------------------      REPORTES   ----------------------------------------------------*/}
        {permisosMenu.includes("Envío masivo") == true && (
          <Link
            to="envioMasivo/"
            style={{
              textDecoration: "none",
              color:
                location.pathname == "/manager/envioMasivo/"
                  ? colors.focus
                  : colors.neutral,
            }}
            className="mb-1 mt-5"
          >
            <ListItemButton className="ps-2">
              <ListItemIcon>
                <img
                  src={
                    location.pathname == "/manager/envioMasivo/"
                      ? LateralDifusionON
                      : LateralDifusionOFF
                  }
                  width={20}
                  style={{
                    margin: "auto",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Envío masivo" />
            </ListItemButton>
          </Link>
        )}


        {/*------------------------------  CATALOGOS  ----------------------------------------------------*/}
        <ListItemButton
          className="ps-2"
          onClick={handleClickCatalogos}
          style={{
            color:
              location.pathname == "/manager/planes/" ||
                location.pathname == "/manager/plantillas/" ||
                location.pathname == "/manager/addendas/"
                ? colors.focus
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            <img
              className="mb-1"
              src={
                location.pathname == "/manager/planes/" ||
                  location.pathname == "/manager/plantillas/" ||
                  location.pathname == "/manager/addendas/"
                  ? LateralSeriesON
                  : LateralSeriesOFF
              }
              width={20}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Catálogos" />
          {activeItem === "Catalogos" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Catalogos"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {permisosMenu.includes("Addendas") == true ? (
              <Link
                to="addendas/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/addendas/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Addendas" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Planes") == true ? (
              <Link
                to="planes/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/planes/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Planes" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("Plantilla") == true ? (
              <Link
                to="plantillas/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/plantillas/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Plantillas" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("Ayuda") == true ? (
              <Link
                to="ayuda/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/ayuda/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Ayuda" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("Series") == true ? (
              <Link
                to="series/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/series/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Series" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>

        {/*------------------------------      CONFIGURACIÓN  ----------------------------------------------------*/}
        <ListItemButton
          className="ps-2"
          onClick={handleClickConfig}
          style={{
            color:
              location.pathname == "/manager/configuracion/" ||
                location.pathname == "manager/correos" ||
                location.pathname == "manager/generales" ||
                location.pathname == "/manager/usuariossistema/" ||
                location.pathname == "/manager/facturacion/"
                ? colors.focus
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            <img
              className="mb-1"
              src={
                location.pathname == "/manager/configuracion/" ||
                  location.pathname == "manager/correos" ||
                  location.pathname == "manager/generales" ||
                  location.pathname == "/manager/usuariossistema/" ||
                  location.pathname == "/manager/facturacion/"
                  ? LateralConfON
                  : LateralConfOFF
              }
              width={20}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Configuración" />
          {activeItem === "Configuraciones" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={activeItem === "Configuraciones"}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {permisosMenu.includes("Facturacion") == true ? (
              <Link
                to="facturacion/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/facturacion/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Facturación" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("Correos") == true ? (
              <Link
                to="correos/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/correos/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Correos" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("Generales") == true ? (
              <Link
                to="generales/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/generales/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Generales" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("GruposDeacceso") == true ? (
              <Link
                to="gruposacceso/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/gruposacceso/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Grupos de acceso" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("Administradores") == true ? (
              <Link
                to="usuariossistema/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/manager/usuariossistema/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton className="ps-3">
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Usuarios del sistema" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
      </List>
    </div>
  );
}
