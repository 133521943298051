

import { stringify } from "query-string";
import { postUrl, postdData } from "../../Context/backend";
 
export const realizarCompraPlantilla = async (
    Values = {},
    Errores = {},
    setErrores = () => { }
  ) => {
    try {
      let error = false; 
      if (
        !Values.tarjeta ||
        Values.tarjeta == "" ||
        Values.tarjeta == "0"
      ) {
        return new Promise((resolve, reject) => {
            resolve({ mensaje: "Selecciona una tarjeta" });
          });
        error = true;
      }
  
      
      if (!error) {      
        let data = [];
       
        const url =  "plantillas/adquirirPlantilla";
  
        const res = await postdData(url, Values);
        //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };
  
        return new Promise((resolve, reject) => {
          if (!res.error) {
            data = res.data;
            //console.log(data);
            resolve(data);
          } else {
            reject(res);
          }
        });
      } else {
        return new Promise((resolve, reject) => {
          resolve({ mensaje: "Completa todos los campos requeridos" });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  

  export const defaultCompraPlantilla = async (id) => {
    if (id != undefined) {
      let data = [];
      let queryParamsObj = {
        id: id,
      };
      // console.log(queryParamsObj)
      const url = `plantillas/defaultCompra?${stringify(queryParamsObj)}`;
      const res = await postUrl(url);
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "No se pudo obtener el identificador" });
      });
    }
  };
  