import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Plantillas from "./Componentes/Plantillas";
import RepPlantillas from "./Componentes/RepPlantillas";

const routerPlantillas = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepPlantillas />} />
        <Route path="/add" element={<Plantillas />} />
        <Route path="/edit/:id" element={<Plantillas />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerPlantillas;
