import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Divider,
  Radio,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Eliminar, getLista, seDefault } from "../Funciones/Tarjetas";


import Swal from "sweetalert2";
import Loading from "../../../Includes/Loading";
import ModalTarjeta from "./Dialogos/ModalTarjeta";
import AccEliminar from "../../../assets/AccEliminar.svg";
import visa from "../../../assets/visa.jpg";
import MasterCard from "../../../assets/masterCard.png";
import conektaLogo from "../../../assets/conektaLogo.png";


export default function MisTarjetas() {
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalTarjeta, SetshowModalTarjeta] = useState(false);
  const [Lista, setLista] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const getTarjetas = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  const EliminarRegistro = (id, idTarjeta) => {
   new Swal({
      title: "¿Estás seguro de eliminar esta tarjeta?",
      text: "Esta acción es irreversible",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) { 
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(id, idTarjeta)
            .then((resp) => {
              new Swal({
                title: "¡Éxito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                getTarjetas();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };

  const MarcarDefault = (id) => {
    new Swal({
      title: "¿Estás seguro de cambiar tu forma de pago preferida?",
      text: "Podrás cambiarla cuando quieras",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          seDefault(id)
            .then((resp) => {
              new Swal({
                title: "¡Éxito!",
                text: "Actualizado correctamente",
                icon: "success",
              }).then(function () {
                getTarjetas();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };

   

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    getTarjetas();
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {showModalTarjeta ? (
        <ModalTarjeta
          SetshowModalTarjeta={SetshowModalTarjeta}
          getTarjetas={getTarjetas}
        />
      ) : (
        ""
      )}

     
          <div
            className="pt-3 d-flex flex-column "
            style={{ maxWidth: "500px" }}
          >

            {IsLoading ? <Loading /> : 
            Lista.length==0? 
            <>
            <h6 className="font-AvenirMedium text-muted mb-0">Sin formas de pago registradas</h6>
            </>
            :
             Lista.map((row) => (
              <>
                <div
                  className={(row.DEFAULT ? "tarjeta-default" : "") + " px-2 "}
                >
                  {row.DEFAULT && (
                    <label
                      style={{ fontSize: "10px" }}
                      className="font-AvenirBold mb-0"
                    >
                      Preferida{" "}
                    </label>
                  )}

                  <div className="d-flex justify-content-between mb-2">
                    <div className=" align-self-center">
                      <Radio
                        onClick={() => {
                          if (!row.DEFAULT) {
                            MarcarDefault(row.ID);
                          }
                        }}
                        checked={row.DEFAULT}
                      />
                    </div>

                    <div className=" align-self-center">
                      {" "}
                      <img
                        src={
                          row.TIPO == "visa"
                            ? visa
                            : row.TIPO == "mastercard"
                            ? MasterCard
                            : conektaLogo
                        }
                        style={{ maxWidth: "30px" }}
                      />
                    </div>
                    <div className="flex-grow-1 mx-3">
                      <h6 className="text-muted mb-0 font-AvenirMedium">
                        {row.TIPO_TARJETA} ***** {row.NUMERO}
                      </h6>
                      <h6 className="text-muted mb-0 font-AvenirMedium">
                        {row.NOMBRE}
                      </h6>
                    </div>
                    <div className=" align-self-center">
                      <Tooltip title="Eliminar" placement="top">
                        <>
                          <Button
                            onClick={() => {
                              EliminarRegistro(row.ID, row.ID_TARJETA_CONEKTA);
                            }}
                            style={{ fontSize: "11px", color: "black" }}
                          >
                            <span className="me-2 fw-bold">Eliminar</span>
                            <img src={AccEliminar} />
                          </Button>
                        </>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div>
                  <Divider light className="mt-0 mb-2" />
                </div>
              </>
            ))}
          </div>

          <Button
            onClick={() => SetshowModalTarjeta(true)}
            className="btn btn-Crexendo font-AvenirMedium mt-3 py-2 px-4 "
            variant="contained"
            xs={{ width: "100%" }}
          >
            <span className={"px-4"}>Agregar método de pago</span>
          </Button>
    </>
  );
}
