import RepFacturasUsuariosClientes from "./Componentes/RepFacturasUsuariosClientes";
import RepFacturasComprasUsuarios from "./Componentes/RepFacturasComprasUsuarios";
import { useEffect } from "react";
import RepVentasConsolidado from "./Componentes/RepVentasConsolidado";

export default function Reportes() {

    useEffect(() => {
        document.title = `Reporte de facturas`;
    }, [])
    return (
        <>
            <div className="px-0 pb-5">
                <div class="d-flex justify-content-between flex-wrap mt-4 mb-2">
                    <div className="p-2">
                        <h4 className="text-left font-AvenirBold">Reportes</h4>
                    </div>
                </div>

                <div className="w-100 pt-3">
                    <RepFacturasComprasUsuarios />
                </div>

                <div className="w-100 pt-3">
                    <RepFacturasUsuariosClientes />
                </div>

                <div className="w-100 pt-3">
                    <RepVentasConsolidado />
                </div>
            </div>
        </>
    );
}
