import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import BACKEND_URL from "../../Context/backend";
import LOGO from "../../../assets/LogoCrexendo.svg";

import { setLocalStorageJWT_Manager, setLocalStorage_Manager } from "../../Context/Storage";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { PALETA } from "../../../Lib/Funciones Generales/Colores";

export default function LoginManager() {
  const [Values, setValues] = useState({
    Correo: "soporte@flexor.mx",
    Contraseña: "Flexor2022",
  });
  const [openLoading, setOpenLoading] = useState(false);
  const [CorreoError, setCorreoError] = useState(false);
  const [ContraseñaError, setContraseñaError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });

    if (!Values.Correo || Values.Correo == "") {
      setCorreoError(true);
    } else {
      setCorreoError(false);
    }
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      return;
    } else {
      setContraseñaError(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clicLogin();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clicLogin = () => {
    let error = false;
    if (!Values.Correo || Values.Correo == "") {
      setCorreoError(true);
      error = true;
    }
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      error = true;
    }
    if (!error) {
      setOpenLoading(true);
      const info = {
        usuario: Values.Correo,
        contraseña: Values.Contraseña,
        key: "manager"
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(info),
      };
      fetch(BACKEND_URL + "accesoManager/login", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          setOpenLoading(false);
          if (response.resp.codigo == "200") {
            setLocalStorageJWT_Manager(response.resp.respuesta.token);
            setLocalStorage_Manager("img_manager", response.resp.respuesta.img);
            setLocalStorage_Manager("tipo_manager", response.resp.respuesta.tipo);
            setLocalStorage_Manager("nombre_manager", response.resp.respuesta.nombre);
            setLocalStorage_Manager("correo_manager", response.resp.respuesta.correo);
            setLocalStorage_Manager("key_manager", response.resp.respuesta.key);
            window.location.reload();
          } else {
            setMensaje("Acceso incorrecto, verifica tus credenciales");
            setOpen(true);
          }
        })
        .catch((error) => {
          setOpenLoading(false);
          setMensaje(
            "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde"
          );
          setOpen(true);
          return;
        });
    }
  };


  useEffect(() => {
    document.title = `Manager`;
  }, []);

  return (
    <>
      <Snackbar
        direction="left"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <div className="d-flex vh-100 ">
        <Card className="shadow-lg p-4 m-auto" sx={{ maxWidth: 350 }}>
          <center>
            <img
              src={LOGO}
              className="my-4"
              width={125}
              style={{ margin: "auto" }}
            />
          </center>
          <CardContent>
            <TextField
              error={CorreoError}
              fullWidth
              id="Correo"
              name="Correo"
              label="Correo electrónico"
              variant="outlined"
              value={Values.Correo}
              onChange={handlInputChange}
            />
            <TextField
              fullWidth
              error={ContraseñaError}
              id="Contraseña"
              name="Contraseña"
              label="Contraseña"
              variant="outlined"
              className="mt-3"
              type="password"
              value={Values.Contraseña}
              onChange={handlInputChange}
              onKeyDown={handleKeyDown}
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              className="btn-Crexendo mt-4"
              onClick={clicLogin}
            >
              <Typography
                style={{
                  fontFamily: "Avenir",
                  textTransform: "none",
                }}
              >
                Iniciar sesión
              </Typography>
            </Button>

            <hr className="w-100 my-4" style={{ backgroundColor: PALETA.colorSecondary }} />

            <Link
              to={"restablecer-contrasena"}
              style={{
                fontFamily: "Avenir",
                textTransform: "none",
                //color: "#65748B",
                fontWeight: 650,
                textDecoration: "none",
              }}
            >
              <Typography
                fontWeight={'400'}
                className="w-100 text-center m-1"
                style={{ color: PALETA.colorSecondary }}
              >
                Olvidé mi contraseña
              </Typography>
            </Link>


          </CardContent>
        </Card>
      </div>
    </>
  );
}
