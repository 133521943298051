import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import TableEnvioMasivo from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../../Includes/SelectOrdenamiento";
import Buscador from "../../../../Includes/Buscador";
import { asignarUsuario, getEnvioMasivo } from "../Funciones/EnvioMasivo";

import ModalSelectUsuario from "../../Clientes/Componentes/Dialogos/ModalSelectUsuario";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Backdrop, CircularProgress } from "@mui/material";
const MySwal = withReactContent(Swal);

const RepEnvioMasivo = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const [IsGuardando, setIsGuardando] = useState(false);
  const [envioMasivoSeleccionado, setEnvioMasivoSeleccionada] = useState(-1)
  const [showDialogoSelectCliente, setShowDialogoSelectCliente] = useState(false)

  useEffect(() => {
    getListaEnvioMasivo();
  }, [ValueBusqueda, ValueSelect]);

  const getListaEnvioMasivo = () => {
    setIsLoading(true);
    getEnvioMasivo(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  const mostrarAlerta = (titulo, mensaje, icono, callback) => {
    MySwal.fire({
      title: titulo,
      html: mensaje,
      icon: icono,
      confirmButtoColor: "#3ABE88",
      showConfirmButton: true,
      timer: 15700,
    }).then((result) => {
      if (callback) {
        callback()
      }
    });
  }

  const handleSelectUser = (isSeleccionado, user) => {
    console.log(user)
    if (isSeleccionado) {

      MySwal.fire({
        title: "¿Seguro que deseas asignar esta envío masivo personalizada?",
        html: 'Esta acción es irreversible',
        icon: "info",
        showDenyButton: true,
        denyButtonText: "Cancelar",
        confirmButtonText: "Si, estoy seguro",
        confirmButtonColor: "#3ABE88",
        denyButtonColor: "#65748B",
        reverseButtons: true,
        timer: 15700,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsGuardando(true);
          asignarUsuario(envioMasivoSeleccionado, user.ID)
            .then((data) => {
              if (data.codigo == "200") {
                setIsGuardando(false);
                mostrarAlerta('Correcto', data.mensaje, 'success', () => {
                  const index = Lista.findIndex(x => x.ID === envioMasivoSeleccionado)
                  if (index != -1) {
                    Lista[index].NOMBRE_CLIENTE = user.NOMBRE
                    Lista[index].ID_USUARIO_ASIGNADO = 1 // De momento esto funciona, sino implementar la recarga completa
                    setLista([...Lista])
                    setShowDialogoSelectCliente(false)
                    setEnvioMasivoSeleccionada(null)
                  }
                })
              } else {
                setIsGuardando(false);
                mostrarAlerta('Error', data.mensaje, 'error')
                setIsGuardando(false);
              }
            })
            .catch((data) => {
              setIsGuardando(false);
              mostrarAlerta('Error', 'Ocurrio un error interno', 'error')
              setIsGuardando(false);
            });
        }
      })
    } else {
      setShowDialogoSelectCliente(false)
      setEnvioMasivoSeleccionada(null)
    }
  }

  useEffect(() => {
    document.title = `Envío masivo`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Envío masivo</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>

            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-Crexendo font-AvenirMedium px-4 "
                variant="contained"
              >
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar por nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableEnvioMasivo
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              setShowDialogoSelectCliente={setShowDialogoSelectCliente}
              setRowSeleccionada={setEnvioMasivoSeleccionada}
            />
          </Col>
        </Row>
      </Card>


      {
        showDialogoSelectCliente && envioMasivoSeleccionado !== null && (
          <ModalSelectUsuario
            handleSeleccionado={handleSelectUser}
          />
        )
      }

      {
        IsGuardando && (
          <Backdrop
            sx={{ color: "#fff", zIndex: 9999999 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }

    </>
  );
};

export default RepEnvioMasivo;
