import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import GruposAccesos from "./Componentes/GruposAccesos";
import RepGrupos from "./Componentes/RepGrupos";

const GruposAcceso = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepGrupos />} />
        <Route path="/add" element={<GruposAccesos />} />
        <Route path="/edit/:id" element={<GruposAccesos />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default GruposAcceso;
