import React from "react";
import Footer from "./Componentes/Footer";
import Contacto from "./Componentes/Contacto";
import PreguntasFrecuentes from "./Componentes/PreguntasFrecuentes";
import Planes from "./Componentes/Planes";
import DashboardImgs from "./Componentes/DashboardImgs";
import Caracteristicas from "./Componentes/Caracteristicas";
import Header from "./Componentes/Header";
import FooterFlotante from "./Componentes/FooterFlotante";

function Index() {
  return (
    <div>
      <Header />
      <div className="container">
        <Caracteristicas />
        <DashboardImgs />
        <Planes />
        <PreguntasFrecuentes />
      </div>
      <Contacto />
      <Footer />
      <FooterFlotante />
    </div>
  );
}

export default Index;
