import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setLocalStorageJWT, setLocalStorage } from "../../Context/Storage";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { guardar } from "../Funciones/Login";
import { PALETA } from "../../../Lib/Funciones Generales/Colores";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import GoogleButton from "./GoogleButton";
import FacebookButton from "./FacebookButton";
import BACKEND_URL from "../../Context/backend";
import LOGO from "../../../assets/LogoCrexendo.svg";

export default function LoginUsuario() {
  const [Values, setValues] = useState({
    Correo: "",
    Contraseña: "",
  });
  const [openLoading, setOpenLoading] = useState(false);
  const [CorreoError, setCorreoError] = useState(false);
  const [ContraseñaError, setContraseñaError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });

    if (target.name == "Correo") {
      if (!Values.Correo || Values.Correo == "") {
        setCorreoError(true);
      } else {
        setCorreoError(false);
      }
    }

    if (target.name == "Contraseña") {
      if (!Values.Contraseña || Values.Contraseña == "") {
        setContraseñaError(true);
        return;
      } else {
        setContraseñaError(false);
      }
    }


  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clicLogin();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clicLogin = () => {
    let error = false;
    if (!Values.Correo || Values.Correo == "") {
      setCorreoError(true);
      error = true;
    }
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      error = true;
    }
    if (!error) {
      setOpenLoading(true);
      const info = {
        usuario: Values.Correo,
        contraseña: Values.Contraseña,
        key: "usuario"
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(info),
      };
      fetch(BACKEND_URL + "acceso/login", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          setOpenLoading(false);
          if (response.resp.codigo == "200") {
            setLocalStorageJWT(response.resp.respuesta.token);
            setLocalStorage("img", response.resp.respuesta.img);
            setLocalStorage("nombre", response.resp.respuesta.nombre);
            window.location.reload();
          } else {
            setMensaje("Acceso incorrecto, verifica tus credenciales");
            setOpen(true);
          }
        })
        .catch((error) => {
          setOpenLoading(false);
          setMensaje(
            "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde"
          );
          setOpen(true);
          return;
        });
    }
  };

  const handleRsCredencials = (obj) => {
    guardar(
      obj.nombre,
      obj.tipo,
      obj.idRS,
      obj.img,
      { correo: obj.correo },
      {},
      () => { }
    )
      .then((data) => {
        console.log("RESULTADO: ", data)
        if (data.codigo == "200") {
          setLocalStorageJWT(data.respuesta.token);
          setLocalStorage("img", data.respuesta.img);
          setLocalStorage("key", data.respuesta.key);
          window.location.reload();
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
        }
      })
      .catch((data) => {
        console.error("ERROR LOGIN: ", data);
      });
  };

  useEffect(() => {
    document.title = `Usuarios`;
  }, []);



  return (
    <div className="w-100 vh-100 d-flex flex-wrap align-items-center justidy-content-center">
      <Snackbar
        direction="left"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <div className="w-100 mt-auto" style={{ height: 10 }}></div>
      <Card className="shadow-lg p-4 mx-auto" sx={{ maxWidth: 400 }}>
        <center>
        <a href="/">
          <img
            src={LOGO}
            className="my-4"
            width={125}
            style={{ margin: "auto" }}
          />
          </a>
        </center>
        <CardContent className="pb-3 px-4">
          <TextField
            error={CorreoError}
            fullWidth
            id="Correo"
            name="Correo"
            label="Correo electrónico"
            variant="outlined"
            value={Values.Correo}
            onChange={handlInputChange}
          />
          <TextField
            fullWidth
            error={ContraseñaError}
            id="Contraseña"
            name="Contraseña"
            label="Contraseña"
            variant="outlined"
            className="mt-3"
            type="password"
            value={Values.Contraseña}
            onChange={handlInputChange}
            onKeyDown={handleKeyDown}
          />
          <Button
            fullWidth
            variant="contained"
            size="large"
            className="btn-Crexendo mt-4"
            onClick={clicLogin}
          >
            <Typography
              style={{
                fontFamily: "Avenir",
                textTransform: "none",
              }}
            >
              Iniciar sesión
            </Typography>
          </Button>


          <FacebookButton
            className="mt-3"
            handleRsCredencials={handleRsCredencials} />


          <GoogleButton
            className="mt-3"
            handleRsCredencials={handleRsCredencials} />


          <hr className="w-100 my-4" style={{ backgroundColor: PALETA.colorSecondary }} />

          <Link
            to={"restablecer-contrasena"}
            style={{
              fontFamily: "Avenir",
              textTransform: "none",
              //color: "#65748B",
              fontWeight: 650,
              textDecoration: "none",
            }}
          >
            <Typography
              fontWeight={'400'}
              className="w-100 text-center m-1"
              style={{ color: PALETA.colorSecondary }}
            >
              Olvidé mi contraseña
            </Typography>
          </Link>

          <Link
            to={"registro"}
            style={{
              fontFamily: "Avenir",
              textTransform: "none",
              //color: "#65748B",
              fontWeight: 650,
              textDecoration: "none",
            }}
          >
            <Typography
              fontWeight={'bold'}
              className="w-100 text-center m-1 mt-3"
              style={{ color: PALETA.colorPrimary }}
            >
              Crear una cuenta
            </Typography>
          </Link>




        </CardContent>
      </Card>
      <div className="w-100 mb-auto" style={{ height: 10 }}></div>
    </div>
  );
}
