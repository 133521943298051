export const PALETA = {
    colorLogo: '#4CD89F',
    colorPrimary: '#3ABE88',
    colorSecondary: '#65748B',
    // colorSecondary: '#5d6066',
    colorWarning: '#FFB020',
    colorError: '#D14343',
    colorDivision: '#DFDEE0',
    colorTexto: '#151916',
    colorFondo: '#F9FAFC'
}

export const ESTILOS = {
    checkbox: {
        color: PALETA.colorPrimary,
        '&.Mui-checked': {
            color: PALETA.colorPrimary,
        },
    }
}