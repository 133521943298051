import { useEffect, useState } from "react";
import { getLocalStorageJWT_Manager } from "./manager/Context/Storage";
import { PermissionsProvider } from './manager/Context/PermissionsContext';
import LoginManager from "./manager/Login/Componentes/LoginManager";
import StartLoad from "./Includes/StartLoad";
import Menu from "./manager/Includes/Menu";

  
const AppManagers = () => {
  
  const [isLoged, setIsLoged] = useState(false);
  const [load, setLoad] = useState(true);
  const getInfoManager = async () => {
    if (getLocalStorageJWT_Manager() != "") {
      setIsLoged(true);
    }
  };
  
  useEffect(() => {
    setLoad(true);
    getInfoManager().then(
      setTimeout(() => {
        setLoad(false);
      }, 1500)
    );
  }, []);


  return load ? <StartLoad /> : isLoged ? (
    <PermissionsProvider>
      <Menu />
    </PermissionsProvider>
  ) : (
    <LoginManager />
  );
};
export default AppManagers;
