import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import Badge from "@mui/material/Badge";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getNotificaciones, verNotificaciones } from "../Funciones/Notificaciones";
import { SocketReceptor } from "../../../../Lib/Funciones Generales/Constantes";

import NotificacionMenu from "../../../../assets/NotificacionMenu.svg";

const MenuNotificaciones = ({ Values, IsLoading }) => {

  const initNotificaciones = {
    consultar: true,
    nuevas: false,
    total: 0,
    totalVentasNuevas: 0
  }
  const [notificaciones, setNotificaciones] = useState(initNotificaciones)




  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };




  useEffect(() => {
    getNotificaciones(SocketReceptor.EMPRESA).then((resp) => {
      setNotificaciones(resp.contadores)
    })
  }, [])

  useEffect(() => {
    if (open) {
      if (!notificaciones.consultar) {
        verNotificaciones(SocketReceptor.EMPRESA).then((resp) => {
          console.log(resp)
          if (resp.respuesta == "Ok") {
            setNotificaciones({ ...initNotificaciones })
          }
        })
      }
    }
  }, [open])




  // console.log(notificaciones, Values)

  return (
    <>
      <Tooltip title="Notificaciones" onClick={handleClick}>
        {Values.pass_default ? (
          <IconButton
            className="mx-0 px-0 me-2"
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-notificaciones" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <>
              <Badge variant="dot" color="error">
                <img src={NotificacionMenu} />
              </Badge>{" "}
            </>
          </IconButton>
        ) : (
          !notificaciones.nuevas ? (
            <IconButton
              className="mx-0 px-0 me-2"
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-notificaciones" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <img src={NotificacionMenu} />
            </IconButton>
          ) : (
            <IconButton
              className="mx-0 px-0 me-2"
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-notificaciones" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <>
                <Badge badgeContent={notificaciones.total} color="error">
                  <img src={NotificacionMenu} />
                </Badge>{" "}
              </>
            </IconButton>
          )
        )}
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-notificaciones"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {Values.pass_default ? (
          <Link
            to="perfil/update"
            style={{ textDecoration: "none", color: "black" }}
          >
            <MenuItem>
              <ListItemIcon>
                <GppMaybeIcon className="text-danger" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Es necesario cambiar tu contraseña</ListItemText>
            </MenuItem>
          </Link>
        ) : (
          !notificaciones.nuevas ? (
            <MenuItem>Sin notificaciones</MenuItem>
          ) : (
            <>{
              notificaciones.totalVentasNuevas != 0 && (
                <Link to="monitor_pedidos/" style={{ textDecoration: 'none', color: '#191919' }}>
                  <MenuItem>
                    Tienes {notificaciones.totalVentasNuevas}
                    {notificaciones.totalVentasNuevas == 1 ? " nuevo pedido " : " nuevos pedidos"}
                  </MenuItem>
                </Link>
              )
            }
            </>
          )
        )}
      </Menu>
    </>
  );
};

export default MenuNotificaciones;
