import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import EnvioMasivo from "./Componentes/EnvioMasivo";
import RepEnvioMasivo from "./Componentes/RepEnvioMasivo";

const routerEnvioMasivo = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepEnvioMasivo />} />
        <Route path="/add" element={<EnvioMasivo />} />
        <Route path="/edit/:id" element={<EnvioMasivo />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerEnvioMasivo;
