import { getData, postdData } from "../../../Context/backend";

export const guardarCorreo = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.descripcion || Values.descripcion == "") {
    Errores.descripcion = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.host || Values.host == "") {
    Errores.host = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.correo_emisor || Values.correo_emisor == "") {
    Errores.correo_emisor = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.nombre_emisor || Values.nombre_emisor == "") {
    Errores.nombre_emisor = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.user || Values.user == "") {
    Errores.user = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.password || Values.password == "") {
    Errores.password = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.puerto || Values.puerto == "") {
    Errores.puerto = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "ConfigCorreos/Editar"
        : "ConfigCorreos/Editar";

    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const guardarConfNotificaciones = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.dias_vencimiento_plan || Values.dias_vencimiento_plan == "" || Values.dias_vencimiento_plan < 0) {
    Errores.dias_vencimiento_plan = true;
    setErrores(Errores);
    error = true;
  }
  
  if (!Values.dias_vencimiento_certificado || Values.dias_vencimiento_certificado == "" || Values.dias_vencimiento_certificado < 0) {
    Errores.dias_vencimiento_certificado = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.existencia_folios || Values.existencia_folios == "" || Values.existencia_folios < 0) {
    Errores.existencia_folios = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "notificacionesManager/EditarConfiguracion"
        : "notificacionesManager/EditarConfiguracion";

    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getInfoConfNotificacion = async () => {
  let data = [];
  const url = `notificacionesManager/ConsultarConfiguracion`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta condiguración de correo",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data);
        let Value = {
          dias_vencimiento_plan: data.find(config => config.KEY === 'dias_vencimiento_plan')?.VAL,
          dias_vencimiento_certificado: data.find(config => config.KEY === 'dias_vencimiento_certificado')?.VAL,
          existencia_folios: data.find(config => config.KEY === 'existencia_folios')?.VAL,
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getInfCorreo = async () => {
  let data = [];
  const url = `ConfigCorreos/Consultar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta condiguración de correo",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: String(data.ID),
          descripcion: String(data.DESCRIPCION),
          fecha: String(data.FECHA),
          host: String(data.HOST),
          correo_emisor: String(data.CORREO_EMISOR),
          nombre_emisor: String(data.NOMBRE_EMISOR),
          user: String(data.USER),
          password: String(data.PASSWORD),
          puerto: String(data.PUERTO),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
