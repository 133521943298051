import { useState } from "react"
import {
  Alert,
  Avatar,
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { DICPERFIL } from "../Perfil";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import { eliminarCuenta, guardarGenFactura, guardarGenPrincipal, requiereFactura } from "../../Funciones/Perfiles";


import RegimenFiscalSat from "../../../../SAT/Componentes/RegimenFiscalSat";
import UpdatePassword from "../UpdatePassword";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '../../../../Lib/Funciones Generales/Prototipos'
import Switch from "../../../../Includes/Switch";
import BACKEND_URL from "../../../Context/backend";


const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const TabGenerales = ({
  img_url = "",
  handleOpenModal,
  handlInputChange,
  Values,
  setValues,
  Errores,
  setErrores,
  IsLoading,
}) => {

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState({ texto: '', estatus: 'error' })
  const [IsGuardandoPrincipal, setIsGuardandoPrincipal] = useState(false)
  const [IsGuardandoFactura, setIsGuardandoFactura] = useState(false)
  const [IsProcessing, setIsProcessing] = useState(false)

  const mostrarConfirmación = (titulo, mensaje, fnCallback, config = { iconColor: '' }) => {
    MySwal.fire({
      title: titulo,
      html: mensaje,
      icon: "info",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
      timer: 15700,
      ...config
    }).then((result) => {
      if (result.isConfirmed) {
        setIsProcessing(true)
        if (fnCallback) {
          fnCallback()
        }
      }
    });
  }

  const mostrarAlerta = (data, fnCallback) => {
    if (data.codigo == "200") {
      MySwal.fire({
        title: "Correcto",
        html: data.mensaje,
        icon: "success",
        confirmButtoColor: "#3ABE88",
        showConfirmButton: false,
        timer: 1700,
      }).then((result) => {
        if (fnCallback) {
          fnCallback(data)
        }
      });
    } else {
      setMensaje({ texto: data.mensaje, estatus: 'error' });
      setOpen(true);
    }
  }

  const GuardarDatosPerfil = () => {
    setIsGuardandoPrincipal(true)
    guardarGenPrincipal(Values, Errores, setErrores)
      .then((data) => {
        mostrarAlerta(data)
        setIsGuardandoPrincipal(false)
      })
      .catch((data) => {
        setMensaje({ texto: data.mensaje, estatus: 'error' });
        setOpen(true);
        setIsGuardandoPrincipal(false)
      });
  };


  const GuardarDatosFactura = () => {
    setIsGuardandoFactura(true)
    guardarGenFactura(Values, Errores, setErrores)
      .then((data) => {
        mostrarAlerta(data)
        setIsGuardandoFactura(false)
      })
      .catch((data) => {
        setMensaje({ texto: data.mensaje, estatus: 'error' });
        setOpen(true);
        setIsGuardandoFactura(false)
      });
  };


  const onChangeRequiereFactura = (checked) => {
    requiereFactura(checked)
      .then((resp) => {
        setValues({ ...Values, is_requiere_factura: !Values.is_requiere_factura })
        setMensaje({ texto: 'Actualizado correctamente', estatus: 'success' });
        setOpen(true);
      })
      .catch((resp) => {
        setMensaje({ texto: 'Problemas al actualizar', estatus: 'error' });
        setOpen(true);
      });
  };

  const RestablecerContraseña = () => {
    const titulo = "Restablecer contraseña"
    const mensaje = 'Se enviará un correo a ' + Values.correo + ' para restablecerla ¿Deseas continuar?'
    mostrarConfirmación(titulo, mensaje, () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "text/html" },
          body: "",
        };
        fetch(BACKEND_URL + "acceso/recuperarcontrasena?correo=" + Values.correo, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            setIsProcessing(false);
            if (response.resp.codigo == "200") {
              MySwal.fire({
                title: "Correcto",
                html: "<h4>Se ha enviado información a tu correo para reestablecer tu contraseña</h4>",
                icon: "success",
                confirmButtoColor: "#FE8D27",
                showConfirmButton: true,
              }).then((result) => {
                window.localStorage.clear();
                window.location.href = "/app";
              });
            } else {
              setMensaje("El correo no esta en nuestro sistema, verifica tu correo");
              setOpen(true);

            }
          })
          .catch((error) => {
            setMensaje(
              "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde"

            );
            setOpen(true);
            return;
          });
      } catch (error) {
        console.log("ERROR: ", error)
      }

    })
  }


  const EliminarCuenta = () => {
    const titulo = "Eliminar cuenta"
    const mensaje = 'Eliminar esta cuenta y todos sus datos, esta acción será irreversible ¿Deseas continuar?'
    mostrarConfirmación(titulo, mensaje, () => {

      eliminarCuenta().then(data => {
        setIsProcessing(false)
        mostrarAlerta(data, () => {
          window.localStorage.clear();
          window.location.href = "/app";
        })
      }).catch(error => {
        setIsProcessing(false)
        setMensaje({ texto: 'Problemas al eliminar cuenta', estatus: 'error' });
        setOpen(true);
      })


    }, { iconColor: '#D14343' })
  }


  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setOpen(false)} severity={mensaje.estatus} sx={{ width: "100%" }}>
          {mensaje.texto}
        </Alert>
      </StyledSnackbar>

      {
        (IsProcessing || IsGuardandoFactura || IsGuardandoPrincipal) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }

      {/* <UpdatePassword /> */}

     

      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Información de la cuenta</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">
              <Col
                sm={12}
                className="d-flex flex-wrap align-items-center px-3"
              >
                <Avatar
                  sx={{ width: 80, height: 80 }}
                  src={img_url}
                />
                <p
                  className="text-center my-0 ms-3 font-AvenirBold"
                  style={{ color: PALETA.colorPrimary, cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar
                </p>
              </Col>
              <Col sm={12} className="p-3 mt-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 100 }}
                />
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo"
                  variant="outlined"
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                  disabled
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  value={(Values.telefono === 0 || Values.telefono === "0") ? '' : Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 10 }}
                />
              </Col>
            </Col>

            <Col sm={12} className="d-flex justify-content-end  mt-3">
              <LoadingButton
                loading={IsGuardandoPrincipal}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarDatosPerfil(DICPERFIL.GEN_PRINCIPAL)}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoPrincipal ? "px-4" : "px-2"}>
                  {IsGuardandoPrincipal ? "Guardando..." : "Guardar"}
                </span>
              </LoadingButton>
            </Col>
          </Row>
        </CardContent>
      </Card>





      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="m-0 mt-2 text-left font-AvenirBold">Información de facturación</h6>
            </Col>
            <Col sm={12} md={8} className="mt-0">
              <div className="row mx-0 px-0">
                <Col sm={12} className="d-flex justify-content-end mb-3">
                  <p className="m-0 my-auto font-Avenir"> Requieres facturar tu suscripción</p>
                  <Switch
                    checked={Values.is_requiere_factura}
                    onChange={onChangeRequiereFactura}
                    id={Values.id}
                    tituloActivo="Si"
                    tituloInactivo="No"
                    on
                  />
                </Col>
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="rfc"
                    label="RFC"
                    variant="outlined"
                    helperText={Errores.rfc ? "RFC invalido" : ""}
                    value={Values.rfc}
                    error={Errores.rfc}
                    onChange={handlInputChange}
                    inputProps={{ maxLength: 13 }}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="razon_social"
                    label="Razón social"
                    variant="outlined"
                    helperText={Errores.razon_social ? "Razón social invalida" : ""}
                    value={Values.razon_social}
                    error={Errores.razon_social}
                    onChange={handlInputChange}
                    inputProps={{ maxLength: 250 }}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <RegimenFiscalSat
                    Value={Values.regimen_fiscal}
                    Error={Errores.regimen_fiscal}
                    handlInputChange={handlInputChange}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="cp"
                    inputProps={{ maxLength: 5 }}
                    label="Código postal"
                    variant="outlined"
                    helperText={Errores.cp ? "Código postal invalido" : ""}
                    value={Values.cp}
                    error={Errores.cp}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} className="d-flex p-3 pt-4">
                  <p className="m-0 font-AvenirBold">Nota:</p>
                  <p className="m-0 font-font-AvenirMedium ms-1">Verifica los datos en tu Constancia de Situación Fiscal
                  </p>
                </Col>
              </div>
            </Col>


            <Col sm={12} className="d-flex justify-content-end  mt-3">
              <LoadingButton
                loading={IsGuardandoFactura}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={GuardarDatosFactura}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoFactura ? "px-4" : "px-2"}>
                  {IsGuardandoFactura ? "Guardando..." : "Guardar"}
                </span>
              </LoadingButton>
            </Col>
          </Row>
        </CardContent>
      </Card>



      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="m-0 mt-2 text-left font-AvenirBold">Restablecer contraseña</h6>
            </Col>
            <Col sm={12} md={8} className="mt-0 d-flex justify-content-end">
              <LoadingButton
                loadingPosition="start"
                disabled={IsLoading}
                onClick={RestablecerContraseña}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={"px-2"}>
                  Restablecer
                </span>
              </LoadingButton>
            </Col>
          </Row>
        </CardContent>
      </Card>



      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Eliminar cuenta</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">
              <p className="font-AvenirMedium">
                Eliminar esta cuenta y todos sus datos, esta acción será irreversible.
              </p>
              <Button
                onClick={EliminarCuenta}
                className="btn btn-eliminar font-AvenirBold py-2 px-4"
                variant="outlined"
              >
                Eliminar cuenta
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Card>






    </>

  )
}

export default TabGenerales;