import {
  Alert,
  Card,
  CardContent,
  Snackbar,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { actualizarContrasena } from "../Funciones/Perfiles";


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingButton from "@mui/lab/LoadingButton";

const MySwal = withReactContent(Swal);

export default function UpdatePassword() {
  const { id } = useParams();
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    contrasena_actual: "",
    nueva_contrasena: "",
    repetir_contrasena: "",
  });

  const [Errores, setErrores] = useState({
    contrasena_actual: false,
    nueva_contrasena: false,
    repetir_contrasena: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };


  const ActualizarContrasena = () => {
    setIsGuardando(true);
    actualizarContrasena(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            window.location.href = "../";
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Card className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Cambiar contraseña</h6>
            </Col>
            <Col sm={12} md={8}>
              <Col sm={12} className="p-3 mt-3">
                <TextField
                  fullWidth
                  name="contrasena_actual"
                  label="Contraseña actual"
                  variant="outlined"
                  helperText={
                    Errores.contrasena_actual
                      ? "La contraseña es incorrecta"
                      : ""
                  }
                  value={Values.contrasena_actual}
                  error={Errores.contrasena_actual}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="nueva_contrasena"
                  label="Nueva contraseña"
                  variant="outlined"
                  helperText={
                    Errores.nueva_contrasena
                      ? "Las contraseñas no coinciden"
                      : ""
                  }
                  value={Values.nueva_contrasena}
                  error={Errores.nueva_contrasena}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="repetir_contrasena"
                  label="Repite la contraseña"
                  variant="outlined"
                  helperText={
                    Errores.repetir_contrasena
                      ? "Las contraseñas no coinciden"
                      : ""
                  }
                  value={Values.repetir_contrasena}
                  error={Errores.repetir_contrasena}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
            </Col>

            <Col sm={12} className="d-flex justify-content-end mt-3">
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                onClick={() => ActualizarContrasena()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  Guardar
                </span>
              </LoadingButton>
            </Col>

          </Row>
        </CardContent>
      </Card>

    </>
  );
}
