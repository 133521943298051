import {
  FormGroup,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
 
import { styled } from "@mui/material/styles"; 
const Conceptos = () => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9fafc",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
 
  return (
    <>
      <div className="p-3">
        <Table sx={{ width: "100%" }} aria-label="spanning table">
          <TableHead className="bg-tableHead">
            <TableRow>
              <TableCell
                style={{ fontSize: "10px" }}
                className=" fw-bold font-Avenir font-AvenirBold"
              >
                CÓDIGO
              </TableCell>
               <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                UNIDAD
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                DESCRIPCIÓN
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                CANTIDAD
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                TOTAL
              </TableCell>  
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={"0"}>
              <TableCell>84111506</TableCell>
              <TableCell align="left">ACT</TableCell>
              <TableCell align="left">Pago</TableCell>
              <TableCell align="left">1</TableCell>
              <TableCell align="left">$00.00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
 
      </div>
    </>
  );
};

export default Conceptos;
