import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";

//Peticiones POST
export const Guardar = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.folio_inicial || Values.folio_inicial == "") {
    Errores.folio_inicial = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.tipo || Values.tipo == "" || Values.tipo == 0) {
    Errores.tipo = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    const body = {
      id: Values.id,
      nombre: Values.nombre,
      tipo: Values.tipo,
      folio_inicial: Values.folio_inicial,
      predeterminada: Values.predeterminada,
    };
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "seriesManager/Insertar"
        : "seriesManager/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        //res.mensaje = "asdas";
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `seriesManager/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `seriesManager/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

//Peticiones GET
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `seriesManager/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpcionesSeries = async (tipo) => {
  let data = [];
  let queryParamsObj = { tipo: tipo };
  const url = `seriesManager/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoSerie = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `seriesManager/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta serie",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          fecha: String(data.FECHA),
          nombre: String(data.NOMBRE),
          folio_inicial: data.FOLIO_INICIAL,
          tipo: String(data.TIPO),
          predeterminada: Boolean(data.PREDETERMINADO),
        };
        resolve(Values);
        console.log(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const CambiarSeries = async (ID_SERIE) => {
  if (ID_SERIE != undefined) {
    let data = [];
    let queryParamsObj = {
      ID: ID_SERIE,
    };
    const url = `seriesManager/CambiarPredeterminada?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
