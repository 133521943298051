import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Col, Row } from "react-bootstrap";
import { formatMoneda } from "../../../Funciones Generales/Generales";

export default function TabConceptos({
  data = { conceptos: [] },
  ivaTotal = 0,
  totalProds = 0,
  subTotal = 0,
}) {
  return (
    <>
      <Row>
        <Col xs={12} className="p-3">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>CODIGO</TableCell>
                  <TableCell align="center">DESCRIPCIÓN</TableCell>
                  <TableCell align="center">PRECIO UNITARIO</TableCell>
                  <TableCell align="center">I.V.A.</TableCell>
                  <TableCell align="center">CANTIDAD</TableCell>
                  <TableCell align="right">IMPORTE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.conceptos.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.CODIGO}</TableCell>
                    <TableCell align="center">{row.NOMBRE}</TableCell>
                    <TableCell align="center">
                      {formatMoneda(parseFloat(row.PRECIO_SIN_IVA.toFixed(2)))}
                      {" MXN"}
                    </TableCell>
                    <TableCell align="center">
                      {formatMoneda(
                        parseFloat(1) * parseFloat(row.TOTAL_IVA.toFixed(2))
                      )}{" MXN"}
                    </TableCell>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="right">
                      {formatMoneda(
                        parseFloat(1) *
                          (parseFloat(row.PRECIO_SIN_IVA) +
                            parseFloat(row.TOTAL_IVA))
                      )}{" MXN"}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                </TableRow>
                <TableRow>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    colSpan={1}
                  >
                    SUBTOTAL
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    align="right"
                  >
                    {formatMoneda(parseFloat(subTotal.toFixed(2)))}{" MXN"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    colSpan={1}
                  >
                    I.V.A.
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    align="right"
                  >
                    {formatMoneda(parseFloat(ivaTotal.toFixed(2)))}{" MXN"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    colSpan={1}
                  >
                    TOTAL
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    align="right"
                  >
                    {formatMoneda(parseFloat(totalProds))}{" MXN"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
    </>
  );
}
