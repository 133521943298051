import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Col, Row } from "react-bootstrap";
import {
  TextField,
  CardContent,
  Snackbar,
  Card,
  Alert,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { guardar } from "../Funciones/Login";
import { setLocalStorage, setLocalStorageJWT } from "../../Context/Storage";
import { PALETA } from "../../../Lib/Funciones Generales/Colores";


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import LOGO from "../../../assets/LogoCrexendo.svg";
import StartLoad from "../../../Includes/StartLoad";
import '../../../Lib/Funciones Generales/Prototipos'


const MySwal = withReactContent(Swal);

export default function RegistroUsuario() {
  const [Values, setValues] = useState({
    nombre: "",
    correo: "",
    telefono: "",
    contrasena: "",
    confirmcontrasena: "",
    fuente: "",
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    correo: false,
    telefono: false,
    contrasena: false,
    confirmcontrasena: false,
    fuente: false,
    contraseniaEquals: false,
  });

  const [load, setLoad] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const navigate = useNavigate();

  const handleInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }
    if (
      (Name == "contrasena" || Name == "confirmcontrasena") &&
      Errores.contraseniaEquals
    ) {
      let Errores2 = Errores;

      Errores2.contrasena = false;
      Errores2.confirmcontrasena = false;
      Errores2.contraseniaEquals = false;
      setErrores(Errores2);
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const clickRegistro = () => {
    guardar(Values.nombre, "Registro", "", "", Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            setLocalStorageJWT(data.respuesta.token);
            setLocalStorage("img", data.respuesta.img);
            window.location.href = "../app/"
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const handleRsCredencials = (obj) => {
    guardar(
      obj.nombre,
      obj.tipo,
      obj.idRS,
      obj.img,
      { correo: obj.correo },
      {},
      () => { }
    )
      .then((data) => {
        if (data.codigo == "200") {
          setLocalStorageJWT(data.respuesta.token);
          setLocalStorage("img", data.respuesta.img);
          window.location.href = "../app/"
        } else {
          setMensaje("Acceso incorrecto, verifica tus credenciales");
          setOpen(true);
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    document.title = `Usuarios`;
    setTimeout(() => {
      setLoad(true)
    }, 1500)
  }, []);

  if (!load) {
    return <StartLoad />
  }

  return (


    <div className="w-100 vh-100 d-flex flex-wrap align-items-center justidy-content-center">
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <div className="w-100 mt-auto" style={{ height: 10 }}></div>
      <Card className="shadow-lg p-4 mx-auto" sx={{ maxWidth: 400 }}>
        <center>
          <a href="/">
          <img
            src={LOGO}
            className="mt-4"
            width={125}
            style={{ margin: "auto" }}
          />   </a>
        </center>
        <CardContent className="pb-0">


          <Row> 
            <Col sm={12}>
              <TextField
                required
                fullWidth
                label="Nombre"
                name="nombre"
                variant="outlined"
                className="mt-3"
                value={Values.nombre}
                error={Errores.nombre}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Col>

            <Col sm={12}>
              <TextField
                required
                error={Errores.correo}
                fullWidth
                id="correo"
                name="correo"
                label="Correo electrónico"
                variant="outlined"
                className="mt-3"
                value={Values.correo}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Col>

          

            <Col sm={12}>
              <TextField
                required
                fullWidth
                error={Errores.contrasena}
                id="contrasena"
                name="contrasena"
                label="Contraseña"
                variant="outlined"
                className="mt-3"
                type="password"
                value={Values.contrasena}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </Col>

            <Col sm={12}>
              <TextField
                required
                fullWidth
                error={Errores.confirmcontrasena}
                id="confirmcontrasena"
                name="confirmcontrasena"
                label="Confirmar contraseña"
                variant="outlined"
                className="mt-3"
                type="password"
                value={Values.confirmcontrasena}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </Col>
            <Col sm={12}>
            <Button
            fullWidth
            variant="contained"
            size="large"
            className="btn-Crexendo mt-4"
            onClick={clickRegistro}
          >
            <Typography
              style={{
                fontFamily: "Avenir",
                textTransform: "none",
              }}
            >
              Registrarme
            </Typography>
          </Button>
          </Col>

            

            <Col sm={12}>
            
           
            <FacebookButton
            className="mt-3"
            title="Registrarme con Facebook"
            handleRsCredencials={handleRsCredencials} />


          <GoogleButton
            className="mt-3"
            title="Registrarme con Google"
            handleRsCredencials={handleRsCredencials} />


          <hr className="my-4" style={{ backgroundColor: PALETA.colorSecondary }} />

          <Link
            to={"/app"}
            style={{
              fontFamily: "Avenir",
              textTransform: "none",
              //color: "#65748B",
              fontWeight: 650,
              textDecoration: "none",
            }}
          >
            <Typography
              fontWeight={'bold'}
              className="w-100 text-center m-1 mt-3"
              style={{ color: PALETA.colorPrimary }}
            >
              Inicia sesión
            </Typography>
          </Link>
          </Col>

 

          </Row>
          



        



        </CardContent>
      </Card>
      <div className="w-100 mb-auto" style={{ height: 10 }}></div>
    </div>
  );
}
