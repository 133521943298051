import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarAyuda, getInfAyuda, parseHtmlToEditor, parseEditorToHtml } from "../Funciones/Ayuda";
import ImagenDefault from "../../../../assets/EditarImagen.svg";
import "../../../../Lib/Funciones Generales/Prototipos";
import { Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HelperError from "../../../../Includes/HelperError";



const MySwal = withReactContent(Swal);



const Ayuda = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [editorState, setEditorState] = useState(parseHtmlToEditor(''));

  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    descripcion: "",
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    descripcion: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfAyuda(id, ImagenDefault)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setEditorState(parseHtmlToEditor(resp.descripcion))
            delete resp.descripcion;
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }

    // setEditorState(parseHtmlEditor('<p>my text</p>'));
  }, []);

  const GuardarAyuda = () => {
    setIsGuardando(true);

    Values.descripcion = parseEditorToHtml(editorState)

    guardarAyuda(Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );


  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Ayuda
      </Button>

      <Card elevation={3} className="shadow">
        <CardContent className="p-0">


          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold"> {id ? "Editar pregunta" : "Nueva pregunta"}</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">
              {IsLoading ? (
                <Row className="px-3">
                  <Col sm={12} className="p-3">
                    <Loading />
                  </Col>
                </Row>
              ) : (
                <Row className="p-3">

                  <Col sm={12} className="p-3 mt-3">
                    <TextField
                      fullWidth
                      name="nombre"
                      label="Nombre de la pregunta"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={
                        Errores.nombre ? "Nombre de la pregunta no valido" : ""
                      }
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} className="p-3 mt-3">
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                    <HelperError Error={Errores.descripcion} Mensaje="Descripción de la pregunta no valida" />
                  </Col>

                </Row>
              )}
            </Col>
          </Row>
          <Stack
            className="p-3 d-flex justify-content-end w-100"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarAyuda()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Ayuda;
