import { Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DoneIcon from "@mui/icons-material/Done";
import Loading from "../../Includes/Loading";
import { getLista } from "./Funciones/Tarjetas";
import ModalTarjeta from "./Componentes/Dialogos/ModalTarjeta";
import visa from "../../assets/visa.jpg";
import MasterCard from "../../assets/masterCard.png";
import conektaLogo from "../../assets/conektaLogo.png";

const SelectTarjetas = ({ paymentMethod = "TARJETA", tarjeta, setTarjeta }) => {
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showModalTarjeta, SetshowModalTarjeta] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Lista, setLista] = useState([]);

  const getTarjetas = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
       
        setLista(list);
        setIsLoading(false);
        list.map((row) => {
          if (row.DEFAULT) {
            setTarjeta({
              id: row.ID_TARJETA_CONEKTA,
              tarjeta: row.TIPO + "**** **** **** " + row.NUMERO,
              nombre: row.NOMBRE_SUCURSAL,
              tipo: "DOMICILIO",
            });
          }
        });
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTarjetas();
  }, []);
 
  return (
    <>
      {showModalTarjeta ? (
        <ModalTarjeta
          SetshowModalTarjeta={SetshowModalTarjeta}
          getTarjetas={getTarjetas}
        />
      ) : (
        ""
      )}

      <div className="pt-2">
        {paymentMethod == "TARJETA" ? (
          <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
            Selecciona una tarjeta
          </h6>
        ) : (
          ""
        )}

        <Row>
          {IsLoading ? (
            <div className="col-12 my-5">
              <Loading />
            </div>
          ) : (
            <>
              <div className="col-12">
                {Lista.map((row) => (
                  <>
                    <div
                      onClick={() => {
                        setTarjeta({
                          id: row.ID_TARJETA_CONEKTA,
                          tarjeta: row.TIPO + "**** **** **** " + row.NUMERO,
                          nombre: row.NOMBRE_SUCURSAL,
                          tipo: "DOMICILIO",
                        });
                      }}
                      className="px-3 py-2  mt-3 direccion-hover"
                      style={{
                        backgroundColor:
                          tarjeta.id == row.ID_TARJETA_CONEKTA
                            ? "#e7f5ef"
                            : "#F7F7F7",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex justify-content-star w-100">
                        <div className="row">
                          <div className="col-12">
                            <h6 className="font-OxygenBold">{row.NOMBRE} </h6>
                          </div>

                          <div className="col-12  mt-2 mt-md-0 d-flex justify-content-star">
                            <div className="me-2">
                              <img
                                src={
                                  row.TIPO == "visa"
                                    ? visa
                                    : row.TIPO == "mastercard"
                                    ? MasterCard
                                    : conektaLogo
                                }
                                style={{ maxWidth: "30px" }}
                              />
                            </div>

                            <small>
                              <p className="font-OxygenBold my-1">
                                **** **** **** {row.NUMERO}
                              </p>
                            </small>
                          </div>
                        </div>
                        <div className="flex-grow-1 text-end align-self-center">
                          {tarjeta.id == row.ID_TARJETA_CONEKTA && (
                            <DoneIcon className="" />
                          )}
                        </div>
                      </div> 
                    </div>
                    <Divider light className="mt-0 mb-1" />
                  </>
                ))}
              </div>
              <div className="col-12 mt-3 text-star">
                <Button
                  onClick={() => SetshowModalTarjeta(true)}
                  className="btn shadow-none btn-Crexendo btn-outline-black font-Avenir  py-2 px-4 "
                  variant="contained"
                  style={{ max: "400px" }}
                >
                  <span className={"px-2 font-AvenirMedium"}>
                    Agregar tarjeta
                  </span>
                </Button>
              </div>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default SelectTarjetas;
