import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Serie from "./Componentes/Serie";
import RepSeries from "./Componentes/RepSeries";

const SeriesRuta = () => {
  console.log("renderizando router");
  return (
    <>
      <Routes>
        <Route index element={<RepSeries />} />
        <Route path="/add" element={<Serie />} />
        <Route path="/edit/:id" element={<Serie />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default SeriesRuta;
