import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { getCFDI } from "../Funciones/SAT";
import { RECEPTOR_GLOBAL } from "../../Lib/Funciones Generales/Constantes";


const UsoCFDI = ({ Value, Error, handlInputChange, Name = "uso_cfdi", disabled = false }) => {

    const opcionInicial = { CLAVE: 0, DESCRIPCION: "CFDI" }
    const [ValorFiltrado, setFiltro] = useState()
    const [CFDI, setCFDI] = useState({ cargado: false, lista: [opcionInicial] });

    
    const getOpcion = () => {
        const opcion = CFDI.lista.find(x => x.CLAVE == Value)
        if (opcion) {
            return opcion
        } else {
            return null
        }
    }


    useEffect(() => {
        getCFDI()
            .then((resp) => {
                let list = JSON.parse(resp.data);
                if (list.length != 0) {
                    setCFDI({ cargado: true, lista: list });
                }
                else {
                    setCFDI({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin CFDI disponibles" }] });
                }
            })
            .catch((resp) => {
                setCFDI({ cargado: true, lista: [{ CLAVE: 0, DESCRIPCION: "Sin CFDI disponibles" }] });
            });
    }, [])

    return (
        Value != RECEPTOR_GLOBAL.USO_CFDI ? (
            CFDI.cargado ? (
                <Autocomplete
                    fullWidth
                    disabled={disabled}
                    isOptionEqualToValue={(option, value) => {
                        return option.CLAVE == value.CLAVE
                    }}
                    defaultValue={getOpcion}
                    inputValue={ValorFiltrado}
                    onChange={(e, value) => {
                        if (value) {
                            handlInputChange({ target: { name: Name, value: value.CLAVE } })
                        }
                        else {
                            handlInputChange({ target: { name: Name, value: "" } })
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        setFiltro(newInputValue);
                    }}
                    options={CFDI.lista}
                    getOptionLabel={(option) => {
                        return option.DESCRIPCION
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                disabled={disabled}
                                {...params}
                                error={Error}
                                helperText={Error ? "Selecciona un CFDI" : ""}
                                label="Uso de CFDI"
                            />
                        )
                    }}
                />
            ) : (
                <TextField
                    fullWidth
                    disabled={true}
                    value={"CFDI"}
                />
            )
        ) : (
            <TextField
                label="Uso de CFDI"
                fullWidth
                disabled={true}
                value={getOpcion().DESCRIPCION}
            />
        )
    )
}

export default UsoCFDI