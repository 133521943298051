import {
  getLocalStorageJWT_Manager,
  clearStorageJWT_Manager,
} from "./../Context/Storage";

import { getLocalStorageJWT } from "../../app/Context/Storage";

export const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://crexendoapi.azurewebsites.net/api/"
    : "http://localhost:7053/api/";

export const PRUEBAS = process.env.NODE_ENV === "production" ? 0 : 1;

//const BACKEND_URL = "https://tymtrackingapi.azurewebsites.net/api/"
//const BACKEND_URL = "http://localhost:7053/api/";
export const KEY = "27a7f6f3-f9fe-421f-ab72-fdcbf751ff97";

export function postdData(url, body, isUser = false) {
  let bearer_token =
    isUser === true ? getLocalStorageJWT() : getLocalStorageJWT_Manager();
  try {
    return fetch(BACKEND_URL + url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + bearer_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status != 200) {
          console.log(response);
          return {
            resp: {
              codigo: String(response.status),
              mensaje: "Error: " + response.statusText,
            },
          };
        }
        return response.json();
      })
      .then((response) => {
        //console.log(response);
        if (response.resp.codigo == "200") {
          return { error: false, data: response.resp };
        } else {
          return {
            error: true,
            mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
            data: response.resp,
          };
        }
      })
      .catch((error) => {
        console.log(error);
        return { error: true, mensaje: error };
      });
  } catch (error) {
    console.log(error);
  }
}

export function postUrl(url) {
  let bearer_token = getLocalStorageJWT_Manager();
  return fetch(BACKEND_URL + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status != 200) {
        console.log(response);
        return {
          resp: {
            codigo: String(response.status),
            mensaje: "Error: " + response.statusText,
          },
        };
      }
      return response.json();
    })
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        return {
          error: true,
          mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return { error: true, mensaje: error };
    });
}

export const getData = (url, isUser = false) => {
  let bearer_token =
    isUser === true ? getLocalStorageJWT() : getLocalStorageJWT_Manager();
  return fetch(BACKEND_URL + url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status == 401) {
        console.log(bearer_token, isUser);
      }
      if (response.status != 200) {
        console.log(response);
        return {
          resp: {
            codigo: String(response.status),
            mensaje: "Error: " + response.statusText,
          },
        };
      }
      return response.json();
    })
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        if (response.resp.codigo == "403") {
          clearStorageJWT_Manager();
        } else {
          return {
            error: true,
            mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
          };
        }
      }
    })
    .catch((error) => {
      return { error: true, mensaje: error };
    });
};
export default BACKEND_URL;
