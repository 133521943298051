import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers"; 
import { GetDate } from "../Lib/Funciones Generales/ObtenerFecha";

// import "dayjs/locale/es-mx";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

export const BasicTimePicker = ({
  hora,
  setTime,
  label,
  format = "HH:mm",
  size = "small",
  disabledd = false,
  name="",
}) => {
  const [Value, setValue] = useState();
  useEffect(() => { 
    if (!hora) { 
      hora = String(dayjs().hour() + ":" + dayjs().minute()); 
      setValue(hora);
    } else {
      setValue( hora);
    }
  }, [hora]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-MX">
      <TimePicker
      fullWidth
      className="w-100"
        disabled={disabledd}
        ampm={false}
        orientation="landscape"
        label={label}
        name={name} 
        value={Value}
        inputFormat={format}
        onChange={setTime}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
