import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { clearStorageJWT, getLocalStorage } from "../Context/Storage";
import MenuMiPerfil from "../../assets/MenuMiPerfil.svg";
import MenuCerrarSes from "../../assets/MenuCerrarSes.svg";
import MenuSuscripcion from "../../assets/MenuSuscripcion.svg";
import EditarImagen from "../../assets/EditarImagen.svg";
import Swal from "sweetalert2";
import { Avatar, Divider } from "@mui/material";
import { useState } from "react";

const CerrarSesion = () =>
  new Swal({
    title: "¿Estás seguro de cerrar sesión?",
    icon: "warning",
    showDenyButton: true,
    denyButtonText: "Cancelar",
    confirmButtonText: "Si, estoy seguro",
    confirmButtonColor: "#3ABE88",
    denyButtonColor: "#65748B",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        icon: "success",
        title: "Se ha cerrado tu sesión",
        showConfirmButton: false,
        timer: 2000,
      }).then(clearStorageJWT);
    }
  });
const AccountMenu = ({ Values, IsLoading }) => {
  // console.log("CARGANDO: ", IsLoading, Values)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const img_url = IsLoading ? getLocalStorage("img") : Values.img;
  const negocio = IsLoading ? "..." : Values.negocio ?? "Mi negocio";
  const correo = IsLoading ? "..." : Values.correo_negocio ?? "";
  const img_negocio = IsLoading ? "..." :
    Values.img_negocio != undefined && Values.img_negocio != ""
      ? Values.img_negocio : EditarImagen;
  

  return (
    <>
      <Tooltip title="Configuración de la cuenta">
        <IconButton>
          <Avatar
            sx={{ width: 25, height: 25 }}
            src={img_url}
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          ></Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="d-flex  m-3  ">
          <div className="align-self-center">
            <Avatar src={img_negocio}  >
            </Avatar>
          </div>
          <div className="align-self-center">
            <p className="m-0 font-AvenirBold "
              style={{ fontSize: "14px" }}>{negocio == '' ? 'Sin negocio' : ''}</p>
            <div style={{ marginTop: "-5px" }}>
              <p className="m-0 font-Avenir text-muted"
                style={{ fontSize: "12px", marginTop: "-30px" }}
              >{correo}</p>
            </div>

          </div>
        </div>
        <Divider className="mb-2" light />

        <Link to="perfil/" style={{ textDecoration: "none", color: "black" }}>
          <MenuItem>
            <ListItemIcon>
              <img src={MenuMiPerfil} />
            </ListItemIcon>
            Mi perfil
          </MenuItem>
        </Link>

        <Link to="perfil/suscripcion" style={{ textDecoration: "none", color: "black" }}>
          <MenuItem>
            <ListItemIcon>
              <img src={MenuSuscripcion} />
            </ListItemIcon>
            Suscripción
          </MenuItem>
        </Link>
        <Divider className="my-2" light />
        <Link
          onClick={CerrarSesion}
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem>
            <ListItemIcon>
              <img src={MenuCerrarSes} />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default AccountMenu;
