import { useEffect, useState } from "react";
import { getLocalStorageJWT } from "./app/Context/Storage";
import { PermissionsProvider } from './app/Context/PermissionsContext';
import LoginUsuario from "./app/Login/Componentes/LoginUsuario";
import StartLoad from "./Includes/StartLoad";
import Menu from "./app/Includes/Menu";
import { GlobalProviderUser } from "./app/Context/ContextInfoUser";

  
const AppUsuarios = () => {
  
  const [isLoged, setIsLoged] = useState(false);
  const [load, setLoad] = useState(true);
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  
  useEffect(() => {
    setLoad(true);
    getInfoUsuario().then(
      setTimeout(() => {
        setLoad(false);
      }, 1500)
    );
  }, []);


  return load ? <StartLoad /> : isLoged ? (
    <PermissionsProvider>
      <GlobalProviderUser>
      <Menu />
      </GlobalProviderUser>
    </PermissionsProvider>
  ) : (
    <LoginUsuario />
  );
};
export default AppUsuarios;
