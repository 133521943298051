import { getData } from "../../Context/backend";

export const GetdataDashboard = async () => {
  let data = [];
  const url = "dashboard/ConsultarManagerInfo";
  const res = await getData(url);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};