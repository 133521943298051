import { postdData } from "../../Context/backend";
import validator from "validator";

//////////////////
/*FUNCIONES POST*/
//////////////////

export const guardar = async (
  nombre = "",
  tipo = "",
  id_rs = "",
  img = "",
  Values = {},
  Errores = {},
  setErrores = () => { }
) => {
  let error = false;

  /* REQUERIDOS */
  if (tipo == "Registro") {
    if (!Values.nombre || Values.nombre == "") {
      Errores.nombre = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.correo || Values.correo == "") {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!validator.isEmail(Values.correo)) {
        Errores.correo = true;
        setErrores(Errores);
        error = true;
      }
    }

    // if (!Values.telefono || Values.telefono == "") {
    //   Errores.telefono = true;
    //   setErrores(Errores);
    //   error = true;
    // } else {
    //   if (!validator.isNumeric(Values.telefono)) {
    //     Errores.telefono = true;
    //     setErrores(Errores);
    //     error = true;
    //   } else if (Values.telefono.length != 10) {
    //     Errores.telefono = true;
    //     setErrores(Errores);
    //     error = true;
    //   }
    // }


    if (!Values.contrasena || Values.contrasena == "") {
      Errores.contrasena = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.confirmcontrasena || Values.confirmcontrasena == "") {
      Errores.contrasena = true;
      Errores.confirmcontrasena = true;
      setErrores(Errores);
      error = true;
    }

    if (Values.contrasena != Values.confirmcontrasena) {

      Errores.contrasena = true;
      Errores.confirmcontrasena = true;
      Errores.contrasenaEquals = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {

    let data = [];
    const body = {
      id: 0,
      key: "",
      nombre: nombre,
      iD_RS: id_rs,
      tipo: tipo,
      img: img,
      correo: Values.correo ? Values.correo : "",
      telefono: Values.telefono ? Values.telefono : "",
      contrasena: Values.contrasena ? Values.contrasena : "",
      confirmarcontrasena: Values.confirmcontrasena
        ? Values.confirmcontrasena
        : "",
      fuente: Values.fuente,
    };
    //console.log(body);
    const url = `acceso/Insertar`;

    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      data = res.data;
      resolve(data);
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
