import RepFacturas from "./Componentes/RepFacturas";

export default function Reportes() {




    return (
        <>
            <div className="container pb-5">
                <h4 className="font-AvenirBold mt-4 mb-4 pt-2"> Reportes </h4>
                <RepFacturas />

            </div>
        </>
    );
}
