import { IMG } from "../../../Lib/Funciones Generales/Constantes";
import { getData, postdData, postUrl, PRUEBAS } from "../../Context/backend";
import { setLocalStorageJWT } from "../../Context/Storage";
import validator from "validator";
import RFCValidator from "../../../Lib/Funciones Generales/ValidarRFC";
import { stringify } from "query-string";


///////////////////
/* FUNCIONES GET */
///////////////////

export const getInfoPerfil = async (imgDefault, setTok = false) => {
  let data = [];

  const url = `perfil/Consultar?nwt=${setTok}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      // console.log(data);
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
        correo: String(data.CORREO),
        telefono: String(data.TELEFONO),
        img: data.IMG,
        plan: String(res.data.respuesta.plan),
        folios: String(res.data.respuesta.folios),
        negocio: String(res.data.respuesta.negocio),
        correo_negocio: String(res.data.respuesta.correo),
        img_negocio: String(res.data.respuesta.img_negocio),
        key: String(data.KEY),
      };
      if (setTok) {
        if (res.data.respuesta.setToken.confirm) {
          setLocalStorageJWT(res.data.respuesta.setToken.token);
        }
      }
      resolve(Values);


    } else {
      reject(res);
    }
  });
};



export const getPrecioAjusteNuevoPlan = async (id_plan, periodo) => {
  let data = []; 
  const url = `planes/getPrecioAjustado?id_plan=${id_plan}&periodo=${periodo}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      resolve({
        precio:res.data.respuesta.precio,
        puedecomprar:res.data.respuesta.puedeComprar
      }); 
    } else {
      reject(res);
    }
  });
};



export const getInfoPerfilInformacion = async () => {
  let generales = [];
  let negocio = [];
  let suscripciones = [];
  let plantillas = [];
  let addendas = [];

  const url = `perfil/ConsultarInformacion`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      generales = JSON.parse(res.data.respuesta.generales)[0];
      negocio = JSON.parse(res.data.respuesta.negocio)[0];
      suscripciones = JSON.parse(res.data.respuesta.suscripciones)
      plantillas = JSON.parse(res.data.respuesta.plantillas)
      addendas = JSON.parse(res.data.respuesta.addendas)

      /*
       * Obtener la información general del perfil
       */
      let ValuesGenerales = {
        id: generales.ID,
        nombre: String(generales.NOMBRE),
        correo: String(generales.CORREO),
        telefono: String(generales.TELEFONO),
        img: generales.IMG,

        rfc: String(generales.RFC),
        razon_social: String(generales.RAZON_SOCIAL),
        regimen_fiscal: String(generales.REGIMEN_FISCAL),
        cp: String(generales.CP),
        is_requiere_factura: generales.IS_REQUIERE_FACTURA == 1,

        plan_activo: res.data.respuesta.plan_activo,
        plantilla_activa: generales.PLANTILLA_ACTIVA

      };

      /* 
       * Obtener la información general del negocio
       */
      let ValuesNegocio = {
        id: negocio ? negocio.ID : 0,

        nombre: String(negocio ? negocio.NOMBRE : ''),
        correo: String(negocio ? negocio.CORREO : ''),
        telefono: String(negocio ? negocio.TELEFONO : ''),

        img: negocio ? negocio.IMG : '',

        rfc: String(negocio ? negocio.RFC : ''),
        razon_social: String(negocio ? negocio.RAZON_SOCIAL : ''),
        regimen_fiscal: String(negocio ? negocio.REGIMEN_FISCAL : ''),
        cp: String(negocio ? negocio.CP : ''),
        id_estado: String(negocio ? negocio.ID_ESTADO : ''),

        nombre_contacto: String(negocio ? negocio.NOMBRE_CONTACTO : ''),
        apellido_paterno_contacto: String(negocio ? negocio.APELLIDO_PATERNO_CONTACTO : ''),
        apellido_materno_contacto: String(negocio ? negocio.APELLIDO_MATERNO_CONTACTO : ''),
        correo_facturacion: String(negocio ? negocio.CORREO_FACTURACION : ''),
        telefono_contacto: String(negocio ? negocio.TELEFONO_CONTACTO : ''),
        cer_vigencia: String(negocio ? negocio.CER_VIGENCIA : ''),
        archivoCer: {
          archivo: '',
          nombre: '',
          loading: false
        },
        archivoKey: {
          archivo: '',
          nombre: '',
          loading: false
        },
        cer_pass: '',
        cargar_certificados: negocio ? (negocio.CER_ACTUALIZADO != '' ? false : true) : true,
        is_carga_sertificados: negocio ? (negocio.CER_ACTUALIZADO != '' ? true : false) : false,
      };

      console.log(ValuesNegocio)


      resolve({
        ValuesGenerales,
        ValuesNegocio,
        Suscripciones: suscripciones,
        Plantillas: plantillas,
        Addendas: addendas
      });

    } else {
      reject(res);
    }
  });
};


export const getInfoPerfilInformacionLanding = async () => {
  let planes = [];

  const url = `Planes/ConsultarInformacion`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      planes = JSON.parse(res.data.respuesta.planes)

      /*
       * Obtener la información general del perfil
       */

      resolve({
        Planes: planes
      });

    } else {
      reject(res);
    }
  });
};


////////////////////
/* FUNCIONES POST */
////////////////////
export const guardarGenPrincipal = async (Values, Errores, setErrores) => {
  let data = [];
  let error = false;
  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: `Completa todos los campos requeridos` });
    });
  }

  const body = {
    nombre: Values.nombre,
    apellido_paterno: Values.apellido_paterno,
    apellido_materno: Values.apellido_materno,
    telefono: Values.telefono,
  }

  const url = "perfil/EditarGeneralesPrincipal";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
export const guardarGenFactura = async (Values, Errores, setErrores) => {
  let data = [];
  let error = false;
  if (!RFCValidator(Values.rfc)) {
    Errores.rfc = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.razon_social || Values.razon_social == "") {
    Errores.razon_social = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.regimen_fiscal || Values.regimen_fiscal == "") {
    Errores.regimen_fiscal = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.cp || Values.cp == "" || String(Values.cp).length < 5) {
    Errores.cp = true;
    setErrores(Errores);
    error = true;
  }



  if (error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: `Completa todos los campos requeridos` });
    });
  }

  const body = {
    rfc: Values.rfc,
    razon_social: Values.razon_social,
    regimen_fiscal: String(Values.regimen_fiscal),
    cp: Values.cp,

  }

  const url = "perfil/EditarGeneralesFactura";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};





export const guardarNegPrincipal = async (Values, Errores, setErrores) => {
  let data = [];
  let error = false;
  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (!validator.isEmail(Values.correo)) {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: `Completa todos los campos requeridos` });
    });
  }

  let imgNegocio = null;
  if (Values.img.includes("base64")) {
    imgNegocio = {
      id_negocio: Values.id,
      img64: Values.img.split("base64,")[1],
      tipo: IMG.TIPO_EMPRESA,
      pruebas: PRUEBAS
    }
  }
  let body = {
    id_negocio: Values.id,
    nombre: Values.nombre,
    correo: Values.correo,
    telefono: Values.telefono,
  }
  body = (imgNegocio ? { ...body, img_nueva: imgNegocio } : body)

  const url = "perfil/EditarNegocioPrincipal";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarNegFactura = async (Values, Errores, setErrores) => {
  let data = [];
  let error = false;
  if (!RFCValidator(Values.rfc)) {
    Errores.rfc = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.razon_social || Values.razon_social == "") {
    Errores.razon_social = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.regimen_fiscal || Values.regimen_fiscal == "") {
    Errores.regimen_fiscal = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.cp || Values.cp == "" || String(Values.cp).length < 5) {
    Errores.cp = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.id_estado || Values.id_estado == "" || Values.id_estado == 0) {
    Errores.id_estado = true;
    setErrores(Errores);
    error = true;
  }
  if (error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: `Completa todos los campos requeridos` });
    });
  }

  const body = {
    id_negocio: Values.id,
    rfc: Values.rfc,
    razon_social: Values.razon_social,
    regimen_fiscal: String(Values.regimen_fiscal),
    cp: Values.cp,
    id_estado: String(Values.id_estado),
  }

  const url = "perfil/EditarNegocioFactura";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarNegContacto = async (Values, Errores, setErrores) => {
  let data = [];
  let error = false;
  if (Values.correo_facturacion && Values.correo_facturacion != "") {
    if (!validator.isEmail(Values.correo_facturacion)) {
      Errores.correo_facturacion = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (Values.telefono_contacto && Values.telefono_contacto != "") {
    if (!validator.isNumeric(Values.telefono_contacto)) {
      Errores.telefono_contacto = true;
      setErrores(Errores);
      error = true;
    }
    if (String(Values.telefono_contacto).length != 10) {
      Errores.telefono_contacto = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: `Completa todos los campos requeridos` });
    });
  }

  const body = {
    id_negocio: Values.id,
    nombre_contacto: Values.nombre_contacto,
    apellido_paterno_contacto: Values.apellido_paterno_contacto,
    apellido_materno_contacto: Values.apellido_materno_contacto,
    telefono_contacto: Values.telefono_contacto,
    correo_facturacion: Values.correo_facturacion,
  }

  const url = "perfil/EditarNegocioContacto";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarNegCertificados = async (Values, Errores, setErrores) => {
  let data = [];
  const body = {
    id_negocio: Values.id,
    archivoCer: Values.archivoCer,
    archivoKey: Values.archivoKey,
    cer_pass: Values.cer_pass,
    rfc: Values.rfc,
    razon_social: Values.razon_social
  }

  const url = "perfil/EditarNegocioCertificados";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const requiereFactura = async (checked) => {
  let data = [];
  let queryParamsObj = {
    activo: checked,
  };
  // console.log(queryParamsObj)
  const url = `perfil/RequiereFactura?${stringify(queryParamsObj)}`;
  const res = await postUrl(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });

};


export const eliminarCuenta = async (checked) => {
  let data = [];
  // console.log(queryParamsObj)
  const url = `perfil/EliminarCuenta`;
  const res = await postUrl(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });

};


export const actualizarContrasena = async (Values, Errores, setErrores) => {
  let data = [];
  let error = false;

  if (!Values.contrasena_actual || Values.contrasena_actual == "") {
    Errores.contrasena_actual = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!Values.nueva_contrasena || Values.nueva_contrasena == "") {
      Errores.nueva_contrasena = true;
      setErrores(Errores);
      error = true;
    } else {
      if (Values.repetir_contrasena != Values.nueva_contrasena) {
        Errores.repetir_contrasena = true;
        setErrores(Errores);
        error = true;
      }
    }
  }

  if (!error) {
    let data = [];
    const body = {
      id: Values.id,
      contrasenA_ACTUAL: Values.contrasena_actual,
      nuevA_CONTRASENA: Values.nueva_contrasena,
      confirmaR_NUEVA_CONTRASENA: Values.repetir_contrasena,
    };
    // console.log(body);
    const url = "perfil/CambiarPass";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        setLocalStorageJWT(data.respuesta.nwToken);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};



