import { Route, Routes } from "react-router-dom";
//import Error404 from "../../../../Includes/Error404";
import RepFacturas from "./Componentes/RepFacturas";
//import PDFFactura from "./Componentes/PDFFactura";
import NeuvaFactura from "./Componentes/NuevaFactura";
import NuevoComplemento from "./Componentes/Complementos/NuevoComplemento";

const RouterFacturas = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RepFacturas />} />
        <Route path="/add" element={<NeuvaFactura />} />
        <Route path="/complemento" element={<NuevoComplemento />} />
        <Route path="/complemento/:uuid" element={<NuevoComplemento />} /> 
      </Routes>
    </>
  );
};

export default RouterFacturas;
