import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";


import LogoLateral from "../../assets/LogoLateral.svg";
import AvatarMenu from "../../assets/AvatarMenu.svg";
import LateralDashboardOFF from "../../assets/LateralDashboardOFF.svg";
import LateralDashboardON from "../../assets/LateralDashboardON.svg";
import LateralClientesOFF from "../../assets/LateralClientesOFF.svg";
import LateralClientesON from "../../assets/LateralClientesON.svg";
import LateralConfOFF from "../../assets/LateralConfOFF.svg";
import LateralConfON from "../../assets/LateralConfON.svg";
import LateralFacturasOFF from "../../assets/LateralFacturasOFF.svg";
import LateralFacturasON from "../../assets/LateralFacturasON.svg";
import LateralProductosOFF from "../../assets/LateralProductosOFF.svg";
import LateralProductosON from "../../assets/LateralProductosON.svg";
import LateralSeriesOFF from "../../assets/LateralSeriesOFF.svg";
import LateralSeriesON from "../../assets/LateralSeriesON.svg";

import MenuMiPerfil from "../../assets/MenuMiPerfil.svg";
import MenuCerrarSes from "../../assets/MenuCerrarSes.svg";
import MenuSuscripcion from "../../assets/MenuSuscripcion.svg";

import ReportesLateralOFF from "../../assets/ReportesLateralOFF.svg";
import ReportesLateralON from "../../assets/ReportesLateralON.svg";


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  clearStorageJWT,
  getLocalStorage,
  setLocalStorage,
  setLocalStorageJWT,
} from "../Context/Storage";

import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { ContextInfoUser } from "../Context/ContextInfoUser";
const MySwal = withReactContent(Swal);

export default function Sidebar({ openMenu, permisosMenu, IsLoading }) {
  const location = useLocation();
  const colors = {
    focus: "#3ABE88",
    neutral: "#FFFFFF",
  };

  const [menuActivo, setMenuActivo] = useState({
    configuracion: false
  })

  const [activeItem, setActiveItem] = useState(null);

  /*
  const [Values, setValues] = useState({
    img: AvatarMenu,
  });

  */

  const handleClickPerfil = () => {
    if (activeItem === "Perfil") {
      setActiveItem(null);
    } else {
      setActiveItem("Perfil");
    }
  };

  const handleClickMenu = (opcion) => {
    setMenuActivo({ ...menuActivo, [opcion]: !menuActivo[opcion] })
  };

  const { Values, getInfoUsuario } = useContext(ContextInfoUser);
  
  const img_url = IsLoading ? getLocalStorage("img") : Values.img;
   const nombreUsuario = IsLoading ? "..." : Values.nombre;
     const nombreEmpresa= IsLoading ? "..." : Values.negocio;
   console.log( Values)
  const CerrarSesion = () =>
    new Swal({
      title: "¿Estás seguro de cerrar sesión?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Se ha cerrado tu sesión",
          showConfirmButton: false,
          timer: 1500,
        }).then(clearStorageJWT);
      }
    });

  return (
    <div
      className=" d-flex flex-column justify-content-between h-100 "
      style={{
        backgroundColor: "#100D1C",
      }}
    >
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        style={{
          backgroundColor: "#101727",
          height: "100%",
          color: "white",
          zIndex: 1,
          paddingTop: openMenu ? "10px" : "50px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {
          //#region INICIO
        }
        {openMenu ? (
          <center>
            <Link to="inicio/" style={{ textDecoration: "none" }}>
              <img
                className="mb-5 mt-3"
                src={LogoLateral}
                width={180}
                style={{ margin: "auto" }}
              />
            </Link>
          </center>
        ) : (
          <></>
        )}
 

        <hr className="d-sm-none d-block" />
        {/*------------------------------      PERFIL   ----------------------------------------------------*/}
        <ListItemButton
          className="ps-2 d-sm-none d-flex"
          onClick={handleClickPerfil}
          style={{
            color:
              location.pathname == "/app/perfil/" ? colors.focus : colors.neutral,
          }}
        >
          <ListItemIcon>
            <Avatar className="border" src={img_url}></Avatar>
          </ListItemIcon>
          <ListItemText
            className="text-truncate"
            style={{ maxWidth: "150px", color:"white" }}
            primary={nombreUsuario}
            secondary={nombreEmpresa}
            color="white"
          />
          {activeItem === "Perfil" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <div  className="ps-2 d-sm-none d-flex"> 
        <Collapse in={activeItem === "Perfil"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="d-lg-none d-lg-block">
            <Link
              to="perfil/"
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/app/facturas/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton className="ps-3">
                <ListItemIcon>
                <img src={LateralClientesOFF} />
                </ListItemIcon>
                <ListItemText primary="Mi perfil" />
              </ListItemButton>
            </Link>
  
            <Link
              to="perfil/suscripcion"
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/app/facturas/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton className="ps-3">
                <ListItemIcon>
                <img src={MenuSuscripcion} />
                </ListItemIcon>
                <ListItemText primary="Suscripción" />
              </ListItemButton>
            </Link>
            
            <Link
              onClick={CerrarSesion}
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/app/clientes/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton className="ps-3">
                <ListItemIcon>
                <img src={MenuCerrarSes} style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        </div>
        <hr className="d-sm-none d-block" />



        {/*------------------------------      DASHBOARD   ----------------------------------------------------*/}
        <Link
          to="inicio/"
          style={{
            textDecoration: "none",
            color:
              (location.pathname == "/app/inicio/" || location.pathname == "/app/")
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  (location.pathname == "/app/inicio/" || location.pathname == "/app/")
                    ? LateralDashboardON
                    : LateralDashboardOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>




        {/*------------------------------      CLIENTES   ----------------------------------------------------*/}
        <Link
          to="clientes/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/app/clientes/"
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/app/clientes/"
                    ? LateralClientesON
                    : LateralClientesOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItemButton>
        </Link>


        {/*------------------------------      PRODUCTOS   ----------------------------------------------------*/}
        <Link
          to="productos/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/app/productos/"
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/app/productos/"
                    ? LateralProductosON
                    : LateralProductosOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Productos" />
          </ListItemButton>
        </Link>


        {/*------------------------------      SERIES   ----------------------------------------------------*/}
        <Link
          to="series/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/app/series/"
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/app/series/"
                    ? LateralSeriesON
                    : LateralSeriesOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Series" />
          </ListItemButton>
        </Link>


        {/*------------------------------      FACTURAS   ----------------------------------------------------*/}
        <Link
          to="facturas/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/app/facturas/"
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/app/facturas/"
                    ? LateralFacturasON
                    : LateralFacturasOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Facturas" />
          </ListItemButton>
        </Link>

 {/*------------------------------      REPORTES   ----------------------------------------------------*/}
 <Link
          to="reportes/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/app/reportes/"
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/app/reportes/"
                    ? ReportesLateralON
                    : ReportesLateralOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
          </ListItemButton>
        </Link>


        <Link
          to="configuraciones/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/app/configuraciones/"
                ? colors.focus
                : colors.neutral,
          }}
          className="mb-1 mt-5"
        >
          <ListItemButton className="ps-2">
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/app/configuraciones/"
                    ? LateralConfON
                    : LateralConfOFF
                }
                width={20}
                style={{
                  margin: "auto",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Configuración" />
          </ListItemButton>
        </Link>


        






      </List>
    </div>
  );
}
