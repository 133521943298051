import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getData } from "../../Context/backend";
import Loading from "../../../Includes/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

// función para convertir base64 a excel
const convertBase64ToExcel = (content) => {
  var data = content;

  var contentType = "application/vnd.ms-excel";
  var blob1 = b64toBlob(data, contentType);
  var blobUrl1 = URL.createObjectURL(blob1);

  try {
    var popUp = window.open(blobUrl1, "Reporte de facturas");
    if (popUp == null || typeof (popUp) == 'undefined') {
      // alert('Por favor permite las ventanas emergentes y vuelve a recargar la pagina');
      MySwal.fire({
        title: "Permisos",
        html: 'Por favor permite las ventanas emergentes y vuelve a recargar la pagina',
        icon: "warning",
        confirmButtonColor: "#3ABE88",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      });
    }
    else {
      window.close()
    }

  } catch (error) { }

};

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const RepFacturasExcel = ({ }) => {
  const [searchParams] = useSearchParams();
  const [base64Data, setBase64Excel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = `Reporte de facturas`;
    let url = `Facturacion/repFactClientesUsuariosExcel?${searchParams.toString()}`;
    getData(url)
      .then((res) => {
        if (!res.error) {
          const base64 = res.data.respuesta.base64;
          // setBase64Excel(convertBase64ToExcel(base64));
          // window.close();
          setBase64Excel(base64)
          convertBase64ToExcel(base64)
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <h6 className="text-center pt-5">
          Cargando reporte de facturas, por favor espere
        </h6>
        <div className="p-5 mt-3">
          <Loading />
        </div>
      </>
    );
  }

  if (!base64Data) {
    return (
      <div>
        No se pudo obtener el archivo xls, revisa tu conexión a internet e
        intenta de nuevo
      </div>
    );
  }
  else {
    // window.close();
  }
  // return (
  //   <object
  //     data={base64Data}
  //     style={{ height: "100vh" }}
  //     //type="application/vnd.ms-excel"
  //     width="100%"
  //     name="Factura"
  //   />
  // );
};

export default RepFacturasExcel;
