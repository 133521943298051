import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";

import { BasicDatePickers_ } from "../../../../Includes/BasicDatePickers";
import { getAddendas, getLicencias, getPlantillas } from "../Funciones/Licencias";
import ModalFacturacion from "../../../../Lib/Modals/Facturacion/ModalFacturacion";
import {
  ConvertDate,
  GetDate,
} from "../../../../Lib/Funciones Generales/ObtenerFecha";
import { Tab, Tabs, Tooltip } from "@mui/material";

import TableLicencias from "./Tabla";
import TablaAddendas from "./TablaAddendas";
import TablaPlantillas from "./TablaPlantillas";
import { TIPO_FACTURA } from "../../../../Lib/Funciones Generales/Constantes";

const MENU = {
  LICENCIA: 0,
  ADDENDA: 1,
  PLANTILLA: 2
}

const RepLicenciasVendidas = () => {

  const [indexTab, setIndexTab] = useState(0)
  const [IsLoadingLicencias, setIsLoadingLicencias] = useState(true);
  const [IsLoadingAddendas, setIsLoadingAddendas] = useState(true);
  const [IsLoadingPlantillas, setIsLoadingPlantillas] = useState(true);
  const [ListaLicencias, setListaLicencias] = useState(null);
  const [ListaAddendas, setListaAddendas] = useState(null);
  const [ListaPlantillas, setListaPlantillas] = useState(null);
  const [pedidoSeleccionado, setPedidoSeleccionado] = useState(0);
  const [showDialogFacturacion, setShowDialogFacturacion] = useState(false);

  const [rangoFecha, setRangoFecha] = useState({
    inicio: GetDate(-30),
    fin: GetDate(0),
  });

  const handleChangeFecha = (tipo, fecha) => {
    fecha = ConvertDate(fecha);
    setRangoFecha({ ...rangoFecha, [tipo]: fecha });
  };

  const getListaLicenciasVendidas = (forzarPeticion = false) => {
    if (!ListaLicencias || forzarPeticion) {
      setIsLoadingLicencias(true);
      getLicencias(0, 0, rangoFecha.inicio, rangoFecha.fin)
        .then((resp) => {
          let list = JSON.parse(resp.data);
          let listOrder = [...list.filter(row => row.ID_FACTURA === -1), ...list.filter(row => row.ID_FACTURA !== -1)]
          setListaLicencias(listOrder);
          setIsLoadingLicencias(false);
        })
        .catch((resp) => {
          setListaLicencias([]);
          setIsLoadingLicencias(false);
          setPedidoSeleccionado(0);
        });
    }
  };

  const getListaAddendasVendidas = (forzarPeticion = false) => {
    if (!ListaAddendas || forzarPeticion) {
      setIsLoadingAddendas(true);
      getAddendas(0, 0, rangoFecha.inicio, rangoFecha.fin)
        .then((resp) => {
          let list = JSON.parse(resp.data);
          let listOrder = [...list.filter(row => row.ID_FACTURA === -1), ...list.filter(row => row.ID_FACTURA !== -1)]
          setListaAddendas(listOrder);
          setIsLoadingAddendas(false);
        })
        .catch((resp) => {
          setListaAddendas([]);
          setIsLoadingAddendas(false);
          setPedidoSeleccionado(0);
        });
    }
  };

  const getListaPlantillasVendidas = (forzarPeticion = false) => {
    if (!ListaPlantillas || forzarPeticion) {
      setIsLoadingPlantillas(true);
      getPlantillas(0, 0, rangoFecha.inicio, rangoFecha.fin)
        .then((resp) => {
          let list = JSON.parse(resp.data);
          let listOrder = [...list.filter(row => row.ID_FACTURA === -1), ...list.filter(row => row.ID_FACTURA !== -1)]
          setListaPlantillas(listOrder);
          setIsLoadingPlantillas(false);
        })
        .catch((resp) => {
          setListaPlantillas([]);
          setIsLoadingPlantillas(false);
          setPedidoSeleccionado(0);
        });
    }
  };

  const getTipoFacturacion = () => {
    let TIPO = ""
    switch (indexTab) {
      case MENU.LICENCIA: TIPO = TIPO_FACTURA.LICENCIA; break;
      case MENU.ADDENDA: TIPO = TIPO_FACTURA.ADDENDA; break;
      case MENU.PLANTILLA: TIPO = TIPO_FACTURA.PLANTILLA; break;
    }
    return TIPO
  }

  const getListaRegistros = (forzarPeticion = false) => {
    switch (indexTab) {
      case MENU.LICENCIA: getListaLicenciasVendidas(forzarPeticion); break;
      case MENU.ADDENDA: getListaAddendasVendidas(forzarPeticion); break;
      case MENU.PLANTILLA: getListaPlantillasVendidas(forzarPeticion); break;
    }
  }

  useEffect(() => {
    getListaRegistros(true)
  }, [rangoFecha, indexTab]);

  useEffect(() => {
    document.title = `Licencias`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap mt-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">
            Licencias
          </h4>
        </div>
      </div>

      <div className="d-flex flex-wrap mb-3">
        <Tabs
         TabIndicatorProps={{
          bgcolor: "#3abe88",
          style: {
            backgroundColor: "#3abe88",
          },
        }}
          value={indexTab}
          onChange={(event, index) => setIndexTab(index)}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529" 
          indicatorColor=""
        >
          <Tooltip
            title="Mostrar solo las licencias vendidas"
            placement="top">
            <Tab
            className="font-font-AvenirMedium" style={{ textTransform: 'none' }} 
              label={<span style={{ fontSize: "12px" }}>Planes</span>}
            />
          </Tooltip>

          <Tooltip
            title="Mostrar solo las addendas vendidas"
            placement="top">
            <Tab
              className="font-font-AvenirMedium" style={{ textTransform: 'none' }}
              label={<span style={{ fontSize: "12px" }}>Addendas</span>}
            />
          </Tooltip>

          <Tooltip
            title="Mostrar solo las plantillas vendidas"
            placement="top">
            <Tab
             className="font-font-AvenirMedium" style={{ textTransform: 'none' }}
              label={<span style={{ fontSize: "12px" }}>Plantillas</span>}
            />
          </Tooltip>
        </Tabs>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={4}>
            <BasicDatePickers_
              fullWidth
              size="small"
              format="DD/MM/YYYY"
              label="Fecha de inicio"
              fecha={rangoFecha.inicio}
              setFecha={(fecha) => {
                handleChangeFecha("inicio", fecha);
              }}
            />
          </Col>
          <Col xs={12} md={4}>
            <BasicDatePickers_
              fullWidth
              size="small"
              format="DD/MM/YYYY"
              label="Fecha de fin"
              fecha={rangoFecha.fin}
              setFecha={(fecha) => {
                handleChangeFecha("fin", fecha);
              }}
            />
          </Col>
        </Row>
        <Row>

          {
            indexTab === MENU.LICENCIA && (
              <Col sm={12}>
                <TableLicencias
                  Lista={ListaLicencias ?? []}
                  IsLoading={IsLoadingLicencias}
                  setLista={setListaLicencias}
                  setShowDialogFacturacion={setShowDialogFacturacion}
                  setPedidoSeleccionado={setPedidoSeleccionado}
                />
              </Col>
            )
          }

          {
            indexTab === MENU.ADDENDA && (
              <Col sm={12}>
                <TablaAddendas
                  Lista={ListaAddendas ?? []}
                  IsLoading={IsLoadingAddendas}
                  setLista={setListaAddendas}
                  setShowDialogFacturacion={setShowDialogFacturacion}
                  setPedidoSeleccionado={setPedidoSeleccionado}
                />
              </Col>
            )
          }

          {
            indexTab === MENU.PLANTILLA && (
              <Col sm={12}>
                <TablaPlantillas
                  Lista={ListaPlantillas ?? []}
                  IsLoading={IsLoadingPlantillas}
                  setLista={setListaPlantillas}
                  setShowDialogFacturacion={setShowDialogFacturacion}
                  setPedidoSeleccionado={setPedidoSeleccionado}
                />
              </Col>
            )
          }

        </Row>
      </Card>

      {showDialogFacturacion && (
        <ModalFacturacion
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogFacturacion={setShowDialogFacturacion}
          getListaLicenciasVendidas={() => getListaRegistros(true)}
          tipo={getTipoFacturacion()}
        />
      )}
    </>
  );
};

export default RepLicenciasVendidas;
