import { Backdrop, CircularProgress } from "@mui/material";
import { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

const CargarArchivo = ({
    children,
    handleChangeFile,
    archivosAceptados = '',
    archivoDefault = '',
    leerTexto = false
}) => {

    const [loading, setLoading] = useState(false)

    const onDrop = useCallback((files) => {

        setLoading(true)
        if (files) {
            if (files.length > 0) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setTimeout(() => {
                        handleChangeFile({
                            url: URL.createObjectURL(files[0]),
                            archivo: reader.result,
                            loading: true,
                            nombre: files[0].name
                        });
                        setLoading(false)
                    }, 1000)
                };
                if (!leerTexto) {
                    reader.readAsDataURL(files[0]);
                }
                else {
                    reader.readAsText(files[0]);
                }
            } else {
                handleChangeFile({ archivo: '', loading: false, nombre: '' });
                setLoading(false)
            }
        } else {
            handleChangeFile({ archivo: '', loading: false, nombre: '' });
            setLoading(false)
        }
    }, []);

    let archivosValidos = {
        'image/webp': [],
        'image/jpg': [],
        'image/jpeg': [],
        'image/png': [],
    }
    archivosValidos = archivosAceptados != '' ? archivosAceptados : archivosValidos
    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop: onDrop,
        accept: archivosValidos,
    });


    return (
        <>
            <div
                {...getRootProps()}
                style={{ border: "1px dotted lightgray" }}
                className="mt-0 mb-4"
            >
                <input {...getInputProps()} />
                {
                    <Row className="p-4 w-100 h-100">
                        <Col sm={6} md={4} className="d-flex justify-content-center">
                            <img
                                id={"imgAzure"}
                                src={archivoDefault}
                                className="img-fluid"
                            />
                        </Col>
                        <Col sm={6} md={8}>
                            <div className="w-100 h-100 d-flex">
                                <div className="m-auto">
                                    {children}
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
            </div>
            {fileRejections && fileRejections.length != 0 && (
                <div className="alert alert-danger py-4 mx-4" role="alert">
                    <strong> Error al cargar: </strong> Solo se aceptan archivos {archivosAceptados}
                </div>
            )}

            <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default CargarArchivo