import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GetdataDashboard } from "./Funciones/Dashboard";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import ChartTop from "./Charts/ChartTop";
import ChartBar from "./Charts/ChartBar";
import ChartDonut from "./Charts/ChartDonut";
import ChartTab from "./Charts/ChartTab";


function createData(img, titulos, subtitulos) {
  return { img, titulos, subtitulos };
}

const Dashboard = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [graficas, setgraficas] = useState({
    paquetesUltimosMeses: { label: [], value: [] },
    facturasUltimosMeses: { label: [], value: [] },
    ingresosUltimasSemanas: [],
    paquetesTop: { label: [], value: [] },
    usuariosEstadosTop: { label: [], value: [] },
    clientesFacturasTop: []
  });
  const h = "491px";
  const h2 = "491px";

  const GetDataDash = () => {
    setIsLoading(true);
    GetdataDashboard()
      .then((data) => {

        let graficasDashboard = graficas
        /*
         * Sacar los ultimos meses definido desde el api
         */
        const ULTIMOS_MESES = [];
        if (Array.isArray(data.ULTIMOS_MESES)) {
          data.ULTIMOS_MESES.map((row, index) => {
            ULTIMOS_MESES.push({ index, mes: row.MES })
          })
        }
        const ULTIMOS_MESES_LABEL = ULTIMOS_MESES.map(x => x.mes)


        /***********************************************************************************
         * Formatear los paquetes vendidos en los ultimos meses
         */
        const PAQUETES_ULTIMOS_MESES_VALUE = new Array(ULTIMOS_MESES.length).fill(0)
        if (Array.isArray(data.PAQUETES_ULTIMOS_MESES)) {
          data.PAQUETES_ULTIMOS_MESES.map(row => {
            const mes = ULTIMOS_MESES.find(x => x.mes == row.MES)
            PAQUETES_ULTIMOS_MESES_VALUE[mes.index] = row.TOTAL
          })
        }
        graficasDashboard.paquetesUltimosMeses = {
          label: ULTIMOS_MESES_LABEL,
          value: PAQUETES_ULTIMOS_MESES_VALUE,
        }



        /***********************************************************************************
         * Formatear las facturas emitidas en los ultimos meses
         */
        const FACTURAS_ULTIMOS_MESES_VALUE = new Array(ULTIMOS_MESES.length).fill(0)
        if (Array.isArray(data.FACTURAS_ULTIMOS_MESES)) {
          data.FACTURAS_ULTIMOS_MESES.map(row => {
            const mes = ULTIMOS_MESES.find(x => x.mes == row.MES)
            FACTURAS_ULTIMOS_MESES_VALUE[mes.index] = row.TOTAL
          })
        }
        graficasDashboard.facturasUltimosMeses = {
          label: ULTIMOS_MESES_LABEL,
          value: FACTURAS_ULTIMOS_MESES_VALUE,
        }



        /***********************************************************************************
        * Formatear los paquetes más vendidos
        */
        const PAQUETES_TOP_LABEL = []
        const PAQUETES_TOP_VALUE = []
        if (Array.isArray(data.PAQUETES_TOP)) {
          data.PAQUETES_TOP.map(row => {
            PAQUETES_TOP_LABEL.push(row.NOMBRE)
            PAQUETES_TOP_VALUE.push(row.TOTAL)
          })
        }
        graficasDashboard.paquetesTop = {
          label: PAQUETES_TOP_LABEL,
          value: PAQUETES_TOP_VALUE,
        }


        /***********************************************************************************
       * Formatear los usuarios por estado
       */
        const CLIENTES_TOP_ESTADOS_LABEL = []
        const CLIENTES_TOP_ESTADOS_VALUE = []
        if (Array.isArray(data.CLIENTES_TOP_ESTADOS)) {
          data.CLIENTES_TOP_ESTADOS.map(row => {
            CLIENTES_TOP_ESTADOS_LABEL.push(row.NOMBRE)
            CLIENTES_TOP_ESTADOS_VALUE.push(row.TOTAL)
          })
        }
        graficasDashboard.usuariosEstadosTop = {
          label: CLIENTES_TOP_ESTADOS_LABEL,
          value: CLIENTES_TOP_ESTADOS_VALUE,
        }

        /***********************************************************************************
        * Formatear los ingresos de las ultimas semanas
        */
        graficasDashboard.ingresosUltimasSemanas = data.INGRESOS_ULTIMAS_SEMANAS

        /***********************************************************************************
        * Formatear los clientes que más facturan
        */
        graficasDashboard.clientesFacturasTop = Array.isArray(data.CLIENTES_TOP_FACTURAS) ?
          data.CLIENTES_TOP_FACTURAS.map((row, index) => createData(row.IMG, row.NOMBRE, `Lugar ${index + 1}`))
          : [];


        setgraficas(graficasDashboard);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Dashboard`;
    GetDataDash();
  }, []);



  return (
    <>
      <div className="p-3">
        <h4 className="text-left font-AvenirBold">Dashboard</h4>
      </div>
      <Row>
        <Col sm={12} md={12}>
          <Row>




            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartBar
                  Height={h}
                  titulo="Paquetes en los últimos 6 meses"
                  Labels={graficas.paquetesUltimosMeses.label}
                  values={graficas.paquetesUltimosMeses.value}
                  Color="#3ABE88"
                />
              )}
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartBar
                  Height={h}
                  titulo="Facturas emitidas en los últimos 6 meses"
                  Labels={graficas.facturasUltimosMeses.label}
                  values={graficas.facturasUltimosMeses.value}
                  Color="#3ABE88"
                />
              )}
            </Col>


            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartDonut
                  titulo="Paquetes más vendidos"
                  Labels={graficas.paquetesTop.label}
                  values={graficas.paquetesTop.value}
                  Height={h}
                  widthChart={250}
                />
              )}
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartDonut
                  Tipo="pie"
                  titulo="Usuarios por estados"
                  Labels={graficas.usuariosEstadosTop.label}
                  values={graficas.usuariosEstadosTop.value}
                  Height={h}
                  widthChart={250}
                />
              )}
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartTop
                  titulo="Clientes que más facturan"
                  rows={graficas.clientesFacturasTop}
                  avatarColor={"#99A2AD"}
                  namePropios
                />
              )}
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
              {IsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <ChartTab
                  ingresosSemanales={graficas.ingresosUltimasSemanas}
                  height={(h2)}
                />
              )}
            </Col>

          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
