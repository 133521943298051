import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getAddendas = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `addendas/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const guardarAddendas = async (Values, Errores, setErrores) => {
  let error = false;


  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.is_gratis) {
    Values.precio = 0;
  } else {
    if (!Values.precio || Values.precio == "" || Values.precio == 0) {
      Errores.precio = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.costo || Values.costo == "" || Values.costo == 0) {
      Errores.costo = true;
      setErrores(Errores);
      error = true;
    }
  }


  if (!error) {
    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    }

    let data = [];
    const url =
      ((Values.id == undefined || Values.id == "" || Values.id == 0) && Values.id != -1)
        ? "addendas/Insertar"
        : "addendas/Editar";

    const body = Values;
    const res = await postdData(url, { ...body, id: body.id == -1 ? 0 : body.id });

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarAddendas = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `addendas/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfAddendas = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    ID: id == -1 ? 0 : id,
  };
  const url = `addendas/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta addenda",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: data.ID == 0 ? -1 : data.ID,
          nombre: String(data.NOMBRE),
          descripcion: String(data.DESCRIPCION),
          is_personalizada: data.IS_PERSONALIZADA,
          is_gratis: data.PRECIO == 0,
          precio: data.PRECIO,
          costo: data.COSTO,
          url: String(data.URL),
          img:
            String(data.IMG) == ""
              ? imgDefault
              : String(data.IMG)
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id == -1 ? 0 : id,
      activo: checked,
    };
    const url = `addendas/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const asignarUsuario = async (id_addenda, id_usuario) => {
  let data = [];
  let body = {
    id_addenda, id_usuario
  };
  const url = `addendas/asignarUsuario`;
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
