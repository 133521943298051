import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import GoogleIcon from '@mui/icons-material/Google';
import { PALETA } from "../../../Lib/Funciones Generales/Colores";
import { Button } from "@mui/material";

export default function GoogleButton({
  className = '',
  title = "Iniciar sesión con Google",
  handleRsCredencials = () => { }
}) {
  const clienteID =
    "865319230470-uqo7sj0que6t89cmkmro3gkpb8bduq2t.apps.googleusercontent.com";
  function handleLoginSuccess(response) {
    //console.log("Inicio de sesión exitoso:", response);

    const datos = {
      nombre: response.profileObj.name,
      correo: response.profileObj.email,
      img: response.profileObj.imageUrl,
      idRS: response.googleId,
      tipo: "Google",
    };
    handleRsCredencials(datos);
  }

  function handleLoginFailure(error) {
    console.log("Error en el inicio de sesión:", error);
  }
  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId: clienteID,
        scope: "",
      });
    };

    gapi.load("client:auth2", initClient);
  });
  return (
    <>
      <GoogleLogin
        clientId={clienteID}
        buttonText="Continua con Google"
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
        cookiePolicy={"single_host_origin"}
        // className="btn btn-block"
        render={renderProps => (
          // <button onClick={renderProps.onClick}>This is my custom FB button</button>
          <Button
            onClick={renderProps.onClick}
            className={"w-100 btn btn-cancelar font-AvenirMedium py-2 px-4" + ' ' + className}
            variant="outlined"
          >
            <GoogleIcon style={{ marginRight: "5px", color: PALETA.colorSecondary }} />
            {title}
          </Button>
        )}
      />
    </>
  );
}
