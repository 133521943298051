///////////////////
/* FUNCIONES GET */

import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";
import { validarUUID } from "../../../../Lib/Funciones Generales/Generales";

///////////////////
export const getFacturas = async (
  estatus,
  fecha_inicio = "",
  fecha_fin = ""
) => {
  let data = [];
  let queryParamsObj = {
    estatus: estatus,
    fecha_inicio,
    fecha_fin,
  };

  const url = `Facturacion/ListarManager?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const enviarFactura = async (Values, Errores, setErrores) => {
  if (Values.id != undefined) {
    let data = [];
    if (Values.correos.trim() == "") {
      Errores.correos = true;
      setErrores(Errores);
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El campo de corres no es valido" });
      });
    }
    let queryParamsObj = {
      id_factura: Values.id,
      correo: Values.correos,
      observaciones: Values.observaciones,
    };
    const url = `facturacion/EnviarFacturaCorreoManager?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const cancelarFactura = async (Values, Errores, setErrores) => {
  if (Values.id != undefined) {
    let data = [];
    if (Values.motivo.trim() == "") {
      Errores.correos = true;
      setErrores(Errores);
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El motivo es requerido" });
      });
    }
    if (Values.UUID_SUSTITUCION.trim() != "") {
      if (!validarUUID(Values.UUID_SUSTITUCION.trim())) {
        Errores.correos = true;
        setErrores(Errores);
        return new Promise((resolve, reject) => {
          resolve({ mensaje: "El campo de UUID de sustitución no es valido" });
        });
      }
    }
    Values.observaciones = String(Values.observaciones);

    let queryParamsObj = Values;
    const url = `facturacion/cancelarFacturaManager`;
    const res = await postdData(url, Values);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const descargarXML = (id, uuid = "", xmltext = "") => {
  var filename = "Factura_" + id + ".xml";
  var pom = document.createElement("a");
  var bb = new Blob([xmltext], { type: "text/plain" });

  pom.setAttribute("href", window.URL.createObjectURL(bb));
  pom.setAttribute("download", filename);

  pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":");
  pom.draggable = true;
  pom.classList.add("dragout");

  pom.click();
};

export const getDatosEmisor = async () => {
  let data = [];
  const url = `perfil/getDatosFacturacion`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const em = JSON.parse(res.data.respuesta.emisor);
      let values = {
        emisor: em.length > 0 ? em[0] : {},
      };
      resolve(values);
    } else {
      reject([]);
    }
  });
};
