import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import {
    Card,
    CardContent,
    Button
} from "@mui/material";
import Carrousel from './Carrousel';
import { Link } from 'react-router-dom';


function DashboardImgs() {
    return (

        <div  >
            <CardContent style={{ backgroundColor: "#eefbf6", borderRadius: "16px" }} 
            className='my-5  py-md-5 container-caracteristicas'>
                <Row className='mx-5 mx-md-5'>
                    <Col xs={12} xl={4}>
                        <h1 className='mb-5 font-AvenirMedium mt-4 mt-md-0'>
                        Control inteligente de tu <span style={{ color: "#3ABE88" }}>negocio</span>
                        </h1>
                        <h3 className='mb-5 font-AvenirMedium'>
                        Con nuestra solución de facturación en línea, alcanza la eficiencia, precisión y organización que te permitirán tomar decisiones financieras sólidas.
                        </h3>
                        <Link to="./app/registro" style={{ textDecoration: "none" }}>
                        <Button className='btn-Crexendo px-4 py-2 mb-3 font-Avenir'>Regístrate</Button>
                        </Link>
                    </Col>
                    <Col xs={12} xl={8}>
                        <Carrousel />
                    </Col>
                </Row>
            </CardContent>
        </div>


    )
}

export default DashboardImgs;