import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import RepClientes from "./Componentes/RepClientes";
import Cliente from "./Componentes/Cliente";


const routerClientes = () => {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<RepClientes />} />
        <Route path="/add" element={<Cliente />} />
        <Route path="/edit/:id" element={<Cliente />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerClientes;


