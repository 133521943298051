import { getLocalStorageJWT, getLocalStorage } from "./../Context/Storage";
import { getLocalStorageJWT_Manager } from "../../manager/Context/Storage";

export const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://crexendoapi.azurewebsites.net/api/"
    : "http://localhost:7053/api/";

export const PRUEBAS = process.env.NODE_ENV === "production" ? 0 : 1;

export const KEY_CONEKTA = process.env.NODE_ENV === "production" ? 
getLocalStorage("key")=="50498a98-e274-4999-8da7-1f74c5f44b66"
?"key_OIuLO40g10uyJO0PaNyONrp"//user dev en prod
:"key_bW2cPxJbQXuIsvigzwQJhpN" //produccion
:"key_OIuLO40g10uyJO0PaNyONrp" //dev;

export function postdData(url, body) {
  let bearer_token = getLocalStorageJWT();
  try {
    return fetch(BACKEND_URL + url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + bearer_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status != 200) {
          return {
            resp: {
              codigo: String(response.status),
              mensaje: "Error: " + response.statusText,
            },
          };
        }
        return response.json();
      })
      .then((response) => {
        //
        if (response.resp.codigo == "200") {
          return { error: false, data: response.resp };
        } else {
          return {
            error: true,
            mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
            data: response.resp,
          };
        }
      })
      .catch((error) => {
        console.log(error);
        return { error: true, mensaje: error };
      });
  } catch (error) {
    console.log(error);
  }
}

export function postUrl(url) {
  let bearer_token = getLocalStorageJWT();
  return fetch(BACKEND_URL + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return {
          resp: {
            codigo: String(response.status),
            mensaje: "Error: " + response.statusText,
          },
        };
      }
      return response.json();
    })
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        return {
          error: true,
          mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return { error: true, mensaje: error };
    });
}

export const getData = (url, useJWT = true, isUsuario = true) => {
  let bearer_token =
    isUsuario === true ? getLocalStorageJWT() : getLocalStorageJWT_Manager();
  return fetch(BACKEND_URL + url, {
    method: "GET",
    headers: {
      Authorization: useJWT ? "Bearer " + bearer_token : "Basic",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status == 401) {
      }
      if (response.status != 200) {
        return {
          resp: {
            codigo: String(response.status),
            mensaje: "Error: " + response.statusText,
          },
        };
      }
      //console.log(response.status);
      return response.json();
    })
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        if (response.resp.codigo == "403") {
          // clearStorageJWT();
        } else {
          return {
            error: true,
            mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
          };
        }
      }
    })
    .catch((error) => {
      //alert('response.status: ', error.status); // 👉️ 200
      // console.log(error);
      return { error: true, mensaje: error };
    });
};
export default BACKEND_URL;
