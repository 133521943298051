import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { getLista, getListaProd } from "../Funciones/Productos";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";
import Buscador from "../../../../Includes/Buscador";
import { Col, Row } from "react-bootstrap";
 

function ModalSelectProd({ 
  onClose = () => {},
  handleProductoSeleccionado = () => {}, 
  titulo = "Seleccionar productos",    
  titulo_ = "Costo",  
}) {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const disminuirItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD -= 1;
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
    }
    setProductosDisponibles(items);
  };
  const addItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      items.push(newItem);
    }
    setProductosDisponibles(items);
  };
  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value = target.value;
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID == ID)
      : -1;
    if (itemIndex !== -1) {
      //let cantidad = parseInt(Value);
      //items[itemIndex].CANTIDAD = cantidad.toString();
      let cantidad = Value.toDecimal();
      items[itemIndex].CANTIDAD = !/^\d*\.?\d{0,2}$/.test(cantidad)
        ? parseFloat(cantidad).toFixed(2)
        : cantidad;
    } else {
      alert("producto no encontrado");
    }
    setProductosDisponibles(items);
  };

  const getListaProductos = () => {
    getListaProd( ValueBusqueda
    )
      .then((resp) => {
        resp= JSON.parse(resp.data);
        if (resp.length > 0) {
          resp = resp.map((item) => { 
            return item;
          });
        }
        setProductosDisponibles(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getListaProductos();
  }, [ValueBusqueda, ValueSelect]);

  const quitarProducto = (producto) => {
    const nuevosProductos = productosDisponibles.filter(
      (p) => p.ID !== producto.ID
    );
    setProductosDisponibles(nuevosProductos);
  };

  console.log("Lista Productos", productosDisponibles);

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true}>
        <IconButton
          aria-label="Close"
          className="m-2"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon style={{ width: "30px", height: "30px" }} />
        </IconButton>
        {
          <DialogTitle>
            <h5 className="fw-bolder f-12 mt-2">{titulo}</h5>
          </DialogTitle>
        }
        <DialogContent style={{ minHeight: "70vh" }}>
          <div className="px-1 py-2">
            <Row>
              <Col xs={12} md= { 10 }>
                {" "}
                <Buscador
                  placeholder="Buscar producto"
                  ValueBusqueda={ValueBusqueda}
                  setValueBusqueda={setValueBusqueda}
                />
              </Col>{" "}
              {false ? (
                <>
                  <Col xs={12} md={2}>
                    {" "}
                    {/*  <Button
                      style={{ float: "right" }}
                      className="btn btn-Crexendo font-AvenirMedium  py-1 px-4"
                      onClick={() => {
                        setShowModal(!showModal);
                      }}
                    >
                      Agregar productos
                    </Button>
                  showModal && (
                      <ModalCrearProductos
                        onClose={() => {
                          setShowModal(!showModal);
                        }}
                        titulo="Crear productos para el ajuste"
                        productosDisponibles={productosDisponibles}
                        setProductosDisponibles={setProductosDisponibles}
                        getListaProductos={getListaProductos}
                      />
                    ) */  }
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="left"
                >
                  Descripcion
                </TableCell>
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="left"
                >
                  {/*proveedorId == 0 &&
                  onlyInventariables == false &&
                  id_almacen == 0 &&
                  id_sucursal == 0 &&
                  isProductosDescuento == false &&
                  existencias == false
                    ? "Precio"
                    : "Costo"*/}
                  {titulo_}
                </TableCell>
 
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="right"
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productosDisponibles && Array.isArray(productosDisponibles)
                ? productosDisponibles.map((Productos) => (
                    <>
                      <TableRow key={Productos.ID}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell"
                        >
                          {Productos.DESCRIPCION}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell"
                        >
                          { formatMoneda(Productos.PRECIO)
                             }
                        </TableCell>
                        
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            onClick={() => {
                              handleProductoSeleccionado(
                                Productos,
                                1
                              );
                              quitarProducto(Productos);
                            }}
                          >
                            Agregar
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                : null}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default ModalSelectProd;
