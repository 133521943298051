import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import FormasDePago from "./../../../../../SAT/Componentes/FormasDePagoSat";
import SelectSeries from "../../../../Catalogos/Series/Componentes/SelectSeries";
import UsoCFDI from "./../../../../../SAT/Componentes/UsoCFDI";
import { Tune } from "@mui/icons-material";

const DatosCFDI = ({ Values, Errores, handlInputChange }) => {
  return (
    <Row className="p-3">
      <Col xs={12} md={4} lg={3} className="p-3">
        {}

        <FormControl fullWidth>
          <InputLabel id="demo-select-small-label">Fecha de emisión</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={Values.fecha_emision}
            error={Errores.fecha_emision}
            label="Fecha de emisión"
            onChange={handlInputChange}
            fullWidth
            name="fecha_emision"
            defaultValue={"0"}
          >
            <MenuItem value={"0"}> Actual </MenuItem>
            <MenuItem value={"-1"}>1 día antes</MenuItem>
            <MenuItem value={"-2"}>2 días antes</MenuItem>
            <MenuItem value={"-3"}>3 días antes</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col xs={12} md={4} lg={3} className="p-3">
        <SelectSeries
         tipo="PAGO"
          name="serie"
          Value={Values.serie}
          Error={Errores.serie}
          handlInputChange={handlInputChange}
        />
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3">
        <FormControl fullWidth>
          <InputLabel id="demo-select-small-label">
            Tipo de comprobante
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={Values.tipo_comprobante}
            error={Errores.tipo_comprobante}
            label="Fecha de emisión"
            onChange={handlInputChange}
            fullWidth
            name="tipo_comprobante"
            defaultValue={"0"}
          >
            <MenuItem value={"P"}>P - Pago </MenuItem> 
          </Select>
        </FormControl>
      </Col>

      <Col xs={12} md={4} lg={3} className="p-3">
        <UsoCFDI
        disabled={true}
          showCodigos={true}
          Value={Values.uso_cfdi}
          handlInputChange={handlInputChange}
          Error={Errores.uso_cfdi}
        />
      </Col>

     
      <Col xs={12} className="p-3">
        <TextField
          fullWidth
          multiline
          rows={2}
          value={Values.observaciones}
          label={"Observaciones"}
          inputProps={{ maxLength: 500 }}
          name="observaciones"
          onChange={handlInputChange}
        />
      </Col>
    </Row>
  );
};

export default DatosCFDI;
