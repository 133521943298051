import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";

export const getInfoConfig = async () => {
  let data = [];

  const url = `configUsuarios/Consultar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Values = {
          id: String(data.ID),
          id_usuario: String(data.ID_USUARIO),
          decimales: String(data.NUMERO_DE_DECIMALES),
          correo_vigencia: String(data.CORREO_VIGENCIA_SISTEMA),
          correo_folios: String(data.CORREO_EXISTENCIA_FOLIOS),
          correo_certificados: String(data.CORREO_VIGENCIA_CERTIFICADOS),
          is_requiere_sms: data.IS_REQUIERE_SMS,
          is_requiere_whatsapp: data.IS_REQUIERE_WHATSAPP,
          telefono_sms: data.TELEFONO_SMS == 0 ? '' : String(data.TELEFONO_SMS),
          telefono_whatsapp: data.TELEFONO_WHATSAPP == 0 ? '' : String(data.TELEFONO_WHATSAPP),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};


export const guardarDecimales = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.decimales || Values.decimales == "") {
    Errores.decimales = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const url = "configUsuarios/EditarDecimales";
    const body = Values;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const guardarCorreos = async (Values, Errores, setErrores) => {
  let error = false;


  if (!error) {
    let data = [];
    const url = "configUsuarios/EditarCorreos";
    const body = Values;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};


export const guardarTelefonos = async (Values, Errores, setErrores) => {
  let error = false;

  if (String(Values.telefono_sms).trim() != "") {
    if (String(Values.telefono_sms).length != 10) {
      error = true
      Errores.telefono_sms = true;
      setErrores(Errores)
    }
  }

  if (String(Values.telefono_whatsapp).trim() != "") {
    if (String(Values.telefono_whatsapp).length != 10) {
      error = true
      Errores.telefono_whatsapp = true;
      setErrores(Errores)
    }
  }


  if (!error) {
    let data = [];
    const url = "configUsuarios/EditarTelefonos";
    const body = Values;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};


export const requiereSMS = async (Values, Errores, setErrores, checked) => {

  let error = false;

  // if (String(Values.telefono_sms).trim() != "") {
  //   if (String(Values.telefono_sms).length != 10) {
  //     error = true
  //     Errores.telefono_sms = true;
  //     setErrores(Errores)
  //   }
  // }
  
  if (!error) {
    let data = [];
    let queryParamsObj = {
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `configUsuarios/RequiereSMS?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }

};


export const requiereWhatsApp = async (Values, Errores, setErrores, checked) => {

  let error = false;

  // if (String(Values.telefono_whatsapp).trim() != "") {
  //   if (String(Values.telefono_whatsapp).length != 10) {
  //     error = true
  //     Errores.telefono_whatsapp = true;
  //     setErrores(Errores)
  //   }
  // }
  
  if (!error) {
    let data = [];
    let queryParamsObj = {
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `configUsuarios/RequiereWhatsApp?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }

};