import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Col, Row } from "react-bootstrap";

import Suscripcion from "../Items/Suscripcion";
import CloseIcon from "@mui/icons-material/Close";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CompraPlan from "../CompraPlan"; 

const ModalSuscripcion = ({
  planes,
  setShowDialogoDetalle,
  planSeleccionado
}) => {


 

  const [periodo, setPeriodo] = useState("anual");
  const [idPlanSelec, setIdPlanSelec] = useState(0);
 

  const handleChange = (event, newAlignment) => {
    if (newAlignment != null) {
      setPeriodo(newAlignment);
    }
  };



  return (
    <Dialog fullWidth maxWidth="lg" open={true}>
      <IconButton
        aria-label="Close"
        className="m-2"
        style={{ position: "absolute", right: "0%" }}
        onClick={() => {
          setShowDialogoDetalle(false);
        }}
      >
        <CloseIcon style={{ width: "30px", height: "30px" }} />
      </IconButton>
      {
        <DialogTitle>
          <h5 className="fw-bolder f-12 mt-2 w-100 text-center">
            {idPlanSelec == 0 ? " Conoce nuestros planes" : "Adquirir plan"}
          </h5>
        </DialogTitle>
      }
      <DialogContent style={{ minHeight: "70vh" }}>
        <Row className="my-4">
          <Col xs={12} className="d-flex justify-content-center mb-4">
            <ToggleButtonGroup
              color="primary"
              value={periodo}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton style={{ width: "180px" }} value="anual">
                Plan anual
              </ToggleButton>
              <ToggleButton style={{ width: "180px" }} value="semestral">
                Plan semestral
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>

          {idPlanSelec == 0 ? (
            planes.map((data, index) => (
              <Col sm={12} md={6} lg={4} className="mb-3">
                <Suscripcion
                  key={"modal_plan_" + data.ID}
                  data={data}
                  isVistaDetalle={true}
                  periodo={periodo}
                  planSeleccionado={planSeleccionado}
                  setIdPlanSelec={setIdPlanSelec}
                  isVisibleBoton={true}
                />
              </Col>
            ))
          ) : (
            <CompraPlan
              data={planes}
              planSeleccionado={planSeleccionado}
              periodo={periodo}
              idPlanSelec={idPlanSelec}
              setIdPlanSelec={setIdPlanSelec}
              isVisibleBoton={true}
            />
          )}
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSuscripcion;
