import React, { useEffect } from "react";
import Error from "../assets/404error.png";

const Error404 = () => {
  useEffect(() => {
    document.title = `404`;
  }, []);
  return (
    <div className="d-flex" style={{height: 'calc(100vh - 80px)', width: '100%', flex: 1}}>
      <div className="m-auto">
        <div>
          <center>
            <img
              src={Error}
              className="img-fluid"
              style={{ maxWidth: "100px" }}
            />
          </center>
        </div>
        <h4 className="font-AvenirMedium mt-5">
          No se encontró el recurso solicitado
        </h4>
      </div>
    </div>
  );
};

export default Error404;
