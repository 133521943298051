import { Col, Row } from "react-bootstrap"
import { MesesBimestralSat, MesesSat, PeriodicidadSat } from "../../../../Lib/Funciones Generales/Constantes"
import { getAñosAnteriores, getCurrentYear } from "../../../../Lib/Funciones Generales/ObtenerFecha"

import Select from "../../../../Includes/Select"


const DatosGlobales = ({ Values, Errores, handlInputChange }) => {
    return (
        <Row className="p-3">
            <Col xs={12} md={4} lg={3} className="p-3">
                <Select
                    Error={Errores.periodicidad_global}
                    HelperText={Errores.periodicidad_global && "Falta seleccionar la periodicidad"}
                    Name={'periodicidad_global'}
                    Value={Values.periodicidad_global}
                    handlInputChange={handlInputChange}
                    Opciones={PeriodicidadSat}
                    Titulo="Periodicidad"
                />
            </Col>

            <Col xs={12} md={4} lg={3} className="p-3">
                <Select
                    Error={Errores.mes_global}
                    HelperText={Errores.mes_global && "Falta seleccionar el mes"}
                    Name={'mes_global'}
                    Value={Values.mes_global}
                    handlInputChange={handlInputChange}
                    Opciones={Values.periodicidad_global != "05" ? MesesSat : MesesBimestralSat}
                    Titulo="Mes"
                />
            </Col>

            <Col xs={12} md={4} lg={3} className="p-3">
                <Select
                    Error={Errores.anio_global}
                    HelperText={Errores.anio_global && "Falta seleccionar el año"}
                    Name={'anio_global'}
                    Value={Values.anio_global}
                    handlInputChange={handlInputChange}
                    Opciones={getAñosAnteriores(getCurrentYear(), 5)}
                    Titulo="Año"
                />
            </Col>
        </Row>
    )
}

export default DatosGlobales