import { useState } from "react";
import { Alert, Button, Dialog, DialogContent, DialogTitle, IconButton, Snackbar, TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { guardarSolicitud } from "../../Funciones/Soporte";
import { styled } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"
import '../../../../Lib/Funciones Generales/Prototipos'

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);

const ModalSoporte = ({
    setShowDialogoSoporte,
    ValuesGenerales,
    EndPoint = "",
    Asunto = "",
    Titulo = "Solicitar soporte",
}) => {

    const [Values, setValues] = useState({
        nombre: ValuesGenerales.nombre,
        correo: ValuesGenerales.correo,
        telefono: ValuesGenerales.telefono == 0 ? '' : ValuesGenerales.telefono,
        asunto: Asunto,
        descripcion: '',
    })
    const [Errores, setErrores] = useState({
        nombre: false,
        correo: false,
        telefono: false,
        asunto: false,
        descripcion: false,
    })

    const [IsGuardando, setIsGuardando] = useState(false);
    const [open, setOpen] = useState(false);
    const [mensaje, setMensaje] = useState("");

    const handlInputChange = ({ target }) => {
        let Name = target.name;
        let Value = target.value;

        if (Name == "telefono") {
            Value = Value.toNumber()
        }

        let NewValue = {
            ...Values,
            [Name]: Value,
        };
        setValues(NewValue);
        setErrores({
            ...Errores,
            [Name]: false,
        });
    }

    const GuardarSolicitud = () => {
        setIsGuardando(true);

        guardarSolicitud(Values, Errores, setErrores, EndPoint)
            .then((data) => {
                if (data.codigo == "200") {
                    setIsGuardando(false);
                    MySwal.fire({
                        title: "Correcto",
                        html: data.mensaje,
                        icon: "success",
                        confirmButtoColor: "#C9A396",
                        showConfirmButton: false,
                        timer: 1700,
                    }).then((result) => {
                        setIsGuardando(false);
                        setShowDialogoSoporte(false);
                    });
                } else {
                    setMensaje(data.mensaje);
                    setOpen(true);
                    setIsGuardando(false);
                }
            })
            .catch((data) => {
                setMensaje(data.mensaje);
                setOpen(true);
                setIsGuardando(false);
            });
    }


    return (
        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={() => setOpen(false)} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog fullWidth maxWidth="lg" open={true}>
                <IconButton
                    aria-label="Close"
                    className="m-2"
                    style={{ position: "absolute", right: "0%" }}
                    onClick={() => {
                        setShowDialogoSoporte(false);
                    }}
                >
                    <CloseIcon style={{ width: "30px", height: "30px" }} />
                </IconButton>

                <DialogContent >

                    <Row className="mt-3">
                        <Col sm={12} md={4}>
                            <h6 className="font-AvenirBold">{Titulo}</h6>
                        </Col>
                        <Col sm={12} md={8}>
                            <Row className="m-0 pe-3 pt-3">

                                <Col sm={12} className="p-2">
                                    <TextField
                                        fullWidth
                                        name="nombre"
                                        label="Nombre de contacto"
                                        variant="outlined"
                                        placeholder="Nombre de contacto"
                                        value={Values.nombre}
                                        error={Errores.nombre}
                                        helperText={Errores.nombre && "Ingresa el nombre de contacto"}
                                        onChange={handlInputChange}
                                    />
                                </Col>

                                <Col sm={12} md={6} className="p-2">
                                    <TextField
                                        fullWidth
                                        inputProps={{ maxLength: 10 }}
                                        name="telefono"
                                        label="Teléfono"
                                        variant="outlined"
                                        placeholder="Teléfono"
                                        value={Values.telefono}
                                        error={Errores.telefono}
                                        helperText={Errores.telefono && "Ingresa el teléfono"}
                                        onChange={handlInputChange}
                                    />
                                </Col>

                                <Col sm={12} md={6} className="p-2">
                                    <TextField
                                        fullWidth
                                        name="correo"
                                        label="Correo"
                                        variant="outlined"
                                        placeholder="Correo"
                                        value={Values.correo}
                                        error={Errores.correo}
                                        helperText={Errores.correo && "Ingresa el correo"}
                                        onChange={handlInputChange}
                                    />
                                </Col>

                                <Col sm={12} className="p-2">
                                    <TextField
                                        inputProps={{
                                            readOnly: Asunto != "",
                                        }}
                                        fullWidth
                                        name="asunto"
                                        label="Asunto"
                                        variant="outlined"
                                        placeholder="Asunto"
                                        value={Values.asunto}
                                        error={Errores.asunto}
                                        helperText={Errores.asunto && "Ingresa el asunto"}
                                        onChange={handlInputChange}
                                    />
                                </Col>

                                <Col sm={12} className="p-2">
                                    <TextField
                                        fullWidth
                                        name="descripcion"
                                        label="Descripción"
                                        variant="outlined"
                                        placeholder="Descripción"
                                        value={Values.descripcion}
                                        error={Errores.descripcion}
                                        helperText={Errores.descripcion && "Ingresa el descripción"}
                                        onChange={handlInputChange}
                                        rows={3}
                                        multiline
                                    />
                                </Col>

                                <Col sm={12} className="p-2 pt-3 d-flex justify-content-end">
                                    <LoadingButton
                                        loading={IsGuardando}
                                        loadingPosition="start"
                                        onClick={GuardarSolicitud}
                                        className="btn btn-Crexendo font-AvenirMedium mt-0 py-2 px-4"
                                        variant="contained"
                                        xs={{ with: "100$" }}
                                    >
                                        <span
                                            className={IsGuardando ? "px-4 " : "px-2 font-AvenirMedium "}
                                        >
                                            {IsGuardando ? "Enviando..." : "Enviar"}
                                        </span>
                                    </LoadingButton>

                                    <Button
                                        onClick={() => setShowDialogoSoporte(false)}
                                        className="btn btn-cancelar font-AvenirMedium py-2 px-4 ms-3"
                                        variant="outlined"
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ModalSoporte