import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import AppUsuarios from "./AppUsuarios";
import AppManager from "./AppManager";
import Error404 from "./Includes/Error404";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RegistroUsuario from "./app/Login/Componentes/RegistroUsuario";
import RestablecerContrasenaUsuario from "./app/Login/Componentes/RestablecerContrasena";
import BuscarCorreoUsuario from "./app/Login/Componentes/BuscarCorre";

import RestablecerContrasenaManager from "./manager/Login/Componentes/RestablecerContrasena";
import BuscarCorreoManager from "./manager/Login/Componentes/BuscarCorre";

import StartLoad from "./Includes/StartLoad";
import Index from "./Landing";
import ReportesPDFUsuario from "./app/Reportes/Rutas/RoutesReportesPDF";
import ReportesPDFManager from "./manager/Reportes/Rutas/ReportesPDF";

const root = ReactDOM.createRoot(document.getElementById("root"));

// window.localStorage.clear();
root.render(
  // <StrictMode>
    <Router>
      <Routes>
        {/* <Route path="/" element={ <StartLoad />} /> */}
        <Route path="/" element={ < Index/>} />
        <Route path="/app/pdf/*" element={<ReportesPDFUsuario />}></Route>
        <Route path="/manager/pdf/*" element={<ReportesPDFManager />}></Route>

        <Route path="/app/restablecer-contrasena" element={<BuscarCorreoUsuario />} />
        <Route path="/app/cambiar-contrasena/:token?" element={<RestablecerContrasenaUsuario />} />
        <Route path="/app/registro" element={<RegistroUsuario />} />
        <Route path="/app/*" element={<AppUsuarios />} />

        <Route path="/manager/restablecer-contrasena" element={<BuscarCorreoManager />} />
        <Route path="/manager/cambiar-contrasena/:token?" element={<RestablecerContrasenaManager />} />
        <Route path="/manager/*" element={<AppManager />} />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  // </StrictMode>
);
