import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { Col, Row, Stack } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import DatosCFDI from "./DatosCFDI";
import { useState } from "react";
import ModalSelectProd from "../../../Catalogos/Productos/Componentes/ModalSelectProd";
import ModalSelectCliente from "../../../Catalogos/Clientes/Componentes/ModalSeelctCliente";
import { useEffect } from "react";
import { getDatosEmisor, timbrarCFDI } from "../Funciones/Facturas";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "./../../../../Includes/Loading";
import Conceptos from "./Conceptos";
import { LoadingButton } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext } from "react";
import { ContextInfoUser } from "../../../Context/ContextInfoUser";
import DatosGlobales from "./DatosGlobales";
import Switch from "../../../../Includes/Switch";
import { RECEPTOR_GLOBAL } from "../../../../Lib/Funciones Generales/Constantes";



const initialReceptor = {
  id: 0,
  razon_social: "Selecciona un receptor",
  regimen_fiscal_clave: "",
  regimen_fiscal: "",
  RFC: "XAXX010101000",
  nombre: "",
  correo: "",
  CP: "",
}

const NeuvaFactura = () => {
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [pagado, setPagado] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [showModalProductos, setShowModalProductos] = useState(false);
  const [showModalCliente, setShowModalCliente] = useState(false);
  const [isVisibleInfoEmi, setIsVisibleInfoEmi] = useState(false);
  const { getInfoUsuario } = useContext(ContextInfoUser);

  const [productosCarrito, setProductosCarrito] = useState([]);
  const [emisor, setEmisor] = useState([]);
  const [datosReceptor, setDatosReceptor] = useState(initialReceptor);
  const [Values, setValues] = useState({
    id_cliente: 0,
    fecha_emision: "0",
    tipo_comprobante: "I",
    condicion_pago: "",
    doc_relacionado: "00",
    uuid_relacion: "",
    serie: "",
    moneda: "MXN",
    tipo_cambio: "",
    forma_pago: "01",
    metodo_pago: "PUE",
    uso_cfdi: "G03",
    observaciones: "",
    pagado: true,
    conceptos: [],
    regimen_fiscal: '',

    is_factura_global: false,
    periodicidad_global: "00",
    mes_global: "00",
    anio_global: "00"
  });

  const [Errores, setErrores] = useState({
    id_cliente: false,
    fecha_emision: false,
    tipo_comprobante: false,
    condicion_pago: false,
    doc_relacionado: false,
    uuid_relacion: false,
    serie: false,
    moneda: false,
    tipo_cambio: false,
    forma_pago: false,
    metodo_pago: false,
    uso_cfdi: false,
    observaciones: false,
    pagado: false,
    periodicidad_global: false,
    mes_global: false,
    anio_global: false
  });

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    let NewValue = Value;

    if (Name == "tipo_cambio") {
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(Value)) {
        NewValue = Values.tipo_cambio;
      }
    }
    setValues((Values) => ({
      ...Values,
      [Name]: NewValue,
    }));

    if (Name == "metodo_pago") {
      if (Value == "PPD") {
        setValues((Values) => ({
          ...Values,
          "forma_pago": "99",
        }));
        return;
      }
    }
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };


  const validarInfoGlobalBimestral = () => {
    const valido = true;
    if (Values.periodicidad_global == "05") {
      const Mensaje = `(${RECEPTOR_GLOBAL.REGIMEN_FISCAL_CLAVE_BIMESTRAL}) - ${RECEPTOR_GLOBAL.REGIMEN_FISCAL_BIMESTRAL}`
      if (emisor.REGIMEN_FISCAL != RECEPTOR_GLOBAL.REGIMEN_FISCAL_CLAVE_BIMESTRAL) {
        Swal.fire({
          title: "Advertencia",
          html: `Para emitir facturas globales es nesesario el regimen fiscal de ${Mensaje}`,
          icon: "warning",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: true,
          confirmButtonText: "Ok",
          timer: 10000,
        });
        return false;
      }
    }
    return valido;
  }

  const handleProductoSeleccionado = (producto, cantidad = 1) => {
    const items = [...productosCarrito];

    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === producto.ID)
      : -1;
    if (itemIndex !== -1) {
      const productoExistente = items[itemIndex];
      productoExistente.CANTIDAD =
        Number(productoExistente.CANTIDAD) + Number(cantidad);
    } else {
      producto.CANTIDAD = cantidad;
      items.push(producto);
    }

    setProductosCarrito(items);
  };

  const GenerarCFDI = () => {

    if (validarInfoGlobalBimestral()) {
      setIsGuardando(true);
      timbrarCFDI(
        Values,
        Errores,
        setErrores,
        productosCarrito,
        datosReceptor,
        pagado
      )
        .then((data) => {
          console.log(data);
          if (data.codigo == "200") {
            setIsGuardando(false);
            Swal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: true,
              confirmButtonText: "OK",
              timer: 10000,
            }).then((result) => {
              getInfoUsuario();
              navigate(-1);
            });
          } else {
            Swal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "warning",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: true,
              confirmButtonText: "OK",
            }).then((result) => {
              setIsGuardando(false);
            });
          }
        })
        .catch((data) => {
          console.log(data);
          Swal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            setIsGuardando(false);
          });
        });
    }





  };

  useEffect(() => {
    setIsLoading(true);
    getDatosEmisor()
      .then((data) => {
        if (data.emisor.length == 0 || data.emisor?.RFC.trim() == "" || !data.emisor?.RFC) {
          Swal.fire({
            title: "Advertencia",
            html: "No se encontraron datos del emisor",
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            timer: 10000,
          }).then((result) => {
            navigate(-1);
          });
        }

        setEmisor(data.emisor);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      })
      .catch((data) => {
        console.log(data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (Values.is_factura_global) {
      setDatosReceptor({
        global: true,
        id: RECEPTOR_GLOBAL.ID,
        razon_social: RECEPTOR_GLOBAL.RAZON_SOCIAL,
        regimen_fiscal_clave: RECEPTOR_GLOBAL.REGIMEN_FISCAL_CLAVE,
        regimen_fiscal: RECEPTOR_GLOBAL.REGIMEN_FISCAL,
        RFC: RECEPTOR_GLOBAL.RFC,
        nombre: "",
        correo: "",
        CP: emisor.CP,
      })
    } else {
      if (datosReceptor.global) {
        setDatosReceptor({ global: true, ...initialReceptor })
      }
    }
  }, [Values.is_factura_global])

  useEffect(() => {
    if (Values.is_factura_global) {
      setValues({ ...Values, uso_cfdi: RECEPTOR_GLOBAL.USO_CFDI, metodo_pago: "PUE", forma_pago: "01" })
    } else {
      if (datosReceptor.global) {
        setValues({ ...Values, uso_cfdi: "G03" })
      }
    }
  }, [datosReceptor])

  useEffect(() => {
    if (Values.is_factura_global) {
      setValues({
        ...Values,
        mes_global: "00",
      })
      validarInfoGlobalBimestral();
    }

  }, [Values.periodicidad_global])

  console.log("USO CFDI:", Values.uso_cfdi)

  return (
    <>
      {showModalProductos && (
        <ModalSelectProd
          handleProductoSeleccionado={handleProductoSeleccionado}
          proveedorId={0}
          onClose={() => {
            setShowModalProductos(false);
          }}
          titulo_="Precio"
        ></ModalSelectProd>
      )}
      {showModalCliente && (
        <ModalSelectCliente
          setShowDialog={setShowModalCliente}
          setDatosReceptor={setDatosReceptor}
          titulo="Seleccionar un receptor"
        />
      )}

      <div className="container-lg   ">
        <div className="row">
          <div className="col-12">
            <Button
              onClick={() => navigate(-1)}
              className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
              variant="text"
            >
              <ArrowBackIcon className="me-3" />
              Facturas
            </Button>
          </div>
          {IsLoading ? (
            <div className="col-12">
              <Loading />
            </div>
          ) : (
            <>
              <div className="col-12 col-md-6 mb-4">
                <Card elevation={3} className=" shadow">
                  <CardContent className="p-0">
                    <div className="p-3">
                      <h6
                        style={{ fontSize: "13px" }}
                        className="mb-0 text-left font-AvenirBold"
                      >
                        Emisor
                      </h6>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Row className="px-4">
                      <Col xs={12} className="p-1">
                        <Typography
                          style={{ fontSize: "1.1rem" }}
                          className="font-AvenirBold"
                          variant="h6"
                        >
                          {emisor.RAZON_SOCIAL}
                        </Typography>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent={"center"}
                        >
                          <Typography
                            alignSelf="center"
                            style={{ fontSize: "13px" }}
                            className="font-Avenir text-muted me-2"
                          >
                            RFC
                          </Typography>
                          <Chip size="small" label={emisor.RFC}></Chip>
                        </Grid>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Accordion
                          expanded={isVisibleInfoEmi}
                          className="m-0 p-0 shadow-none"
                          onChange={() =>
                            setIsVisibleInfoEmi(!isVisibleInfoEmi)
                          }
                        >
                          <AccordionSummary
                            className="p-0 m-1"
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              style={{ color: "#3ABE88", flexShrink: 0 }}
                            >
                              Información del emisor{" "}
                              <ArrowDropDownIcon style={{ color: "#3ABE88" }} />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="p-0 m-0">
                            <Grid
                              className="mb-2"
                              container
                              direction="column"
                              alignItems="start"
                            >
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                {emisor.RAZON_SOCIAL}
                              </Typography>
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                {emisor.CORREO}
                              </Typography>
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                Régimen: {emisor.REGIMEN_FISCAL} - {emisor.REGIMEN_FISCAL_DESCRIPCION}
                                {emisor.DESCRIPCION}
                              </Typography>

                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                CP: {emisor.CP}
                              </Typography>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </div>

              <div className="col-12 col-md-6 mb-4">
                <Card elevation={3} className=" shadow">
                  <CardContent className="p-0">
                    <div className="p-3 py-2 d-flex justify-content-between flex-wrap">
                      <h6
                        className="mb-0 text-left font-AvenirBold align-self-center"
                        style={{ fontSize: "13px" }}
                      >
                        Receptor
                      </h6>
                      {
                        !Values.is_factura_global ? (
                          <div>
                            <Button
                              className="btn btn-Crexendo-light px-3 shadow-none "
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setShowModalCliente(true);
                              }}
                            >
                              <SearchIcon className="me-2" /> Seleccionar
                            </Button>
                          </div>
                        ) : (
                          <div style={{ height: 33 }} />
                        )
                      }

                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Row className="px-4">
                      <Col xs={12} className="p-1">
                        <Typography
                          style={{ fontSize: "1.1rem" }}
                          className="font-AvenirBold"
                          variant="h6"
                        >
                          {datosReceptor.razon_social}
                        </Typography>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent={"center"}
                        >
                          <Typography
                            alignSelf="center"
                            style={{ fontSize: "13px" }}
                            className="font-Avenir text-muted me-2"
                          >
                            RFC
                          </Typography>
                          <Chip size="small" label={datosReceptor.RFC}></Chip>
                        </Grid>
                      </Col>
                      <Col xs={12} md={12} className="p-1">
                        <Accordion
                          expanded={expanded === "panel2"}
                          className="m-0 p-0 shadow-none"
                          onChange={handleChange("panel2")}
                        >
                          <AccordionSummary
                            className="p-0 m-1"
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography
                              style={{ color: "#3ABE88", flexShrink: 0 }}
                            >
                              Información del receptor{" "}
                              <ArrowDropDownIcon style={{ color: "#3ABE88" }} />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="p-0 m-0 w-100">
                            <Grid
                              className="mb-2"
                              container
                              direction="column"
                              alignItems="start"
                            >
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                {datosReceptor.nombre}
                              </Typography>
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                {datosReceptor.correo}
                              </Typography>

                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                Régimen: {datosReceptor.regimen_fiscal_clave} - {datosReceptor.regimen_fiscal}
                                {datosReceptor.DESCRIPCION}
                              </Typography>

                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                CP: {datosReceptor.CP}
                              </Typography>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </div>


              <div className="col-12  ">
                <Card elevation={3} className="mb-4 shadow">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6
                        className="my-auto text-left font-AvenirBold"
                        style={{ fontSize: "13px" }}
                      >
                        Factura global
                      </h6>

                      <Switch
                        checked={Values.is_factura_global}
                        onChange={(is_factura_global) => {
                          const global = {
                            periodicidad_global: is_factura_global ? Values.periodicidad_global : "00",
                            mes_global: is_factura_global ? Values.periodicidad_global : "00",
                            anio_global: is_factura_global ? Values.anio_global : "00",
                          }
                          setValues({ ...Values, ...global, is_factura_global })
                        }}
                        tituloActivo="Si factura global"
                        tituloInactivo="No factura global"
                      />

                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />

                    {
                      Values.is_factura_global && (
                        <DatosGlobales
                          Values={Values}
                          Errores={Errores}
                          handlInputChange={handlInputChange}
                        />
                      )
                    }

                  </CardContent>
                </Card>
              </div>

              <div className="col-12  ">
                <Card elevation={3} className="mb-4 shadow">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6
                        className="mb-0 text-left font-AvenirBold"
                        style={{ fontSize: "13px" }}
                      >
                        Datos del CFDI
                      </h6>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />

                    <DatosCFDI
                      Values={Values}
                      Errores={Errores}
                      handlInputChange={handlInputChange}
                    />
                  </CardContent>
                </Card>
              </div>

              <div className="col-12  ">
                <Card elevation={3} className="mb-4">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6
                        className="mb-0 text-left font-AvenirBold"
                        style={{ fontSize: "13px" }}
                      >
                        Conceptos
                      </h6>
                      <div>
                        <Button
                          className="btn btn-Crexendo-light  px-3 shadow-none "
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setShowModalProductos(true);
                          }}
                        >
                          Buscar productos <SearchIcon className="ms-2" />
                        </Button>
                      </div>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Conceptos
                      Values={Values}
                      pagado={pagado}
                      setPagado={setPagado}
                      productosCarrito={productosCarrito}
                      setProductosCarrito={setProductosCarrito}
                    />
                  </CardContent>

                  <CardActions></CardActions>
                </Card>

                <div className="text-star mt-2 w-100">
                  <Stack
                    className="p-0"
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                    style={{}}
                  >
                    <LoadingButton
                      loading={IsGuardando}
                      loadingPosition="start"
                      disabled={IsLoading}
                      onClick={() => {
                        GenerarCFDI();
                      }}
                      className="btn btn-Crexendo font-AvenirMedium  py-2 px-4  me-2"
                      variant="contained"
                      xs={{ with: "100$" }}
                    >
                      <span className={IsGuardando ? "px-4" : "px-2"}>
                        {IsGuardando ? "Timbrando factura..." : "Crear"}
                      </span>
                    </LoadingButton>

                    <Button
                      onClick={() => navigate(-1)}
                      className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                      variant="outlined"
                    >
                      Cancelar
                    </Button>
                  </Stack>
                </div>
              </div>
              <div className="col-12  pt-5 m-t5"></div>
              <div className="col-12  pt-5 m-t5"></div>
              <div className="col-12  pt-5 m-t5"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NeuvaFactura;
