import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";

import whatsflotante from "../Assets/8WhatsAppFlotante.svg";
import { EnviarCorreo } from "../Funciones/Contacto";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingButton from "@mui/lab/LoadingButton";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const MySwal = withReactContent(Swal);

function Contacto() {
  const [mensaje, setMensaje] = useState("");
  const [IsEnviado, setIsEnviado] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [Values, setValues] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    mensaje: "",
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellido: false,
    correo: false,
    telefono: false,
    mensaje: false,
  });

  const handleInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    console.log("Nombre:", Name, "Valor:", Value);
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    if (Name == "telefono") {
      const re = /^[0-9\b]+$/;
      if (re.test(target.value)) {
        NewValue = { ...NewValue, telefono: target.value };
      }
    }
    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    setIsEnviado(true);
    EnviarCorreo(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsEnviado(false);
          setIsLoading(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
          setValues({
            nombre: "",
            apellido: "",
            correo: "",
            telefono: "",
            mensaje: "",
          });
        } else {
          MySwal.fire({
            title: "Alerta",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
          setIsLoading(false);
          setIsEnviado(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsEnviado(false);
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{ backgroundColor: "#ecedef" }}
      id="contacto"
      className="container-fluid py-1 px-1 py-md-5 px-md-5 mt-5"
    >
      <div className="container">
        <Row>
          <Col xs={12} lg={5}>
            <Row>
              <Col xs={12} lg={12}>
                <div>
                  <h1 className="mt-5 mt-md-0">
                    ¡Estamos aquí para ayudarte!{" "}
                  </h1>
                  <p>
                    No dudes en ponerte en contacto con nosotros para cualquier
                    consulta, comentario o solicitud que tengas. Tu feedback es
                    importante para nosotros.
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={12}>
                <div>
                  <Card className="text-white card-Crexendo responsive-container p-2 mt-2 mb-2">
                    <CardContent>
                      <div
                        style={{
                          maxHeight: "auto",
                        }}
                      >
                        <h5 className="mb-3 Avenir">
                          ¿Requieres Asistencia Técnica?
                        </h5>
                        {/*  <h6 className="mb-3 Avenir">
                          Comunícate con nuestro equipo de soporte aquí
                        </h6>
                     
    <Button
                          style={{
                            backgroundColor: "white",
                            color: "#3ABE88",
                            width: "100px",
                            height: "50px",
                          }}
                        >
                          Contactar
                        </Button>
                          */}
                        <div className="d-flex flex-column w-100">
                          <div>
                            <a
                              href="https://wa.me/3326016638 "
                              target="_blank"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              <WhatsAppIcon></WhatsAppIcon>
                              {"  "}3326016638
                            </a>
                          </div>
                          <div className="mt-3">
                            <a
                              href="mailto:soporte@crexendo.app"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              <MailOutlineIcon></MailOutlineIcon>
                              {"  "}soporte@crexendo.app
                            </a>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} lg={7} className="mt-0 p-2 mt-3 mt-md-0">
            <Row className="m-0">
              <Col xs={12} md={6} className="p-2">
                <TextField
                  multiline
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  inputProps={{ maxLength: 50 }}
                  placeholder="Nombre"
                  style={{ width: "100%", height: "80px" }}
                  value={Values.nombre}
                  error={Errores.nombre}
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={12} md={6} className="p-2">
                <TextField
                  multiline
                  fullWidth
                  name="apellido"
                  label="Apellido"
                  inputProps={{ maxLength: 50 }}
                  placeholder="Apellido"
                  style={{ width: "100%", height: "80px" }}
                  value={Values.apellido}
                  error={Errores.apellido}
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={12} md={6} className="p-2">
                <TextField
                  multiline
                  fullWidth
                  name="correo"
                  label="Correo Electrónico"
                  inputProps={{ maxLength: 100 }}
                  placeholder="Correo Electrónico"
                  style={{ width: "100%", height: "80px" }}
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} md={6} className="p-2">
                <TextField
                  multiline
                  fullWidth
                  id="Telefono"
                  name="telefono"
                  label="Teléfono"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 10,
                  }}
                  placeholder="Teléfono"
                  style={{ width: "100%", height: "80px" }}
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} md={12} className="p-2">
                <TextField
                  multiline
                  fullWidth
                  name="mensaje"
                  label="Mensaje"
                  inputProps={{ maxLength: 500 }}
                  placeholder="Mensaje"
                  value={Values.mensaje}
                  error={Errores.mensaje}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="m-0 mb-5 mb-md-0">
              <Col xs={12} md={12} className="p-2">
                <LoadingButton
                  loading={IsEnviado}
                  loadingPosition="start"
                  disabled={isLoading}
                  onClick={() => handleSubmit()}
                  className="btn btn-Crexendo font-AvenirMedium"
                  variant="contained"
                >
                  <span className={IsEnviado ? "px-4" : "px-2"}>
                    {IsEnviado ? "Enviando..." : "Enviar"}
                  </span>
                </LoadingButton>
              </Col>
            </Row>
            {/* <Row className="mt-5 mx-1">
              <Col align="right">
                <a href="www.whatsapp.com">
                  <img src={whatsflotante} />
                </a>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Contacto;
