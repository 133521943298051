import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Button, Card, CardContent } from "@mui/material"

import Addenda from "../Items/Addenda"
import ImgDefault from '../../../../assets/EditarImagen.svg'
import ModalAddendaCompra from "../Dialogos/ModalAddendaCompra"
import ModalSoporte from "../Dialogos/ModalSoporte"


const TabAddenda = ({
  Addendas,
  setAddendas,
  ValuesGenerales
}) => {

  const [showDialogoContacto, setShowDialogoContacto] = useState(false)
  const [showDialogoCompra, setShowDialogoCompra] = useState(false)
  const [addendaSeleccionada, setAddendaSeleccionada] = useState(null)


  const handleClickComprar = (ID) => {
    setShowDialogoCompra(true)
    setAddendaSeleccionada(Addendas.find(x => x.ID == ID))
  }


  return (
    <>
      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 pt-3">
          <Row className="p-3">
            <Col sm={12} md={8}>
              <h6 className="mb-0 text-left font-AvenirBold">
                Addendas disponibles
              </h6>
              <p>Adquiere la addenda que requieres</p>
            </Col>
            <Col sm={12} md={4} className="d-flex justify-content-end">
              <Button
                onClick={() => setShowDialogoContacto(true)}
                className="btn btn-Crexendo font-AvenirMedium py-2 px-5 mt-3 mb-3"
                variant="contained"
              >
                Solicitar addenda
              </Button>
            </Col>
          </Row>
          <Row className="p-3 pt-0">
            {
              Addendas.map((data, index) => (
                <Col xs={6} lg={4} className="mb-3">
                  <Addenda
                    key={'addenda_' + data.ID}
                    imgDefault={ImgDefault}
                    data={data}
                    handleClickComprar={handleClickComprar} />
                </Col>
              ))
            }
          </Row>


          <div className="px-3">
            <hr />
            <h6 className="mb-0 text-left font-AvenirMedium pt-3 pb-4">
            No podemos reembolsar tu compra una vez que ya se procesó el pago.
            </h6>

          </div>

        </CardContent>
      </Card>



      {
        showDialogoCompra && addendaSeleccionada && (
          <ModalAddendaCompra
            imgDefault={ImgDefault}
            Addendas={Addendas}
            setAddendas={setAddendas}
            addendaSeleccionada={addendaSeleccionada}
            setAddendaSeleccionada={setAddendaSeleccionada}
            setShowDialogoCompra={setShowDialogoCompra}
          />
        )
      }

      {
        showDialogoContacto && (
          <ModalSoporte
            Titulo="Solicitar addenda"
            Asunto="Solicitud de nueva addenda"
            EndPoint='perfil/SolicitarAddenda'
            setShowDialogoSoporte={setShowDialogoContacto}
            ValuesGenerales={ValuesGenerales}
          />
        )
      }

    </>
  )
}

export default TabAddenda