import { stringify } from "query-string";
import { getData, postdData } from "../../../Context/backend";
import RFCValidator from "../../../../Lib/Funciones Generales/ValidarRFC";
import { TIPO_FACTURA } from "../../../../Lib/Funciones Generales/Constantes";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLicencias = async (
  iTake,
  iSkip,
  FechaInicio = "",
  FechaFin = ""
) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    FechaInicio,
    FechaFin,
  };
  const url = `planes/ListarLicenciasVendidas?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getAddendas = async (
  iTake,
  iSkip,
  FechaInicio = "",
  FechaFin = ""
) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    FechaInicio,
    FechaFin,
  };
  const url = `planes/ListarAddendasVendidas?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getPlantillas = async (
  iTake,
  iSkip,
  FechaInicio = "",
  FechaFin = ""
) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    FechaInicio,
    FechaFin,
  };
  const url = `planes/ListarPlantillasVendidas?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getDatosFacturacion = async (id, isUsuario = false, tipo = TIPO_FACTURA.LICENCIA) => {
  let queryParamsObj = {
    ID: id,
    TIPO: tipo,
  };
  let url;

  if (isUsuario) {
    url = `planes/getDatosFacturacionUsuario?${stringify(queryParamsObj)}`;
  } else {
    url = `planes/getDatosFacturacion?${stringify(queryParamsObj)}`;
  }

  const res = await getData(url, isUsuario);
  return new Promise((resolve, reject) => {
    //console.log(res);
    if (!res.error) {
      // data = JSON.parse(res.data.respuesta);
      const em = JSON.parse(res.data.respuesta.emisor);
      const rec = JSON.parse(res.data.respuesta.receptor);
      const concep = JSON.parse(res.data.respuesta.conceptos);

      let values = {
        emisor: em.length > 0 ? em[0] : {},
        receptor: rec.length > 0 ? rec[0] : {},
        conceptos: concep,
      };
      resolve(values);
    } else {
      reject([]);
    }
  });
};

///////////////////
/* FUNCIONES POST */
///////////////////

export const timbrarCFDICompra = async (
  Values,
  Errores,
  setErrores,
  id,
  isUsuario = false
) => {
  let error = false;

  if (!Values.uso_cfdi || Values.uso_cfdi == "") {
    Errores.uso_cfdi = true;
    error = true;
  }

  if (!Values.metodo_pago || Values.metodo_pago == "") {
    Errores.metodo_pago = true;
    error = true;
  }
  if (!Values.forma_pago || Values.forma_pago == "") {
    Errores.forma_pago = true;
    error = true;
  }
  if (!Values.fecha_emision || Values.fecha_emision == "") {
    Errores.fecha_emision = true;
    error = true;
  }

  if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
    Errores.cp = true;
    setErrores(Errores);
    error = true;
  }
  if (
    !Values.regimen_fiscal ||
    Values.regimen_fiscal == "" ||
    Values.regimen_fiscal == "0"
  ) {
    Errores.regimen_fiscal = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.razon_social || Values.razon_social == "") {
    Errores.razon_social = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.rfc || Values.rfc == "") {
    Errores.rfc = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!RFCValidator(Values.rfc)) {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.id_cliente || Values.id_cliente <= 0 || Values.id_cliente == "") {
    Errores.id_cliente = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.serie || Values.serie == "" || Values.serie == undefined) {
    Errores.serie = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    Values.id = id;
    Values.regimen_fiscal = String(Values.regimen_fiscal);
    Values.auto_factura = false;
    const body = Values;
    const url = `planes/facturarPlan`;

    const res = await postdData(url, body, isUsuario);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      if (Errores.rfc === true) {
        resolve({ mensaje: "Los datos de receptor son requeridos" });
      }
      if (Errores.uso_cfdi === true) {
        resolve({ mensaje: "El uso de cdfi es requerido." });
      }

      if (Errores.forma_pago === true) {
        resolve({ mensaje: "La forma de pago es requerida" });
      }

      if (Errores.metodo_pago === true) {
        resolve({ mensaje: "El metodo de pago es requerido" });
      }
    });
  }
};
