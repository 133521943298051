import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PDFFactura from "../../../app/Facturacion/Facturas/Componentes/PDF/PDFFactura";
import Error404 from "../../../Includes/Error404";
import ComponentPDF from "../Componentes/ComponentPDF";
import ComponentExcel from "../Componentes/ComponentExcel";

const ReportesPDFManager = () => {
  return (
    <Routes>
      <Route path="repFactUsuariosPdf/" element={
        <ComponentPDF title="Reporte facturas" url="Facturacion/repFactClientesUsuariosPdf" />
      } />
      <Route path="repFactUsuariosExcel/" element={
        <ComponentExcel title="Reporte facturas" url="Facturacion/repFactClientesUsuariosExcel" />
      } />

      <Route path="repFactComprasPdf/" element={
        <ComponentPDF title="Reporte facturas" url="Facturacion/repFactComprasUsuariosPdf" />
      } />
      <Route path="repFactComprasExcel/" element={
        <ComponentExcel title="Reporte facturas" url="Facturacion/repFactComprasUsuariosExcel" />
      } />

      <Route path="repVentasConsolidadoPdf/" element={
        <ComponentPDF title="Ventas consolidado" url="Planes/repVentasConsolidadoPdf" />
      } />
      <Route path="repVentasConsolidadoExcel/" element={
        <ComponentExcel title="Reporte facturas" url="Planes/repVentasConsolidadoExcel" />
      } />

      <Route
        path="repFacturaManager/:uuid"
        element={<PDFFactura isManagger={true} isUsuario={false} />}
      />

      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};
export default ReportesPDFManager;
