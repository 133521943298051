import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

import facebook from "../Assets/7.2facebook.svg";
import twitter from "../Assets/7.3twitter.svg";
import instagram from "../Assets/7.4Instagram.svg";
import linkedin from "../Assets/7.5linkedin.svg";
import whatsapp from "../Assets/7.6WhatsApp.svg";

function Footer() {
  return (
    <div className="container-fluid py-5 px-1  px-md-5" style={{
      background: "#151916",
    }}>
      <div className="container">
        <Row>
          <Col xs={12} sm={6} className="mb-5 mb-sm-2 d-flex justify-content-center justify-content-md-start ">
            <img src="https://crexendo.blob.core.windows.net/home/LogoFooter.svg" />
          </Col>
          <Col xs={12} sm={6} className="d-flex justify-content-center justify-content-md-end">
            <a href="https://www.facebook.com/CrexendoSW" target="_blank" className="mx-3">
              <img src={facebook} />
            </a> 
            <a href="https://www.instagram.com/crexendosw" target="_blank"  className="mx-3">
              <img src={instagram} />
            </a>
            <a href="https://www.linkedin.com/company/crexendosw" target="_blank" className="mx-3">
              <img src={linkedin} />
            </a>
            <a href="https://wa.me/3326016638" target="_blank" className="mx-3">
              <img src={whatsapp} />
            </a>
          </Col>
        </Row>
        <div className="my-5" style={{
          width: "100%",
          height: "0.1rem",
          backgroundColor:"#424242",
          borderRadius:"1em"
        }} />
        {/* <hr className="text-white my-5" style={{
          fontWeight: "bold"
        }} /> */}
        <Row>
          <Col xs={12} sm={6} className="mb-5 mb-sm-0 ">
            <h6 className="text-white font-Avenir text-center text-md-start">
              Copyright © Crexendo 2023 Todos los derechos reservados
            </h6>
          </Col>
          <Col xs={12} sm={6} className="mb-5 mb-md-0 pb-5 pb-md-0">
            <div className="d-flex justify-content-center justify-content-md-end   font-Avenir">
              <a href="/terminos" className="text-white mx-5 link-underline" style={{ textDecoration: "none" }}>
                Términos y condiciones
              </a>
              <a href="/politicas" className="text-white font-Avenir link-underline" style={{ textDecoration: "none", hover: { textDecoration: "underline" } }}>
                Política de privacidad
              </a>
            </div>
          </Col>



        </Row>
      </div>
    </div>
  );
}

export default Footer;
