import { getData, postdData } from "../../../Context/backend";

export const guardarConfNotificaciones = async (
  Values,
  Errores,
  setErrores
) => {
  let error = false;

  if (
    !Values.dias_vencimiento_plan ||
    Values.dias_vencimiento_plan == "" ||
    Values.dias_vencimiento_plan <= 0
  ) {
    Errores.dias_vencimiento_plan = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.dias_vencimiento_certificado ||
    Values.dias_vencimiento_certificado == "" ||
    Values.dias_vencimiento_certificado <= 0
  ) {
    Errores.dias_vencimiento_certificado = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.existencia_folios ||
    Values.existencia_folios == "" ||
    Values.existencia_folios <= 0
  ) {
    Errores.existencia_folios = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const url = "configGenerales/editarNotificaciones";
    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const guardarConfConekta = async (Values, Errores, setErrores) => {
  let error = false;

  if (!error) {
    let data = [];
    const url = "configGenerales/editarConekta";
    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getInfConfNotificacion = async () => {
  let data = [];
  const url = `configGenerales/consultar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      let Value = {
        dias_vencimiento_plan: data.find(
          (config) => config.KEY === "DIAS_VENCIMIENTO_PLAN"
        )?.VAL,
        dias_vencimiento_certificado: data.find(
          (config) => config.KEY === "DIAS_VENCIMIENTO_CERTIFICADO"
        )?.VAL,
        existencia_folios: data.find(
          (config) => config.KEY === "EXISTENCIA_FOLIOS"
        )?.VAL,
        comision_conekta: data.find(
          (config) => config.KEY === "COMISION_CONEKTA"
        )?.VAL,
        comision_fija: data.find((config) => config.KEY === "COMISION_FIJA")
          ?.VAL,
        IVA: data.find((config) => config.KEY === "IVA")?.VAL,
        url_banner_factura: data.find(
          (config) => config.KEY === "URL_BANNER_FACTURA"
        )?.VAL,
        url_banner_mailing: data.find(
          (config) => config.KEY === "URL_BANNER_MAILING"
        )?.VAL,
        link_banner_factura: data.find(
          (config) => config.KEY === "LINK_BANNER_FACTURA"
        )?.VAL,
        link_banner_mailing: data.find(
          (config) => config.KEY === "LINK_BANNER_MAILING"
        )?.VAL,
      };
      resolve(Value);
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardarConfURL = async (Values, Errores, setErrores, tipo) => {
  let error = false;
  console.log(Values);
  if (!error) {
    let data = [];
    let body;
    const url = "configGenerales/editarURLs";
    if (tipo === "LINK_BANNER_FACTURA") {
      body = {
        link: Values.link_banner_factura,
        key_config: tipo,
      };
    } else {
      body = {
        link: Values.link_banner_mailing,
        key_config: tipo,
      };
    }
    console.log(body);
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
