
import { PALETA } from '../../../../Lib/Funciones Generales/Colores';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Ayuda = ({ data, handleChange }) => {

    const styleMostrar = data.mostrar ? {} : { height: 30, overflowY: 'hidden' }
    return (

        <Card elevation={3} className="mb-3 shadow">
            <CardContent className="d-flex flex-wrap p-3">
                <div style={{ width: 'calc(100% - 60px)' }}>
                    <Typography className='font-AvenirBold mb-2' style={{ fontSize: 20 }}>
                        {data.NOMBRE}
                    </Typography>
                    <div
                        className={'item-soporte'}
                        style={styleMostrar}
                        dangerouslySetInnerHTML={{
                            __html: data.DESCRIPCION
                        }} />
                </div>
                <div
                    onClick={handleChange}
                    className='d-flex '
                    style={{ width: 60, cursor: 'pointer' }}>
                    <div className='mx-auto pt-1'>
                        {
                            !data.mostrar
                                ? <AddIcon style={{ color: PALETA.colorPrimary, fontSize: 32 }} />
                                : <RemoveIcon style={{ color: PALETA.colorDivision, fontSize: 32 }} />
                        }
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default Ayuda
