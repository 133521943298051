import FacebookIcon from "@mui/icons-material/Facebook";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Button } from "@mui/material";
import { PALETA } from "../../../Lib/Funciones Generales/Colores";

export default function FacebookButton({ 
  className="", 
  title="Iniciar sesión con Facebook",
  handleRsCredencials = () => { } 
}) {
  const responseFacebook = (response) => {
    //console.log(response); // Aquí puedes procesar la respuesta de Facebook y enviar los datos a la API de tu aplicación
    const datos = {
      nombre: response.name,
      correo: response.email,
      img: response.picture.data.url,
      idRS: response.id,
      tipo: "Facebook",
    };
    handleRsCredencials(datos);
  };
  function handleLoginFailure(error) {
    console.log("Error en el inicio de sesión:", error);
  }
  return (
    <FacebookLogin
      //appId="Tu ID de aplicación de Facebook"
      appId="1932795207088659"
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      onFailure={handleLoginFailure}
      // icon={<FacebookIcon style={{ marginRight: "3px", color: "white" }} />}
      language="es"
      render={renderProps => (
        // <button onClick={renderProps.onClick}>This is my custom FB button</button>
        <Button
          onClick={renderProps.onClick}
          className={"w-100 btn btn-cancelar font-AvenirMedium py-2 px-4" + ' ' + className}
          variant="outlined"
        >
          <FacebookIcon style={{ marginRight: "5px", color: PALETA.colorSecondary }} /> 
          {title}
        </Button>
      )}
    />
  );
}
