import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    cat: 0,
  };

  const url = `productos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaProd = async (query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    query: query,
  };

  const url = `productos/search?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
export const getInfoProducto = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `productos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este producto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Values = {
          id: String(data.ID),
          codigo: String(data.CODIGO),
          descripcion: String(data.DESCRIPCION),
          precio: String(data.PRECIO),
          unidad_de_medida: String(data.UNIDAD_DE_MEDIDA),
          clave_codigo_prod_sat: String(data.CLAVE_CODIGO_PROD_SAT),
          clave_unidad_medida_sat: String(data.CLAVE_UNIDAD_MEDIDA_SAT),
          iva: String(data.IVA),
          descuento: String(data.DESCUENTO),
          costo: String(data.COSTO),
          retencion_iva: String(data.RETENCION_IVA),
          retencion_isr: String(data.RETENCION_ISR),
          retencion_ieps: String(data.RETENCION_IEPS),
          ieps: String(data.IEPS),
          objeto_impuesto: String(data.OBJETO_IMPUESTO),
          is_requiere_impuestos: data.IS_REQUIERE_IMPUESTOS,
          is_requiere_retenciones: data.IS_REQUIERE_RETENCIONES,
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;
  // let error = false;
  // console.log(Values);

  // Values.iva = String(Values.iva)
  // Values.precio_de_venta = String(Values.precio_de_venta)

  if (!Values.descripcion || Values.descripcion == "") {
    Errores.descripcion = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.codigo || Values.codigo === "") {
    Errores.codigo = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.precio || Values.precio == "" || Values.precio == "0") {
    Errores.precio = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.precio != 0) {
    if (Values.precio < 0) {
      Errores.precio = true;
      setErrores(Errores);
      error = true;

      return new Promise((resolve, reject) => {
        resolve({ mensaje: "El precio de venta no puede ser menor a 0" });
      });
    }
  }

  if (!Values.iva || Values.iva == "") {
    Errores.iva = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.is_requiere_retenciones) {
    if (Values.retencion_iva >= 100 && Values.retencion_iva != "") {
      Errores.retencion_iva = true;
      setErrores(Errores);
      error = true;
    }

    if (Values.retencion_isr >= 100 && Values.retencion_isr != "") {
      Errores.retencion_isr = true;
      setErrores(Errores);
      error = true;
    }

    if (Values.retencion_ieps >= 100 && Values.retencion_ieps != "") {
      Errores.retencion_ieps = true;
      setErrores(Errores);
      error = true;
    }
  } else {
    Values.retencion_iva = 0;
    Values.retencion_isr = 0;
    Values.retencion_ieps = 0;
  }


  // if (!Values.clave_unidad_medida_sat || Values.clave_unidad_medida_sat == "") {
  //   Errores.clave_unidad_medida_sat = true;
  //   setErrores(Errores);
  //   error = true;
  // }
  // if (!Values.clave_codigo_prod_sat || Values.clave_codigo_prod_sat == "") {
  //   Errores.clave_codigo_prod_sat = true;
  //   setErrores(Errores);
  //   error = true;
  // } 



  Values.id_categoria = String(Values.id_categoria);
  Values.horas = String(Values.horas);
  Values.minutos = String(Values.minutos);
  Values.clave_codigo_prod_sat = String(Values.clave_codigo_prod_sat);
  Values.clave_unidad_medida_sat = String(Values.clave_unidad_medida_sat);
  Values.descuento = String(Values.descuento);
  Values.costo =  Values.costo;
  Values.retencion_iva = String(Values.retencion_iva);
  Values.retencion_isr = String(Values.retencion_isr);
  Values.retencion_ieps = String(Values.retencion_ieps);

  if (!error) {
    let data = [];
    const body = Values;
    //console.log(body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "productos/Insertar"
        : "productos/Editar";
    //return;
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `productos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `productos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_COLABORADOR: ID,
  };
  const url = `productos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getUnidadesMedidasInternas = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarUnidadMedidaInterna?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};