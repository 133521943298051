import { Checkbox, FormControlLabel /*, Typography*/ } from '@mui/material'
import { Col, Row } from 'react-bootstrap'

import TableDestinatarios from '../TablaDestinatarios';
// import Switch from '../../../../../Includes/Switch';
import BuscadorUsuario from '../../../Clientes/Componentes/Buscadores/BuscadorUsuario';
import { getOpcionesClientes as getLista } from "../../../Clientes/Funciones/Clientes";
import { useEffect, useState } from 'react';
import { ESTILOS } from '../../../../../Lib/Funciones Generales/Colores';

const TabDestinatarios = ({ isInit, Values, Errores, setValues, handleSelected }) => {

    const [isLoading, setIsLoading] = useState(false)

    // const resetListaClientes = () => {
    //     setValues({ ...Values, destinatarios: [] })
    // }

    const getTodosClientes = () => {
        if (Values.consultar_clientes) {
            setIsLoading(true)
            getLista(100000, 0, 0, "", "").then((resp) => {
                let list = JSON.parse(resp.data);                
                setValues({ ...Values, destinatarios: list })
                setIsLoading(false)
            }).catch((resp) => {
                setValues({ ...Values, destinatarios: [] })
                setIsLoading(false)
            });
        }
    }

    useEffect(() => {
        if (Values.is_todos_usuarios) {
            getTodosClientes()
        }
    }, [Values.is_todos_usuarios])

    useEffect(() => {
        if (!Values.consultar_clientes) {
            setValues({ ...Values, consultar_clientes: true })
        }
    }, [])

    return (
        <Row className="">
            <div className="w-100 d-flex justify-content-between mt-3 mb-3">
                <div>
                    <h6 className="font-AvenirMedium m-0 p-0">
                        Lista de destinatarios
                    </h6>
                    <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
                        Busca o agrega las personas a recibir el mensaje
                    </h6>
                </div>
            </div>

            <Col sm={12} className='d-flex justify-content-between align-items-center mt-2 mb-4'>
                <BuscadorUsuario
                    disabled={isLoading}
                    handleSeleccionar={(usuario) => {
                        setValues({ ...Values, destinatarios: [usuario, ...Values.destinatarios] })
                    }}
                    ListaSeleccionados={Values.destinatarios}
                />
                <div className='d-flex align-items-center justify-content-end w-100'>
                    {/* <Switch
                        disabled={isLoading}
                        checked={Values.is_todos_usuarios}
                        onChange={(cheked) => {
                            setValues({ ...Values, is_todos_usuarios: cheked })
                        }}
                        tituloActivo="Buscar usuario"
                        tituloInactivo="Incluir todos los clientes"
                    />
                    <Typography variant="body2" className='font-Avenir' color="text.secondary">
                        Todos los clientes
                    </Typography> */}
                    <FormControlLabel
                        label="Todos los clientes"
                        control={
                            <Checkbox
                                sx={ESTILOS.checkbox}
                                name='is_todos_usuarios'
                                checked={Values.is_todos_usuarios}
                                onChange={({ target }) => {
                                    setValues({
                                        ...Values,
                                        [target.name]: target.checked,
                                        destinatarios: target.checked ? Values.destinatarios : [],
                                        destinatarios_eliminados: target.checked ? Values.destinatarios_eliminados : []
                                    })
                                }}
                            />
                        }
                    />
                </div>
            </Col>


            <TableDestinatarios
                IsLoading={isLoading}
                Lista={Values.destinatarios}
                setLista={(destinatarios, eliminado) => {
                    setValues({
                        ...Values,
                        destinatarios: destinatarios,
                        destinatarios_eliminados: [...Values.destinatarios_eliminados, eliminado]
                    })
                }}
                setRowSeleccionada={(id) => { }}
            />

        </Row>
    )
}

export default TabDestinatarios