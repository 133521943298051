import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  guardarInfoFacturacion,
  getInfFacturacion,
  guardarNegCertificados,
} from "../Funciones/Facturacion";
import RegimenFiscalSat from "../../../../SAT/Componentes/RegimenFiscalSat";
import "../../../../Lib/Funciones Generales/Prototipos";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import withReactContent from "sweetalert2-react-content";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import CargarArchivo from "./CargarArchivo";
import CargarCertificado from "../../../../assets/CargarCertificado.svg";
import CargarKey from "../../../../assets/CargarKey.svg";

const MySwal = withReactContent(Swal);

const Correo = () => {
  const id = 1;
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [IsGuardandoCertificados, setIsGuardandoCertificados] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const [Values, setValues] = useState({
    id: id ?? "0",
    rfc: "",
    razon_social: "",
    regimen_fiscal: "",
    cp: "",
    archivoKey: { loading: false, archivo: "" },
    archivoCer: { loading: false, archivo: "" },
    cer_pass: "",
    cer_vigencia: "",
    cargar_certificados: true,
    is_carga_sertificados: false,
  });
  const [Errores, setErrores] = useState({
    rfc: false,
    razon_social: false,
    regimen_fiscal: false,
    cp: false,
  });

  // Info de certificados
  const [certificado, setCertificado] = useState({
    nombre: Values.archivoCer.nombre,
    archivo: Values.archivoCer.archivo,
  });

  const [key, setKey] = useState({
    nombre: Values.archivoKey.nombre,
    archivo: Values.archivoKey.archivo,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;
    console.log(Name);
    if (Name == "cp") {
      Value = Value.toNumber();
    }

    if (Name == "cer_pass") {
      setValues({
        ...setValues,
        [Name]: Value,
      });
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const GuardarDatosCertificados = () => {
    setIsGuardandoCertificados(true);
    guardarNegCertificados(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardandoCertificados(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoCertificados(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoCertificados(false);
      });
  };

  const handleFile = (result, tipo) => {
    if (result.loading) {
      const base64String = result.archivo
        .replace("data:", "")
        .replace(/^.+,/, "");
      if (tipo == "cer") {
        setCertificado({ nombre: result.nombre, archivo: base64String });
      } else if (tipo == "key") {
        setKey({ nombre: result.nombre, archivo: base64String });
      }
    }
  };

  useEffect(() => {
    if (certificado.nombre != "" && certificado.archivo != "ok") {
      setValues({
        ...Values,
        archivoCer: {
          loading: true,
          archivo: certificado.archivo,
          nombre: certificado.nombre,
          nuevo: true,
        },
      });
    }
  }, [certificado]);

  useEffect(() => {
    if (key.nombre != "" && key.archivo != "ok") {
      setValues({
        ...Values,
        archivoKey: {
          loading: true,
          archivo: key.archivo,
          nombre: key.nombre,
          nuevo: true,
        },
      });
    }
  }, [key]);

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfFacturacion(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp.Value);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardadatosFactura = () => {
    setIsGuardando(true);
    guardarInfoFacturacion(Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            // navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  useEffect(() => {
    document.title = `Facturación`;
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container pb-5">
        <h4 className="font-AvenirBold mt-4 mb-4 pt-2">Facturación</h4>

        <Card elevation={3}>
          <CardContent className="p-0">
            <Row className="p-3">
              <Col sm={12} md={4}>
                <h6 className="text-left font-AvenirBold">
                  Información Fiscal del Emisor
                </h6>
              </Col>
              <Col sm={12} md={8} className="mt-3">
                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <Row className="p-3">
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="rfc"
                        variant="outlined"
                        value={Values.rfc}
                        error={Errores.rfc}
                        helperText={Errores.descripcion ? "RFC no valido" : ""}
                        label="RFC"
                        onChange={handlInputChange}
                      />
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="razon_social"
                        label="Razón social"
                        variant="outlined"
                        helperText={
                          Errores.razon_social ? "Razón social invalida" : ""
                        }
                        value={Values.razon_social}
                        error={Errores.razon_social}
                        onChange={handlInputChange}
                        inputProps={{ maxLength: 250 }}
                      />
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <RegimenFiscalSat
                        Value={Values.regimen_fiscal}
                        Error={Errores.regimen_fiscal}
                        handlInputChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="cp"
                        inputProps={{ maxLength: 5 }}
                        label="Código postal"
                        variant="outlined"
                        helperText={Errores.cp ? "Código postal invalido" : ""}
                        value={Values.cp}
                        error={Errores.cp}
                        onChange={handlInputChange}
                      />
                    </Col>

                    <Col sm={12} className="p-3">
                      <div className=" ">
                        <h6 className="mb-0 text-left font-AvenirMedium  ">
                          Nota: Verifica los datos en tu Constancia de Situación
                          Fiscal
                        </h6>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col xs={12} className="text-end">
                <Stack
                  className="p-3 d-flex justify-content-end w-100"
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                  style={{}}
                >
                  <LoadingButton
                    loading={IsGuardando}
                    loadingPosition="start"
                    disabled={IsLoading}
                    onClick={() => GuardadatosFactura()}
                    className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                    variant="contained"
                    xs={{ with: "100$" }}
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                    </span>
                  </LoadingButton>
                </Stack>
              </Col>
            </Row>
          </CardContent>
        </Card>
        <Card elevation={3} className="mt-5">
          <CardContent className="p-0">
            <Row className="p-3">
              <Col sm={12} md={4}>
                <h6 className="text-left font-AvenirBold">
                  Carga de certificados
                </h6>
              </Col>
              <Col sm={12} md={8} className="mt-3">
                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <Row className="p-3 pt-0">
                    {Values.is_carga_sertificados && (
                      <div className="row mx-0 px-0">
                        <Col sm={12} className="p-3">
                          <div className="m-0 text-truncate w-100 overflow-hidden">
                            <Alert severity="success" sx={{ width: "100%" }}>
                              Certificados cargados correctamente
                            </Alert>
                          </div>
                        </Col>
                        {!Values.cargar_certificados && (
                          <Col
                            sm={12}
                            className="d-flex justify-content-between p-0"
                          >
                            <div className="d-flex flex-wrap ps-3">
                              <p
                                className="m-0 font-AvenirBold"
                                style={{ color: PALETA.colorPrimary }}
                              >
                                Vigencia:
                              </p>
                              <p className="m-0 ms-1 font-AvenirMedium">
                                {Values.cer_vigencia}
                              </p>
                            </div>
                            <LoadingButton
                              loading={false}
                              loadingPosition="start"
                              disabled={IsLoading}
                              onClick={() =>
                                setValues({
                                  ...Values,
                                  cargar_certificados: true,
                                })
                              }
                              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                              variant="contained"
                              xs={{ with: "100$" }}
                            >
                              <span className={IsGuardando ? "px-4" : "px-2"}>
                                Cargar
                              </span>
                            </LoadingButton>
                          </Col>
                        )}
                      </div>
                    )}
                    {Values.cargar_certificados && (
                      <>
                        <Col sm={12} className="p-3 pt-0">
                          <CargarArchivo
                            archivo={Values.archivoCer}
                            handleChangeFile={(result) => {
                              handleFile(result, "cer");
                            }}
                            archivosAceptados={{ "files/cer": [".cer"] }}
                            archivoDefault={CargarCertificado}
                          >
                            <strong> Cargar certificado </strong>
                            <p className="text-mutted mb-0">
                              Arrastra el archivo del certificado vigente o
                              <strong style={{ color: "#3ABE88" }}>
                                {" "}
                                selecciona
                              </strong>{" "}
                              desde tu ordenador
                            </p>
                          </CargarArchivo>
                        </Col>

                        {Values.archivoCer.nombre != "" &&
                          Values.archivoCer.nombre && (
                            <Col sm={12} className="p-3 pt-0">
                              <div className="m-0 text-truncate w-100 overflow-hidden">
                                <Alert
                                  severity="success"
                                  sx={{ width: "100%" }}
                                >
                                  {Values.archivoCer.nombre}
                                </Alert>
                              </div>
                            </Col>
                          )}

                        <Col sm={12} className="p-3 pt-0">
                          <CargarArchivo
                            archivo={Values.archivoKey}
                            handleChangeFile={(result) => {
                              handleFile(result, "key");
                            }}
                            archivosAceptados={{ "files/key": [".key"] }}
                            archivoDefault={CargarKey}
                          >
                            <strong> Cargar archivo key </strong>
                            <p className="text-mutted mb-0">
                              Arrastra el archivo key o
                              <strong style={{ color: "#3ABE88" }}>
                                {" "}
                                selecciona
                              </strong>{" "}
                              desde tu ordenador
                            </p>
                          </CargarArchivo>
                        </Col>

                        {Values.archivoKey.nombre != "" &&
                          Values.archivoKey.nombre && (
                            <Col sm={12} className="p-3 pt-0">
                              <div className="m-0 text-truncate w-100 overflow-hidden">
                                <Alert
                                  severity="success"
                                  sx={{ width: "100%" }}
                                >
                                  {Values.archivoKey.nombre}
                                </Alert>
                              </div>
                            </Col>
                          )}

                        <Col sm={12} className="p-3 pt-0">
                          <h6
                            className="mb-0 text-left font-AvenirBold"
                            style={{ color: PALETA.colorTexto }}
                          >
                            Ingresa la contraseña del certificado y da clic en
                            guardar
                          </h6>
                        </Col>

                        <Col sm={12} className="p-3">
                          <TextField
                            fullWidth
                            type="password"
                            name="cer_pass"
                            label="Contraseña"
                            variant="outlined"
                            helperText={
                              Errores.cer_pass ? "Contraseña invalido" : ""
                            }
                            value={Values.cer_pass}
                            error={Errores.cer_pass}
                            onChange={handlInputChange}
                            inputProps={{
                              maxLength: 150,
                            }}
                          />
                        </Col>

                        <Col
                          sm={12}
                          className="d-flex justify-content-end mt-3"
                        >
                          <LoadingButton
                            loading={IsGuardandoCertificados}
                            loadingPosition="start"
                            disabled={IsLoading}
                            onClick={GuardarDatosCertificados}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                            variant="contained"
                            xs={{ with: "100$" }}
                          >
                            <span
                              className={
                                IsGuardandoCertificados ? "px-4" : "px-2"
                              }
                            >
                              {IsGuardandoCertificados
                                ? "Guardando..."
                                : "Guardar"}
                            </span>
                          </LoadingButton>
                          {Values.is_carga_sertificados && (
                            <Button
                              onClick={() =>
                                setValues({
                                  ...Values,
                                  cargar_certificados: false,
                                })
                              }
                              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                              variant="outlined"
                            >
                              Cancelar
                            </Button>
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                )}
              </Col>
            </Row>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Correo;
