import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TextField, Avatar, Alert, Card, CardContent, Button, Snackbar, Backdrop, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { PALETA } from '../../../../Lib/Funciones Generales/Colores'
import { guardarNegCertificados, guardarNegContacto, guardarNegFactura, guardarNegPrincipal } from "../../Funciones/Perfiles";


import CargarArchivo from "../CargarArchivo";
import CargarCertificado from '../../../../assets/CargarCertificado.svg'
import CargarKey from '../../../../assets/CargarKey.svg'
import RegimenFiscalSat from "../../../../SAT/Componentes/RegimenFiscalSat";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '../../../../Lib/Funciones Generales/Prototipos'
import SelectEstado from "../../../../Direcciones/Componentes/SelectEstado";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);


const TabMiNegocio = ({
  img_url = "",
  handleOpenModal,
  handlInputChange,
  Values,
  setValues,
  Errores,
  setErrores,
  IsLoading,
  IsGuardando,
  ConsultarPerfil
}) => {

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [IsGuardandoPrincipal, setIsGuardandoPrincipal] = useState(false)
  const [IsGuardandoFactura, setIsGuardandoFactura] = useState(false)
  const [IsGuardandoContacto, setIsGuardandoContacto] = useState(false)
  const [IsGuardandoCertificados, setIsGuardandoCertificados] = useState(false)
  const [certificado, setCertificado] = useState({ 
    nombre: Values.archivoCer.nombre, 
    archivo: Values.archivoCer.archivo 
  });
  const [key, setKey] = useState({ 
    nombre: Values.archivoKey.nombre, 
    archivo: Values.archivoKey.archivo 
  });


  const mostrarAlerta = (data, fnCallback) => {
    console.log(data)
    if (data.codigo == "200") {
      MySwal.fire({
        title: "Correcto",
        html: data.mensaje,
        icon: "success",
        confirmButtoColor: "#3ABE88",
        showConfirmButton: false,
        timer: 1700,
      }).then((result) => {
        if (fnCallback) {
          fnCallback(data)
        }
      });
    } else {
      setMensaje(data.mensaje);
      setOpen(true);
    }
  }

  const GuardarDatosPrincipal = () => {
    setIsGuardandoPrincipal(true)
    guardarNegPrincipal(Values, Errores, setErrores)
      .then((data) => {
        mostrarAlerta(data, (result) => {
          if (result.respuesta.img_negocio != "") {
            setValues({ ...Values, img: result.respuesta.img_negocio })
          }
        })
        setIsGuardandoPrincipal(false)
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoPrincipal(false)
      });
  };


  const GuardarDatosFactura = () => {
    setIsGuardandoFactura(true)
    guardarNegFactura(Values, Errores, setErrores)
      .then((data) => {
        mostrarAlerta(data)
        setIsGuardandoFactura(false)
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoFactura(false)
      });
  };

  const GuardarDatosContacto = () => {
    setIsGuardandoContacto(true)
    guardarNegContacto(Values, Errores, setErrores)
      .then((data) => {
        mostrarAlerta(data)
        setIsGuardandoContacto(false)
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoContacto(false)
      });
  };

  const GuardarDatosCertificados = () => {
    setIsGuardandoCertificados(true)
    guardarNegCertificados(Values, Errores, setErrores)
      .then((data) => {
        mostrarAlerta(data, () => ConsultarPerfil())
        setIsGuardandoCertificados(false)
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoCertificados(false)
      });
  };



  const handleFile = (result, tipo) => {
    if (result.loading) {
      const base64String = result.archivo
        .replace("data:", "")
        .replace(/^.+,/, "");
      if (tipo == "cer") {
        setCertificado({ nombre: result.nombre, archivo: base64String });
      } else if (tipo == "key") {
        setKey({ nombre: result.nombre, archivo: base64String });
      }
    }
  }

  useEffect(() => {
    if (certificado.nombre != "" && certificado.archivo != "ok") {
      setValues({
        ...Values, archivoCer: {
          loading: true,
          archivo: certificado.archivo,
          nombre: certificado.nombre,
          nuevo: true
        }
      });
    }
  }, [certificado])

  useEffect(() => {
    if (key.nombre != "" && key.archivo != "ok") {
      setValues({
        ...Values, archivoKey: {
          loading: true,
          archivo: key.archivo,
          nombre: key.nombre,
          nuevo: true
        }
      });
    }
  }, [key])



  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setOpen(false)} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      {
        (IsGuardandoCertificados || IsGuardandoContacto || IsGuardandoFactura || IsGuardandoPrincipal) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }


      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Información del negocio</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">
              <Col
                sm={12}
                className="d-flex flex-wrap align-items-center px-3"
              >
                <Avatar
                  sx={{ width: 80, height: 80 }}
                  src={img_url}
                />
                <p
                  className="text-center my-0 ms-3 font-AvenirBold"
                  style={{ color: PALETA.colorPrimary, cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar
                </p>
              </Col>
              <Col sm={12} className="p-3 mt-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre del negocio"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 100 }}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo del negocio"
                  variant="outlined"
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                  inputProps={{
                    maxLength: 150,
                  }}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono del negocio"
                  variant="outlined"
                  value={(Values.telefono === 0 || Values.telefono === "0") ? '' : Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 10 }}
                />
              </Col>
            </Col>

            <Col sm={12} className="d-flex justify-content-end  mt-3">
              <LoadingButton
                loading={IsGuardandoPrincipal}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={GuardarDatosPrincipal}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoPrincipal ? "px-4" : "px-2"}>
                  {IsGuardandoPrincipal ? "Guardando..." : "Guardar"}
                </span>
              </LoadingButton>
            </Col>

          </Row>



        </CardContent>
      </Card>

      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Información Fiscal del Emisor</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">
              <div className="row mx-0 px-0">
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="rfc"
                    label="RFC"
                    variant="outlined"
                    helperText={Errores.rfc ? "RFC invalido" : ""}
                    value={Values.rfc}
                    error={Errores.rfc}
                    onChange={handlInputChange}
                    inputProps={{ maxLength: 13 }}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="razon_social"
                    label="Razón social"
                    variant="outlined"
                    helperText={Errores.razon_social ? "Razón social invalida" : ""}
                    value={Values.razon_social}
                    error={Errores.razon_social}
                    onChange={handlInputChange}
                    inputProps={{ maxLength: 250 }}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <RegimenFiscalSat
                    Value={Values.regimen_fiscal}
                    Error={Errores.regimen_fiscal}
                    handlInputChange={handlInputChange}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="cp"
                    inputProps={{ maxLength: 5 }}
                    label="Código postal"
                    variant="outlined"
                    helperText={Errores.cp ? "Código postal invalido" : ""}
                    value={Values.cp}
                    error={Errores.cp}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <SelectEstado
                    Value={Values.id_estado}
                    Error={Errores.id_estado}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} className="d-flex p-3 pt-4">
                  <p className="m-0 font-AvenirBold">Nota:</p>
                  <p className="m-0 font-font-AvenirMedium ms-1">Verifica los datos en tu Constancia de Situación Fiscal</p>
                </Col>
              </div>
            </Col>


            <Col sm={12} className="d-flex justify-content-end  mt-3">
              <LoadingButton
                loading={IsGuardandoFactura}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={GuardarDatosFactura}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoFactura ? "px-4" : "px-2"}>
                  {IsGuardandoFactura ? "Guardando..." : "Guardar"}
                </span>
              </LoadingButton>
            </Col>
          </Row>
        </CardContent>
      </Card>


      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Información de contacto del negocio</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">
              <div className="row mx-0 px-0">
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="nombre_contacto"
                    label="Nombre"
                    variant="outlined"
                    value={Values.nombre_contacto}
                    error={Errores.nombre_contacto}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Col>
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="apellido_paterno_contacto"
                    label="Apellido Paterno"
                    variant="outlined"
                    value={Values.apellido_paterno_contacto}
                    error={Errores.apellido_paterno_contacto}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Col>
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="apellido_materno_contacto"
                    label="Apellido Materno"
                    variant="outlined"
                    value={Values.apellido_materno_contacto}
                    error={Errores.apellido_materno_contacto}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="telefono_contacto"
                    label="Teléfono"
                    variant="outlined"
                    value={(Values.telefono_contacto === 0 || Values.telefono_contacto === "0") ? '' : Values.telefono_contacto}
                    error={Errores.telefono_contacto}
                    onChange={handlInputChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </Col>
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="correo_facturacion"
                    label="Correo electrónico de facturación"
                    variant="outlined"
                    value={Values.correo_facturacion}
                    error={Errores.correo_facturacion}
                    onChange={handlInputChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Col>
              </div>
            </Col>
            <Col sm={12} className="d-flex justify-content-end  mt-3">
              <LoadingButton
                loading={IsGuardandoContacto}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={GuardarDatosContacto}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardandoContacto ? "px-4" : "px-2"}>
                  {IsGuardandoContacto ? "Guardando..." : "Guardar"}
                </span>
              </LoadingButton>
            </Col>
          </Row>
        </CardContent>
      </Card>

      <Card elevation={3} className="mb-4 mt-4 shadow">
        <CardContent className="p-0 py-3">
          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">Carga de certificados</h6>
            </Col>
            <Col sm={12} md={8} className="mt-3">

              {
                Values.is_carga_sertificados && (
                  <div className="row mx-0 px-0">
                    <Col sm={12} className="p-3">
                      <div className="m-0 text-truncate w-100 overflow-hidden">
                        <Alert severity="success" sx={{ width: "100%" }} >
                          Certificados cargados correctamente
                        </Alert>
                      </div>
                    </Col>
                    {
                      !Values.cargar_certificados && (
                        <Col sm={12} className="d-flex justify-content-between p-0">
                          <div className="d-flex flex-wrap ps-3">
                            <p className="m-0 font-AvenirBold" style={{ color: PALETA.colorPrimary }}>
                              Vigencia:
                            </p>
                            <p className="m-0 ms-1 font-AvenirMedium">{Values.cer_vigencia}</p>
                          </div>
                          <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            disabled={IsLoading}
                            onClick={() => setValues({ ...Values, cargar_certificados: true })}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                            variant="contained"
                            xs={{ with: "100$" }}
                          >
                            <span className={IsGuardando ? "px-4" : "px-2"}>
                              Cargar
                            </span>
                          </LoadingButton>
                        </Col>
                      )
                    }

                  </div>
                )
              }

              {
                Values.cargar_certificados && (
                  <>
                    <Col sm={12} className="p-3 pt-0">

                      <CargarArchivo
                        archivo={Values.archivoCer}
                        handleChangeFile={(result) => {
                          handleFile(result, 'cer')
                        }}
                        archivosAceptados={{ "files/cer": ['.cer'] }}
                        archivoDefault={CargarCertificado} >
                        <strong> Cargar certificado </strong>
                        <p className="text-mutted mb-0">
                          Arrastra el archivo del certificado vigente o
                          <strong style={{ color: "#3ABE88" }}> selecciona</strong> desde tu ordenador
                        </p>
                      </CargarArchivo>

                    </Col>

                    {
                      (Values.archivoCer.nombre != "" && Values.archivoCer.nombre) && (
                        <Col sm={12} className="p-3 pt-0">
                          <div className="m-0 text-truncate w-100 overflow-hidden">
                            <Alert severity="success" sx={{ width: "100%" }} >
                              {Values.archivoCer.nombre}
                            </Alert>
                          </div>
                        </Col>
                      )
                    }

                    <Col sm={12} className="p-3 pt-0">
                      <CargarArchivo
                        archivo={Values.archivoKey}
                        handleChangeFile={(result) => {
                          handleFile(result, 'key')
                        }}
                        archivosAceptados={{ "files/key": ['.key'] }}
                        archivoDefault={CargarKey} >
                        <strong> Cargar archivo key </strong>
                        <p className="text-mutted mb-0">
                          Arrastra el archivo key o
                          <strong style={{ color: "#3ABE88" }}> selecciona</strong> desde tu ordenador
                        </p>
                      </CargarArchivo>
                    </Col>

                    {
                      (Values.archivoKey.nombre != "" && Values.archivoKey.nombre) && (
                        <Col sm={12} className="p-3 pt-0">
                          <div className="m-0 text-truncate w-100 overflow-hidden">
                            <Alert severity="success" sx={{ width: "100%" }} >
                              {Values.archivoKey.nombre}
                            </Alert>
                          </div>
                        </Col>
                      )
                    }

                    <Col sm={12} className="p-3 pt-0">
                      <h6 className="mb-0 text-left font-AvenirBold" style={{ color: PALETA.colorTexto }}>
                        Ingresa la contraseña del certificado y da clic en guardar
                      </h6>
                    </Col>

                    <Col sm={12} className="p-3">
                      <TextField
                        fullWidth
                        type="password"
                        name="cer_pass"
                        label="Contraseña"
                        variant="outlined"
                        helperText={Errores.cer_pass ? "Contraseña invalido" : ""}
                        value={Values.cer_pass}
                        error={Errores.cer_pass}
                        onChange={handlInputChange}
                        inputProps={{
                          maxLength: 150,
                        }}
                      />
                    </Col>

                    <Col sm={12} className="d-flex justify-content-end  mt-3">
                      <LoadingButton
                        loading={IsGuardandoCertificados}
                        loadingPosition="start"
                        disabled={IsLoading}
                        onClick={GuardarDatosCertificados}
                        className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 me-3"
                        variant="contained"
                        xs={{ with: "100$" }}
                      >
                        <span className={IsGuardandoCertificados ? "px-4" : "px-2"}>
                          {IsGuardandoCertificados ? "Guardando..." : "Guardar"}
                        </span>
                      </LoadingButton>
                      {
                        Values.is_carga_sertificados && (
                          <Button
                            onClick={() => setValues({ ...Values, cargar_certificados: false })}
                            className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                            variant="outlined"
                          >
                            Cancelar
                          </Button>
                        )
                      }
                    </Col>
                  </>
                )
              }

            </Col>



          </Row>
        </CardContent>
      </Card >


    </>
  );
};

export default TabMiNegocio;
