
import { stringify } from "query-string";
import { getData, postdData } from "../../Context/backend";
import validator from "validator";

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardarSolicitud = async (Values, Errores, setErrores, EndPoint="") => {
    let error = false;

    if (!Values.nombre || Values.nombre == "") {
        Errores.nombre = true;
        setErrores(Errores);
        error = true;
    }

    if (!validator.isNumeric(Values.telefono)) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
    }
    if (Values.telefono.length != 10) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
    }

    if (!validator.isEmail(Values.correo)) {
        Errores.correo = true;
        setErrores(Errores);
        error = true;
    }

    if (!Values.asunto || Values.asunto == "") {
        Errores.asunto = true;
        setErrores(Errores);
        error = true;
    }

    if (!Values.descripcion || Values.descripcion == "") {
        Errores.descripcion = true;
        setErrores(Errores);
        error = true;
    }
    if (error) {
        return new Promise((resolve, reject) => {
            resolve({ mensaje: `Completa todos los campos requeridos` });
        });
    }


    let data = [];
    const body = { ...Values, telefono: String(Values.telefono) }

    // const url = "perfil/EnviarCorreoSoporte";
    const res = await postdData(EndPoint, body);


    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = res.data;
            resolve(data);
        } else {
            reject(res);
        }
    });

};


export const getOpcionesAyuda = async (query) => {
    let data = [];
    let queryParamsObj = {
        query: query,
    };
    const url = `perfil/ConsultarOpcionesAyuda?query=${query}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
        if (!res.error) {
            data = JSON.parse(res.data.respuesta.data);
            resolve({ data });
        } else {
            reject(res);
        }
    });
};
