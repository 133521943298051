import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PDFFactura from "../../Facturacion/Facturas/Componentes/PDF/PDFFactura";
import Error404 from "../../../Includes/Error404";
import PDFPago from "../../Facturacion/Facturas/Componentes/PDF/PDFPago";
import RepFactuasPDF from "../Componentes/RepFacturasPDF";
import RepFacturasExcel from "../Componentes/RepFacturasExcel";

const ReportesPDF = () => {
  return (
    <Routes>
      {" "}
      <Route path="repFacturas/" element={<RepFactuasPDF />}></Route>
      <Route path="repFacturasExcel/" element={<RepFacturasExcel />}></Route>
      <Route path="factura/:uuid" element={<PDFFactura />}></Route>
      <Route path="facturacompra/:uuid" element={<PDFFactura isManagger={true} isUsuario={true} />}></Route>
      <Route path="pago/:uuid" element={<PDFPago />}></Route>{" "}
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};
export default ReportesPDF;
