import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { realizarCompraPlantilla } from "../../Funciones/FacturacionDiseno";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CloseIcon from "@mui/icons-material/Close";
import FacturacionDiseno from "../Items/FacturacionDiseno";
import SelectTarjetas from "../../SelectTarjetas";


const MySwal = withReactContent(Swal);

const ModalFacturacionDisenoCompra = ({
    setShowDialogoCompra,
    plantillaSeleccionada,
    setPlantillaSeleccionada,
    imgDefault,
    Plantillas,
    setPlantillas
}) => {

    const [tarjeta, setTarjeta] = useState([]);
    const [IsGuardando, setIsGuardando] = useState(false);
    const [values, setValues] = useState({
        id_plantilla: plantillaSeleccionada.ID,
        tarjeta: 0,
    });

    const GuardarCompra = () => {

        MySwal.fire({
            title: "Confirmación",
            html: '¿Deseas comprar este diseño?',
            icon: "info",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
            timer: 15700,
        }).then((result) => {
            if (result.isConfirmed) {
                setIsGuardando(true);
                realizarCompraPlantilla(
                    values
                )
                    .then((data) => {
                        if (data.codigo == "200") {
                            setIsGuardando(false);
                            MySwal.fire({
                                title: "Correcto",
                                html: data.mensaje,
                                icon: "success",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: true,
                                timer: 15700,
                            }).then((result) => {
                                //window.location.reload(true);
                                setShowDialogoCompra(false)
                                const index = Plantillas.findIndex(x => x.ID == values.id_plantilla)
                                if (index != -1) {
                                    Plantillas[index] = { ...Plantillas[index], ID_COMPRA: 1 }
                                    setPlantillas([...Plantillas])
                                }
                            });
                        } else {
                            MySwal.fire({
                                title: "Error",
                                html: data.mensaje,
                                icon: "info",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: true,
                                timer: 15700,
                            }).then((result) => {
                            });
                            setIsGuardando(false);
                        }
                    })
                    .catch((data) => {
                        MySwal.fire({
                            title: "Error",
                            html: data.mensaje,
                            icon: "info",
                            confirmButtoColor: "#3ABE88",
                            showConfirmButton: true,
                            timer: 15700,
                        }).then((result) => {
                        });
                        setIsGuardando(false);
                    });
            }
        })

    };

    useEffect(() => {
        setValues(prevState => ({
            ...prevState,
            tarjeta: tarjeta.id
        }));
    }, [tarjeta]);


    const total = plantillaSeleccionada.PRECIO
    const subtotal = total / 1.16
    const iva = total - subtotal


    return (
        <Dialog fullWidth maxWidth="lg" open={true}>
            <IconButton
                aria-label="Close"
                className="m-2"
                style={{ position: "absolute", right: "0%" }}
                onClick={() => {
                    setShowDialogoCompra(false);
                    setPlantillaSeleccionada(null)
                }}
            >
                <CloseIcon style={{ width: "30px", height: "30px" }} />
            </IconButton>
            {
                <DialogTitle>
                    <h5 className="fw-bolder f-12 mt-2 w-100 text-center">
                        Adquirir {plantillaSeleccionada.NOMBRE}
                    </h5>
                </DialogTitle>
            }
            <DialogContent style={{ minHeight: "70vh" }}>
                <Row className="my-4">
                    <Col sm={12} md={6} lg={4} className="pt-4">
                        <FacturacionDiseno
                            key={'plantilla_compra_' + plantillaSeleccionada.ID}
                            data={plantillaSeleccionada}
                            idPlantillaActiva={-1}
                            isVisibleBotones={false}
                            imgDefault={imgDefault} />
                    </Col>
                    <Col sm={12} md={6} lg={8}>
                        <h6 className="font-AvenirBold text-center">
                            Elige una forma de pago
                        </h6>

                        <SelectTarjetas
                            paymentMethod={"TARJETA"}
                            tarjeta={tarjeta}
                            setTarjeta={setTarjeta}
                        />

                        <table className="w-100 mt-5">
                            <tr>
                                <td align="right">
                                    <h6 className="font-Avenir ">Subtotal</h6>
                                </td>
                                <td align="right">
                                    <h6 className="font-Avenir  ">
                                        {
                                            formatMoneda(subtotal)
                                        }
                                        MXN
                                    </h6>
                                </td>
                            </tr>

                            <tr>
                                <td align="right">
                                    <h6 className="font-Avenir ">I.V.A.</h6>
                                </td>
                                <td align="right">
                                    <h6 className="font-Avenir  ">
                                        {
                                            formatMoneda(iva)
                                        }
                                        MXN
                                    </h6>
                                </td>
                            </tr>

                            <tr>
                                <td align="right">
                                    <h6 className="font-AvenirBold ">TOTAL A PAGAR</h6>
                                </td>
                                <td align="right">
                                    <h6 className="font-AvenirBold  ">
                                        {
                                            formatMoneda(total)
                                        }
                                        MXN
                                    </h6>
                                </td>
                            </tr>
                        </table>


                        <LoadingButton
                            onClick={() => { GuardarCompra() }}
                            loading={IsGuardando}
                            disabled={IsGuardando}
                            loadingPosition="start"
                            className="btn btn-Crexendo font-AvenirMedium  w-100 py-2 px-4 mt-3"
                            variant="contained"
                            xs={{ with: "100$" }}
                        >
                            <span className={IsGuardando ? "px-4" : "px-2"}>
                                {IsGuardando ? "Realizando compra..." : "Pagar "}
                            </span>
                        </LoadingButton>
                    </Col>
                </Row>
            </DialogContent>
        </Dialog>
    )
}

export default ModalFacturacionDisenoCompra