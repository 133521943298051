import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { Col, Row, Stack } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import DatosCFDI from "./DatosCFDI";
import { useState } from "react";
import ModalSelectProd from "../../../../Catalogos/Productos/Componentes/ModalSelectProd";
import ModalSelectCliente from "../../../../Catalogos/Clientes/Componentes/ModalSeelctCliente";
import { useEffect } from "react";
import { getDatosEmisor } from "../../Funciones/Facturas";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "./../../../../../Includes/Loading";
import Conceptos from "./Conceptos";
import { LoadingButton } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext } from "react";
import { ContextInfoUser } from "../../../../Context/ContextInfoUser";
import ModalSelectDocumento from "../Dialogos/ModalSelectDocumento";
import DocumentosRel from "./DocumentosRel";
import dayjs from "dayjs";
import { getFacturaByUUID, timbrarCFDI } from "../../Funciones/Pagos";

const NuevoComplemento = () => {
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [pagado, setPagado] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [showModalDocuemntos, setShowModalDocuemntos] = useState(false);
  const [showModalCliente, setShowModalCliente] = useState(false);
  const [isVisibleInfoEmi, setIsVisibleInfoEmi] = useState(false);
  const { getInfoUsuario } = useContext(ContextInfoUser);
  const [documentos, setDocumentos] = useState([]);
  const { uuid } = useParams();

  const [emisor, setEmisor] = useState([]);
  const [datosReceptor, setDatosReceptor] = useState({
    id: 0,
    razon_social: "Selecciona un receptor",
    regimen_fiscal: "",
    RFC: "XAXX010101000",
    nombre: "",
    correo: "",
    CP: "",
  });
  const [Values, setValues] = useState({
    id_cliente: 0,
    fecha_emision: "0",
    tipo_comprobante: "P",
    serie: "",
    uso_cfdi: "CP01",
    observaciones: "",
    documentos_rel: [],
  });

  const [Errores, setErrores] = useState({
    id_cliente: false,
    fecha_emision: false,
    tipo_comprobante: false,
    serie: false,
    uso_cfdi: false,
    observaciones: false,
  });

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    let NewValue = Value;

    if (Name == "tipo_cambio") {
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(Value)) {
        NewValue = Values.tipo_cambio;
      }
    }
    setValues((Values) => ({
      ...Values,
      [Name]: NewValue,
    }));

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleProductoSeleccionado = (producto, cantidad = 1) => {
    const items = [...documentos];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === producto.ID)
      : -1;
    if (itemIndex !== -1) {
    } else {
      producto.fecha_pago = dayjs();
      producto.monto_pago = "";
      producto.moneda_pago = "MXN";
      producto.tipo_cambio = "";
      producto.forma_pago = "01";
      producto.observaciones = "";
      producto.hora_pago = dayjs().format("HH:mm");
      producto.impuesto = "0";
      producto.siInfodeposito = false;
      producto.cuenta_ordenante = "";
      producto.cuenta_beneficiario = "";
      producto.numero_operacion = "";
      producto.rfc_emisor = "";
      producto.rfc_receptor = "";
      producto.parcialidad = parseInt(producto.PAGOS + 1);
      items.push(producto);
    }
    setDocumentos(items);
  };

  const GenerarCFDI = () => {
    setIsGuardando(true);
    timbrarCFDI(Values, Errores, setErrores, documentos, datosReceptor)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          Swal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
            timer: 10000,
          }).then((result) => {
            getInfoUsuario();
            navigate(-1);
          });
        } else {
          Swal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            setIsGuardando(false);
          });
        }
      })
      .catch((data) => {
        console.log(data);
        Swal.fire({
          title: "Error",
          html: data.mensaje,
          icon: "warning",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          setIsGuardando(false);
        });
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getDatosEmisor()
      .then((data) => {
        if (data.emisor.length == 0) {
          Swal.fire({
            title: "Advertencia",
            html: "No se encontraron datos del emisor",
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            timer: 10000,
          }).then((result) => {
            return;
          });
        }
        setEmisor(data.emisor);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      })
      .catch((data) => {
        console.log(data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (uuid != undefined && uuid != "") {
      setIsLoading(true);
      getFacturaByUUID(uuid)
        .then((data) => {
          console.log(data);
          handleProductoSeleccionado(data.factura);
          setDatosReceptor({
            id: data.cliente.ID,
            razon_social: data.cliente.RAZON_SOCIAL,
            regimen_fiscal: data.cliente.REGIMEN_FISCAL,
            RFC: data.cliente.RFC,
            nombre: data.cliente.NOMBRE_COMERCIAL,
            correo: data.cliente.CORREO,
            CP: data.cliente.CP,
          });
        })
        .catch((data) => {
          setIsLoading(false);
        });
    }
  }, []);

  // console.log(datosReceptor);

  return (
    <>
      {showModalDocuemntos && (
        <ModalSelectDocumento
          handleProductoSeleccionado={handleProductoSeleccionado}
          datosReceptor={datosReceptor}
          onClose={() => {
            setShowModalDocuemntos(false);
          }}
        ></ModalSelectDocumento>
      )}
      {showModalCliente && (
        <ModalSelectCliente
          setShowDialog={setShowModalCliente}
          setDatosReceptor={setDatosReceptor}
          titulo="Seleccionar un receptor"
        />
      )}

      <div className="container-lg   ">
        <div className="row">
          <div className="col-12">
            <Button
              onClick={() => navigate(-1)}
              className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
              variant="text"
            >
              <ArrowBackIcon className="me-3" />
              Facturas
            </Button>
          </div>
          {IsLoading ? (
            <div className="col-12">
              <Loading />
            </div>
          ) : (
            <>
              <div className="col-12 col-md-6 mb-4">
              <Card elevation={3} className=" shadow">
                  <CardContent className="p-0">
                    <div className="p-3">
                      <h6
                        style={{ fontSize: "13px" }}
                        className="mb-0 text-left font-AvenirBold"
                      >
                        Emisor
                      </h6>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Row className="px-4">
                      <Col xs={12} className="p-1">
                        <Typography
                          style={{ fontSize: "1.1rem" }}
                          className="font-AvenirBold"
                          variant="h6"
                        >
                          {emisor.RAZON_SOCIAL}
                        </Typography>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent={"center"}
                        >
                          <Typography
                            alignSelf="center"
                            style={{ fontSize: "13px" }}
                            className="font-Avenir text-muted me-2"
                          >
                            RFC
                          </Typography>
                          <Chip  size="small" label={emisor.RFC}></Chip>
                        </Grid>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Accordion
                          expanded={isVisibleInfoEmi}
                          className="m-0 p-0 shadow-none"
                          onChange={() =>
                            setIsVisibleInfoEmi(!isVisibleInfoEmi)
                          }
                        >
                          <AccordionSummary
                            className="p-0 m-1"
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              style={{ color: "#3ABE88", flexShrink: 0 }}
                            >
                              Información del emisor{" "}
                              <ArrowDropDownIcon style={{ color: "#3ABE88" }} />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="p-0 m-0">
                            <Grid
                              className="mb-2"
                              container
                              direction="column"
                              alignItems="start"
                            >

<Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                 {emisor.RAZON_SOCIAL}
                              </Typography>
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                {emisor.CORREO}
                              </Typography>
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                              Régimen: {datosReceptor.regimen_fiscal_clave} -{datosReceptor.regimen_fiscal}
                                {datosReceptor.DESCRIPCION} 
                              </Typography>

                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                CP: {emisor.CP}
                              </Typography>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </div>

              <div className="col-12 col-md-6 mb-4">
                <Card elevation={3} className=" shadow">
                  <CardContent className="p-0">
                    <div className="p-3 py-2 d-flex justify-content-between flex-wrap">
                      <h6
                        className="mb-0 text-left font-AvenirBold align-self-center"
                        style={{ fontSize: "13px" }}
                      >
                        Receptor
                      </h6>
                      <div>
                        <Button
                          className="btn btn-Crexendo-light px-3 shadow-none "
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setShowModalCliente(true);
                          }}
                        >
                          <SearchIcon className="me-2" /> Seleccionar 
                        </Button>
                      </div>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Row className="px-4">
                      <Col xs={12} className="p-1">
                        <Typography
                          style={{ fontSize: "1.1rem" }}
                          className="font-AvenirBold"
                          variant="h6"
                        >
                          {datosReceptor.razon_social}
                        </Typography>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent={"center"}
                        >
                          <Typography
                            alignSelf="center"
                            style={{ fontSize: "13px" }}
                            className="font-Avenir text-muted me-2"
                          >
                            RFC
                          </Typography>
                          <Chip size="small" label={datosReceptor.RFC}></Chip>
                        </Grid>
                      </Col>
                      <Col xs={12} md={12} className="p-1">
                        <Accordion
                          expanded={expanded === "panel2"}
                          className="m-0 p-0 shadow-none"
                          onChange={handleChange("panel2")}
                        >
                          <AccordionSummary
                            className="p-0 m-1"
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography
                              style={{ color: "#3ABE88", flexShrink: 0 }}
                            >
                              Información del receptor{" "}
                              <ArrowDropDownIcon style={{ color: "#3ABE88" }} />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="p-0 m-0 w-100">
                            <Grid
                              className="mb-2"
                              container
                              direction="column"
                              alignItems="start"
                            >
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                {datosReceptor.nombre}
                              </Typography>
                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                {datosReceptor.correo}
                              </Typography>

                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                Régimen: {datosReceptor.regimen_fiscal_clave} -{datosReceptor.regimen_fiscal}
                                {datosReceptor.DESCRIPCION}
                              </Typography>

                              <Typography
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                CP: {datosReceptor.CP}
                              </Typography>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </div>

              <div className="col-12  ">
                <Card elevation={3} className="mb-4 shadow">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6
                        className="mb-0 text-left font-AvenirBold"
                        style={{ fontSize: "13px" }}
                      >
                        Datos del CFDI
                      </h6>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />

                    <DatosCFDI
                      Values={Values}
                      Errores={Errores}
                      handlInputChange={handlInputChange}
                    />
                  </CardContent>
                </Card>
              </div>

              <div className="col-12  ">
                <Card elevation={3} className="mb-4 shadow">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6
                        className="mb-0 text-left font-AvenirBold"
                        style={{ fontSize: "13px" }}
                      >
                        Conceptos
                      </h6>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Conceptos />
                  </CardContent>

                  <CardActions></CardActions>
                </Card>

                <Card elevation={3} className="mb-4 shadow">
                  <CardContent className="p-0">
                    <div className="p-3 py-2 d-flex justify-content-between flex-wrap">
                      <h6
                        className="mb-0 text-left font-AvenirBold align-self-center"
                        style={{ fontSize: "13px" }}
                      >
                        Documentos relacionados
                      </h6>
                      <div>
                        <Button
                          className="btn btn-Crexendo-light  px-3 shadow-none "
                          size="small"
                          disabled={datosReceptor.id == 0}
                          variant="contained"
                          onClick={() => {
                            setShowModalDocuemntos(true);
                          }}
                        >
                          Buscar documento <SearchIcon className="ms-2" />
                        </Button>
                      </div>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    {datosReceptor.id == 0 && (
                      <Alert severity="warning">
                        Selecciona un receptor para buscar documentos
                        relacionados
                      </Alert>
                    )}
                    <DocumentosRel
                      documentos={documentos}
                      setDocumentos={setDocumentos}
                    />
                  </CardContent>

                  <CardActions></CardActions>
                </Card>

                <div className="text-star mt-2 w-100">
                  <Stack
                    className="p-0"
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                    style={{}}
                  >
                    <LoadingButton
                      loading={IsGuardando}
                      loadingPosition="start"
                      disabled={IsLoading}
                      onClick={() => {
                        GenerarCFDI();
                      }}
                      className="btn btn-Crexendo font-AvenirMedium  py-2 px-4  me-2"
                      variant="contained"
                      xs={{ with: "100$" }}
                    >
                      <span className={IsGuardando ? "px-4" : "px-2"}>
                        {IsGuardando ? "Timbrando factura..." : "Crear"}
                      </span>
                    </LoadingButton>

                    <Button
                      onClick={() => navigate(-1)}
                      className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                      variant="outlined"
                    >
                      Cancelar
                    </Button>
                  </Stack>
                </div>
              </div>
              <div className="col-12  pt-5 m-t5"></div>
              <div className="col-12  pt-5 m-t5"></div>
              <div className="col-12  pt-5 m-t5"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NuevoComplemento;
