import { stringify } from "query-string";
import { getData } from "../../app/Context/backend";

export const getRegimenFiscalSat = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarRegimenFiscal?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getCFDI = async (query = "") => {
  let data = [];
  let queryParamsObj = {
      "query": query
  }
  const url = `sat/ConsultarUsoCFDI?${stringify(queryParamsObj)}`
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
      if (!res.error) {
          data = res.data.respuesta;
          resolve(data);
      } else {
          reject(res);
      }
  });
}


export const getInfoRegimenFiscalSat = async (clave) => {
  let data = [];
  let queryParamsObj = {
    clave: clave,
  };
  const url = `sat/ConsultarRegimenFiscal?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      let Values = {
        clave: String(data.CLAVE),
        descripcion: String(data.DESCRIPCION),
      };
      resolve(Values);
    } else {
      reject(res);
    }
  });
};

export const getInfoProductoServicio = async (clave) => {
  let data = [];
  let queryParamsObj = {
    clave: clave,
  };
  const url = `sat/getInfoProductoServicio?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoUnidadMedida = async (clave) => {
  let data = [];
  let queryParamsObj = {
    clave: clave,
  };
  const url = `sat/getInfoUnidadMedida?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getProductosServicios = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarProdServicio?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getUnidadesMedidas = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarUnidadMedida?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log("dataSat", res.data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getFormaPAgoByClave = (CLAVE) => { 
  if(CLAVE=="01"){return "01 - Efectivo";}
  else if(CLAVE=="02"){return "02 - Cheque nominativo ";}
  else if(CLAVE=="03"){return "03 - Transferencia electrónica de fondos ";}
  else if(CLAVE=="04"){return "04 - Tarjeta de crédito ";}
  else if(CLAVE=="05"){return "05 - Monedero electrónico ";}
  else if(CLAVE=="06"){return "06 - Dinero electrónico ";}
  else if(CLAVE=="08"){return "08 - Vales de despensa ";}
  else if(CLAVE=="12"){return "12 - Dación en pago ";}
  else if(CLAVE=="13"){return "13 - Pago por subrogación ";}
  else if(CLAVE=="14"){return "14 - Pago por consignación ";}
  else if(CLAVE=="15"){return "15 - Condonación";}
  else if(CLAVE=="17"){return "17 - Compensación";}
  else if(CLAVE=="23"){return "23 - Novación";}
  else if(CLAVE=="24"){return "24 - Confusión";}
  else if(CLAVE=="25"){return "25 - Remisión de deuda ";}
  else if(CLAVE=="26"){return "26 - Prescripción o caducidad ";}
  else if(CLAVE=="27"){return "27 - A satisfacción del acreedor ";}
  else if(CLAVE=="28"){return "28 - Tarjeta de débito ";}
  else if(CLAVE=="29"){return "29 - Tarjeta de servicios ";}
  else if(CLAVE=="30"){return "30 - Aplicación de anticipos ";}
  else if(CLAVE=="31"){return "31 - Intermediario pagos ";}
  else if(CLAVE=="99"){return "99 - Por definir";}
  else {return "--"} 
};