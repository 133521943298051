import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import logoPreguntas from "../Assets/5PreguntasFrecuentes.webp";
import iconoMas from "../Assets/6.1IconoMas.svg";
import iconoMenos from "../Assets/6.2IconoMenos.svg";
import BasicAccordion from "./Accordion";
import { useMediaQuery } from "@mui/material";

function PreguntasFrecuentes() {
  const isLarge = useMediaQuery("(min-width: 1200px)");
  const isSmall = useMediaQuery("(min-width: 576px)");
  return (
    <Row className="py-1  py-md-5" id="preguntas">
      <Col xs={12} xl={4} className=" ">
        <div
          style={{
            background: "#151916",
           /*   height: isLarge ? "35rem" : "15rem", */ 
            color: "white",
            borderRadius: "16px",
          }}
          className="d-flex flex-column justify-content-center h-100"
        >
          <div className="mx-3 py-4">
            <center>
               <img className="mb-3" src={logoPreguntas} width={isSmall?"100vw":"70vw"} />
            </center> 
            <h1 style={{
              color: "#3ABE88",
              fontSize: "30px"
            }} className="font-AvenirMedium text-center">
              Preguntas Frecuentes
            </h1>
            <h2 style={{
              fontSize: "22px"
            }} className="font-AvenirMedium text-center">Resolvemos tus dudas</h2>
          </div>
        </div>
      </Col>

      <Col xs={12} xl={8}>
        <BasicAccordion />
      </Col>
    </Row>
  );
}
export default PreguntasFrecuentes;
