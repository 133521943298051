import CloseIcon from "@mui/icons-material/Close";
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getClientes as getLista } from "../../Funciones/Clientes";

import Buscador from "../../../../../Includes/Buscador";
import Loading from "../../../../../Includes/Loading";
import { avatarLetters, firstLeterMayus } from "../../../../../Lib/Funciones Generales/Generales";
  
  const ModalSelectUsuario = ({
    titulo = "Seleccionar cliente",
    handleSeleccionado,
  }) => {

    
    const [ValueSelect, setValueSelect] = useState("1");
    const [ValueBusqueda, setValueBusqueda] = useState("");
    const [IsLoading, setIsLoading] = useState(true);
    const [Lista, setLista] = useState([]); 


    const getListaCliente = () => {
      setIsLoading(true); 
      getLista(10, 0, ValueSelect, ValueBusqueda)
        .then((resp) => {
          let list = JSON.parse(resp.data);
          setLista(list);
          setIsLoading(false);
        })
        .catch((resp) => {
          setLista([]);
          setIsLoading(false);
        });
    }; 
    
    useEffect(() => {
      getListaCliente();
    }, [ValueBusqueda, ValueSelect]);
  
    return (
      <>
        <Dialog fullWidth maxWidth="md" open={true}>
          <IconButton
            aria-label="Close"
            className="m-2"
            style={{ position: "absolute", right: "0%" }}
            onClick={() => {
              handleSeleccionado(false);
            }}
          >
            <CloseIcon style={{ width: "30px", height: "30px" }} />
          </IconButton>
          {
            <DialogTitle>
              <h5 className="fw-bolder f-12 mt-2">{titulo}</h5>
            </DialogTitle>
          }
          <DialogContent style={{minHeight:"70vh"}}>
            <div className="p-2">
              <Buscador
                placeholder="Buscar cliente"
                ValueBusqueda={ValueBusqueda}
                setValueBusqueda={setValueBusqueda}
              />
            </div>
  
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className=" d-sm-table-cell font-Avenir font-AvenirBold"
                    align="left"
                  >
                    CLIENTE  
                  </TableCell>
  
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="left"
                  >
                    RFC
                  </TableCell>
                  <TableCell
                    className="  d-sm-table-cell font-Avenir font-AvenirBold"
                    align="right"
                  >
                    ACCIONES
                  </TableCell>
                </TableRow>
              </TableHead>
              {IsLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={6}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {Lista.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell>
                        <div className="d-flex">
                          <div>
                            {row.IMG ? (
                              <Avatar
                                sx={{ width: 45, height: 45 }}
                                src={row.IMG}
                              />
                            ) : (
                              <Avatar sx={{ bgcolor: "#FD8C27" }}>
                                {avatarLetters(
                                  firstLeterMayus(row.NOMBRE_COMERCIAL)
                                )}
                              </Avatar>
                            )}
                          </div>
  
                          <div
                            className="ms-2 d-flex flex-column justify-content-center"
                            style={{ maxWidth: "250px" }}
                          >
                            <h6 className="text-truncate fw-bold font-AvenirBold mb-1">
                              {" "}
                              {firstLeterMayus(
                                row.NOMBRE 
                              )}{" "}
                            </h6>
                            <h6 className="text-truncate mb-1">
                              {" "}
                              {row.CORREO}
                            </h6>
                          </div>
                        </div>
                      </TableCell>
  
                      <TableCell
                        component="th"
                        scope="row"
                        className="d-none d-sm-table-cell"
                      >
                        <h6 className="text-truncate">
                          {" "}
                          {row.RFC == "" || row.RFC == null
                            ? "Sin RFC"
                            : row.RFC.toUpperCase()}
                        </h6>
                      </TableCell>
  
                      <TableCell align="right">
                        <Button
                          className="btn btn-Crexendo px-3 "
                          size="small"
                          variant="contained"
                          onClick={() => {
                            handleSeleccionado(true, row)
                          }}
                        >
                          Seleccionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default ModalSelectUsuario;
  