import { stringify } from "query-string";
import { getData} from "../../app/Context/backend";

export const getEstados = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `direcciones/ListarEstados?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getMunicipios = async (id_estado, query = "") => {
  let data = [];
  let queryParamsObj = {
    estado: id_estado,
    query: query,
  };
  const url = `direcciones/ListarMunicipios?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoEstado = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `direcciones/ConsultarEstado?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
      };
      resolve(Values);
    } else {
      reject(res);
    }
  });
};

export const getInfoMunicipio = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `direcciones/ConsultarMunicipio?${stringify(queryParamsObj)}`;
  const res = await getData(url, false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
      };
      resolve(Values);
    } else {
      reject(res);
    }
  });
};


export const getInfoDireccion = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `direcciones/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url,false);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este dimicilio",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          id_cliente: String(data.ID_USUARIO),
          nombre: String(data.NOMBRE_CONTACTO),
          telefono: String(data.TELEFONO),
          cp: String(data.CP),
          calle: String(data.CALLE),
          numeroext: String(data.NUMERO_EXT),
          numeroint: String(data.NUMERO_INT),
          colonia: String(data.COLONIA),
          estado: data.ID_ESTADO,
          municipio: data.ID_MUNICIPIO,
          entrecalle1: String(data.CALLE1),
          entrecalle2: String(data.CALLE2),
          tipodomicilio: String(data.TIPODOMICILIO),
          indicaciones: String(data.INDICACIONES),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

