// import React, { useEffect, useState } from "react";
// import { Col, Row } from "react-bootstrap";
// import LinearProgress from "@mui/material/LinearProgress";
// import Box from "@mui/material/Box";
// import { GetdataDashboard } from "./Funciones/Dashboard";
// import ChartTop from "./Charts/ChartTop";
// import ChartTab from "./Charts/ChartTab";
// import ChartBar from "./Charts/ChartBar";
// import ChartDonut from "./Charts/ChartDonut";
// import RadilBarServiceSuc from "./Charts/RadialBarServiceSuc";

// function createData(img, titulos, subtitulos) {
//   return { img, titulos, subtitulos };
// }

// const Dashboard = () => {
//   const [IsLoading, setIsLoading] = useState(true);
//   const [graficas, setgraficas] = useState([]);
//   const h = "491px";

//   const GetDataDash = (setgraficas) => {
//     setIsLoading(true);
//     GetdataDashboard()
//       .then((resp) => {
//         console.log("DASHBOARD: ", resp.data);
//         setgraficas(resp.data);
//         setIsLoading(false);
//       })
//       .catch((resp) => {
//         setgraficas([]);
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     document.title = `Dashboard`;
//     GetDataDash(setgraficas);
//   }, []);

//   let maximoFolios, disponibles;

//   if (graficas["foliosLicenciaActual"] !== undefined) {
//     const folios = JSON.parse(graficas["foliosLicenciaActual"]);
//     maximoFolios = folios[0]?.FOLIOS || 0;
//     disponibles = folios[0]?.DISPONIBLES || 0;
//   } else {
//     maximoFolios = 0;
//     disponibles = 1;
//   }

//   let top10Clientes;

//   if (graficas["top10ClientesFacturas"] !== undefined) {
//     const data = JSON.parse(graficas["top10ClientesFacturas"]);
//     top10Clientes = data.map((item, index) => {
//       return createData(item.IMG, item.NOMBRE, `Lugar ${index + 1}`);
//     });
//   }

//   let facturas4mesNombre, facturas4mesValores;

//   if (graficas["facturas4semana"] != undefined) {
//     const pedidosMes = JSON.parse(graficas["facturas4semana"]);

//     facturas4mesValores = pedidosMes.map((item) => {
//       return item.CANTIDAD;
//     });

//     facturas4mesNombre = pedidosMes.map((item, i) => {
//       return `Sem ${i + 1} (${item.DIAS})`;
//     });
//   }

//   let topProductos, topProductosNombre;

//   if (graficas["topProductos"] != undefined) {
//     const topProds = JSON.parse(graficas["topProductos"]);
//     //console.log(topProds);

//     topProductos = topProds.map((item) => {
//       return item.TOTAL_VENTAS;
//     });
//     topProductosNombre = topProds.map((item) => {
//       return item.DESC;
//     });
//   }

//   let ingresosSemanales;

//   if (graficas["ingresosSemanales"] != undefined) {
//     ingresosSemanales = JSON.parse(graficas["ingresosSemanales"]);
//   }

//   return (
//     <>
//       <div className="p-3">
//         <h4 className="text-left font-AvenirBold">Dashboard</h4>
//       </div>
//       <Row>
//         <Col sm={12} md={12}>
//           <Row>
//             <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4">
//               {IsLoading ? (
//                 <Box sx={{ width: "100%" }}>
//                   <LinearProgress />
//                 </Box>
//               ) : (
//                 <RadilBarServiceSuc
//                   label="Folios disponibles"
//                   val={disponibles}
//                   facturas={maximoFolios}
//                   titulo="Facturas restantes"
//                   text="De"
//                 />
//               )}
//             </Col>

//             <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4">
//               {IsLoading ? (
//                 <Box sx={{ width: "100%" }}>
//                   <LinearProgress />
//                 </Box>
//               ) : (
//                 <ChartTop
//                   titulo="Top clientes"
//                   rows={top10Clientes}
//                   avatarColor={"#99A2AD"}
//                   namePropios
//                 />
//               )}
//             </Col>

//             <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4">
//               {IsLoading ? (
//                 <Box sx={{ width: "100%" }}>
//                   <LinearProgress />
//                 </Box>
//               ) : (
//                 <ChartBar
//                   Height={h}
//                   titulo="Facturas por semana realizadas este mes"
//                   Labels={facturas4mesNombre}
//                   values={facturas4mesValores}
//                   Color="#3ABE88"
//                 />
//               )}
//             </Col>

//             <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4">
//               {IsLoading ? (
//                 <Box sx={{ width: "100%" }}>
//                   <LinearProgress />
//                 </Box>
//               ) : (
//                 <ChartTab ingresosSemanales={ingresosSemanales} />
//               )}
//             </Col>

//             <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4">
//               {IsLoading ? (
//                 <Box sx={{ width: "100%" }}>
//                   <LinearProgress />
//                 </Box>
//               ) : (
//                 <ChartDonut
//                   titulo="Productos más vendidos"
//                   values={topProductos}
//                   Labels={topProductosNombre}
//                   Height={h}
//                   widthChart={250}
//                 />
//               )}
//             </Col>
//             <Col xs={12} sm={12} md={6} className="pt-4 pt-sm-4 pt-md-4">
//               {IsLoading ? (
//                 <Box sx={{ width: "100%" }}>
//                   <LinearProgress />
//                 </Box>
//               ) : (
//                 <RadilBarServiceSuc
//                   label="Facturadas"
//                   val={[10]}
//                   titulo="Facturas pendientes VS no facturadas"
//                   text="Pendientes"
//                 />
//               )}
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default Dashboard;


import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { GetdataDashboard } from "./Funciones/Dashboard";
import ChartTop from "./Charts/ChartTop";
import ChartTab from "./Charts/ChartTab";
import ChartBar from "./Charts/ChartBar";
import ChartDonut from "./Charts/ChartDonut";
import RadilBarServiceSuc from "./Charts/RadialBarServiceSuc";
import Donut from "./Charts/Donut";

function createData(img, titulos, subtitulos) {
  return { img, titulos, subtitulos };
}

const Dashboard = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [graficas, setgraficas] = useState([]);
  const h = "370px";
  const h2 = "480px";

  const GetDataDash = (setgraficas) => {
    setIsLoading(true);
    GetdataDashboard()
      .then((resp) => {
        setgraficas(resp.data);
        setIsLoading(false);
      })
      .catch((resp) => {
        setgraficas([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Dashboard`;
    GetDataDash(setgraficas);
  }, []);

  let maximoFolios, disponibles;

  if (graficas["foliosLicenciaActual"] !== undefined) {
    const folios = JSON.parse(graficas["foliosLicenciaActual"]);
    maximoFolios = folios[0]?.FOLIOS || 0;
    disponibles = folios[0]?.DISPONIBLES || 0;
  } else {
    maximoFolios = 0;
    disponibles = 1;
  }

  let top10Clientes;

  if (graficas["top10ClientesFacturas"] !== undefined) {
    const data = JSON.parse(graficas["top10ClientesFacturas"]);
    top10Clientes = data.map((item, index) => {
      return createData(item.IMG, item.NOMBRE, `Lugar ${index + 1}`);
    });
  }

  let facturas4mesNombre, facturas4mesValores;

  if (graficas["facturas4semana"] != undefined) {
    const pedidosMes = JSON.parse(graficas["facturas4semana"]);

    facturas4mesValores = pedidosMes.map((item) => {
      return item.CANTIDAD;
    });

    facturas4mesNombre = pedidosMes.map((item, i) => {
      return `Sem ${i + 1} (${item.DIAS})`;
    });
  }

  let topProductos, topProductosNombre;

  if (graficas["topProductos"] != undefined) {
    const topProds = JSON.parse(graficas["topProductos"]);
    //console.log(topProds);

    topProductos = topProds.map((item) => {
      return item.TOTAL_VENTAS;
    });
    topProductosNombre = topProds.map((item) => {
      return item.DESC;
    });
  }

  let ingresosSemanales;
  if (graficas["ingresosSemanales"] != undefined) {
    ingresosSemanales = JSON.parse(graficas["ingresosSemanales"]);
  }

  let facturasPagadas = 0;
  let facturasPendientes = 0;
  let mes = "";
  if (graficas["facturasPagadasVsPendientes"]) {
    const facturasPagadasVsPendientes = JSON.parse(graficas["facturasPagadasVsPendientes"])
    facturasPagadas = facturasPagadasVsPendientes.PAGADAS;
    facturasPendientes = facturasPagadasVsPendientes.PENDIENTES;
    mes = facturasPagadasVsPendientes.MES;
  }

  
  return (
    <>
      <div className="p-3">
        <h4 className="text-left font-AvenirBold">Dashboard</h4>
      </div>
      <Row>
         

        <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
          {IsLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <ChartBar
              Height={h}
              titulo="Facturas mensuales"
              Labels={facturas4mesNombre}
              values={facturas4mesValores}
              Color="#3ABE88"
            />
          )}
        </Col>


        <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
          {IsLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <RadilBarServiceSuc
              heightCard={h}
              label="Disponibles"
              val={disponibles}
              facturas={maximoFolios}
              titulo="Folios disponibles"
            />
          )}
        </Col>



        <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
          {IsLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <Donut
              label="Pagadas"
              porcobrar = {facturasPendientes}
              pagadas = {facturasPagadas}

            
              titulo={"Facturas pagadas en "+mes}
              text="Pendientes"
           
              contadorInferiror={(facturasPendientes)+" por cobrar"}
              isShowContadorInferior={true}
              mes={""}
              heightCard={h}
            />
          )}
        </Col>

        <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
          {IsLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <ChartTab ingresosSemanales={ingresosSemanales} height={(h2)} />
          )}
        </Col>

        <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
          {IsLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <ChartTop
              heightCard={h2}
              titulo="Top clientes ventas"
              rows={top10Clientes}
              avatarColor={"#99A2AD"}
              namePropios
            />
          )}
        </Col>


        <Col xs={12} sm={12} md={6} lg={4} className="pt-4 pt-sm-4 pt-md-4">
          {IsLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <ChartDonut
              typeChart="pie"
              titulo="Productos más vendidos"
              values={topProductos}
              Labels={topProductosNombre}
              Height={h2}
              widthChart={250}
            />
          )}
        </Col>




      </Row>
    </>
  );
};

export default Dashboard;
