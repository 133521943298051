import { useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Tooltip, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Menu } from "@mui/material";
import Loading from "./../../../../Includes/Loading";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { firstLeterMayus, formatMoneda } from "../../../../Lib/Funciones Generales/Generales";
import DatosFacturacion from "../../../../Lib/Modals/Facturacion/Acciones/DatosFacturacion";
import Pdf from "../../../../assets/Pdf.svg";
import visa from "../../../../assets/visa.jpg";
import MasterCard from "../../../../assets/masterCard.png";
import conektaLogo from "../../../../assets/conektaLogo.png";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function TableLicencias({
  Lista,
  IsLoading,
  setShowDialogFacturacion = () => { },
  setPedidoSeleccionado,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        ></Menu>
        <Table aria-label="custom pagination table">
          <TableHead className="bg-tableHead">
            <TableRow>
            <TableCell
                className="font-Avenir font-AvenirBold"
                align="left"
              >
                FOLIO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA DE COMPRA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                CLIENTE
              </TableCell>
              <TableCell
                className="font-Avenir font-AvenirBold"
                align="left"
              >
                PLAN
              </TableCell>
              <TableCell
                className="font-Avenir font-AvenirBold"
                align="left"
              >
                MONTO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                MESES
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FORMA DE PAGO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={8}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-sm-table-cell"
                  >
                    <p className="m-0 ps-2 py-2">{String(row.ID).padStart(5, '0')}</p>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA_COMPRA}
                    {
                      row.ID_FACTURA === -1 && (
                        <>
                          <br />
                          <div className="d-flex align-items-center mt-1">
                            {/* <HighlightOffIcon style={{ color: 'red', fontSize: 14 }} /> */}
                            <Tooltip
                              title="Ocurrio un error en facturación automatica"
                              placement="top"
                            >
                              <p className="font-AvenirBold m-0" style={{ color: 'red', fontSize: 12 }}>
                                Error facturación
                              </p>
                            </Tooltip>
                          </div>
                        </>
                      )
                    }
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {firstLeterMayus(row.NOMBRE)}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-sm-table-cell"
                  >
                    <div style={{ maxWidth: 250 }}>
                      {row.NOMBRE_PLAN}
                    </div>

                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-sm-table-cell"
                  >
                    {formatMoneda(row.MONTO)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.MESES}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {
                      row.ID_TARJETA !== 0 && (
                        <div className="d-flex justify-content-between">
                          <div className=" align-self-center">
                            <img
                              src={
                                row.BRAND_TARJETA == "visa"
                                  ? visa
                                  : row.BRAND_TARJETA == "mastercard"
                                    ? MasterCard
                                    : conektaLogo
                              }
                              style={{ maxWidth: "24px" }}
                            />
                          </div>
                          <div className="flex-grow-1 mx-3">
                            {row.TIPO_TARJETA} ***** {row.NUMERO_TARJETA}
                          </div>
                        </div>
                      )
                    }
                  </TableCell>
                  <TableCell align="right">
                    {(row.ID_FACTURA == 0 || row.ID_FACTURA == -1) ? (
                      <>
                        <DatosFacturacion
                          setShowDialogFacturacion={setShowDialogFacturacion}
                          setPedidoSeleccionado={setPedidoSeleccionado}
                          id={row.ID}
                        />
                      </>
                    ) : (
                      <>
                        <Link
                          to={"/manager/pdf/repFacturaManager/" + row.UUID}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <Tooltip
                            title="Ver factura"
                            placement="top"
                            className="me-2"
                          >
                            <IconButton>
                              <img src={Pdf} width={22} />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
