import {
  Alert,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import {
  getInfConfNotificacion,
  guardarConfNotificaciones,
  guardarConfConekta,
  guardarConfURL,
} from "../Funciones/Generales";

import { IMG } from "../../../../Lib/Funciones Generales/Constantes";

import "../../../../Lib/Funciones Generales/Prototipos";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalCargarImagen from "../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import ImagenDefault from "../../../../assets/EditarImagen.svg";

const MySwal = withReactContent(Swal);

const ConfGenerales = () => {
  // const { id } = useParams();
  const id = 1;
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [IsGuardandoConekta, setIsGuardandoConekta] = useState(false);
  const [IsGuardandoURL, setIsGuardandoURL] = useState(false);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalMailing, setOpenModalMailing] = useState(false);

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModalMailing = () => setOpenModalMailing(false);
  const handleOpenModalMailing = () => setOpenModalMailing(true);

  /*
   * Valores de configuración del notificaciones por ConfNotificacion
   */
  const [Values, setValues] = useState({
    dias_vencimiento_certificado: "",
    dias_vencimiento_plan: "",
    existencia_folios: "",
    comision_conekta: "",
    comision_fija: "",
    IVA: "",
    url_banner_factura: ImagenDefault,
    url_banner_mailing: ImagenDefault,
    link_banner_factura: "",
    link_banner_mailing: "",
  });

  const [Errores, setErrores] = useState({
    dias_vencimiento_certificado: false,
    dias_vencimiento_plan: false,
    existencia_folios: false,
    comision_conekta: false,
    comision_fija: false,
    IVA: false,
    url_banner_factura: false,
    url_banner_mailing: false,
    link_banner_factura: false,
    link_banner_mailing: false,
  });

  const fnDevolverImagen = (img) => {
    console.log(img);
    let random = "";
    if (Values.id != "0" && Values.id != 0) {
      random = "?" + Math.floor(Math.random() * 1000000);
    }
    Values.url_banner_factura = img + random;
    setValues({ ...Values });
  };

  const fnDevolverImagenMailing = (img) => {
    console.log(img);
    let random = "";
    if (Values.id != "0" && Values.id != 0) {
      random = "?" + Math.floor(Math.random() * 1000000);
    }
    Values.url_banner_mailing = img + random;
    setValues({ ...Values });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target, tipo }) => {
    let Name = target.name;
    let Value = target.value;

    console.log(Value);

    if (tipo == "confNotificaciones") {
      Value = Value.toNumber();
    } else if (tipo == "confConekta") {
      Value = Value.toDecimal();
    }
    // Value = Value.toNumber();

    setValues({ ...Values, [Name]: Value });
    setErrores({ ...Errores, [Name]: false });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfConfNotificacion(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarConfNotificaciones = () => {
    setIsGuardando(true);
    guardarConfNotificaciones(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const GuardarConfConekta = () => {
    setIsGuardandoConekta(true);
    guardarConfConekta(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardandoConekta(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoConekta(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoConekta(false);
      });
  };

  const GuardarURL = (tipo) => {
    setIsGuardandoURL(true);
    guardarConfURL(Values, Errores, setErrores, tipo)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardandoURL(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardandoURL(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardandoURL(false);
      });
  };

  // const GuardarImagen = () => {
  //   setIsGuardandoImagen(true);
  //   guardarImagenBanner(Values, Errores, setErrores, true)
  //     .then((data) => {
  //       if (data.codigo == "200") {
  //         setIsGuardandoImagen(false);
  //         MySwal.fire({
  //           title: "Correcto",
  //           html: data.mensaje,
  //           icon: "success",
  //           confirmButtoColor: "#3ABE88",
  //           showConfirmButton: false,
  //           timer: 1700,
  //         }).then((result) => {
  //           console.log("I was closed by the timer");
  //         });
  //       } else {
  //         console.log(data);
  //         setMensaje(data.mensaje);
  //         setOpenModal(true);
  //         setIsGuardandoImagen(false);
  //       }
  //     })
  //     .catch((data) => {
  //       console.log(data);

  //       setMensaje(data.mensaje);
  //       setOpenModal(true);
  //       setIsGuardandoImagen(false);
  //     });
  // };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  useEffect(() => {
    document.title = `Generales`;
  }, []);

  let imgSrc = Values.url_banner_factura;
  let imgSrcMailing = Values.url_banner_mailing;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container pb-5">
        <h4 className="font-AvenirBold mt-4 mb-4 pt-2">Generales</h4>
        <Card elevation={3}>
          <CardContent className="p-0">
            <Row className="p-3">
              <Col sm={12} md={6}>
                <h6 className="text-left font-AvenirBold">
                  Configuración de parámetros de envío de notificaciones
                </h6>
              </Col>
              <Col sm={12} md={6} className="mt-3">
                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="p-3">
                      <Col sm={12} className="p-3">
                        <TextField
                          fullWidth
                          name="dias_vencimiento_plan"
                          variant="outlined"
                          placeholder="Ingresa el número de días"
                          value={Values.dias_vencimiento_plan}
                          error={Errores.dias_vencimiento_plan}
                          helperText={
                            Errores.dias_vencimiento_plan
                              ? "Número no valido"
                              : ""
                          }
                          label="Días a notificar antes del vencimiento del plan"
                          onChange={({ target }) =>
                            handlInputChange({
                              target,
                              tipo: "confNotificacion",
                            })
                          }
                        />
                      </Col>

                      <Col sm={12} className="p-3">
                        <TextField
                          fullWidth
                          name="dias_vencimiento_certificado"
                          variant="outlined"
                          placeholder="Ingresa el número de días"
                          value={Values.dias_vencimiento_certificado}
                          error={Errores.dias_vencimiento_certificado}
                          helperText={
                            Errores.dias_vencimiento_certificado
                              ? "Número no valido"
                              : ""
                          }
                          label="Días a notificar antes del vencimiento del certificado"
                          onChange={({ target }) =>
                            handlInputChange({
                              target,
                              tipo: "confNotificacion",
                            })
                          }
                        />
                      </Col>

                      <Col sm={12} className="p-3">
                        <TextField
                          fullWidth
                          name="existencia_folios"
                          variant="outlined"
                          placeholder="Ingresa el número de folios"
                          value={Values.existencia_folios}
                          error={Errores.existencia_folios}
                          helperText={
                            Errores.existencia_folios ? "Número no valido" : ""
                          }
                          label="Notificar existencia de folios menores a"
                          onChange={({ target }) =>
                            handlInputChange({
                              target,
                              tipo: "confNotificacion",
                            })
                          }
                        />
                      </Col>
                    </Row>

                    <Stack
                      className="p-3 d-flex justify-content-end w-100"
                      spacing={2}
                      direction={{ xs: "column", sm: "row" }}
                      style={{}}
                    >
                      <LoadingButton
                        loading={IsGuardando}
                        loadingPosition="start"
                        disabled={IsLoading}
                        onClick={() => GuardarConfNotificaciones()}
                        className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                        variant="contained"
                        xs={{ with: "100$" }}
                      >
                        <span className={IsGuardando ? "px-4" : "px-2"}>
                          {IsGuardando ? "Guardando..." : "Guardar"}
                        </span>
                      </LoadingButton>
                    </Stack>
                  </>
                )}
              </Col>
            </Row>
          </CardContent>
        </Card>

        <Card elevation={3} className="mt-4">
          <CardContent className="p-0">
            <Row className="p-3">
              <Col sm={12} md={6}>
                <h6 className="text-left font-AvenirBold">
                  Configuración de parametros Conekta
                </h6>
              </Col>
              <Col sm={12} md={6} className="mt-3">
                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="p-3">
                      <Col sm={12} className="p-3">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Comisión Conekta
                          </InputLabel>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            fullWidth
                            name="comision_conekta"
                            variant="outlined"
                            placeholder="Ingresa la comisión bancaria"
                            value={Values.comision_conekta}
                            error={Errores.comision_conekta}
                            label=" Comisión Conekta"
                            helperText={
                              Errores.comision_conekta
                                ? "Comisión bancaria no valida"
                                : ""
                            }
                            onChange={({ target }) =>
                              handlInputChange({ target, tipo: "confConekta" })
                            }
                          />
                        </FormControl>
                      </Col>

                      <Col sm={12} className="p-3">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Comisión bancaria
                          </InputLabel>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            }
                            fullWidth
                            name="comision_fija"
                            variant="outlined"
                            placeholder="Ingresa la comisión bancaria"
                            value={Values.comision_fija}
                            error={Errores.comision_fija}
                            helperText={
                              Errores.comision_fija
                                ? "Comisión bancaria no valida"
                                : ""
                            }
                            label="Comisión bancaria"
                            onChange={({ target }) =>
                              handlInputChange({ target, tipo: "confConekta" })
                            }
                          />
                        </FormControl>
                      </Col>

                      <Col sm={12} className="p-3">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            I.V.A.
                          </InputLabel>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            }
                            fullWidth
                            name="IVA"
                            variant="outlined"
                            placeholder="Ingresa el porcentaje de IVA"
                            value={Values.IVA}
                            error={Errores.IVA}
                            helperText={Errores.IVA ? "IVA no valido" : ""}
                            label="I.V.A."
                            onChange={({ target }) =>
                              handlInputChange({ target, tipo: "confConekta" })
                            }
                          />
                        </FormControl>
                      </Col>

                      <Stack
                        className="p-3 d-flex justify-content-end w-100"
                        spacing={2}
                        direction={{ xs: "column", sm: "row" }}
                        style={{}}
                      >
                        <LoadingButton
                          loading={IsGuardandoConekta}
                          loadingPosition="start"
                          disabled={IsLoading}
                          onClick={() => GuardarConfConekta()}
                          className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                          variant="contained"
                          xs={{ with: "100$" }}
                        >
                          <span
                            className={IsGuardandoConekta ? "px-4" : "px-2"}
                          >
                            {IsGuardandoConekta ? "Guardando..." : "Guardar"}
                          </span>
                        </LoadingButton>
                      </Stack>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </CardContent>
        </Card>

        <Card elevation={3} className="mt-4">
          <CardContent className="p-0">
            <Row className="p-3">
              <Col sm={12} md={6}>
                <h6 className="text-left font-AvenirBold mb-5">
                  Configuración de banner en facturas gratis
                </h6>
                <div className="d-flex text-align-center flex-column">
                  <div className="d-flex text-align-center mb-3">
                    <img
                      src={imgSrc}
                      style={{
                        width: "100%",
                        maxHeight: "250px",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenModal}
                    />  
                  </div>
                  <p className="text-center  my-0 ms-3 font-Avenir mb-3 text-muted">
                    Se recomienda un ancho de 730px 
                  </p>
                  <p
                    className="text-center my-0 ms-3 font-AvenirBold mb-3"
                    style={{ color: "#3abe88", cursor: "pointer" }}
                    onClick={handleOpenModal}
                  >
                    Editar
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} className="mt-3">
                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="p-3">
                      <Stack
                        className="p-3 d-flex justify-content-end w-100"
                        spacing={2}
                        direction={{ xs: "column", sm: "row" }}
                        style={{}}
                      >
                        <Col sm={12} className="py-3">
                          <div className="d-flex flex-column ">
                            <FormControl fullWidth>
                              <InputLabel>URL</InputLabel>
                              <OutlinedInput
                                className="mb-4"
                                fullWidth
                                name="link_banner_factura"
                                variant="outlined"
                                placeholder="Ingresa el URL"
                                inputProps={{ type: "URL" }}
                                value={Values.link_banner_factura}
                                error={Errores.link_banner_factura}
                                helperText={
                                  Errores.link_banner_factura
                                    ? "URL no valido"
                                    : ""
                                }
                                label="URL"
                                onChange={(e) => handlInputChange(e)}
                              />
                            </FormControl>
                            <div className="d-flex justify-content-end ">
                              <LoadingButton
                                loading={IsGuardandoURL}
                                loadingPosition="start"
                                disabled={IsLoading}
                                onClick={() =>
                                  GuardarURL("LINK_BANNER_FACTURA")
                                }
                                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                                variant="contained"
                                xs={{ with: "100$" }}
                                style={{ width: "20%" }}
                              >
                                <span
                                  className={IsGuardandoURL ? "px-4" : "px-2"}
                                >
                                  {IsGuardandoURL ? "Guardando..." : "Guardar"}
                                </span>
                              </LoadingButton>
                            </div>
                          </div>
                        </Col>

                        {openModal && (
                          <ModalCargarImagen
                            id={"7"}
                            tipo={IMG.TIPO_BANNER}
                            Titulo="Cargar imagen del banner para las facturas"
                            Descripcion="Este es el banner que aparecerá en las facturas"
                            mostrar={openModal}
                            imgUrl={Values.url_banner_factura}
                            fnCerrar={handleCloseModal}
                            fnDevolverImagen={fnDevolverImagen}
                            ConfigRecortar={{
                              requerida: false,
                              aspectoAlto: 1,
                              aspectoAncho: 1,
                            }}
                            requiereConfirmar={false}
                          />
                        )}
                      </Stack>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </CardContent>
        </Card>

        <Card elevation={3} className="mt-4">
          <CardContent className="p-0">
            <Row className="p-3">
              <Col sm={12} md={6}>
                <h6 className="text-left font-AvenirBold mb-5">
                  Configuración de imagen banner en mailing
                </h6>
                <div className="d-flex text-align-center flex-column">
                  <div className="d-flex text-align-center mb-3">
                    <img
                      src={imgSrcMailing}
                      style={{
                        width: "100%",
                        maxHeight: "250px",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenModalMailing}
                    />
                  </div>
                  <p className="text-center  my-0 ms-3 font-Avenir mb-3 text-muted">
                    La imagen debe tener un tamaño de 720 x 300 
                  </p>
                  <p
                    className="text-center my-0 ms-3 font-AvenirBold mb-3"
                    style={{ color: "#3abe88", cursor: "pointer" }}
                    onClick={handleOpenModalMailing}
                  >
                    Editar
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} className="mt-3">
                {IsLoading ? (
                  <Row className="px-3">
                    <Col sm={12} className="p-3">
                      <Loading />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="p-3">
                      <Stack
                        className="p-3 d-flex justify-content-end w-100"
                        spacing={2}
                        direction={{ xs: "column", sm: "row" }}
                        style={{}}
                      >
                        <Col sm={12} className="py-3">
                          <div className="d-flex flex-column ">
                            <FormControl fullWidth>
                              <InputLabel>URL</InputLabel>
                              <OutlinedInput
                                className="mb-4"
                                fullWidth
                                name="link_banner_mailing"
                                variant="outlined"
                                placeholder="Ingresa el URL"
                                inputProps={{ type: "URL" }}
                                value={Values.link_banner_mailing}
                                error={Errores.link_banner_mailing}
                                helperText={
                                  Errores.link_banner_mailing
                                    ? "URL no valido"
                                    : ""
                                }
                                label="URL"
                                onChange={(e) => handlInputChange(e)}
                              />
                            </FormControl>
                            <div className="d-flex justify-content-end">
                              <LoadingButton
                                loading={IsGuardandoURL}
                                loadingPosition="start"
                                disabled={IsLoading}
                                onClick={() =>
                                  GuardarURL("LINK_BANNER_MAILING")
                                }
                                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                                variant="contained"
                                xs={{ with: "100$" }}
                                style={{ width: "20%" }}
                              >
                                <span
                                  className={IsGuardandoURL ? "px-4" : "px-2"}
                                >
                                  {IsGuardandoURL ? "Guardando..." : "Guardar"}
                                </span>
                              </LoadingButton>
                            </div>
                          </div>
                        </Col>

                        {openModalMailing && (
                          <ModalCargarImagen
                            id={"8"}
                            tipo={IMG.TIPO_MAILING}
                            Titulo="Cargar imagen del banner para el mailing"
                            Descripcion="Este es el banner que aparecerá en el mailing"
                            mostrar={openModalMailing}
                            imgUrl={Values.url_banner_mailing}
                            fnCerrar={handleCloseModalMailing}
                            fnDevolverImagen={fnDevolverImagenMailing}
                            ConfigRecortar={{
                              requerida: false,
                              aspectoAlto: 1,
                              aspectoAncho: 1,
                            }}
                            requiereConfirmar={false}
                          />
                        )}
                      </Stack>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ConfGenerales;
