import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  TableHead,
  Tooltip,
  Alert,
  Snackbar,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";

import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { eliminarEnvioMasivo, procesarEnvioMasivo } from "../Funciones/EnvioMasivo";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Loading from "../../../../Includes/Loading";
import AccEliminar from "./../../../../assets/AccEliminar.svg";
import AccEditar from "./../../../../assets/AccEditar.svg";
import MoreVert from "@mui/icons-material/MoreVert";
import SobreCerrado from "../../../../assets/SobreCerrado.svg";
import SobreAbierto from "../../../../assets/SobreAbierto.svg";
import Fade from "@mui/material/Fade";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';

const MySwal = withReactContent(Swal);

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function TableEnvioMasivo({
  Lista,
  IsLoading,
  setLista,
  setShowDialogoSelectCliente,
  setRowSeleccionada
}) {


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const EliminarRegistro = (id) => {
    MySwal.fire({
      title: "¿Estás seguro de eliminar esta envío masivo?",
      text: "Esta acción es irreversible",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          eliminarEnvioMasivo(id)
            .then((resp) => {
              MySwal.fire({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarPlanTillaDeTabla(id);
              });
            })
            .catch((resp) => {
              // alert("error al eliminar");
            });
        }
      }
    });
  };

  const ProcesarEnvio = (id) => {
    MySwal.fire({
      title: "¿Estás seguro de procesar este envío masivo?",
      text: "Esta acción es irreversible",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          procesarEnvioMasivo(id)
            .then((resp) => {
              if (resp.codigo == "200") {
                MySwal.fire({
                  title: "Exito!",
                  text: "Envio procesado correctamente",
                  icon: "success",
                }).then(function () {
                  const newLista = Lista
                  newLista.map(item => {
                    if (item.ID == id) {
                      item.ENVIADO = 1
                    }
                    return item;
                  })
                  setLista([...newLista])
                });
              } else {
                MySwal.fire({
                  title: "Lo sentimos",
                  text: resp.mensaje,
                  icon: "error",
                })
              }




            })
            .catch((resp) => {

            });
        }
      }
    });
  };

  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarPlanTillaDeTabla = (id) => {
    const ListaFiltrado = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(ListaFiltrado);
  };


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const rowSelect = { ID: 0, ENVIADO: 0 }
  try {
    rowSelect = Lista.find(x => x.ID === idRow)
  }
  catch (error) { }

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >


          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
            component={Link}
            to={"edit/" + idRow}
          >
            <IconButton>
              <img src={AccEditar} />
            </IconButton>
            Editar
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClose();
              ProcesarEnvio(idRow);
            }}
          >
            <IconButton>
              {
                rowSelect.ENVIADO == 1 ?
                  <img src={SobreAbierto} width={22} />
                  : <img src={SobreCerrado} width={22} />
              }
            </IconButton>
            Eliminar
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClose();
              EliminarRegistro(idRow);
            }}
          >
            <IconButton>
              <img src={AccEliminar} />
            </IconButton>
            Eliminar
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead className='bg-tableHead'>
            <TableRow>
              <TableCell
                className="font-Avenir font-AvenirBold d-none d-sm-table-cell"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                DESCRIPCION
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                FORMAS DE ENVÍO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold d-none d-sm-table-cell" align="left">
                DESTINATARIOS
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold d-none d-sm-table-cell" align="left">
                FECHA DE ENVÍO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold " align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row) => {
                // console.log()
                row = { ...row, ...JSON.parse(row.DATOS) }
                return (
                  <TableRow key={row.ID}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {row.FECHA}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Grid className="text-truncate" style={{ maxWidth: 300 }}>
                        {row.DESCRIPCION}
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.IS_ENVIO_EMAIL && (
                        <Tooltip title="Correo electrónico" placement="top">
                          <img src={SobreCerrado} width={22} className="mx-1" />
                        </Tooltip>
                      )}
                      {row.IS_ENVIO_SMS && (
                        <Tooltip title="Mensaje de texto" placement="top">
                          <MessageIcon className="mx-1" />
                        </Tooltip>
                      )}
                      {row.IS_ENVIO_WHATSAPP && (
                        <Tooltip title="WhatsApp" placement="top">
                          <WhatsAppIcon className="mx-1" style={{ color: '#25d366' }} />
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {
                        Array.isArray(row.DESTINATARIOS) ? row.DESTINATARIOS.length : 0
                      }
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      className="d-none d-sm-table-cell"
                    >
                      {row.FECHA_ENVIO}
                    </TableCell>

                    <TableCell align="right">
                      <Grid key={row.ID} className="d-none d-sm-block">


                        <Link
                          to={"./edit/" + row.ID}
                          style={{ textDecoration: "none" }}
                        >
                          <Tooltip title="Editar" placement="top">
                            <IconButton>
                              <img src={AccEditar} />
                            </IconButton>
                          </Tooltip>
                        </Link>

                        <Tooltip
                          title="Procesar envío"
                          placement="top"
                          onClick={() => ProcesarEnvio(row.ID)}
                        >
                          <IconButton>
                            {
                              row.ENVIADO ?
                                <img src={SobreAbierto} width={22} />
                                : <img src={SobreCerrado} width={22} />
                            }
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Eliminar" placement="top">
                          <IconButton onClick={() => EliminarRegistro(row.ID)}>
                            <img src={AccEliminar} />
                          </IconButton>
                        </Tooltip>

                      </Grid>
                      <Grid>
                        <DrawerHeader
                          style={{ backgroundColor: "#FFFFFF" }}
                          className="d-xsm-none d-sm-block d-sm-none"
                          onClick={() => {
                            setIdRow(row.ID);
                            setStateRow(row.ACTIVO);
                          }}
                        >
                          <IconButton onClick={handleClick}>
                            <MoreVert />
                          </IconButton>
                        </DrawerHeader>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              }
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
