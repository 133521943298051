import { createContext, useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Backdrop, Chip, CircularProgress, Divider } from "@mui/material";
import { ContextInfoUser } from "../Context/ContextInfoUser";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";

import ValidadorWebMovile from "../../Lib/Funciones Generales/ValidadorWebMovile";
import MenuIcon from "@mui/icons-material/Menu";

import Sidebar from "./Sidebar";
import AccountMenu from "./MenuPerfil";
import MenuNotificaciones from "../Generales/Componentes/Notificaciones";
import Perfil from "../Perfil/Perfil";
import Clientes from "../Catalogos/Clientes/Clientes";
import Series from "../Catalogos/Series/Series";
import Productos from "../Catalogos/Productos/Productos";
import MisTarjetas from "../Perfil/Componentes/MisTarjetas";
import Configuraciones from "../Configuraciones/ConfigGenerales/Componentes/Configuraciones";

import Dashboard from "../Inicio/Dashboard";
import Error404 from "../../Includes/Error404";
import RouterFacturas from "../Facturacion/Facturas/Facturas";
import Reportes from "../Reportes/Reportes";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#101727",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#101727",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 5,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const IconContext = createContext();

export default function Menu() {
  const theme = useTheme();
  const [open, setOpen] = useState(ValidadorWebMovile());
  const [url, setUrl] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isTabletOrMobile = useMediaQuery("(max-width: 1224px)");

  useEffect(() => {
    setOpen(!isTabletOrMobile);
  }, [isTabletOrMobile]);


  const { Values, getInfoUsuario, IsLoading } = useContext(ContextInfoUser);

  useEffect(() => {
    getInfoUsuario(true);
  }, []);

  if (IsLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <IconContext.Provider value={{ url, setUrl, open, setOpen }}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar 
            position="fixed"
            open={open}
            color="transparent"
            style={{ boxShadow: "none", zIndex: 10 }}
          >
            <Toolbar     style={open ? { paddingLeft: "0px", alignItems:"self-start" } : {alignItems:"self-start"}}>
              <div className="d-flex justify-content-between w-100 ">
                <div>
                  {open ? (
                    <DrawerHeader style={{ backgroundColor: "#FFFFFF" }}>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                          <MoreVert />
                        ) : (
                          <MoreVert />
                        )}
                      </IconButton>
                    </DrawerHeader>
                  ) : (
                    <IconButton onClick={handleDrawerOpen}>
                      <MenuIcon style={{ color: "white", zIndex: 10 }} />
                    </IconButton>
                  )}
                </div>
                <div
                  className="d-flex justify-content-end w-100 align-content-center"
                  style={{ backgroundColor: "#FFFFFF" }}
                >

                  <div className="align-self-center">
                    <span className="me-2 fw-bold" style={{ fontSize: "10px" }}>
                      Plan
                    </span>

                    <Chip
                      size="small"
                      className="me-2  shadow text-white fw-bolder"
                      label={Values.plan}
                      style={{ backgroundColor: "#3abe88", fontSize: "9px" }}
                    />
                  </div>

                  <div className="align-self-center">
                    <span className="me-2 fw-bold" style={{ fontSize: "10px" }}>
                      Folios
                    </span>
                    <Chip
                      size="small"
                      className="me-2 px-1  shadow text-white fw-bolder"
                      label={Values.folios}
                      style={{ backgroundColor: Values.folios==0 ?"#D14343":"#3abe88", fontSize: "9px" }}
                    />
                  </div>

                  <div className="p-1 d-flex align-content-center">
                    <div className="align-self-center d-none d-sm-flex d-md-block">
                      <MenuNotificaciones
                        Values={Values}
                        IsLoading={IsLoading}
                        className="align-self-center"
                      />
                    </div>
                    <div className="align-self-center d-none d-sm-flex d-md-block">
                      <AccountMenu
                        Values={Values}
                        IsLoading={IsLoading}
                        className="align-self-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            style={{
              backgroundColor: "#101727",
              height: "100%",
              color: "white",
              zIndex: 2,
            }}
          >
            <Sidebar
              openMenu={open}
              permisosMenu={null}
              IsLoading={IsLoading}
            />
          </Drawer>
          <Divider light />
          <Box
            component="main"
            sx={{ flexGrow: 1 }}
            className="p-xsm-0 p-sm-3 "
            style={{ backgroundColor: "#F9FAFC", minHeight: "100vh" }}
          >
            <div className="mb-5"></div>
            <Routes>
              {!IsLoading && (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="inicio/*" element={<Dashboard />} />
                  <Route path="perfil/*" element={<Perfil />} />
                  <Route path="perfil/:tabselect" element={<Perfil />} />
                  <Route path="clientes/*" element={<Clientes />} />
                  <Route path="series/*" element={<Series />} />
                  <Route path="productos/*" element={<Productos />} />
                  <Route path="reportes/*" element={<Reportes />} />
                  <Route path="tarjetas/*" element={<MisTarjetas />} />
                  <Route path="configuraciones/*" element={<Configuraciones />} />
                  <Route path="facturas/*" element={<RouterFacturas />} />
                  <Route path="*" element={<Error404 />} />
                </>
              )}
            </Routes>
          </Box>
        </Box>
      </IconContext.Provider>
    </>
  );
}
