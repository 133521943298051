import { useRef } from "react"
import { PALETA } from "../../../../Lib/Funciones Generales/Colores"
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales"
import { Button } from "@mui/material"

const FacturacionDiseno = ({
    data,
    idPlantillaActiva = 0,
    handleClickVerDiseno,
    handleClickComprar,
    handleClickDefault,
    imgDefault = '',
    isVisibleBotones = true
}) => {

    console.log(data)

    const refElement = useRef(null)
    const handleMouseEnter = () => {
        refElement.current.style.border = `3px solid ${PALETA.colorPrimary}`
    }
    const handleMouseLeave = () => {
        if (idPlantillaActiva != data.ID) {
            refElement.current.style.border = `3px solid ${PALETA.colorDivision}`
        }
    }

    const styleCenter = isVisibleBotones ? { width: '80%', marginLeft: '10%' } : { width: '40%', marginLeft: '30%' }

    return (
        <div
            className="w-100 h-100 d-flex flex-wrap"
            ref={refElement}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                borderRadius: 15,
                border: `3px solid ${data.ID == idPlantillaActiva ? PALETA.colorPrimary : PALETA.colorDivision}`,
                backgroundColor: PALETA.colorFondo
            }}>

            {isVisibleBotones && (
                <div className="p-3 w-100" style={{
                    borderTopRightRadius: (data.ID == idPlantillaActiva) ? 12 : 15,
                    borderTopLeftRadius: (data.ID == idPlantillaActiva) ? 12 : 15,
                    backgroundColor: (data.ID == idPlantillaActiva) ? PALETA.colorPrimary : PALETA.colorFondo,
                    minHeight: 51.19
                }}>
                    <h6 className="w-100 text-white text-center font-AvenirBold m-0">
                        {
                            (data.ID == idPlantillaActiva) ? 'DISEÑO ACTUAL' : ''
                        }
                    </h6>
                </div>
            )}


            <div className="p-4 d-flex flex-wrap mt-auto">
                <img
                    className="mt-4"
                    src={data.IMG == '' ? imgDefault : data.IMG}
                    style={{
                        ...(isVisibleBotones ? { marginLeft: '15%', maxWidth: '70%' } : { marginLeft: '25%', maxWidth: '50%' }),
                        objectFit: 'contain'
                    }} />

                <div className="w-100 d-flex justify-content-center mt-5">
                    {
                        data.PRECIO != 0 ? (
                            <>
                                <h6 className="font-AvenirBold m-0 me-2">
                                    {
                                        formatMoneda(data.PRECIO)
                                    } MXN
                                </h6>
                                <p className="m-0 text-muted" style={{ fontSize: 12 }}> / Un solo pago</p>
                            </>
                        ) : (
                            <h6 className="font-AvenirBold m-0 me-2">Gratis </h6>
                        )
                    }

                </div>

                <h6 className="w-100 text-center font-AvenirBold m-0 mt-3">
                    {data.NOMBRE}
                </h6>

                {
                    isVisibleBotones && (
                        <>
                            <Button
                                style={styleCenter}
                                onClick={() => handleClickVerDiseno(data.ID)}
                                className="btn btn-cancelar font-AvenirMedium py-2 px-4 mt-5"
                                variant="outlined"
                            >
                                Ver diseño
                            </Button>

                            {
                                (data.ID_COMPRA != null || data.PRECIO == 0) ? (
                                    <Button
                                        style={{ ...styleCenter, color: PALETA.colorPrimary }}
                                        onClick={() => handleClickDefault(data.ID)}
                                        className="btn font-AvenirMedium py-2 px-4 mt-3 mb-3"
                                    >
                                        Predeterminado
                                    </Button>
                                ) : (
                                    <Button
                                        style={styleCenter}
                                        onClick={() => handleClickComprar(data.ID)}
                                        className="btn btn-Crexendo font-AvenirMedium py-2 px-4 mt-3 mb-3"
                                        variant="outlined"
                                    >
                                        Comprar
                                    </Button>
                                )
                            }
                        </>
                    )
                }




            </div>
        </div>
    )
}

export default FacturacionDiseno