import { useState, useEffect } from "react";
import { Alert, Avatar, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  TableHead,
  Menu
} from "@mui/material";
import Loading from "./../../../../Includes/Loading";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { firstLeterMayus } from "../../../../Lib/Funciones Generales/Generales";
import { avatarLetters } from "../../../../Lib/Funciones Generales/Generales";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import { REGISTRO } from "../../../../Lib/Funciones Generales/Constantes";
import ImgTipoFacebook from '../../../../assets/TipoFacebook.svg'
import ImgTipoGoogle from '../../../../assets/TipoGoogle.svg'
import ImgTipoFormulario from '../../../../assets/TipoFormulario.svg'
import Switch from "../../../../Includes/Switch";
import { DesactivarUsuario } from "../Funciones/Clientes";

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function TableCliente({ Lista, IsLoading, setLista }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);


  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );


  const onChange = (checked, id) => {
    setIdRow(id);
    setStateRow(checked);
    ActivarOrDesactivar(id, checked);
  };
  const ActivarOrDesactivar = (id, checked) => {
    if (id != undefined) {
      DesactivarUsuario(id, checked)
        .then((resp) => {
          const filteredLibraries = Lista.filter((item) => {
            if (item.ID == id) {
              item.ACTIVO = checked;
            }
            return item;
          });
          setLista(filteredLibraries);
          setSeverity("success");
          setMensaje("Cambios guardados correctamente");
          setOpen(true);
        })
        .catch((resp) => {
          setSeverity("error");
          setMensaje("Error al guardar los cambios");
          setOpen(true);
        });
    }
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead className='bg-tableHead'>
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                IMAGEN
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                NOMBRE
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                MÉTODO DE REGISTRO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                TELÉFONO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                CORREO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >

              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-sm-table-cell"
                  >
                    {row.IMG ? (
                      <Avatar sx={{ width: 45, height: 45 }} src={row.IMG} />
                    ) : (
                      <Avatar sx={{ bgcolor: "#FD8C27" }}>
                        {avatarLetters(
                          firstLeterMayus(row.NOMBRE)
                        )}
                      </Avatar>
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-sm-table-cell"
                  >
                    {firstLeterMayus(row.NOMBRE)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-sm-table-cell"
                  >
                    {
                      row.TIPO == REGISTRO.GOOGLE && (
                        <img style={{ width: 24 }} src={ImgTipoGoogle} />
                      )
                    }
                    {
                      row.TIPO == REGISTRO.FACEBOOK && (
                        <img style={{ width: 24 }} src={ImgTipoFacebook} />
                      )
                    }
                    {
                      row.TIPO == REGISTRO.FORMULARIO && (
                        <img style={{ width: 24 }} src={ImgTipoFormulario} />
                      )
                    }
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.TELEFONO}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.CORREO}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={row.ACTIVO}
                      onChange={onChange}
                      id={row.ID}
                      tituloActivo="Desactivar cliente "
                      tituloInactivo="Activar cliente"
                    />
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
