import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import imgFondo from "../../assets/FondoBanner.webp";
import imgLogo from "../../assets/LogoCrexendo.svg";
import imgGrafica from "../../assets/GraficoBanner.webp";
import imgAux from "../../assets/IsotipoBanner.svg";
import { useMediaQuery } from "react-responsive";

import {
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Box,
  ListItemButton,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { PersonAdd, AccountCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!isMobileDrawerOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 30) {
        setIsNavbarTransparent(false);
      } else {
        setIsNavbarTransparent(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 780 });

  return (
    <div
      className="pt-0  pt-sm-5"
      style={{
        backgroundImage: `url(${imgFondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        minHeight: "800px",
      }}
    >
      <Hidden smUp>
        <AppBar
          position="fixed"
          sx={{ boxShadow: "none", background: "none", height: "unset" }}
          className={`  ${isNavbarTransparent ? "transparent-bg shadow-none" : "bg-white shadow"}`}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileDrawer}
              style={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={imgLogo}
              alt="Logo"
              style={{ maxWidth: "80px" }}
              className="img-fluid"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="w-100"
            >
              <Link to="./app" style={{ textDecoration: "none" }}>
                <Button
                  size="small"
                  className="btn btn-Crexendo-Menu font-AvenirMedium py-2 me-3 shadow-none"
                  variant="contained"
                >
                  Iniciar sesión
                </Button>
              </Link>
              <Link to="./app/registro" style={{ textDecoration: "none" }}>
                <Button
                  size="small"
                  className="btn btn-Crexendo font-AvenirMedium py-2 me-2 shadow-none"
                  variant="contained"
                >
                  Registrarme
                </Button>
              </Link>
            </Box>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          anchor="left"
          open={isMobileDrawerOpen}
          onClose={toggleMobileDrawer}
          style={{ backgroundColor: "transparent" }}
        >
          <List style={{ width: "70vw" }}>
            <ListItemButton style={{ marginTop: "10px", marginBottom: "3rem" }}>
              <img src={imgLogo}></img>
            </ListItemButton>
            <ListItemButton
              onClick={toggleMobileDrawer}
              style={{ marginTop: "10px" }}
            >
              <a
                href="#"
                className="linkSinSubrrayado"
                style={{ textDecoration: "none", color: "black" }}
              >
                {" "}
                <ListItemText primary="¿Quiénes somos?" />
              </a>
            </ListItemButton>
            <ListItemButton onClick={toggleMobileDrawer}>
              <a
                href="#caracteristicas"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemText primary="Características" />
              </a>
            </ListItemButton>
            <ListItemButton onClick={toggleMobileDrawer}>
              <a
                href="#planes"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemText primary="Planes" />
              </a>
            </ListItemButton>
            <ListItemButton onClick={toggleMobileDrawer}>
              <a
                href="#preguntas"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemText primary="Preguntas frecuentes" />
              </a>
            </ListItemButton>
            <ListItemButton onClick={toggleMobileDrawer}>
              <a
                href="#contacto"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemText primary="Contacto" />
              </a>
            </ListItemButton>
          </List>
        </SwipeableDrawer>
      </Hidden>

      {/* Barra de navegación para dispositivos de escritorio */}
      <div>
        <Hidden smDown>
          <div
            className={`container-fluid custom-navbar  ${
              isNavbarTransparent ? "transparent-bg" : "white-bg shadow"
            }`}
          >
            <Container className=" d-flex justify-content-between flex-wrap mt-3  w-100 align-content-center">
              <Navbar.Brand>
                <img
                  src={imgLogo}
                  alt="Logo" 
                  className="img-fluid"
                  style={{ marginTop: "-9px" , width:"220px"}}
                />
              </Navbar.Brand>
              <Nav
                className="ml-auto font-AvenirBold flex-nowrap"
                style={{
                  fontSize: "20px",
                }}
              >
                {/*
<Nav.Link href="#" className="nav-link-black">
                  ¿Quiénes somos?
                </Nav.Link>
*/}

                <Nav.Link href="#caracteristicas" className="nav-link-black">
                  Características
                </Nav.Link>
                <Nav.Link
                  href="#planes"
                  className="nav-link-black"
                  style={{ paddingLeft: "20px" }}
                >
                  Planes
                </Nav.Link>
                <Nav.Link
                  href="#preguntas"
                  className="nav-link-black text-nowrap"
                  style={{ paddingLeft: "20px" }}
                >
                  Preguntas frecuentes
                </Nav.Link>
                <Nav.Link
                  href="#contacto"
                  className="nav-link-black"
                  style={{ paddingLeft: "20px" }}
                >
                  Contacto
                </Nav.Link>
              </Nav>

              <Box
                display={"flex"}
                className="flex-fill justify-content-xl-end_ justify-content-start_  justify-content-end"
              >
                <Link to="./app" style={{ textDecoration: "none" }}>
                  <Button
                    className="btn btn-Crexendo-Menu font-AvenirMedium py-2 shadow-none text-nowrap "
                    variant="contained"
                  >
                    Iniciar sesión
                  </Button>
                </Link>
                <Link to="./app/registro" style={{ textDecoration: "none" }}>
                  <Button
                    className="btn btn-Crexendo font-AvenirMedium py-2 shadow-none ms-3"
                    variant="contained"
                  >
                    Registrarme
                  </Button>
                </Link>
              </Box>
            </Container>
          </div>
        </Hidden>
      </div>

      {/* Header para dispositivos de escritorio */}

      <div className="container">
        <Row>
          <Col sm={12}>
            <div className="pt-2 mt-5 pt-sm-5 mt-sm-5 "></div>
          </Col>
          <Col sm={12} md={6}>
            <div className=" mt-4  d-flex flex-column justify-content-center h-100">
              <h1
                className="font-AvenirMedium d-none d-sm-block"
                style={{ fontSize: "calc(3.1rem + 26px)" }}
              >
                <span style={{ color: "#3abe88" }}>Crexe</span> tu negocio con
                Crexendo
              </h1>
              <h1
                className="font-AvenirMedium d-block d-sm-none text-center"
                style={{ fontSize: "calc(1.1rem + 26px)" }}
              >
                <span style={{ color: "#3abe88" }}>Crexe</span> tu negocio con
                Crexendo
              </h1>
              <h2 className="font-AvenirMedium text-center text-sm-start">
                Tu factura siempre en línea
              </h2>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            className="mt-5  mt-sm-2"
            style={{ position: "relative" }}
          >
            <img
              src={imgAux}
              className="img-fluid p-5 p-sm-0"
              alt="Aux"
              style={{
                position: "absolute",
                zIndex: 1,
                left: 0,
                top: isMobile ? 10 : 190,
              }}
            />
            <img
              src={imgGrafica}
              alt="Grafica"
              className="img-fluid p-1 ps-5"
              style={{ zIndex: 5, position: "relative" }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
