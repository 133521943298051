import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
//import Textarea from '@mui/joy/Textarea';
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarPlanes, getPlanes, getInfPlanes } from "../Funciones/Planes";
import "../../../../Lib/Funciones Generales/Prototipos";

const MySwal = withReactContent(Swal);

const Planes = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    precio_semestral: "",
    precio_anual: "",
    costo_semestral: "",
    costo_anual: "",
    productos_incluidos: "",
    clientes_incluidos: "",
    facturas_por_mes: "",
    moneda: "MXN",
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    precio_semestral: false,
    precio_anual: false,
    costo_semestral: false,
    costo_anual: false,
    productos_incluidos: false,
    clientes_incluidos: false,
    facturas_por_mes: false,
    moneda: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name === "precio_semestral" || Name === "precio_anual") {
      NewValue = { ...NewValue, [Name]: Value.toDecimal() };
    }

    if (
      Name === "productos_incluidos" ||
      Name === "clientes_incluidos" ||
      Name === "facturas_por_mes"
    ) {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }

    setValues(NewValue);

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfPlanes(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarPlanes = () => {
    setIsGuardando(true);
    guardarPlanes(Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Planes
      </Button>
      <Card elevation={3} className="shadow"> 
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar plan" : "Nuevo plan"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre del plan"
                  helperText={
                    Errores.nombre ? "Ingresa el nombre del plan" : ""
                  }
                  inputProps={{ maxLength: 250 }}
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  name="precio_semestral"
                  label="Precio semestral"
                  value={Values.precio_semestral}
                  error={Errores.precio_semestral}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  name="precio_anual"
                  label="Precio anual"
                  value={Values.precio_anual}
                  error={Errores.precio_anual}
                  onChange={handlInputChange}
                />
              </Col>


              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  name="costo_semestral"
                  label="Costo semestral"
                  value={Values.costo_semestral}
                  error={Errores.costo_semestral}
                  onChange={handlInputChange}
                />
              </Col>

             
              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  name="costo_anual"
                  label="Costo anual"
                  value={Values.costo_anual}
                  error={Errores.costo_anual}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={6} className="p-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-select-small-label">Moneda</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={Values.moneda}
                    error={Errores.moneda} 
                    onChange={handlInputChange}
                    label="moneda"
                    fullWidth
                    name="moneda"
                    defaultValue={"MXN"}
                  >
                    <MenuItem value={"MXN"}>MXN - Pesos Mexicanos </MenuItem>
                    <MenuItem disabled value={"USD"}>USD - Dolares</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  InputProps={
                    {
                      // startAdornment: (
                      //    <InputAdornment position="start">#</InputAdornment>
                      // ),
                    }
                  }
                  name="productos_incluidos"
                  label="Productos incluidos"
                  value={Values.productos_incluidos}
                  error={Errores.productos_incluidos}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  InputProps={
                    {
                      // startAdornment: (
                      //   <InputAdornment position="start">#</InputAdornment>
                      // ),
                    }
                  }
                  name="clientes_incluidos"
                  label="Clientes incluidos"
                  value={Values.clientes_incluidos}
                  error={Errores.clientes_incluidos}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  InputProps={
                    {
                      // startAdornment: (
                      //   <InputAdornment position="start">#</InputAdornment>
                      // ),
                    }
                  }
                  name="facturas_por_mes"
                  label="Facturas por mes"
                  value={Values.facturas_por_mes}
                  error={Errores.facturas_por_mes}
                  onChange={handlInputChange}
                />
              </Col>
            </Row>
          )}
          <Stack
            className="p-3 d-flex justify-content-end w-100"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarPlanes()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Planes;
