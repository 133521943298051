import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import TablePlanes from "./Tabla";
import SelectOrdenamiento from "../../../../Includes/SelectOrdenamiento";
import Buscador from "../../../../Includes/Buscador";
import { getClientes } from "../Funciones/Clientes";

const RepPlanes = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  useEffect(() => {
    getListaPlanes();
  }, [ValueBusqueda, ValueSelect]);

  const getListaPlanes = () => {
    setIsLoading(true);
    getClientes(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Clientes`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Clientes</h4>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar por nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TablePlanes
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            ></TablePlanes>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default RepPlanes;
