import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, CardContent, Button } from "@mui/material";

import manten from "../Assets/2.1IconoManten.svg";
import personaliza from "../Assets/2.2IconoPersonaliza.svg";
import lista from "../Assets/2.3IconoListaCli.svg";
import gestion from "../Assets/2.4IconoGestion.svg";
import control from "../Assets/2.5IconoControl.svg";
import addendas from "../Assets/2.6IconoAdquAddendas.svg";
import imagenBanner from "../Assets/2.0ImagenBanner.webp";

function Caracteristicas() {
  return (
    <div className="container p-0" id="caracteristicas">
      <div
        className="container p-0 container-caracteristicas"
        style={{
          backgroundColor: "#151916",
          color: "white",
          borderRadius: "16px",
        }}
      >
        <Row className="my-5">
          <Col xs={12} lg={4}>
            <img align="left" 
            style={{borderRadius:"22px"}}
            src={imagenBanner} className="img-fluid " />
          </Col>
          <Col xs={12} lg={8}>
            <div className="p-5 p-lg-2 d-flex flex-column justify-content-center h-100 ">
            <div className="font-AvenirMedium ">
              <h3 style={{ color: "#3ABE88" }}>
              Con Crexendo, haz crecer tu negocio
              </h3>
              <h5 className="font-Avenir">
              mientras gestionas tus operaciones de forma sencilla y rápida.
              </h5>
              <p className="font-Avenir">
              Experimenta eficiencia, precisión y organización para un control financiero óptimo.
              </p>
            </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-beetween flex-wrap">
        <Row>
          <Col xs={12} md={6}>
            <div className="d-flex py-3 py-md-5  mb-4 mb-md-0  ">
              <div className="align-self-start">
                <img src={manten} />
              </div>
              <div className="mx-3">
                <h4
                  className="mb-4 font-AvenirMedium"
                  style={{ color: "#68768d" }}
                >
                 Registros precisos al alcance de tu mano
                </h4>
                <h6 className="font-AvenirMedium">
                Mantén un registro eficiente de todas tus transacciones y actividades comerciales. Nosotros te facilitamos un seguimiento preciso de tus ingresos y ganancias, para que tengas un control total de tus finanzas.
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex py-3 py-md-5  mb-4 mb-md-0">
              <div className="align-self-start">
                <img src={personaliza} />
              </div>
              <div className="mx-3">
                <h4
                  className="mb-4 font-AvenirMedium"
                  style={{ color: "#68768d" }}
                >
                Personaliza tu factura
                </h4>
                <h6 className="font-AvenirMedium">
                Destaca tu marca con facturas personalizadas que incluyen tu logotipo. Elige entre una variedad de plantillas con diferentes colores y estilos, o si lo prefieres, solicita una personalización exclusiva para reflejar tu identidad única.
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex py-3 py-md-5  mb-4 mb-md-0">
              <div className="align-self-start">
                <img src={lista} />
              </div>
              <div className="mx-3">
                <h4
                  className="mb-4 font-AvenirMedium"
                  style={{ color: "#68768d" }}
                >
                 Lista de clientes
                </h4>
                <h6 className="font-AvenirMedium">
                Mantén una base de datos organizada con la información de tus clientes. Agrega detalles como nombre, dirección, información de contacto y notas relevantes que te ayudarán a ofrecer un servicio personalizado.
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex py-3 py-md-5  mb-4 mb-md-0">
              <div className="align-self-start">
                <img src={gestion} />
              </div>
              <div className="mx-3">
                <h4
                  className="mb-4 font-AvenirMedium"
                  style={{ color: "#68768d" }}
                >
                  Control total de tus productos 
                </h4>
                <h6 className="font-AvenirMedium">
                Simplifica la gestión de tus productos y servicios con nuestra plataforma. Olvídate del tedioso registro manual, ahora puedes agregar, editar y eliminar productos y servicios de manera sencilla y eficiente, todo en un solo lugar.
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex py-3 py-md-5  mb-4 mb-md-0">
              <div className="align-self-start">
                <img src={control} />
              </div>
              <div className="mx-3">
                <h4
                  className="mb-4 font-AvenirMedium"
                  style={{ color: "#68768d" }}
                >
                 Información es poder
                </h4>
                <h6 className="font-AvenirMedium">
                Descubre el poder de tomar decisiones fundamentadas al conocer los productos más vendidos, a tus clientes más leales y otros datos cruciales que te brindarán el control total sobre tu negocio.
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex py-3 py-md-5  mb-4 mb-md-0">
              <div className="align-self-start">
                <img src={addendas} />
              </div>
              <div className="mx-3">
                <h4
                  className="mb-4 font-AvenirMedium"
                  style={{ color: "#68768d" }}
                >
                 Addendas personalizadas
                </h4>
                <h6 className="font-AvenirMedium">
                Dale a tu empresa el impulso que se merece con addendas personalizadas. Optimiza tus operaciones y experimenta un crecimiento espectacular mientras llevas tus procesos al siguiente nivel.
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Caracteristicas;
