import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
  MenuItem
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  guardar,
  getInfoProducto,
} from "../Funciones/Productos";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import Loading from "../../../../Includes/Loading";
import { Impuestos, ObjetosImpuestos } from "../../../../Lib/Funciones Generales/Constantes";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProductoServicioSat from "../../../../SAT/Componentes/ProductoServicioSat";
import UnidadMedidaSat from "../../../../SAT/Componentes/UnidadMedidaSat";
import UnidadMedidaInterna from "./SelectUnidadMedidaInterna";
import "../../../../Lib/Funciones Generales/Prototipos";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import HelperError from "../../../../Includes/HelperError";
import Switch from "../../../../Includes/Switch";
import Select from "../../../../Includes/Select";

const MySwal = withReactContent(Swal);

const Producto = () => {
  //Variables del formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");


  const [Values, setValues] = useState({
    id: id ?? "0",
    codigo: "",
    descripcion: "",
    precio: "",
    unidad_de_medida: "",
    moneda: "MXN",
    iva: "16",
    clave_codigo_prod_sat: "",
    clave_unidad_medida_sat: "",
    descuento: "",
    costo: 0,
    retencion_iva: "",
    retencion_isr: "",
    retencion_ieps: "",
    objeto_impuesto: "00",
    ieps: "",
    is_requiere_impuestos: true,
    is_requiere_retenciones: false
  });

  const [Errores, setErrores] = useState({
    codigo: false,
    descripcion: false,
    precio: false,
    unidad_de_medida: false,
    moneda: false,
    iva: false,
    clave_codigo_prod_sat: false,
    clave_unidad_medida_sat: false,
    descuento: false,
    costo: false,
    retencion_iva: false,
    retencion_isr: false,
    retencion_ieps: false,
    objeto_impuesto: false,
    ieps: false,
  });


  const handleClose = () => {
    setOpen(false);
  };

  const onChangeRequiereImpuestos = (check, id) => {

  }


  const onChangeRequiereRetenciones = (check, id) => {
    setValues({ ...Values, is_requiere_retenciones: check })
  }

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    if (Name == "descuento" || Name == "precio" ||  Name == "costo" ) {
      Value = Value.toDecimal();
    }

    if (Name == "retencion_iva" || Name == "retencion_isr" || Name == "retencion_ieps" || Name == "ieps") {
      Value = Value.toDecimal();
    }
    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoProducto(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarProducto = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className="container pb-5">
        <Button
          onClick={() => navigate(-1)}
          className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
          variant="text"
        >
          <ArrowBackIcon className="me-3" />
          Productos
        </Button>
        <Card elevation={3} className="mb-4 shadow">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="mb-0 text-left font-AvenirBold">
                {id ? "Editar producto o servicio" : "Crear nuevo producto"}
              </h6>
            </div>
            <hr
              style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
              className="m-0 p-0"
            />

            {IsLoading ? (
              <Row className="px-3">
                <Col sm={12} className="p-3">
                  <Loading />
                </Col>
              </Row>
            ) : (
              <Row className="p-3">

                <Col sm={12} md={8} className="p-3">
                  <TextField
                    required
                    fullWidth
                    inputProps={{ maxLength: 250 }}
                    label={"Descripción del producto o servicio"}
                    variant="outlined"
                    name="descripcion"
                    helperText={
                      Errores.descripcion ? "Nombre no valido" : ""
                    }
                    value={Values.descripcion}
                    error={Errores.descripcion}
                    onChange={handlInputChange}
                  />
                </Col>


                <Col sm={12} md={4} className="p-3">
                  <TextField
                    required
                    fullWidth
                    inputProps={{ maxLength: 250 }}
                    label="Código"
                    variant="outlined"
                    name="codigo"
                    helperText={Errores.codigo ? "Código no valido" : ""}
                    value={Values.codigo}
                    error={Errores.codigo}
                    onChange={handlInputChange}
                  />
                </Col>


                <Col sm={12} md={4} className="p-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Precio de venta
                    </InputLabel>
                    <OutlinedInput
                      required
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      name="precio"
                      value={Values.precio}
                      error={Errores.precio}
                      label="Precio de venta"
                      placeholder="Precio de venta"
                      onChange={handlInputChange}
                    />
                  </FormControl>
                  <HelperError Error={Errores.precio} Mensaje={Errores.precio ? "Precio no valido" : ""} />
                </Col>






                <Col sm={12} md={4} className="p-3">
                  <UnidadMedidaSat
                    Value={Values.clave_unidad_medida_sat}
                    Error={Errores.clave_unidad_medida_sat}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <ProductoServicioSat
                    Value={Values.clave_codigo_prod_sat}
                    Error={Errores.clave_codigo_prod_sat}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <UnidadMedidaInterna
                    Value={Values.unidad_de_medida}
                    Error={Errores.unidad_de_medida}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Descuento
                    </InputLabel>
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">%</InputAdornment>
                      }
                      name="descuento"
                      value={Values.descuento==0?"":Values.descuento}
                      error={Errores.descuento}
                      label="Descuento"
                      placeholder="Descuento"
                      onChange={handlInputChange}
                    />
                  </FormControl>
                  <HelperError Error={Errores.descuento} Mensaje={Errores.descuento ? "Descuento no valido" : ""} />
                </Col>
 <Col sm={12} md={4} className="p-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                    Costo de compra
                    </InputLabel>
                    <OutlinedInput 
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      name="costo"
                      value={Values.costo==0?"":Values.costo}
                      error={Errores.costo}
                      label="Costo de compra"
                      placeholder="Costo de compra"
                      onChange={handlInputChange}
                    />
                  </FormControl>
                  <HelperError Error={Errores.costo} Mensaje={Errores.costo ? "Costo no valido" : ""} />
                </Col>
                

                <Col sm={12} className="d-flex px-3">
                  <p className="m-0 my-auto font-AvenirBold"> Impuestos</p>
                  {/*<Switch
                    checked={Values.is_requiere_impuestos}
                    onChange={onChangeRequiereImpuestos}
                    id={Values.id}
                    tituloActivo="Si"
                    tituloInactivo="No"
                    on
                  />*/}
                </Col>
                <Col sm={12} className="mb-3 px-3">
                  <hr style={{ color: PALETA.colorSecondary }} className="m-0 p-0" />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <Select
                    Titulo="Objeto de impuesto"
                    Name='objeto_impuesto'
                    Value={Values.objeto_impuesto}
                    Error={Errores.objeto_impuesto}
                    HelperText={Errores.objeto_impuesto ? "Objeto de impuesto no valido" : ""}
                    Opciones={ObjetosImpuestos}
                    handlInputChange={handlInputChange} />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <Select
                    Titulo="I.V.A."
                    Name='iva'
                    Value={Values.iva}
                    Error={Errores.iva}
                    HelperText={Errores.iva ? "I.V.A. no valido" : ""}
                    Opciones={Impuestos}
                    handlInputChange={handlInputChange} />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      IEPS
                    </InputLabel>
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">%</InputAdornment>
                      }
                      name="ieps"
                      helperText={Errores.ieps ? "IEPS no valido" : ""}
                      value={Values.ieps == 0 ? '' : Values.ieps}
                      error={Errores.ieps}
                      label="IEPS"
                      placeholder="IEPS"
                      onChange={handlInputChange}
                    />
                  </FormControl>
                  <HelperError Error={Errores.ieps} Mensaje={Errores.ieps ? "IEPS no valido" : ""} />
                </Col>

                <Col sm={12} className="d-flex px-0">
                  <Switch
                    checked={Values.is_requiere_retenciones}
                    onChange={onChangeRequiereRetenciones}
                    id={Values.id}
                    tituloActivo="Si"
                    tituloInactivo="No"
                    on
                  />
                  <p className="m-0 my-auto font-AvenirBold"> Incluye retenciones </p>
                </Col>
                <Col sm={12} className="mb-3 px-3">
                  <hr style={{ color: PALETA.colorSecondary }} className="m-0 p-0" />
                </Col>


                {
                  Values.is_requiere_retenciones && (
                    <>
                      <Col sm={12} md={4} className="p-3">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Retención de I.V.A.
                          </InputLabel>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">%</InputAdornment>
                            }
                            name="retencion_iva"
                            helperText={Errores.retencion_iva ? "Retención de I.V.A. no valido" : ""}
                            value={Values.retencion_iva == 0 ? '' : Values.retencion_iva}
                            error={Errores.retencion_iva}
                            label="Retención de I.V.A."
                            placeholder="Retención de I.V.A."
                            onChange={handlInputChange}
                          />
                        </FormControl>
                        <HelperError Error={Errores.retencion_iva} Mensaje={Errores.retencion_iva ? "Retención de I.V.A. no valido" : ""} />
                      </Col>

                      <Col sm={12} md={4} className="p-3">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Retención de ISR
                          </InputLabel>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">%</InputAdornment>
                            }
                            name="retencion_isr"
                            helperText={Errores.retencion_isr ? "Retención de ISR no valido" : ""}
                            value={Values.retencion_isr == 0 ? '' : Values.retencion_isr}
                            error={Errores.retencion_isr}
                            label="Retención de ISR"
                            placeholder="Retención de ISR"
                            onChange={handlInputChange}
                          />
                          <HelperError Error={Errores.retencion_isr} Mensaje={Errores.retencion_isr ? "Retención de ISR no valido" : ""} />
                        </FormControl>
                      </Col>


                      <Col sm={12} md={4} className="p-3">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Retención de IEPS
                          </InputLabel>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">%</InputAdornment>
                            }
                            name="retencion_ieps"
                            helperText={Errores.retencion_ieps ? "Retención de IEPS no valido" : ""}
                            value={Values.retencion_ieps == 0 ? '' : Values.retencion_ieps}
                            error={Errores.retencion_ieps}
                            label="Retención de IEPS"
                            placeholder="Retención de IEPS"
                            onChange={handlInputChange}
                          />
                        </FormControl>
                        <HelperError Error={Errores.retencion_ieps} Mensaje={Errores.retencion_ieps ? "Retención de IEPS no valido" : ""} />
                      </Col>
                    </>
                  )
                }



              </Row>
            )}
            <Stack
              className="p-3"
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarProducto()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                </span>
              </LoadingButton>
              <Button
                onClick={() => navigate(-1)}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                variant="outlined"
              >
                Cancelar
              </Button>
            </Stack>
          </CardContent>
        </Card>

      </div>
    </>
  );
};

export default Producto;
