import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../Context/backend";
import { TIPO_MENSAJE } from "../../../../Lib/Funciones Generales/Constantes";
import { parseEditorToHtml } from "../../Ayuda/Funciones/Ayuda";


///////////////////
/* FUNCIONES GET */
///////////////////
export const getEnvioMasivo = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `envioMasivo/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const guardarEnvioMasivo = async (Values, Errores, setErrores, Editor) => {

  let texto = "";
  let html = "";

  if (Values.tipo_mensaje == TIPO_MENSAJE.TEXTO) {
    texto = Editor.getCurrentContent().getPlainText();
    html = parseEditorToHtml(Editor)
  }

  if (Values.tipo_mensaje == TIPO_MENSAJE.PLANTILLA) {
    texto = "";
    html = Values.html;
  }

  // console.log("HTML: ", html)
  // console.log("TEXT: ", texto)

  // return new Promise((resolve, reject) => {
  //   resolve({ mensaje: "Error" });
  // });

  let error = false;


  if(Values.is_envio_email){
    if (!Values.asunto || Values.asunto == "") {
      Errores.asunto = true;
      setErrores(Errores);
      error = true;
    }
  }
  

  if (!Array.isArray(Values.destinatarios)) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Selecciona al menos un destinatario" });
    });
  }

  if (Values.destinatarios.length == 0) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Selecciona al menos un destinatario" });
    });
  }

  if (Values.tipo_mensaje == TIPO_MENSAJE.TEXTO) {
    if (!texto || texto == "") {
      Errores.texto = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (Values.tipo_mensaje == TIPO_MENSAJE.PLANTILLA) {
    if (!html || html == "") {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Carga la plantilla a enviar" });
      });
    }
  }



  if (!error) {


    let data = [];
    const url =
      ((Values.id == undefined || Values.id == "" || Values.id == 0) && Values.id != -1)
        ? "envioMasivo/Insertar"
        : "envioMasivo/Editar";

    const body = {
      id: Values.id,
      descripcion: Values.descripcion,
      envio: {
        is_envio_email: Values.is_envio_email,
        is_envio_sms: Values.is_envio_sms,
        is_envio_whatsapp: Values.is_envio_whatsapp,
        is_plantilla_personalizada: Values.is_plantilla_personalizada,
        is_todos_usuarios: Values.is_todos_usuarios,
        destinatarios: Values.destinatarios,
        destinatarios_eliminados: Values.destinatarios_eliminados,
        texto: texto,
        asunto: Values.asunto,
        html: html,
        tipo_mensaje: Values.tipo_mensaje,
      }
    };
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarEnvioMasivo = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `envioMasivo/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const procesarEnvioMasivo = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `envioMasivo/Procesar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      data = res.data;
        resolve(data);
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfEnvioMasivo = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    ID: id == -1 ? 0 : id,
  };
  const url = `envioMasivo/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta envío masivo",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        const envio = JSON.parse(data.DATOS)
        let Value = {
          id: data.ID == 0 ? -1 : data.ID,
          descripcion: String(data.DESCRIPCION),

          is_envio_email: envio ? envio.IS_ENVIO_EMAIL : false,
          is_envio_sms: envio ? envio.IS_ENVIO_SMS : false,
          is_envio_whatsapp: envio ? envio.IS_ENVIO_WHATSAPP : false,
          is_plantilla_personalizada: envio ? envio.IS_PLANTILLA_PERSONALIZADA : false,
          // is_todos_usuarios: envio ? envio.IS_TODOS_USUARIOS : false,
          destinatarios: envio ? envio.DESTINATARIOS : [],
          destinatarios_eliminados: envio ? envio.DESTINATARIOS_ELIMINADOS : [],
          texto: envio ? envio.TEXTO : '',
          asunto: envio ? envio.ASUNTO : '',
          url_archivo: envio ? envio.URL_ARCHIVO : '',
          html: envio ? envio.HTML : '',
          tipo_mensaje: envio ? envio.TIPO_MENSAJE : TIPO_MENSAJE.TEXTO,

        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id == -1 ? 0 : id,
      activo: checked,
    };
    const url = `envioMasivo/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const asignarUsuario = async (id_envio_masivo, id_usuario) => {
  let data = [];
  let body = {
    id_envio_masivo, id_usuario
  };
  const url = `envioMasivo/asignarUsuario`;
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
