import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Ayuda from "./Componentes/Ayuda";
import RepAyuda from "./Componentes/RepAyuda";

const routerAyuda = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepAyuda />} />
        <Route path="/add" element={<Ayuda />} />
        <Route path="/edit/:id" element={<Ayuda />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerAyuda;
