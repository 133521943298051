import { React, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import iconoMas from "../Assets/6.1IconoMas.svg";
import iconoMenos from "../Assets/6.2IconoMenos.svg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material";

const AccordionSummaryLeft = styled((props) => (
  <AccordionSummary
    {...props}
    style={{
      ...props.style,
      flexDirection: "row-reverse",
    }}
  />
))(({ theme }) => ({}));
const TypographySummary = styled(({ className, ...props }) => (
  <Typography {...props} className={`${className} ms-3`} />
))(({ theme }) => ({}));

function BasicAccordion() {
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="d-flex flex-column justify-content-center mt-5 mt-lg-0">
      <Accordion
        elevation={0}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummaryLeft
          expandIcon={
            expanded === "panel1" ? (
              <RemoveIcon style={{ color: "#3ABE88" }} />
            ) : (
              <AddIcon style={{ color: "#3ABE88" }} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographySummary
            style={{ color: "#3ABE88" }}
            className="font-AvenirMedium"
          >
            ¿Cómo activo mi cuenta?
          </TypographySummary>
        </AccordionSummaryLeft>
        <AccordionDetails>
          <TypographySummary className="font-Avenir">
            Para activar tu cuenta Crexendo, simplemente sigue estos pasos:
            <div className="container-fluid text-start">
              <p>
                1.{"   "}En el menú de nuestro sitio web haz clic en
                "Registrarme".
              </p>
              <p>
                2.{"   "}Completa el formulario de registro con tus datos
                personales. También puedes registrarte utilizando tu cuenta de
                Google o Facebook.
              </p>
              <p>
                3.{"   "}Verifica tu dirección de correo electrónico haciendo
                clic en el enlace de confirmación que te enviaremos.
              </p>
              <p>
                4.{"   "}¡Listo! Tu cuenta Crexendo estará activa y podrás
                comenzar a utilizar nuestro sistema de facturación en línea.
              </p>
            </div>
          </TypographySummary>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummaryLeft
          expandIcon={
            expanded === "panel2" ? (
              <RemoveIcon style={{ color: "#3ABE88" }} />
            ) : (
              <AddIcon style={{ color: "#3ABE88" }} />
            )
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <TypographySummary
            style={{ color: "#3ABE88" }}
            className="font-AvenirMedium"
          >
            ¿Cómo instalo Crexendo?
          </TypographySummary>
        </AccordionSummaryLeft>
        <AccordionDetails>
          <TypographySummary className="font-Avenir">
            <p>
              Crexendo es una plataforma web, por lo que no es necesario
              instalar ningún software. <br /> Simplemente accede a tu cuenta
              desde cualquier dispositivo con conexión a Internet y comienza a
              utilizarlo de inmediato.
            </p>
          </TypographySummary>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummaryLeft
          expandIcon={
            expanded === "panel3" ? (
              <RemoveIcon style={{ color: "#3ABE88" }} />
            ) : (
              <AddIcon style={{ color: "#3ABE88" }} />
            )
          }
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <TypographySummary
            style={{ color: "#3ABE88" }}
            className="font-AvenirMedium"
          >
            ¿Cuáles son los precios?
          </TypographySummary>
        </AccordionSummaryLeft>
        <AccordionDetails>
          <TypographySummary className="font-Avenir">
            <p>
              Nuestros precios cambian según el plan que elijas y las
              características que necesites. <br /> Te invitamos a visitar la
              sección de planes para obtener información detallada sobre
              nuestras tarifas y opciones disponibles.
            </p>
          </TypographySummary>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummaryLeft
          expandIcon={
            expanded === "panel4" ? (
              <RemoveIcon style={{ color: "#3ABE88" }} />
            ) : (
              <AddIcon style={{ color: "#3ABE88" }} />
            )
          }
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <TypographySummary
            style={{ color: "#3ABE88" }}
            className="font-AvenirMedium"
          >
            ¿Cuentan con un período de prueba?
          </TypographySummary>
        </AccordionSummaryLeft>
        <AccordionDetails>
          <TypographySummary className="font-Avenir">
            <p>
              Sí, una vez registrado, puedes elegir nuestro plan XERO, el cuál
              es gratuito. <br /> Esto te permitirá explorar todas las
              funcionalidades de Crexendo y decidir si es la solución adecuada
              para tu negocio.
            </p>
          </TypographySummary>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummaryLeft
          expandIcon={
            expanded === "panel5" ? (
              <RemoveIcon style={{ color: "#3ABE88" }} />
            ) : (
              <AddIcon style={{ color: "#3ABE88" }} />
            )
          }
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <TypographySummary
            style={{ color: "#3ABE88" }}
            className="font-AvenirMedium"
          >
            ¿El sistema solo funciona en computadora?
          </TypographySummary>
        </AccordionSummaryLeft>
        <AccordionDetails>
          <TypographySummary className="font-Avenir">
            <p>
              No, Crexendo es una plataforma web y es accesible desde cualquier
              dispositivo con conexión a Internet, ya sea una computadora de
              escritorio, una laptop, una tablet o incluso un teléfono móvil.
            </p>
          </TypographySummary>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummaryLeft
          expandIcon={
            expanded === "panel6" ? (
              <RemoveIcon style={{ color: "#3ABE88" }} />
            ) : (
              <AddIcon style={{ color: "#3ABE88" }} />
            )
          }
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <TypographySummary
            style={{ color: "#3ABE88" }}
            className="font-AvenirMedium"
          >
            ¿Cómo puedo pagar?
          </TypographySummary>
        </AccordionSummaryLeft>
        <AccordionDetails>
          <TypographySummary className="font-Avenir">
            <p>
              Aceptamos como métodos de pago en línea tarjetas de crédito y
              débito. <br /> Puedes configurar tus preferencias de pago en el
              perfil de tu cuenta Crexendo.
            </p>
          </TypographySummary>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default BasicAccordion;
