import {
  Card,
  CardContent,
  Grid,
  Typography,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../Includes/Loading";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";

export default function TabReceptor({ data, IsLoading, ValuesSend = {} }) {
  const [isVisibleInfoEmi, setIsVisibleInfoEmi] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {IsLoading ? (
        <Loading />
      ) : (
        <div className="col-12 col-md-6 mb-4">
          <Card elevation={3} className=" shadow">
            <CardContent className="p-0">
              <div className="p-3">
                <h6
                  style={{ fontSize: "13px" }}
                  className="mb-0 text-left font-AvenirBold"
                >
                  Emisor
                </h6>
              </div>
              <hr
                style={{
                  backgroundColor: "#DFDEE0",
                  height: "1px",
                  opacity: "1",
                }}
                className="m-0 p-0"
              />
              <Row className="px-4">
                <Col xs={12} className="p-1">
                  <Typography
                    style={{ fontSize: "1.1rem" }}
                    className="font-AvenirBold"
                    variant="h6"
                  >
                    {data.emisor?.RAZON_SOCIAL}
                  </Typography>
                </Col>
                <Col xs={12} className="p-1">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent={"center"}
                  >
                    <Typography
                      alignSelf="center"
                      style={{ fontSize: "13px" }}
                      className="font-Avenir text-muted me-2"
                    >
                      RFC
                    </Typography>
                    <Chip size="small" label={data.emisor?.RFC}></Chip>
                  </Grid>
                </Col>
                <Col xs={12} className="p-1">
                  <Accordion
                    expanded={isVisibleInfoEmi}
                    className="m-0 p-0 shadow-none"
                    onChange={() => setIsVisibleInfoEmi(!isVisibleInfoEmi)}
                  >
                    <AccordionSummary
                      className="p-0 m-1"
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography style={{ color: "#3ABE88", flexShrink: 0 }}>
                        Información del emisor{" "}
                        <ArrowDropDownIcon style={{ color: "#3ABE88" }} />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="p-0 m-0">
                      <Grid
                        className="mb-2"
                        container
                        direction="column"
                        alignItems="start"
                      >
                        <Typography
                          className="text-muted"
                          style={{ fontSize: 14 }}
                        >
                          {data.emisor?.RAZON_SOCIAL}
                        </Typography>

                        <Typography
                          className="text-muted"
                          style={{ fontSize: 14 }}
                        >
                          {"soporte@flexor.mx"}
                        </Typography>

                        <Typography
                          className="text-muted"
                          style={{ fontSize: 14 }}
                        >
                          Régimen: {data.emisor?.DESCRIPCION} -{" "}
                          {data.emisor?.REGIMEN}
                        </Typography>

                        <Typography
                          className="text-muted"
                          style={{ fontSize: 14 }}
                        >
                          CP: {data.emisor?.CP}
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Col>
              </Row>
            </CardContent>
          </Card>
        </div>
      )}
      {IsLoading ? (
        <Loading />
      ) : (
        <div className="col-12 col-md-6 mb-4">
          <Card elevation={3} className=" shadow">
            <CardContent className="p-0">
              <div className="p-3 ">
                <h6
                  className="mb-0 text-left font-AvenirBold align-self-center"
                  style={{ fontSize: "13px" }}
                >
                  Receptor
                </h6>
              </div>
              <hr
                style={{
                  backgroundColor: "#DFDEE0",
                  height: "1px",
                  opacity: "1",
                }}
                className="m-0 p-0"
              />
              <Row className="px-4">
                {ValuesSend.rfc === undefined ? (
                  <>
                    <h6 className="p-3 font-AvenirBold">
                      {" "}
                      Sin datos fiscales configurados
                    </h6>
                  </>
                ) : (
                  <>
                    <Col xs={12} className="p-1">
                      <Typography
                        style={{ fontSize: "1.1rem" }}
                        className="font-AvenirBold"
                        variant="h6"
                      >
                        {ValuesSend.razon_social}
                      </Typography>
                    </Col>
                    <Col xs={12} className="p-1">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent={"center"}
                      >
                        <Typography
                          alignSelf="center"
                          style={{ fontSize: "13px" }}
                          className="font-Avenir text-muted me-2"
                        >
                          RFC
                        </Typography>
                        <Chip size="small" label={ValuesSend.rfc}></Chip>
                      </Grid>
                    </Col>
                    <Col xs={12} md={12} className="p-1">
                      <Accordion
                        expanded={expanded === "panel2"}
                        className="m-0 p-0 shadow-none"
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary
                          className="p-0 m-1"
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <Typography
                            style={{ color: "#3ABE88", flexShrink: 0 }}
                          >
                            Información del receptor{" "}
                            <ArrowDropDownIcon style={{ color: "#3ABE88" }} />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 m-0 w-100">
                          <Grid
                            className="mb-2"
                            container
                            direction="column"
                            alignItems="start"
                          >
                            <Typography
                              className="text-muted"
                              style={{ fontSize: 14 }}
                            >
                              {ValuesSend.razon_social}
                            </Typography>
                            <Typography
                              className="text-muted"
                              style={{ fontSize: 14 }}
                            >
                              {data.receptor?.CORREO}
                            </Typography>

                            <Typography
                              className="text-muted"
                              style={{ fontSize: 14 }}
                            >
                              Régimen: {ValuesSend.regimen_fiscal} -{" "}
                              {data.receptor.DESCRIPCION}
                            </Typography>

                            <Typography
                              className="text-muted"
                              style={{ fontSize: 14 }}
                            >
                              CP: {ValuesSend.cp}
                            </Typography>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Col>
                  </>
                )}
              </Row>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
}
