import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Avatar, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { avatarLetters, firstLeterMayus } from '../../../../../Lib/Funciones Generales/Generales';
import { useState } from "react";
import { getOpcionesClientes as getLista } from "../../Funciones/Clientes";

const BuscadorUsuario = ({ disabled, handleSeleccionar, ListaSeleccionados = [] }) => {

    const [destinatarios, setDestinatarios] = useState([])
    const [text, setText] = useState("")


    const getListaCliente = (ValueBusqueda) => {
        let IdSeleccionados = "";
        if (Array.isArray(ListaSeleccionados)) {
            IdSeleccionados = ListaSeleccionados.map(usuario => {
                return usuario.ID
            }).toString(",")
        }
        getLista(10, 0, 0, ValueBusqueda, IdSeleccionados)
            .then((resp) => {
                let list = JSON.parse(resp.data);
                setDestinatarios(list)
            })
            .catch((resp) => {
                setDestinatarios([])
            });
    };

    return (
        <Autocomplete
            disabled={disabled}
            className='w-100'
            inputValue={text}
            options={destinatarios}
            onChange={(e, value) => {
                if (value) {
                    const list = destinatarios.filter(usuario => usuario.ID != value.ID)
                    setDestinatarios(list)
                    handleSeleccionar(value)
                }
                setText("")
            }}
            onInputCapture={({ target }) => {
                setText(target.value)
                setDestinatarios([{ BUSCANDO: true, NOMBRE: target.value + " - Buscando cliente..." }])

                clearInterval(window.intervalo);
                window.intervalo = setInterval(function () {
                    getListaCliente(text)
                    clearInterval(window.intervalo)
                }, 1000);
            }}
            isOptionEqualToValue={(option, value) => {
                return option.NOMBRE == value.NOMBRE
            }}
            getOptionLabel={(option) => {
                // console.log("NOMBRE: ", option.NOMBRE)
                return option.NOMBRE
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Buscar clientes"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                    }}
                    placeholder='Buscar clientes ...'
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">

                            {
                                !option.BUSCANDO && (
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        {option.IMG ? (
                                            <Avatar sx={{ width: 45, height: 45 }} src={option.IMG} />
                                        ) : (
                                            <Avatar sx={{ bgcolor: "#FD8C27" }}>
                                                {avatarLetters(
                                                    firstLeterMayus(option.NOMBRE)
                                                )}
                                            </Avatar>
                                        )}
                                    </Grid>
                                )
                            }

                            <Grid item className='ps-2' sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Typography variant="body2" className='font-font-AvenirBold' color="text.secondary">
                                    {option.NOMBRE}
                                </Typography>
                                <Typography variant="body2" className='font-Avenir' color="text.secondary">
                                    {option.CORREO}
                                </Typography>
                                <Typography variant="body2" className='font-Avenir' color="text.secondary">
                                    {option.TELEFONO}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    )
}

export default BuscadorUsuario