import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import Correo from "./Componentes/Correo";

const routerConfigCorreos = () => {
  return (
    <>
      <Routes>
        <Route index element={<Correo />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerConfigCorreos;
