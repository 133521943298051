import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Avatar,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarAddendas, getInfAddendas } from "../Funciones/Addendas";
import ImagenDefault from "../../../../assets/EditarImagen.svg";
import "../../../../Lib/Funciones Generales/Prototipos";
import ModalCargarImagen from "../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import { IMG } from "../../../../Lib/Funciones Generales/Constantes";
import { PALETA } from "../../../../Lib/Funciones Generales/Colores";
import Switch from "../../../../Includes/Switch";

const MySwal = withReactContent(Swal);

const Addendas = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    descripcion: "",
    is_personalizada: false,
    is_gratis: false,
    precio: "",
    costo: "",
    img: ImagenDefault,
  });

  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  const [Errores, setErrores] = useState({
    nombre: false,
    precio: false,
    costo: false,
    descripcion: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name === "precio") {
      NewValue = { ...NewValue, [Name]: Value.toDecimal() };
    }

    if (Name == "is_personalizada") {
      NewValue = { ...NewValue, is_personalizada: Value };
    }

    setValues(NewValue);

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfAddendas(id, ImagenDefault)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarAddendas = () => {
    setIsGuardando(true);
    guardarAddendas(Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == ImagenDefault ? ImagenDefault : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Addendas
      </Button>

      <Card elevation={3} className="shadow">
        <CardContent className="p-0">
          {/* <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar addenda" : "Nueva addenda"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          /> */}

          <Row className="p-3">
            <Col sm={12} md={4}>
              <h6 className="text-left font-AvenirBold">
                {" "}
                {id ? "Editar addenda" : "Nueva addenda"}
              </h6>
              {/* <div class="text-center col-xs-1 p-5">
              <img
                      style={img_url == ImagenDefault ? { borderRadius: 0 } : {}}
                      sx={{ width: 105, height: 102 }}
                      src={img_url}
                    />
                    </div>
                    <p
                      className="text-center my-0 ms-3 font-AvenirBold"
                      style={{ color: PALETA.colorPrimary, cursor: "pointer" }}
                      onClick={handleOpenModal}
                    >
                      Editar
                    </p> */}
            </Col>

            <Col sm={12} md={8} className="mt-3">
              {IsLoading ? (
                <Row className="px-3">
                  <Col sm={12} className="p-3">
                    <Loading />
                  </Col>
                </Row>
              ) : (
                <Row className="p-3">
                 <Col
                    sm={12}
                    className="d-flex flex-wrap align-items-center px-3"
                  >
                    <Avatar
                      style={img_url == ImagenDefault ? { borderRadius: 0 } : {}}
                      sx={{ width: 80, height: 80 }}
                      src={img_url}
                    />
                    <p
                      className="text-center my-0 ms-3 font-AvenirBold"
                      style={{ color: PALETA.colorPrimary, cursor: "pointer" }}
                      onClick={handleOpenModal}
                    >
                      Editar
                    </p>
                  </Col>
                  <Col sm={12} className="p-3 mt-3">
                    <TextField
                      fullWidth
                      name="nombre"
                      label="Nombre de la addenda"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={
                        Errores.nombre ? "Nombre de la addenda no valido" : ""
                      }
                      onChange={handlInputChange}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  </Col>

                  <Col Col sm={12} className="p-3 pb-0 d-flex justify-content-end">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Values.is_gratis}
                            name="is_gratis"
                            onChange={(checked) => {
                              handlInputChange({
                                target: {
                                  name: "is_gratis",
                                  value: checked,
                                },
                              });
                            }}
                            tituloActivo="Addenda gratis"
                            tituloInactivo="No es gratis"
                            on
                          />
                        }
                        label="Addenda gratis"
                      />
                    </FormGroup>
                  </Col>


                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="precio"
                      label="Precio de la addenda"
                      variant="outlined"
                      value={Values.is_gratis ? "" : Values.precio}
                      error={Values.is_gratis ? "" : Errores.precio}
                      helperText={
                        Errores.precio ? "Precio de la addenda no válido" : ""
                      }
                      onChange={handlInputChange}
                      disabled={Values.is_gratis}
                    />
                  </Col>


                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="costo"
                      label="Costo de la addenda"
                      variant="outlined"
                      value={Values.costo}
                      error={Errores.costo}
                      helperText={
                        Errores.costo ? "Costo de la addenda no válido" : ""
                      }
                      onChange={handlInputChange}
                    />
                  </Col>

                 
                

                  <Col sm={12} className="p-3">
                    <TextField
                      fullWidth
                      multiline
                      maxRows={6}
                      minRows={2}
                      name="descripcion"
                      label="Descripción"
                      variant="outlined"
                      value={Values.descripcion}
                      error={Errores.descripcion}
                      helperText={
                        Errores.descripcion
                          ? "Descripción de la addenda no valida"
                          : ""
                      }
                      inputProps={{
                        maxLength: 250,
                      }}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col Col sm={12} className="p-3">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Values.is_personalizada}
                            name="is_personalizada"
                            error={Errores.is_personalizada}
                            onChange={(checked) => {
                              handlInputChange({
                                target: {
                                  name: "is_personalizada",
                                  value: checked,
                                },
                              });
                            }}
                            tituloActivo="No es una addenda personalizada"
                            tituloInactivo="Addenda personalizada"
                            on
                          />
                        }
                        label="Addenda personalizada"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Stack
            className="p-3 d-flex justify-content-end w-100"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarAddendas()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_ADDENDAS}
          Titulo="Editar imagen de previsualización"
          Descripcion="Esta es la imagen que aparecerá en la previsualización de la addenda"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
          
        />
      )}
    </>
  );
};

export default Addendas;
