import { createContext, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { getInfoPerfil } from "../Perfil/Funciones/Perfiles";
import { Backdrop, CircularProgress, Divider } from "@mui/material";
import { usePermisos } from "../Context/PermissionsContext";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import ValidadorWebMovile from "../../Lib/Funciones Generales/ValidadorWebMovile";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import Error404 from "../../Includes/Error404";
import Dashboard from "../Inicio/Dashboard";
import AccountMenu from "./MenuPerfil";
import MenuNotificaciones from "../Generales/Notificaciones/Componentes/Notificaciones";
import ErrorAccess from "./ErrorAccess";

import Perfil from "../Perfil/Perfil";
import Planes from "../Catalogos/Planes/Planes";
import Plantillas from "../Catalogos/Plantillas/Plantillas";
import Ayuda from "../Catalogos/Ayuda/Ayuda";
import Addendas from "../Catalogos/Addendas/Addendas";
import Correos from "../Configuraciones/Correos/Correos";
import Clientes from "../Catalogos/Clientes/Clientes";
import Usuarios from "../Configuraciones/Usuarios/Usuarios";
import Licencias from "../Catalogos/Licencias/Licencias";
import Facturacion from "../Configuraciones/Facturacion/Facturacion";
import Facturas from "../Catalogos/Facturas/Facturas";
import Series from "../Catalogos/Series/Series";
import GruposAcceso from "../Configuraciones/GruposDeAcceso/GruposAcceso";
import ConfGenerales from "../Configuraciones/Generales/Componentes/Generales";
import Reportes from "../Reportes/Reportes";
import EnvioMasivo from '../Catalogos/EnvioMasivo/EnvioMasivo';

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#101727",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#101727",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 5,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const IconContext = createContext();

export default function Menu() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [url, setUrl] = useState("");

  const { userPermissions, handleSetUserPermissions, IsLoading, setIsLoading } =
    usePermisos();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isTabletOrMobile = useMediaQuery("(max-width: 1224px)");

  useEffect(() => {
    setOpen(!isTabletOrMobile);
  }, [isTabletOrMobile]);

  const [Values, setValues] = useState({
    img: "",
  });

  useEffect(() => {
    setOpen(ValidadorWebMovile());
    getInfoPerfil("", true)
      .then((resp) => {
        setValues(resp.Values);
        handleSetUserPermissions(resp.permisos);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {IsLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <IconContext.Provider value={{ url, setUrl, open, setOpen }}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            color="transparent"
            style={{ boxShadow: "none", zIndex: 10 }}
          >
            <Toolbar style={open ? { paddingLeft: "0px" } : {}}>
              <div className="d-flex justify-content-between w-100 ">
                <div>
                  {open ? (
                    <DrawerHeader style={{ backgroundColor: "#FFFFFF" }}>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                          <MoreVert />
                        ) : (
                          <MoreVert />
                        )}
                      </IconButton>
                    </DrawerHeader>
                  ) : (
                    <IconButton onClick={handleDrawerOpen}>
                      <MenuIcon style={{ color: "white", zIndex: 10 }} />
                    </IconButton>
                  )}
                </div>
                <div
                  className="d-flex justify-content-end w-100 align-content-center"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <div className="p-1 d-flex align-content-center">
                    <div className="align-self-center d-none d-sm-flex d-md-block">
                      <MenuNotificaciones
                        Values={Values}
                        IsLoading={IsLoading}
                        className="align-self-center"
                      />
                    </div>
                    <div className="align-self-center d-none d-sm-flex d-md-block">
                      <AccountMenu
                        Values={Values}
                        IsLoading={IsLoading}
                        className="align-self-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            style={{
              backgroundColor: "#101727",
              height: "100%",
              color: "white",
              zIndex: 2,
            }}
          >
            <Sidebar
              openMenu={open}
              permisosMenu={userPermissions}
              IsLoading={IsLoading}
            />
          </Drawer>
          <Divider light />
          <Box
            component="main"
            sx={{ flexGrow: 1 }}
            className="p-xsm-0 p-sm-3 "
            style={{ backgroundColor: "#F9FAFC", minHeight: "100vh" }}
          >
            <div className="mb-5"></div>
            <Routes>
              {!IsLoading && (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="inicio/*" element={<Dashboard />} />
                  <Route path="perfil/*" element={<Perfil />} />
                  <Route
                    path="planes/*"
                    element={
                      userPermissions.includes("Planes") == true ? (
                        <Planes />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="plantillas/*"
                    element={
                      userPermissions.includes("Plantilla") == true ? (
                        <Plantillas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="addendas/*"
                    element={
                      userPermissions.includes("Addendas") == true ? (
                        <Addendas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="correos/*"
                    element={
                      userPermissions.includes("Correos") == true ? (
                        <Correos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="generales/*"
                    element={
                      userPermissions.includes("Generales") == true ? (
                        <ConfGenerales />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="clientes/*"
                    element={
                      userPermissions.includes("Clientes") == true ? (
                        <Clientes />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="usuariossistema/*"
                    element={
                      userPermissions.includes("Administradores") == true ? (
                        <Usuarios />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="licencias/*"
                    element={
                      userPermissions.includes("Licencias") == true ? (
                        <Licencias />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="facturacion/*"
                    element={
                      userPermissions.includes("Facturacion") == true ? (
                        <Facturacion />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="facturas/*"
                    element={
                      userPermissions.includes("Facturas") == true ? (
                        <Facturas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="reportes/*"
                    element={
                      userPermissions.includes("Reportes") == true ? (
                        <Reportes />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="envioMasivo/*"
                    element={
                      userPermissions.includes("Envío masivo") == true ? (
                        <EnvioMasivo />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="ayuda/*"
                    element={
                      userPermissions.includes("Ayuda") == true ? (
                        <Ayuda />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="series/*"
                    element={
                      userPermissions.includes("Series") == true ? (
                        <Series />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="gruposacceso/*"
                    element={
                      userPermissions.includes("GruposDeacceso") == true ? (
                        <GruposAcceso />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route path="*" element={<Error404 />} />
                </>
              )}
            </Routes>
          </Box>
        </Box>
      </IconContext.Provider>
    </>
  );
}
